import * as React from 'react'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { ICheckboxProps } from './Checkbox.types'
import { styled } from '@mui/material/styles'
import { Icon } from '@ntpkunity/controls'
import cls from 'clsx'
import { FormHelperText } from '@mui/material'

const CustomFormGroup = styled(FormGroup, {
  // shouldForwardProp: props => isPropValid(props),
})<Partial<ICheckboxProps>>(({ theme }) => ({
  '&.custom-checkbox-wrap': {
    '.u-custom-control-label': {
      margin: '0 0 12px',
      alignItems: 'start',
      '.custom-checkbox': {
        padding: 0,
        color: theme.palette.grey[300],
        marginRight: 8,
        '&:hover': {
          backgroundColor: 'transparent',
          color: theme.palette.primary.main
        },
        '&.Mui-checked': {
          color: theme.palette.primary.main,
          '~': {
            '.MuiFormControlLabel-label': {
              color: theme.palette.grey[900]
            }
          },
          svg: {
            '.bg-color': {
              stroke: theme.palette.primary.main,
              fill: theme.palette.primary.main
            }
          }
        },
        '&.Mui-disabled': {
          color: theme.palette.grey[200],
          '~': {
            '.MuiFormControlLabel-label': {
              color: theme.palette.grey[200]
            }
          },
          svg: {
            '.bg-color': {
              stroke: theme.palette.grey[200],
              fill: theme.palette.grey[200]
            }
          }
        },
        '.MuiTouchRipple-root': {
          display: 'none'
        },
        svg: {
          width: 24,
          height: 24
        }
      },
      '.MuiFormControlLabel-label': {
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body2.lineHeight,
        color: theme.palette.grey[600],
        paddingTop: 2
      }
    },
    '.u-error-text': {
      color: theme.palette.error.main,
      marginLeft: 15
    }
  }
}))

const CheckboxLabels: React.FC<ICheckboxProps> = ({
  checkBoxDefaultChecked,
  checkBoxDisabled,
  checkBoxChecked,
  checkedIcon,
  checkBoxClasses,
  checkBoxColor,
  checkBoxDiabledRipple,
  checkBoxId,
  checkBoxIcon,
  checkBoxIndeterminate,
  checkBoxIndeterminateIcon,
  checkBoxInputProps,
  checkBoxInputRef,
  onChange: onChangeProps,
  checkBoxRequired,
  checkBoxSize,
  checkBoxSx,
  checkBoxValue,
  label,
  forControlchecked,
  formControlclasses,
  formControlcomponentsProps,
  formControlcontrol,
  formControldisabled,
  formControldisableTypography,
  formControlinputRef,
  formControllabelPlacement,
  formControlname,
  formControlsx,
  formControlvalue,
  formGroupclasses,
  row,
  formGroupsx,
  theme,
  error,
  errorSx,
  testid
}) => {
  // const [isChecked, setValue] = React.useState(checkBoxChecked);

  // const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setValue(e.target.checked);
  //   onChangeProps?.(e, e.target.checked);
  // };
  return (
    <CustomFormGroup
      theme={theme}
      sx={formGroupsx}
      row={row}
      className={cls('custom-checkbox-wrap', formGroupclasses)}
    >
      <FormControlLabel
        className="u-custom-control-label"
        checked={forControlchecked}
        classes={formControlclasses}
        componentsProps={formControlcomponentsProps}
        disabled={formControldisabled || checkBoxDisabled}
        disableTypography={formControldisableTypography}
        inputRef={formControlinputRef}
        labelPlacement={formControllabelPlacement}
        name={formControlname}
        sx={formControlsx}
        value={formControlvalue}
        control={
          formControlcontrol || (
            <Checkbox
              checkedIcon={checkedIcon || <Icon name="CheckBoxChecked" />}
              disabled={checkBoxDisabled}
              checked={checkBoxChecked}
              classes={checkBoxClasses}
              color={checkBoxColor}
              disableRipple={checkBoxDiabledRipple}
              icon={checkBoxIcon}
              id={checkBoxId}
              indeterminate={checkBoxIndeterminate}
              indeterminateIcon={checkBoxIndeterminateIcon}
              inputProps={checkBoxInputProps}
              inputRef={checkBoxInputRef}
              onChange={onChangeProps}
              required={checkBoxRequired}
              size={checkBoxSize}
              sx={checkBoxSx}
              value={checkBoxValue}
              defaultChecked={checkBoxDefaultChecked}
              className="custom-checkbox"
              data-testid={testid}
            />
          )
        }
        label={label}
      />
      {error && (
        <FormHelperText sx={errorSx} className="u-error-text" id={checkBoxId}>
          {error}
        </FormHelperText>
      )}
    </CustomFormGroup>
  )
}

export default CheckboxLabels
