import { FC, useState } from 'react'
import { useTheme, TablePagination } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { Box, Button, DataTableWrap } from 'components'
import { unityTheme, Icon, Input, DataTable, Menu } from '@ntpkunity/controls'
import { IWQOrderOption, IWorkOrder } from '@models'
import { useNavigate } from 'react-router-dom'
import { Status, WorkQueueMenuOption } from '@helpers/enums'

//@ts-ignore
const SchedulePickupTable: FC<IWQOrderOption> = ({
  pageNumber,
  pageSize,
  setPageNumber,
  setPageSize,
  setColumnFilters,
  menuOptions,
  handleOrderSelection,
  data
}) => {
  const theme = useTheme()
  let navigate = useNavigate()
  const [filters, setFilters] = useState<any>()
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage)
    setColumnFilters(setQueryString(newPage, pageSize))
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value)
    setPageNumber(0)
    setColumnFilters(setQueryString(0, +event.target.value))
  }
  const setQueryString = (pgNo: number, pgSize: number): any => {
    let query_string: string = `page_number=${pgNo}&page_size=${pgSize}&multiple_order_status=${Status.SchedulePickup}`
    if (filters?.orderIdFilter?.trimStart() != null && filters?.orderIdFilter?.trimStart() != '') {
      query_string = query_string.concat(`&reference_number=${filters?.orderIdFilter}`)
    }
    if (filters?.selling_price?.trimStart() != null && filters?.selling_price?.trimStart() != '') {
      query_string = query_string.concat(`&selling_price=${filters?.selling_price}`)
    }
    if (filters?.statusFilter?.trimStart() != null && filters?.statusFilter?.trimStart() != '') {
      query_string = query_string.concat(`&order_status=${filters?.statusFilter}`)
    }
    if (filters?.assetFilter?.trimStart() != null && filters?.assetFilter?.trimStart() != '') {
      query_string = query_string.concat(`&make_model_trim=${filters?.assetFilter}`)
    }
    if (filters?.vinFilter?.trimStart() != null && filters?.vinFilter?.trimStart() != '') {
      query_string = query_string.concat(`&vin=${filters?.vinFilter}`)
    }
    if (
      filters?.stockNumberFilter?.trimStart() != null &&
      filters?.stockNumberFilter?.trimStart() != ''
    ) {
      query_string = query_string.concat(`&stock=${filters?.stockNumberFilter}`)
    }
    if (
      filters?.customerNameFilter?.trimStart() != null &&
      filters?.customerNameFilter?.trimStart() != ''
    ) {
      query_string = query_string.concat(`&customer_name=${filters?.customerNameFilter}`)
    }
    return query_string
  }
  const handleFilter = () => {
    setPageNumber(0)
    setColumnFilters(setQueryString(0, pageSize))
  }
  return (
    <>
      <DataTableWrap className="table-pagination">
        <Box className="scroll">
          <Box className="scroll-hide sh-fixed-cell spr-border" theme={unityTheme} />
        </Box>
        <DataTable
          theme={unityTheme}
          variant="basic"
          theadChildren={
            <>
              <TableRow>
                <TableCell className="img-cell">
                  <Icon name="ImageIcon" />
                </TableCell>
                <TableCell id="descriptionHead">Deal ID</TableCell>
                <TableCell id="sellingPrice">Selling Price</TableCell>
                <TableCell id="priceHead">Year / Make / Model / Trim</TableCell>
                <TableCell id="installationModeHead">VIN</TableCell>
                <TableCell id="supplierHead">Stock Number</TableCell>
                <TableCell id="supplierHead">Customer Name</TableCell>
                <TableCell id="categoryHead">Status</TableCell>
                <TableCell className="action-cell fixed-cell" />
              </TableRow>
              <TableRow className="filters-row">
                <TableCell className="img-cell"></TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'orderIdFilter'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, orderIdFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.orderIdFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      id="descriptionFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'SellingPriceFilter'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, selling_price: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.selling_price?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      id="supplierFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'assetFilter'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, assetFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.assetFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      id="priceFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'vinFilter'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, vinFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.vinFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      id="installationModeFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'stockNumberFilter'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, stockNumberFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.stockNumberFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      id="supplierFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'customerNameFilter'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, customerNameFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.customerNameFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      id="supplierFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'statusFilter'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, statusFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.statusFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      id="categoryFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell className="action-cell fixed-cell" />
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              {data?.result?.map((workOrder: IWorkOrder, index: number) => (
                <TableRow
                  key={index}
                  className="child-tr"
                  onDoubleClick={(_e: any) => {
                    navigate(
                      '/dms/create-deal-ui/' +
                        workOrder.vin +
                        '/' +
                        workOrder.customer_reference_id +
                        '/' +
                        workOrder.reference_number
                    )
                  }}
                >
                  <TableCell className="img-cell">
                    <img
                      className="prd-img"
                      src={workOrder?.photo_urls?.[0]?.location.toString()}
                      alt="Car"
                    />
                  </TableCell>
                  <TableCell id={'order_id' + workOrder?.reference_number}>
                    {workOrder?.reference_number}
                  </TableCell>
                  <TableCell id={'selling_price' + workOrder?.selling_price}>
                    {workOrder.selling_price?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })}
                  </TableCell>
                  <TableCell
                    id={
                      'assets' +
                      workOrder?.year +
                      ' ' +
                      workOrder?.make +
                      ' ' +
                      workOrder?.model +
                      ' ' +
                      workOrder?.trim_description
                    }
                  >
                    {workOrder?.year +
                      ' ' +
                      workOrder?.make +
                      ' ' +
                      workOrder?.model +
                      ' ' +
                      workOrder?.trim_description}
                  </TableCell>
                  <TableCell id={'vin' + workOrder?.vin}>{workOrder?.vin}</TableCell>
                  <TableCell>{workOrder?.stock_number}</TableCell>
                  <TableCell id={'name' + workOrder?.first_name}>
                    {`${workOrder?.first_name || ''} ${workOrder?.middle_name || ''} ${
                      workOrder?.last_name || ''
                    }`}
                  </TableCell>
                  <TableCell id={'status' + workOrder?.status}>{workOrder?.status}</TableCell>
                  <TableCell className="action-cell fixed-cell">
                    <Menu
                      theme={theme}
                      disablePortal
                      options={menuOptions.map((option: any) => {
                        return {
                          optionText:
                            option.optionText == WorkQueueMenuOption.EDIT_ORDER
                              ? 'Schedule'
                              : option.optionText,
                          optionkey: option.optionkey,
                          optionValue: workOrder
                        }
                      })}
                      handleOptionClick={handleOrderSelection}
                      render={(onMenuSelection: any) => (
                        <Button
                          defaultBtn
                          iconText={<Icon name="MoreIcon" />}
                          onClick={onMenuSelection}
                        />
                      )}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </>
          }
        />

        {data && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20]}
            component="div"
            count={data?.total_results ?? -1}
            rowsPerPage={pageSize}
            page={pageNumber}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </DataTableWrap>
    </>
  )
}
export default SchedulePickupTable
