import { FC, useState } from 'react'
import { useTheme } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import {
  IBoxProps,
  Textarea,
  Grid,
  Typography,
  Box,
  Button,
  Radio,
  unityTheme
} from '@ntpkunity/controls'
import { IFeedback } from '@models'
import { styled } from '@mui/system'
import { UseMutation_sendFeedbackEmail } from '@apis/feedback.service'
import { ValidationMessages } from '@helpers/enums'
import { LayoutWithSideNav } from '@styles/layout'
import FeedbackImg from '@public/assets/images/feedback-img.svg'
import Amazing from '@public/assets/images/feedback-ratings/amazing.svg'
import Good from '@public/assets/images/feedback-ratings/good.svg'
import Okay from '@public/assets/images/feedback-ratings/okay.svg'
import Bad from '@public/assets/images/feedback-ratings/bad.svg'
import Terrible from '@public/assets/images/feedback-ratings/terrible.svg'

const FeedbackStyle = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  padding: '5vh 0',
  '.feedback-description': {
    borderRight: '1px solid' + theme.palette.divider,
    '.description': {
      maxWidth: 440,
      display: 'grid',
      margin: 'auto',

      img: {
        margin: '0 auto 40px auto',
        height: '40vh',
        display: 'block'
      },

      '.mini-text': {
        color: theme.palette.grey[600]
      }
    }
  },

  '.submit-feedback': {
    maxWidth: 440,
    display: 'grid',
    margin: 'auto',
    '.MuiInputBase-input': {
      height: '140px !important'
    },
    '.fb-btn-group': {
      '.btn': {
        padding: '16px !important',
        borderColor: theme.palette.grey[100],
        fontSize: theme.typography.caption.fontSize,
        color: theme.palette.grey[600],
        display: 'inline-block',
        fontWeight: theme.typography.fontWeightMedium,
        backgroundColor: 'transparent',

        '&:before': {
          backgroundColor: '#FFCC00',
          display: 'inline-block',
          width: 32,
          height: 32,
          borderRadius: 32,
          position: 'absolute',
          content: "''",
          top: 20,
          right: 20,
          zIndex: -1,
          opacity: 0,
          visibility: 'hidden'
        },

        img: {
          clear: 'both',
          display: 'block',
          margin: '0 auto 8px auto',
          opacity: 0.55,
          transition: 'all 0.3s'
        },

        '&:hover, &:focus': {
          borderColor: theme.palette.common.black,
          color: theme.palette.common.black,
          backgroundColor: 'transparent',

          img: {
            opacity: 1
          }
        },

        '&.selected': {
          borderColor: theme.palette.common.black,
          color: theme.palette.common.black,
          fontWeight: theme.customVariables.fontWeightSemiBold,

          '&:before': {
            opacity: 1,
            visibility: 'visible'
          },

          img: {
            opacity: 1
          }
        }
      }
    },
    '.u-form-group': {
      '.MuiFormControl-root': {
        '.textarea': {
          '.MuiInputBase-root': {
            '.MuiInputBase-input::placeholder': {
              opacity: 0.38
            }
          }
        }
      }
    },
    '.fb-radio-group': {
      width: '100%',
      display: 'inline-block',
      paddingBottom: 22,
      marginBottom: 34,
      borderBottom: '1px solid' + theme.palette.divider
    }
  }
}))

const Feedback: FC = () => {
  const theme = useTheme()
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors }
  } = useForm<IFeedback>()
  const { mutate: sendFeedback } = UseMutation_sendFeedbackEmail()
  const [isDisabled, setIsDisabled] = useState(false)
  function onSubmit(data: any) {
    var access_token_local: any = JSON.parse(localStorage?.getItem('settings') as string)
    setIsDisabled(true)
    data.name = access_token_local?.user_name
    data.email = access_token_local?.email
    data.phone = '03331234567'
    data.subject = 'Lane - Client Feedback'
    data.rating = getValues('rating')
    data.follow_up = getValues('follow_up')
    sendFeedback(data, {
      onSuccess: () => {
        reset()
        setIsDisabled(false)
      },
      onerror: () => {
        setIsDisabled(false)
      }
    })
    reset()
  }

  return (
    <LayoutWithSideNav theme={theme}>
      <FeedbackStyle theme={theme}>
        <Grid theme={theme} container spacing={0}>
          <Grid theme={theme} item sm={6} xs={12} className="feedback-description">
            <Box theme={theme} className="description">
              <img src={FeedbackImg} alt="FeedbackImg" />
              <Typography
                theme={theme}
                variant="h2"
                component="h2"
                data-testid="feedbackHeading"
                sx={{ mb: 2 }}
                children="Your feedback matters to us! We’d love to hear from you."
              />
              <Typography
                theme={theme}
                className="mini-text"
                variant="body1"
                component="p"
                data-testid="feedbackContent"
                children="Please feel free to drop us a suggestion and give us your feedback.We will look into it and be in touch with you as soon as possible."
              />
            </Box>
          </Grid>
          <Grid theme={theme} item sm={6} xs={12}>
            <Box theme={theme} className="submit-feedback">
              <Typography
                theme={theme}
                variant="subtitle2"
                component="p"
                sx={{ mb: 1.5 }}
                children="How is your experience about using our product?"
              />
              <Grid theme={theme} container spacing={1} className="fb-btn-group" sx={{ mb: 4 }}>
                <Grid theme={theme} item xs={2.4}>
                  <Button
                    fullWidth
                    id="first"
                    theme={theme}
                    iconText={
                      <>
                        <img src={Amazing} alt="Amazing" /> Amazing
                      </>
                    }
                    value={'Amazing'}
                    onClick={(e) => {
                      e.currentTarget.classList.toggle('selected')
                      const secondElement = document.getElementById('second') as HTMLElement
                      const thirdElement = document.getElementById('third') as HTMLElement
                      const fouthElement = document.getElementById('fourth') as HTMLElement
                      const fifthElement = document.getElementById('fifth') as HTMLElement

                      secondElement.classList.remove('selected')
                      thirdElement.classList.remove('selected')
                      fouthElement.classList.remove('selected')
                      fifthElement.classList.remove('selected')

                      setValue('rating', e.currentTarget.value)
                    }}
                  />
                </Grid>
                <Grid theme={theme} item xs={2.4}>
                  <Button
                    fullWidth
                    id="second"
                    theme={unityTheme}
                    iconText={
                      <>
                        <img src={Good} alt="Good" /> Good
                      </>
                    }
                    value={'Good'}
                    onClick={(e) => {
                      e.currentTarget.classList.toggle('selected')
                      const firstElement = document.getElementById('first') as HTMLElement
                      const thirdElement = document.getElementById('third') as HTMLElement
                      const fouthElement = document.getElementById('fourth') as HTMLElement
                      const fifthElement = document.getElementById('fifth') as HTMLElement

                      firstElement.classList.remove('selected')
                      thirdElement.classList.remove('selected')
                      fouthElement.classList.remove('selected')
                      fifthElement.classList.remove('selected')
                      setValue('rating', e.currentTarget.value)
                    }}
                  />
                </Grid>
                <Grid theme={theme} item xs={2.4}>
                  <Button
                    fullWidth
                    theme={theme}
                    id="third"
                    iconText={
                      <>
                        <img src={Okay} alt="Okay" /> Okay
                      </>
                    }
                    value={'Okay'}
                    onClick={(e) => {
                      e.currentTarget.classList.toggle('selected')
                      document
                      const firstElement = document.getElementById('first') as HTMLElement
                      const secondElement = document.getElementById('second') as HTMLElement
                      const fouthElement = document.getElementById('fourth') as HTMLElement
                      const fifthElement = document.getElementById('fifth') as HTMLElement

                      firstElement.classList.remove('selected')
                      secondElement.classList.remove('selected')
                      fouthElement.classList.remove('selected')
                      fifthElement.classList.remove('selected')
                      setValue('rating', e.currentTarget.value)
                    }}
                  />
                </Grid>
                <Grid theme={theme} item xs={2.4}>
                  <Button
                    fullWidth
                    theme={theme}
                    id="fourth"
                    iconText={
                      <>
                        <img src={Bad} alt="Bad" /> Bad
                      </>
                    }
                    value={'Bad'}
                    onClick={(e) => {
                      e.currentTarget.classList.toggle('selected')
                      const firstElement = document.getElementById('first') as HTMLElement
                      const secondElement = document.getElementById('second') as HTMLElement
                      const thirdElement = document.getElementById('third') as HTMLElement
                      const fifthElement = document.getElementById('fifth') as HTMLElement

                      firstElement.classList.remove('selected')
                      secondElement.classList.remove('selected')
                      thirdElement.classList.remove('selected')
                      fifthElement.classList.remove('selected')
                      setValue('rating', e.currentTarget.value)
                    }}
                  />
                </Grid>
                <Grid theme={theme} item xs={2.4}>
                  <Button
                    fullWidth
                    theme={theme}
                    id="fifth"
                    iconText={
                      <>
                        <img src={Terrible} alt="Terible" /> Terrible
                      </>
                    }
                    value={'Terrible'}
                    onClick={(e) => {
                      e.currentTarget.classList.toggle('selected')
                      const firstElement = document.getElementById('first') as HTMLElement
                      const secondElement = document.getElementById('second') as HTMLElement
                      const thirdElement = document.getElementById('third') as HTMLElement
                      const fourthElement = document.getElementById('fourth') as HTMLElement

                      firstElement.classList.remove('selected')
                      secondElement.classList.remove('selected')
                      thirdElement.classList.remove('selected')
                      fourthElement.classList.remove('selected')
                      setValue('rating', e.currentTarget.value)
                    }}
                  />
                </Grid>
              </Grid>
              <Typography
                theme={theme}
                variant="subtitle2"
                component="p"
                sx={{ mb: 1.5 }}
                children="Give us your feedback"
              />
              <Box theme={theme} sx={{ mb: 5 }}>
                <Controller
                  name="email_text"
                  control={control}
                  defaultValue={''}
                  rules={{
                    required: ValidationMessages.FEEDBACK_REQUIRED,
                    maxLength: {
                      value: 1000,
                      message: ValidationMessages.FEEDBACK_MAX_LENGTH
                    }
                  }}
                  render={({ field }) => (
                    <Textarea
                      theme={theme}
                      fullWidth={true}
                      rows={2}
                      placeholder={'Type here..'}
                      type="text"
                      {...field}
                      id={'feedback'}
                      error={errors?.email_text?.message}
                      data-testid={'feedback'}
                    />
                  )}
                />
              </Box>
              <Box theme={theme} className="fb-radio-group">
                <Grid container theme={theme} rowSpacing={0} columnSpacing={2}>
                  <Grid item theme={theme} md={8} sm={12}>
                    <Typography
                      theme={theme}
                      variant="subtitle2"
                      component="p"
                      sx={{ mb: 1.5 }}
                      children="May we follow you up on your feedback?"
                    />
                  </Grid>
                  <Grid item theme={theme} md={4} sm={12} sx={{ textAlign: 'right' }}>
                    <Controller
                      name="follow_up"
                      control={control}
                      defaultValue={'Yes'}
                      render={({ field }) => (
                        <Radio
                          theme={theme}
                          row={true}
                          options={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Button
                theme={theme}
                type={'submit'}
                primary
                text={'Submit Feedback'}
                fullWidth
                onClick={handleSubmit(onSubmit)}
                disabled={isDisabled}
              />
            </Box>
          </Grid>
        </Grid>
      </FeedbackStyle>
    </LayoutWithSideNav>
  )
}

export default Feedback
