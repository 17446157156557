import { FC, Key, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Accordion, AccordionDetails, AccordionSummary, useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import { Box, Grid, Icon, Typography } from '@ntpkunity/controls'
import { DetailCard, PageHeader, ProductBanner } from '@components'
import { useGetLeadDetails } from '@apis/lead-management.service'
import { formatCurrency } from '@helpers/methods'
import { useStoreContext } from '@store/storeContext'
import { useQueryClient } from 'react-query'
import { AddressType, QueryKeys } from '@helpers/enums'

interface Filters {
  [key: string]: string
}

const defaultFilters: Filters = {
  emailAddressFilter: '',
  yearMakeModelTrimFilter: '',
  leadStatusFilter: '',
  leadIdFilter: '',
  firstNameFilter: '',
  lastNameFilter: '',
  contactNumberFilter: '',
  preferredCommunicationFilter: '',
  vinFilter: '',
  stockFilter: '',
  createdAtFilter: '',
  lastUpdatedAtFilter: '',
  lastSyncedStatusFilter: '',
  leadSourceFilter: '',
  dealIdFilter: '',
  dealStatusFilter: '',
  dealerNameFilter: ''
}

const LeadSummary: FC = () => {
  const theme = useTheme()
  const { id } = useParams<{ id: string }>()
  const [defaultCurrency, setDefaultCurrency] = useState('')
  const [leadData, setLeadData] = useState<Record<string, any> | null>(null)
  const { states } = useStoreContext()
  const queryClient = useQueryClient()
  const filtersWithId = {
    ...defaultFilters,
    leadIdFilter: id || ''
  }
  const { data } = useGetLeadDetails(states?.dealerAndLenderInfo?.id, -1, -1, filtersWithId)
  const [showAccordionDetail, setShowAccordionDetail] = useState(false)

  useEffect(() => {
    if (data?.data && id) {
      const lead = data?.data?.[0]
      if (lead) {
        const vehicle = lead?.data?.vehicle_details?.find(
          (vehicle: any) => vehicle?.id === lead?.data?.vehicle_details?.[0]?.id
        )
        setLeadData({ ...lead, vehicleDetails: vehicle })
      } else {
        console.error(`No lead found for id: ${id}`)
        setLeadData(null)
      }
    }
  }, [data?.data, id])

  const currencies: any = queryClient.getQueryData([
    QueryKeys.GET_ALL_CURRENCIES,
    states?.lenderInfo?.id
  ])
  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === states?.lenderInfo?.default_currency
    )
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0 ? filteredCurrency.symbol || '$' : '$'
    )
  }, [currencies])

  const customerDetails = leadData?.data?.customer_details
  const customerAddress = customerDetails?.customer_addresses?.find(
    (address: any) => address.address_type === AddressType.MAILING
  )
  const vehicleDetails = leadData?.data?.vehicle_details?.find(
    (vehicleDetail: any) => vehicleDetail?.lead_id === id
  )
  const financeDetails = vehicleDetails?.finance
  const orderAssets = vehicleDetails?.order_asset
  return (
    <LayoutWithSideNav theme={theme}>
      <Box theme={theme} sx={{ mb: 2.5 }}>
        <PageHeader className="main-page-header border-bottom" theme={theme}>
          <Box flexGrow={'1'} theme={theme}>
            <Typography theme={theme} variant="h2" component="h2" className="title">
              Lead Summary
            </Typography>
            <Typography theme={theme} variant="body2" component="p" className="caption">
              You can review the lead details below and reach out to the customer
            </Typography>
          </Box>
        </PageHeader>
      </Box>
      <ProductBanner theme={theme} className="product-banner">
        <Box theme={theme} className="pb-wrap">
          <Box theme={theme} className="thumbnail">
            <img src={orderAssets?.photo_urls?.[0]?.location || vehicleDetails?.photo_urls?.[0]?.location || ''} alt="Product" />
          </Box>
          <Box theme={theme} className="product-details">
            <Grid theme={theme} container columnSpacing={2}>
              <Grid theme={theme} item sm={6} xs={12}>
                <Box theme={theme} className="title-md">
                  {`${orderAssets?.make || vehicleDetails?.make || '-'} ${
                    orderAssets?.model || vehicleDetails?.model || '-'
                  }`}
                </Box>
                <Box theme={theme} className="title-lg">
                  {orderAssets?.trim_description || vehicleDetails?.trim_description || '-'}
                </Box>
                <Box theme={theme} className="title-sm">
                  Lead ID:<b>{vehicleDetails?.lead_id || '-'}</b>, Status:{' '}
                  {vehicleDetails?.status || '-'}
                </Box>
                <Box theme={theme} className="title-sm">
                  VIN: <b>{orderAssets?.vin || vehicleDetails?.vin || '-'}</b>
                </Box>
              </Grid>
              <Grid theme={theme} item sm={6} xs={12} textAlign="right">
                <Box theme={theme} className="actions-wrap">
                  <Box theme={theme} className="action-details">
                    <Box theme={theme} className="title-md">
                      Net Financed Amount
                    </Box>
                    <Box theme={theme} className="title-lg">
                      {defaultCurrency}
                      {vehicleDetails?.finance?.net_finance_amount != null
                        ? Math.abs(vehicleDetails?.finance?.net_finance_amount).toLocaleString(
                            undefined,
                            {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2
                            }
                          )
                        : '-'}
                    </Box>
                    <Box theme={theme} className="title-sm">
                      Type:{' '}
                      <b>
                        {vehicleDetails?.finance?.contract_term &&
                        vehicleDetails?.finance?.finance_type
                          ? `${vehicleDetails?.finance?.contract_term} Months ${vehicleDetails?.finance?.finance_type}`
                          : '-'}
                      </b>
                      , Rate:{' '}
                      <b>
                        {vehicleDetails?.finance?.base_rate != null
                          ? `${vehicleDetails?.finance?.base_rate.toLocaleString(undefined, {
                              maximumFractionDigits: 4,
                              minimumFractionDigits: 4
                            })}%`
                          : '-'}
                      </b>
                    </Box>
                    <Box theme={theme} className="title-sm">
                      <>Est. Periodic Payment: </>
                      <b>
                        {defaultCurrency}
                        {vehicleDetails?.finance?.estimated_monthly_payment != null
                          ? Math.abs(
                              vehicleDetails?.finance?.estimated_monthly_payment
                            ).toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2
                            })
                          : '-'}
                      </b>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </ProductBanner>
      <Grid theme={theme} className="container" container spacing={2}>
        <Grid theme={theme} item xl={8} lg={8} md={8} sm={12} xs={12}>
          <DetailCard className="detail-card" mb={2}>
            <Typography theme={theme} mb={2} component="h4" variant="h4">
              Customer Details
            </Typography>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Name"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={
                    [customerDetails?.first_name, customerDetails?.last_name]
                      ?.filter(Boolean)
                      ?.join(' ') || '-'
                  }
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Address"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={
                    [
                      customerAddress?.address_line_1,
                      customerAddress?.city,
                      customerAddress?.state_name,
                      customerAddress?.zip_code
                    ]
                      ?.filter(Boolean)
                      ?.join(', ') || '-'
                  }
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Email"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={customerDetails?.email || '-'}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Credit Score"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={vehicleDetails?.finance?.credit_rating || '-'}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Mobile Phone Number"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={customerDetails?.mobile_number || '-'}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Preferred Communication"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={customerDetails?.preferred_contact || '-'}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="License Number"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={customerDetails?.license_info?.license_number || '-'}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Date of Birth"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={
                    customerDetails?.license_info?.date_of_birth?.split('T')[0] ||
                    customerDetails?.date_of_birth?.split('T')[0] ||
                    '-'
                  }
                />
              </Grid>
            </Grid>
          </DetailCard>
          <DetailCard className="detail-card" mb={2}>
            <Typography theme={theme} mb={2} component="h4" variant="h4">
              Asset Details
            </Typography>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Asset"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={`${vehicleDetails?.make || '-'} ${vehicleDetails?.model || '-'} ${
                    vehicleDetails?.trim_description || '-'
                  }`}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Asset Condition"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={`${vehicleDetails?.type || '-'}`}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Stock Number"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={`${
                    orderAssets?.stock_number
                      ? orderAssets?.stock_number
                      : vehicleDetails?.stock_number || '-'
                  }`}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Current Mileage"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={vehicleDetails?.mileage ? `${vehicleDetails?.mileage} Miles` : '-'}
                />
              </Grid>
            </Grid>
          </DetailCard>
          {vehicleDetails?.trade_in && (
            <DetailCard className="detail-card" mb={2}>
              <Box className="card-body" theme={theme}>
                <Box theme={theme} mb={2}>
                  <Typography theme={theme} component="h4" variant="h4" children="Trade-In" />
                  <Grid theme={theme} className="container" container>
                    <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
                      <Typography
                        theme={theme}
                        variant="body2"
                        component="h3"
                        className="label"
                        children={
                          <>
                            {vehicleDetails?.trade_in?.year || '-'}{' '}
                            {vehicleDetails?.trade_in?.make || '-'}
                            {vehicleDetails?.trade_in?.model || '-'}
                          </>
                        }
                      />
                      <Typography
                        theme={theme}
                        variant="subtitle1"
                        component="div"
                        className="card-title"
                        children={vehicleDetails?.trade_in?.trim || '-'}
                      />
                    </Grid>
                    {vehicleDetails?.trade_in?.asset_image?.length > 0 && (
                      <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
                        <Box theme={theme} className="thumbnail">
                          <img
                            src={vehicleDetails?.trade_in?.asset_image?.[0] || ''}
                            alt="Product"
                          />
                        </Box>
                      </Grid>
                    )}
                    <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
                      <Box theme={theme} className="priceList">
                        <Typography
                          theme={theme}
                          className="card-title"
                          mb={1}
                          component="h4"
                          variant="h4"
                          children={
                            vehicleDetails?.trade_in?.trade_in_amount != null ? (
                              <>
                                {defaultCurrency}
                                {Math.abs(vehicleDetails?.trade_in?.trade_in_amount).toLocaleString(
                                  undefined,
                                  {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                  }
                                )}
                              </>
                            ) : (
                              '-'
                            )
                          }
                        />
                        <Box theme={theme} className="price-detail">
                          <Typography
                            theme={theme}
                            className="detail offer"
                            component="span"
                            variant="caption"
                            children="Our Offer:&nbsp;"
                          />
                          <Typography
                            theme={theme}
                            className="detail price"
                            component="span"
                            variant="caption"
                            children={
                              <>
                                {vehicleDetails?.finance?.our_offer != null ? (
                                  <>
                                    {defaultCurrency}
                                    {Math.abs(vehicleDetails?.finance?.our_offer).toLocaleString(
                                      undefined,
                                      {
                                        maximumFractionDigits: 4,
                                        minimumFractionDigits: 2
                                      }
                                    )}
                                  </>
                                ) : (
                                  '-'
                                )}
                              </>
                            }
                          />
                        </Box>
                        <Box theme={theme} className="price-detail">
                          <Typography
                            theme={theme}
                            className="detail offer"
                            component="span"
                            variant="caption"
                            children={<>Lease Balance:&nbsp; </>}
                          />
                          <Typography
                            theme={theme}
                            className="detail price"
                            component="span"
                            variant="caption"
                            children={
                              <>
                                {vehicleDetails?.trade_in?.payoff_amount != null ? (
                                  <>
                                    {defaultCurrency}
                                    {Math.abs(
                                      vehicleDetails?.trade_in?.payoff_amount
                                    ).toLocaleString(undefined, {
                                      maximumFractionDigits: 4,
                                      minimumFractionDigits: 2
                                    })}
                                  </>
                                ) : (
                                  '-'
                                )}
                              </>
                            }
                          />
                        </Box>
                        <Box theme={theme} className="price-detail">
                          <Typography
                            theme={theme}
                            className="detail offer"
                            component="span"
                            variant="caption"
                            children="Net Trade-In Amount:&nbsp;"
                          />
                          <Typography
                            theme={theme}
                            className="detail price"
                            component="span"
                            variant="caption"
                            children={
                              vehicleDetails?.trade_in?.trade_in_amount != null ? (
                                <>
                                  {defaultCurrency}
                                  {Math.abs(
                                    vehicleDetails?.trade_in?.trade_in_amount
                                  ).toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                  })}
                                </>
                              ) : (
                                '-'
                              )
                            }
                          />
                        </Box>
                        <Box theme={theme} className="price-detail">
                          <Typography
                            theme={theme}
                            className="detail offer"
                            component="span"
                            variant="caption"
                            children="Estimated Mileage:&nbsp;"
                          />
                          <Typography
                            theme={theme}
                            className="detail price"
                            component="span"
                            variant="caption"
                            children={
                              <> {vehicleDetails?.trade_in?.mileage?.toLocaleString() || '-'} </>
                            }
                          />
                        </Box>
                        <Box theme={theme} className="price-detail">
                          <Typography
                            theme={theme}
                            className="detail offer"
                            component="span"
                            variant="caption"
                            children="Condition:&nbsp;"
                          />
                          <Typography
                            theme={theme}
                            className="detail price"
                            component="span"
                            variant="caption"
                            children={<> {vehicleDetails?.trade_in?.condition || '-'} </>}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </DetailCard>
          )}
          <DetailCard className="detail-card" mb={2}>
            <Typography theme={theme} mb={2} component="h4" variant="h4">
              Search Details
            </Typography>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Keyword"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={
                    leadData && leadData?.data?.vehicle_search_criteria?.length > 0
                      ? leadData.data.vehicle_search_criteria
                          .map(
                            (
                              criteria: { makes: any[]; models: any[]; trims: any[] },
                              index: Key | null | undefined
                            ) => {
                              const parts = [
                                criteria.makes?.[0],
                                criteria.models?.[0],
                                criteria.trims?.[0]
                              ].filter(Boolean)

                              return parts.length > 0 ? (
                                <div key={index}>{parts.join(', ')}</div>
                              ) : null
                            }
                          )
                          .filter(Boolean)
                      : '-'
                  }
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Asset Condition"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={`${
                    leadData?.data?.vehicle_search_criteria?.[0]?.asset_conditions[0] || '-'
                  }`}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Selling Price"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={
                    leadData?.data?.vehicle_details?.[0]?.internet_price ? (
                      <>
                        {defaultCurrency}
                        {Math.abs(
                          leadData?.data?.vehicle_details?.[0]?.internet_price
                        ).toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                      </>
                    ) : (
                      '-'
                    )
                  }
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Year"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={leadData?.data?.vehicle_details?.[0]?.year ?? '-'}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Make/Model/Trim"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={
                    leadData && leadData?.data?.vehicle_search_criteria?.length > 0
                      ? leadData.data.vehicle_search_criteria
                          .map(
                            (
                              criteria: { makes: any[]; models: any[]; trims: any[] },
                              index: Key | null | undefined
                            ) => {
                              const parts = [
                                criteria.makes?.[0],
                                criteria.models?.[0],
                                criteria.trims?.[0]
                              ].filter(Boolean)

                              return parts.length > 0 ? (
                                <div key={index}>{parts.join(', ')}</div>
                              ) : null
                            }
                          )
                          .filter(Boolean)
                      : '-'
                  }
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="VIN"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={`${leadData?.data?.vehicle_search_criteria?.[0]?.vin || '-'}`}
                />
              </Grid>
            </Grid>
            <Grid theme={theme} className="container" container>
              <Grid theme={theme} item sm={3} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Stock Number"
                />
              </Grid>
              <Grid theme={theme} item sm={9} xs={12}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={`${leadData?.data?.vehicle_search_criteria?.[0]?.stock || '-'}`}
                />
              </Grid>
            </Grid>
          </DetailCard>
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12} theme={theme}>
          <DetailCard className="detail-card payment-bd" mb={3}>
            <Box className="card-header" theme={theme}>
              <Typography component="h3" variant="h3" theme={theme}>
                {vehicleDetails?.finance?.contract_term && vehicleDetails?.finance?.finance_type
                  ? `${vehicleDetails?.finance?.contract_term} Months ${vehicleDetails?.finance?.finance_type}`
                  : '-'}
              </Typography>
              <Typography className="text" component="span" variant="body2" theme={theme}>
                Payment Breakdown
              </Typography>
            </Box>
            <Box className="line" theme={theme}>
              <hr />
            </Box>
            <Box className="card-body" theme={theme}>
              <Box className="summary-row toggle" sx={{ mb: 2 }} theme={theme}>
                <Box className="row" theme={theme}>
                  <Box className="center" theme={theme}>
                    <span className="card-text">
                      <b>Periodic Payment</b>
                    </span>
                    <Icon
                      name="ChevronDown"
                      className={showAccordionDetail ? 'toggle-btn ch-up' : 'toggle-btn'}
                      onClick={() => setShowAccordionDetail(!showAccordionDetail)}
                    />
                  </Box>
                  <Typography className="card-text" component="span" variant="body2" theme={theme}>
                    <b>
                      {financeDetails?.estimated_monthly_payment
                        ? defaultCurrency +
                          formatCurrency(
                            Math.abs(
                              financeDetails?.estimated_monthly_payment +
                                (financeDetails?.monthly_sales_use_tax || 0)
                            ),
                            {
                              maximumFractionDigits: 4,
                              minimumFractionDigits: 2
                            }
                          )
                        : '-'}
                    </b>
                  </Typography>
                </Box>
              </Box>
              <Accordion expanded={showAccordionDetail}>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" />
                <AccordionDetails sx={{ ml: 2, mb: 2 }}>
                  <Box theme={theme} className="row" mb={2}>
                    <Typography
                      theme={theme}
                      className="card-text text"
                      component="span"
                      variant="body2"
                    >
                      Net Periodic Payment
                    </Typography>
                    <Typography
                      theme={theme}
                      className="card-text text"
                      component="span"
                      variant="body2"
                    >
                      <b>
                        {financeDetails?.estimated_monthly_payment
                          ? defaultCurrency +
                            formatCurrency(Math.abs(financeDetails?.estimated_monthly_payment), {
                              maximumFractionDigits: 4,
                              minimumFractionDigits: 2
                            })
                          : '-'}
                      </b>
                    </Typography>
                  </Box>
                  <Box theme={theme} className="row">
                    <Typography
                      theme={theme}
                      className="card-text text"
                      component="span"
                      variant="body2"
                    >
                      Tax Amount
                    </Typography>
                    <Typography
                      theme={theme}
                      className="card-text text"
                      component="span"
                      variant="body2"
                    >
                      <b>
                        {financeDetails?.monthly_sales_use_tax
                          ? defaultCurrency +
                            formatCurrency(Math.abs(financeDetails?.monthly_sales_use_tax), {
                              maximumFractionDigits: 4,
                              minimumFractionDigits: 2
                            })
                          : '-'}
                      </b>
                    </Typography>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Box className="row" sx={{ mb: 2 }} theme={theme}>
                <Typography className="card-text" component="span" variant="body2" theme={theme}>
                  Terms
                </Typography>
                <Typography className="card-text" component="span" variant="body2" theme={theme}>
                  {vehicleDetails?.finance?.contract_term
                    ? `${vehicleDetails?.finance?.contract_term} Months`
                    : '-'}
                </Typography>
              </Box>

              <Box className="row" sx={{ mb: 2 }} theme={theme}>
                <Typography className="card-text" component="span" variant="body2" theme={theme}>
                  Rate
                </Typography>
                <Typography className="card-text" component="span" variant="body2" theme={theme}>
                  {vehicleDetails?.finance?.base_rate != null
                    ? `${vehicleDetails?.finance?.base_rate.toLocaleString(undefined, {
                        maximumFractionDigits: 4,
                        minimumFractionDigits: 4
                      })}%`
                    : '-'}
                </Typography>
              </Box>

              <Box className="line" theme={theme}>
                <hr />
              </Box>

              <Box className="row" sx={{ mb: 2 }} theme={theme}>
                <Typography className="card-text" component="span" variant="body2" theme={theme}>
                  Selling Price
                </Typography>
                <Typography className="card-text" component="span" variant="body2" theme={theme}>
                  {vehicleDetails?.internet_price != null ? (
                    <>
                      {defaultCurrency}
                      {Math.abs(vehicleDetails?.internet_price).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </>
                  ) : (
                    '-'
                  )}
                </Typography>
              </Box>

              <Box className="row" theme={theme}>
                <Typography className="card-text" component="span" variant="body2" theme={theme}>
                  Down Payment
                </Typography>
                <Typography className="card-text" component="span" variant="body2" theme={theme}>
                  {defaultCurrency}
                  {vehicleDetails?.finance?.down_payment != null
                    ? Math.abs(vehicleDetails?.finance?.down_payment).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })
                    : '-'}
                </Typography>
              </Box>

              <Box className="summary-row toggle" sx={{ mt: 2 }} theme={theme}>
                <Box className="row" theme={theme}>
                  <Box className="center" theme={theme}>
                    <span className="card-text">Trade-In Equity/Inequity</span>
                  </Box>
                  <Typography className="card-text" component="span" variant="body2" theme={theme}>
                    <b>
                      {vehicleDetails?.trade_in?.trade_in_amount != null ? (
                        <>
                          {defaultCurrency}
                          {Math.abs(vehicleDetails?.trade_in?.trade_in_amount).toLocaleString(
                            undefined,
                            {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2
                            }
                          )}
                        </>
                      ) : (
                        '-'
                      )}
                    </b>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </DetailCard>
        </Grid>
      </Grid>
    </LayoutWithSideNav>
  )
}

export default LeadSummary
