import { styled } from '@mui/material'
import { Box, IBoxProps } from '@ntpkunity/controls'

const ChatNotificationWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.chats-notification-wrap': {
    backgroundColor: theme.palette.common.white,
    borderRadius: 24,
    width: 400,
    maxWidth: '100%',
    boxShadow:
      '0px 533px 149px 0px rgba(0, 0, 0, 0.00), 0px 341px 137px 0px rgba(0, 0, 0, 0.01), 0px 192px 115px 0px rgba(0, 0, 0, 0.05), 0px 85px 85px 0px rgba(0, 0, 0, 0.09), 0px 21px 47px 0px rgba(0, 0, 0, 0.10);',
    border: '1px solid' + theme.palette.grey[50],
    padding: 16,
    marginBottom: 16,
    overflow: 'hidden',
    '.all-notifications': {
      height: 'calc(100vh - 335px)',
      overflow: 'auto',
      margin: '0 -16px',
      padding: '0 16px',
      '.notification-skeleton-box': {
        padding: 16,
        borderRadius: 16,
        marginBottom: 4,
        display: 'flex',
        '.avatar-skeleton': {
          borderRadius: '100%'
        },
        '.skeleton-flex': {
          flex: 1,
          marginLeft: 4,
          '.mt-4': {
            marginTop: 3
          }
        }
      },
      '&::-webkit-scrollbar': {
        width: 9,
        height: 9
      },
      '&::-webkit-scrollbar-track': {
        padding: '0 0px',
        backgroundColor: 'transparent'
      },
      '&::-webkit-scrollbar-thumb': {
        transition: 'all 0.3s',
        backgroundColor: 'rgba(0, 0, 0, 0.16)',
        borderRadius: 4,
        border: '3px solid transparent',
        backgroundClip: 'content-box'
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.16)'
      },
      '.notification-wrap': {
        maxWidth: '100%',
        '.notif-header': {
          padding: '0 !important'
        },
        '.notification-list-wrap': {
          padding: 0,
          '.notification-item-wrap': {
            padding: 0,
            '.notif-item': {
              borderRadius: 16,
              padding: 16,
              marginBottom: 4
            },
            '.notif-content': {
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 3,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxHeight: 60,
              '.MuiTypography-root.MuiTypography-body2': {
                color: theme.palette.grey[600],
                '.highlight': {
                  color: theme.palette.grey[900],
                  '.time-date': {
                    fontWeight: theme.typography.fontWeightRegular,
                    fontSize: theme.typography.caption.fontSize,
                    color: theme.palette.grey[600],
                    paddingLeft: 16,
                    position: 'relative',
                    '&:before': {
                      display: 'block',
                      content: "''",
                      position: 'absolute',
                      width: 4,
                      height: 4,
                      top: 5,
                      left: 7,
                      backgroundColor: theme.palette.grey[100],
                      borderRadius: '50%'
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    '.no-notifications': {
      height: 'calc(100vh - 335px)',
      overflow: 'auto',
      display: 'flex',
      gap: 24,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '.conversation-icon': {
        width: 145,
        height: 145,
        backgroundColor: '#f2f2f2',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%'
      },
      '.text-muted': {
        color: theme.palette.grey[500]
      }
    }
  }
}))

export default ChatNotificationWrap
