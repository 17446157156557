import { styled } from '@mui/material/styles'

export const PriceList = styled('div')(({ theme }) => ({
  '&.price-list': {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.subtitle1,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.grey[900],

    b: {
      color: theme.palette.grey[900]
    },

    '.total': {
      color: theme.palette.primary.main
    },

    'small, span': {
      fontSize: theme.typography.caption.fontSize,
      lineHeight: '18px',
      color: theme.palette.grey[600]
    },

    '.deduct': {
      color: theme.palette.error.main
    }
  },
  '&.border-box': {
    width: '100%',
    padding: 24,
    display: 'inline-block',
    border: '1px solid',
    borderColor: theme.palette.divider,
    borderRadius: 8
  }
}))
