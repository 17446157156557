import { FC } from 'react'
import Carousel from 'react-multi-carousel'
import { ICarouselProps } from './react-carousel.types'
import { styled } from '@mui/material/styles'
import { IBoxProps, Icon, Box } from '@ntpkunity/controls'
import { IconButton, useTheme } from '@mui/material'

const CarouselButtons = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '.carousel-control': {
    position: 'absolute',
    backgroundColor: 'rgba(255,255,255,.5)',
    border: '1px solid' + theme.palette.divider,
    width: 48,
    height: 48,
    marginTop: -15,
    top: '50%',
    padding: 0,
    borderRadius: '50%',
    transform: 'translateY(-50%)',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
    '&.next-btn': {
      right: 0,
      transform: 'translatey(-50%) rotate(180deg)'
    },
    '&.prev-btn': {
      left: 0
    }
  }
}))

const CarouselButtonGroup = ({ next, previous, carouselState }: any) => {
  const theme = useTheme()
  const { totalItems, slidesToShow, currentSlide } = carouselState
  const showRightIcon = totalItems - slidesToShow != currentSlide && totalItems > slidesToShow
  return (
    <CarouselButtons theme={theme}>
      {currentSlide != 0 && (
        <IconButton className="prev-btn carousel-control" onClick={() => previous()}>
          <Icon name="IconLeftArrow" />
        </IconButton>
      )}
      {showRightIcon && (
        <IconButton className="next-btn carousel-control" onClick={() => next()}>
          <Icon name="IconLeftArrow" />
        </IconButton>
      )}
    </CarouselButtons>
  )
}
const ReactCarousel: FC<ICarouselProps> = ({ responsive, children, ...props }) => {
  return (
    <Carousel
      responsive={responsive}
      customButtonGroup={<CarouselButtonGroup next={{}} previous={{}} />}
      {...props}
    >
      {children}
    </Carousel>
  )
}

export default ReactCarousel
