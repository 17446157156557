import { styled } from '@mui/material'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const ChatNotificationHeaderWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.notification-header-wrap': {
    padding: 16,
    position: 'relative',
    '.menu-close-icon': {
      position: 'absolute',
      top: 8,
      right: 8,
      padding: '0px !important',
      '&:hover': {
        backgroundColor: 'initial'
      },
      svg: {
        path: {
          stroke: theme.palette.grey[600]
        }
      }
    },
    '.header-title': {
      marginBottom: 16,
      color: theme.palette.grey[600]
    }
  }
}))
