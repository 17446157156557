import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTheme } from '@mui/material'
import { Box, Typography } from '@ntpkunity/controls'
import ViewOrders from '../compare-orders/view-orders'
import { IQuotationParameters } from 'types/quote-parameters'
import { InstallationMode } from '@helpers/enums'

const OrderGrid: any = ({
  orderStatus,
  programCalculationData,
  quotationParameterData,
  feeData,
  fnIData,
  optionData,
  quotationObject,
  selectedAccordion
}: any) => {
  const form: {
    control: any
    handleSubmit: any
    getValues: any
  } = useForm<IQuotationParameters>({
    defaultValues: useMemo(() => {
      return quotationParameterData
    }, [quotationParameterData])
  })
  const sortedOptionsArrayFinal = optionData?.sort((a: any, b: any) =>
    a?.installation_mode?.localeCompare(b.installation_mode)
  )
  const optionsSum = sortedOptionsArrayFinal?.reduce(function (lastSum: any, obj: any) {
    return (
      lastSum +
      ((obj.installation_mode === InstallationMode.PRE_INSTALLED && !obj.is_price_inclusive) ||
      obj.installation_mode !== InstallationMode.PRE_INSTALLED
        ? obj?.price
        : 0)
    )
  }, 0)
  const theme = useTheme()
  return (
    <Box theme={theme} className="package-container" key={quotationObject[0]?.reference_number}>
      <Box theme={theme} className="package-cell" sx={{ mb: 2 }}>
        <Box theme={theme} className="product-header">
          <Box theme={theme} className="thumbnail">
            <img
              src={
                quotationObject[0]?.order_vehicle?.photo_urls &&
                quotationObject[0]?.order_vehicle?.photo_urls?.length > 0
                  ? quotationObject[0]?.order_vehicle?.photo_urls[0]?.location
                  : ''
              }
              alt="Image"
            />
          </Box>
          <Typography
            theme={theme}
            component="div"
            variant="body2"
            display="block"
            className="text-muted"
            children={
              quotationObject[0]?.order_vehicle?.year +
              ' ' +
              quotationObject[0]?.order_vehicle?.make +
              ' ' +
              quotationObject[0]?.order_vehicle?.model
            }
          />
          <Typography
            theme={theme}
            component="div"
            variant="subtitle1"
            display="block"
            sx={{ mb: 0.5 }}
            children={quotationObject[0]?.order_vehicle?.trim_description}
          />
          <Typography
            theme={theme}
            component="div"
            variant="caption"
            display="block"
            className="text-muted"
            children={
              <>
                Deal ID:{' '}
                <span className="order-id text-primary">
                  {quotationObject[0]?.reference_number}
                </span>
              </>
            }
          />
        </Box>
      </Box>

      <Box theme={theme} className="package-cell">
        <Box theme={theme} className="grid-wrap">
          <Box theme={theme} className="grid">
            {programCalculationData?.length > 0 &&
              programCalculationData.map((data: any, index: any) => (
                <Box theme={theme} className="col" key={index}>
                  <ViewOrders
                    orderStatus={orderStatus}
                    leaseObj={data?.program}
                    calculationData={data?.calculation}
                    form={form}
                    feeData={feeData}
                    fnIData={fnIData}
                    selectedAccordion={selectedAccordion}
                    sortedOptionsArray={sortedOptionsArrayFinal}
                    optionsSum={optionsSum}
                    quotationObject={
                      quotationObject.filter(
                        (obj: any) => obj.finance_type == data.program.finance_type
                      )[0]
                    }
                  />
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default OrderGrid
