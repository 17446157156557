import { useEffect } from 'react'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from '../../../authConfig'

export const SSOLogin = () => {
  const { instance } = useMsal()
  useEffect(() => {
    instance.loginRedirect(loginRequest).catch((_e) => {})
  }, [])

  return <></>
}
