import { FC } from 'react'
import { useTheme } from '@mui/material'
import { Grid, Select, Typography } from '@ntpkunity/controls'
import CommomReturnPageHeader from '@app/in-progress/return-vehicle/PageHeader'
import { useStoreContext } from '@store/storeContext'
import { Controller, useForm } from 'react-hook-form'
import CardBanner from '@app/in-progress/card-banner'
import { RETURNS } from '@helpers/enums'

const Buyout: FC<any> = () => {
  const theme = useTheme()
  const { states } = useStoreContext()
  const defaultValue = {
    return_type: states?.dealerSelectedReturnData?.return_type ?? ''
  }
  const { control } = useForm<any>({
    defaultValues: defaultValue
  })

  const propsForBuyoutPageHeader = {
    handleSubmit: () => {
      return
    },
    formValues: () => {
      return
    },
    formIsDirty: false,
    showSaveButton: false,
    returnType: RETURNS.Buyout,
    returnStatus: states?.dealerSelectedReturnData?.subscription_status
  }

  return (
    <>
      <Grid container theme={theme}>
        <Grid theme={theme} item xl={12} lg={12} md={12} sm={12} xs={12}>
          <CommomReturnPageHeader {...propsForBuyoutPageHeader} />
        </Grid>
        <Grid theme={theme} item xl={12} lg={12} md={12} sm={12} xs={12} mt={3}>
          <CardBanner />
        </Grid>
      </Grid>

      <Grid theme={theme} container mt={1} xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid item theme={theme} xs={4}>
          <Controller
            name="return_type"
            control={control}
            defaultValue={''}
            render={({ field: { onChange, value } }) => (
              <Select
                theme={theme}
                disabled
                label={'Return Type'}
                onChange={(e) => {
                  onChange(e)
                }}
                value={value || ''}
                items={[{ text: 'Buyout', value: states?.dealerSelectedReturnData?.return_type }]}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container theme={theme} xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid item theme={theme} mt={2}>
          <Typography theme={theme} component="p" variant="body2">
            Customer opted to buy this vehicle, so this vehicle will no more returned at store.
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default Buyout
