import { styled } from '@mui/material/styles'

export const CustomImageUploader = styled('div')(({ theme }) => ({
  marginBottom: 24,
  position: 'relative',
  '.img-wrap': {
    backgroundColor: theme.palette.common.white,
    width: '100%',
    height: 224,
    display: 'flex',
    verticalAlign: 'middle',
    textAlign: 'center',
    borderRadius: theme.shape.borderRadius,
    border: '2px dashed' + theme.palette.grey[100],
    overflow: 'hidden',

    img: {
      maxWidth: '100%',
      maxHeight: '100%',
      width: 'auto',
      height: 'auto',
      display: 'block',
      margin: 'auto'
    },

    '.btn-close': {
      backgroundColor: theme.palette.common.white,
      padding: '5px !important',
      position: 'absolute',
      top: 10,
      right: 10,
      height: 26,
      width: 26,
      border: '1px solid' + theme.palette.grey[100],
      borderRadius: '100%',
      '&:hover': {
        backgroundColor: theme.palette.grey[50]
      }
    }
  }
}))
