import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'

import { LayoutWithSideNav } from '@styles/layout'
import { Grid, Typography } from '@ntpkunity/controls'
import CustomerDetailsSection from '@app/in-progress/customer-details'
import CardBanner from '@app/in-progress/card-banner'
import { useStoreContext } from '@store/storeContext'
import { useGetCustomerLicenseInfo, useGetInsuranceDetails } from '@apis/dealer-workqueue.service'
import OrderLicenseInformation from './order-license-information'
import DealerOrderInsuranceInformation from './dealer-order-insurance-info'
import OrderDetailInformation from './dealer-order-details'
import DealerResponses from './dealer-responses'

const OrderDetailsSection: FC = () => {
  const [dataLoading, setDataLoading] = useState(false)
  const [orderDataLoading, setOrderDataLoading] = useState(false)
  const [contractsData, setContractsData] = useState()

  const { mutate: getInsuranceDetails, isLoading: insuranceLoading } = useGetInsuranceDetails()
  const { mutate: getCustomerLicenseInfo, isLoading: licenseLoading } = useGetCustomerLicenseInfo()

  const { states } = useStoreContext()
  let currentURL = window.location.href

  // Check if the URL includes 'completed'
  let isCompleted = currentURL.includes('completed')

  const theme = useTheme()
  useEffect(() => {
    // Set the state based on the result
    if (states?.dealerSelectedOrderData?.customerId) {
      getInsuranceDetails({
        customer_ref_id: states?.dealerSelectedOrderData?.customerId
      })
      getCustomerLicenseInfo({
        customer_ref_id: states?.dealerSelectedOrderData?.customerId
      })
      setDataLoading(true)
    }
  }, [states?.dealerSelectedOrderData?.customerId])

  return (
    <LayoutWithSideNav theme={theme}>
      <CardBanner setOrderDataLoading={setOrderDataLoading} setContractsData={setContractsData} />
      <Typography theme={theme} mb={2} component="h3" variant="h3">
        {isCompleted ? 'Deal Completion Details' : 'Deal Cancellation Details'}
      </Typography>
      <Grid theme={theme} container columnSpacing={3}>
        <Grid theme={theme} item xl={8} lg={8} md={8} sm={12} xs={12}>
          <CustomerDetailsSection />
          <OrderDetailInformation
            orderDataLoading={orderDataLoading}
            contractsData={contractsData}
            isCompleted={isCompleted}
          />
          {isCompleted ? (
            <>
              <DealerOrderInsuranceInformation
                insuranceLoading={insuranceLoading}
                dataLoading={dataLoading}
              />
              <OrderLicenseInformation licenseLoading={licenseLoading} dataLoading={dataLoading} />
            </>
          ) : null}
          {/* <License/> */}
        </Grid>
        <Grid theme={theme} item xl={4} lg={4} md={4} sm={12} xs={12}>
          <DealerResponses />
        </Grid>
      </Grid>
    </LayoutWithSideNav>
  )
}

export default OrderDetailsSection
