import * as React from 'react'
import Snackbar from '@mui/material/Snackbar'
import { ISnackbarProps } from './Snackbar.types'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'

const CustomToast = styled(Snackbar, {
  // shouldForwardProp: props => isPropValid(props),
})<Partial<ISnackbarProps>>(({ theme }) => ({
  '&.u-toast': {
    '.MuiSnackbarContent-root': {
      padding: '8px 16px',
      borderRadius: theme.shape.borderRadius,
      border: '1px solid' + theme.palette.common.black,
      backgroundColor: theme.palette.grey[900],
      textAlign: 'center',
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.16)',
      fontSize: theme.typography.body2.fontSize,
      lineHeight: theme.typography.body1.lineHeight,
      fontWeight: theme.customVariables.fontWeightSemiBold,
      color: theme.palette.common.white,
      [theme.breakpoints.up('sm')]: {
        minWidth: 'auto'
      },
      '.MuiSnackbarContent-message': {
        padding: 0,
        width: '100%'
      }
    },
    '&.u-toast-error': {
      '.MuiSnackbarContent-root': {
        backgroundColor: theme.palette.error.main,
        borderColor: theme.palette.error.main
      }
    },
    '&.u-toast-success': {
      '.MuiSnackbarContent-root': {
        backgroundColor: theme.palette.success.main,
        borderColor: theme.palette.success.main
      }
    },
    '&.u-toast-info': {
      '.MuiSnackbarContent-root': {
        backgroundColor: theme.palette.info.main,
        borderColor: theme.palette.info.main
      }
    },
    '&.u-toast-warning': {
      '.MuiSnackbarContent-root': {
        backgroundColor: theme.palette.warning.main,
        borderColor: theme.palette.warning.main
      }
    }
  }
}))

const CustomSnackbar: React.FC<ISnackbarProps> = ({ theme, children, variant, ...props }) => {
  return (
    <CustomToast
      className={clsx({
        'u-toast': true,
        'u-toast-error': variant === 'error',
        'u-toast-success': variant === 'success',
        'u-toast-info': variant === 'info',
        'u-toast-warning': variant === 'warning'
      })}
      theme={theme}
      {...props}
    >
      {children}
    </CustomToast>
  )
}
export default CustomSnackbar
