import { styled } from '@mui/material/styles'
import { IBoxProps, Box } from '@ntpkunity/controls'

export const VehicleList = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.vehicle-list': {
    '.vl-scroll': {
      display: 'inline-block',
      listStyle: 'none',
      width: '100%',
      margin: 0,
      padding: 0,
      maxHeight: '300px',
      flexGrow: 1,
      overflowY: 'hidden',
      overflowX: 'hidden',
      position: 'relative',
      transition: 'all 0.3s',
      '@supports (-moz-appearance:none)': {
        scrollbarWidth: 'thin'
      },
      '&::-webkit-scrollbar': {
        width: 9,
        height: 9
      },
      '&::-webkit-scrollbar-track': {
        padding: '0 0px',
        backgroundColor: 'transparent',
        marginBottom: 0
      },
      '&::-webkit-scrollbar-thumb': {
        transition: 'all 0.3s',
        backgroundColor: 'rgba(0, 0, 0, 0.16)',
        borderRadius: 4,
        border: '3px solid transparent',
        backgroundClip: 'content-box'
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.16)'
      },
      '&:hover': {
        overflowY: 'auto',
        '@-moz-document url-prefix()': {
          overflowY: 'auto'
        }
      },
      li: {
        margin: '0 0 8px 0',
        listStyle: 'none',
        width: '100%',

        '&:last-child': {
          marginBottom: 0
        }
      }
    },

    '.vl-item': {
      backgroundColor: theme.customVariables.tableStripedBg,
      padding: '8px 16px',
      borderRadius: '8px',
      display: 'flex',
      gap: 1,
      alignItems: 'center',
      minHeight: 80,
      transition: 'all 0.3s',
      '.vl-details': {
        paddingTop: '0',
        maxWidth: '100%',
        overflow: 'hidden',
        flex: '1',
        '.vl-main-title': {
          fontSize: '10px',
          lineHeight: '16px',
          textTransform: 'uppercase'
        },
        '.vl-mini-title': {
          color: theme.palette.grey[300]
        },
        '.vl-title-ul': {
          fontSize: theme.typography.caption.fontSize,
          color: theme.palette.grey[600],
          b: {
            color: theme.palette.grey[900]
          }
        }
      },
      '.vl-img-wrap': {
        width: 96,
        height: 54,
        display: 'flex',
        verticalAlign: 'middle',
        textAlign: 'center',
        overflow: 'hidden',
        // marginLeft: 8,
        // borderRadius: theme.shape.borderRadius,

        img: {
          maxWidth: '100%',
          maxHeight: 54,
          width: 'auto',
          height: 'auto',
          display: 'block',
          margin: 'auto'
        }
      },
      '.vl-menu-btn': {
        padding: '0 !important',
        marginTop: -34,
        height: 22,
        marginRight: -10,
        visibility: 'hidden',
        opacity: 0,
        transition: 'all 0.3s',
        '&:before': {
          display: 'none'
        },
        'svg path': {
          stroke: theme.palette.grey[400] + '!important'
        }
      },
      '&.sm': {
        padding: 8,
        minHeight: 52,
        '.vl-img-wrap': {
          width: 40,
          height: 40,
          backgroundColor: theme.palette.common.white,
          borderRadius: '4px',
          marginRight: 8,
          img: {
            maxHeight: 40
          }
        }
      },
      '&.vertical': {
        display: 'inline-block',
        textAlign: 'center',
        width: '100%',
        minHeight: 120,
        '.vl-img-wrap': {
          width: '100%',
          clear: 'both',
          height: 60,
          backgroundColor: 'transparent',
          borderRadius: 0,
          img: {
            maxHeight: 60
          }
        },
        '.MuiTypography-body2': {
          fontSize: 12
        },
        '.MuiTypography-caption': {
          fontSize: 10
        },
        '.vl-menu-btn': {
          float: 'right',
          marginTop: -100,
          marginRight: 0
        }
      },
      '&.h-list': {
        height: 80
      },
      '&.item-li': {
        marginBottom: 8,

        '&:last-child': {
          marginBottom: 0
        }
      },
      '.loader': {
        position: 'relative'
      },
      '&:hover': {
        '.vl-menu-btn': {
          visibility: 'visible',
          opacity: 1
        }
      }
    }
  }
}))
