import { styled } from '@mui/system'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const PageHeaderStyle = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.page_header': {
    '.MuiTypography-root': {
      '.MuiLink-root': {
        color: theme.palette.common.white,
        textDecoration: 'underline',
        cursor: 'pointer',
        '&:hover': {
          color: theme.palette.primary.main
        }
      }
    }
  }
}))
