import { FC } from 'react'
import { useTheme } from '@mui/material'
import { Typography, Grid, Button } from '@ntpkunity/controls'
import { BtnGroupList } from '@components'
import { useWatch } from 'react-hook-form'
import { useStoreContext } from '@store/storeContext'

export const ScheduleOption: FC<{ isDisable?: boolean; form: any }> = ({ isDisable, form }) => {
  const theme = useTheme()
  const { states } = useStoreContext()
  const { control } = form
  useWatch({
    control,
    name: ['schedule_option']
  })

  const scheduleOptions: any = states?.dealerProfileData?.schedule_option || []
  return (
    <>
      <Typography
        theme={theme}
        variant="h4"
        component="h4"
        sx={{ mb: 2.5 }}
        children="Schedule Option"
      />
      <BtnGroupList>
        <Grid theme={theme} container columnSpacing={1} rowSpacing={0}>
          {scheduleOptions.map((option: any, index: any) => (
            <Grid theme={theme} item xs={6} key={index}>
              <Button
                fullWidth
                primary={
                  form.watch('schedule_option') == option ||
                  (form.getValues().schedule_option == 'Pick up' && index == 0)
                    ? true
                    : false
                }
                secondary={
                  form.watch('schedule_option') == option ||
                  (form.getValues().schedule_option == 'Pick up' && index == 0)
                    ? false
                    : true
                }
                theme={theme}
                text={option === 'pickup' ? 'In-Store Pickup' : 'Delivery'}
                disabled={isDisable}
                onClick={() => form.setValue('schedule_option', option)}
              />
            </Grid>
          ))}
        </Grid>
      </BtnGroupList>
    </>
  )
}
