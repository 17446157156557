import IActions from '../configs'
import { Dispatch, ReducerAction } from 'react'

export const SET_TRADE_IN_EQUITY_STATE = 'SET TRADE IN EQUITY STATE'

export const initialState: any = {}

const reducer = (state: typeof initialState, action: IActions): typeof initialState => {
  switch (action.type) {
    case SET_TRADE_IN_EQUITY_STATE:
      return action.payload as typeof initialState
  }
  return state
}

export const setTradeInEquityData = (
  data: typeof initialState,
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  if (data) {
    dispatch({ type: SET_TRADE_IN_EQUITY_STATE, payload: data })
  }
}

export default reducer
