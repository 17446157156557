import { useTheme } from '@mui/material'
import { IDrawerProps, Drawer } from '@ntpkunity/controls'
import { FC } from 'react'

const Component: FC<IDrawerProps> = (props) => {
  const theme = useTheme()

  return <Drawer theme={theme} {...props} />
}

export default Component
