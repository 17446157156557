import { useTheme } from '@mui/material'
import SingleChat, { MessageSkeleton } from './SingleChat/SingleChat-component'
import ChatMessagesWrap from './ChatMessages.style'
import { useChatServiceContext } from '@hooks/useChatService'
import { MessagePerson } from '@helpers/enums'
import { getUserNameFromChannel } from '@helpers/methods'
import { useEffect, useRef } from 'react'
import { Box, Button } from '@ntpkunity/controls'
import { ProfileChannel } from 'types/chat'

const ChatMessages = () => {
  const theme = useTheme()
  const { messages, loadingMessages, selectedUser, loadMoreMessages, isLoadingMore, messageCount } =
    useChatServiceContext()
  const otherParticipantUserName = getUserNameFromChannel(selectedUser)
  const scrollRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [loadingMessages, messages])

  if (loadingMessages) {
    return (
      <ChatMessagesWrap theme={theme} className="chat-messages-wrap">
        {[...Array(5)].map((_, index) => {
          return <MessageSkeleton key={index} />
        })}
      </ChatMessagesWrap>
    )
  }

  if (!messages?.messages?.length) {
    return <ChatMessagesWrap theme={theme} className="chat-messages-wrap"></ChatMessagesWrap>
  }

  const shouldShowLoadMore =
    messageCount[(selectedUser as ProfileChannel).channel.channel].totalCount >
    messages.messages.length

  return (
    <ChatMessagesWrap theme={theme} className="chat-messages-wrap">
      {shouldShowLoadMore && (
        <Box theme={theme} textAlign="center">
          <Button
            theme={theme}
            onClick={loadMoreMessages}
            size="small"
            text="Load more"
            className="read-more-btn"
          />
        </Box>
      )}
      {isLoadingMore &&
        [...Array(5)].map((_, index) => {
          return <MessageSkeleton key={index} />
        })}
      {messages.messages.map((msg) => {
        const isMyMessage = msg.who === MessagePerson.ME
        const messageTime = new Date(msg.timestamp)
        return (
          <SingleChat
            key={`message-${msg.messageId}`}
            isCustomer={!isMyMessage}
            message={msg.content}
            messageDetails={true}
            name={isMyMessage ? 'You' : otherParticipantUserName}
            time={messageTime.toLocaleString(undefined, {
              hour: '2-digit',
              minute: '2-digit'
            })}
          />
        )
      })}
      <div ref={scrollRef}></div>
    </ChatMessagesWrap>
  )
}

export default ChatMessages
