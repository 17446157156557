import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { PersistentDrawer, Button, MultiSelect } from 'components'
import { useStoreContext } from '@store/storeContext'
import {
  useGetAllFinancialInsuranceProducts,
  useSaveFinancialInsurance,
  useUpdateFinancialInsuranceById
} from '@apis/financial-insurance.service'
import { useGetProductByProviderId, useGetProvider } from '@apis/integrations.service'
import { IFinancialInsurance } from '@models'
import { FinancialInsurancePopup } from './financial-insurance-popup.type'
import { Controller } from 'react-hook-form'
import { ActionOptions } from '@helpers/enums'
import { Select, unityTheme, Input } from '@ntpkunity/controls'
import { getUser } from '@helpers/methods'

const NewFinancialInsurance: FC<FinancialInsurancePopup> = ({
  popUpState,
  actionType,
  financialInsuranceData,
  setPopUpState,
  form
}) => {
  const theme = useTheme()
  const { actions } = useStoreContext()
  const [applyValidation, setApplyValidation] = useState<boolean>(false)
  const { mutate: getFinancialInsuranceProducts } = useGetAllFinancialInsuranceProducts()
  const { mutate: SaveFinancialInsurance } = useSaveFinancialInsurance()
  const { mutate: getProvider, data: provider_data } = useGetProvider()
  const { mutate: getProduct, data: product_data } = useGetProductByProviderId()
  const dealerData = getUser()
  const { mutate: UpdateFinancialInsurance } = useUpdateFinancialInsuranceById()
  const [product, setProduct] = useState([])
  const [financeTypes, setFinanceTypes] = useState([])

  useEffect(() => {
    getFinancialInsuranceProducts()
    getProvider()
  }, [])

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    resetField,
    formState: { errors }
  } = form

  const onSubmit = (data: any) => {
    if (actionType === ActionOptions.ADD) {
      data.applicable_finance_type = JSON.stringify(financeTypes)
      data.dealer_id = dealerData.id
      SaveFinancialInsurance(
        {
          ...data
        },
        {
          onSuccess(response: IFinancialInsurance) {
            actions.addFinancialInsuranceRow(response)
            actions.setToast({
              toastMessage: 'Data Saved Sucessfully',
              toastState: true
            })
          },
          onError(error: any) {
            actions.setToast({
              toastMessage: error?.error?.toString(),
              toastState: true,
              variant: 'error'
            })
          }
        }
      )
    } else if (actionType === ActionOptions.EDIT) {
      data.applicable_finance_type = JSON.stringify(financeTypes)
      UpdateFinancialInsurance(
        {
          ...data
        },
        {
          onSuccess(response: IFinancialInsurance) {
            actions.updateFinancialInsuranceRow(response)
            actions.setToast({
              toastMessage: 'Data Saved Sucessfully',
              toastState: true
            })
          },
          onError(error: any) {
            actions.setToast({
              toastMessage: error?.error?.toString(),
              toastState: true,
              variant: 'error'
            })
          }
        }
      )
    }
    reset()
    setFinanceTypes([])
    onClose()
  }

  const onClose = () => {
    setPopUpState(false)
  }
  const handleChange = () => {
    resetField('product_type')
    resetField('product_id')
  }
  useEffect(() => {
    if (actionType === ActionOptions.EDIT) {
      if (financialInsuranceData?.applicable_finance_type) {
        const a = JSON.stringify(financialInsuranceData?.applicable_finance_type)
        const b = JSON.parse(a)
        const c = JSON.parse(b)
        setFinanceTypes(c)
      }
      if (financialInsuranceData?.taxable) {
        setApplyValidation(true)
      } else {
        setApplyValidation(false)
      }
    } else {
      setApplyValidation(false)
      setFinanceTypes([])
    }
  }, [financialInsuranceData])

  const onFinanceTypeSelection = (e: any) => {
    setFinanceTypes(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)
  }

  return (
    <PersistentDrawer
      title={'Add New Product'}
      openPopUp={popUpState}
      setPopUpState={setPopUpState}
      theme={theme}
      customFooter={
        <Button primary onClick={handleSubmit(onSubmit)} text="Save Product" fullWidth />
      }
    >
      <Controller
        name="provider_id"
        control={control}
        defaultValue={''}
        rules={{
          required: 'Provider is required'
        }}
        render={({ field }) => (
          <Select
            theme={theme}
            label={'Provider'}
            items={provider_data?.map((fi: any) => {
              return {
                value: parseInt(fi.ProviderID.__text),
                text: fi.Name.__text
              }
            })}
            {...field}
            value={field.value}
            //@ts-ignore
            onChange={(e: any, index: any) => {
              field.onChange(e, index)
              setValue('provider_name', index.props.children)
              getProduct({ id: watch('provider_id') })
              handleChange()
            }}
            sxProps={''}
            selectError={errors?.provider_id?.message}
          />
        )}
      />
      <Controller
        name="product_type"
        control={control}
        rules={{
          required: 'Product type is required'
        }}
        defaultValue={''}
        render={({ field }) => (
          <Select
            theme={theme}
            label={'Product Type'}
            items={product_data?.map((fi: any) => {
              return {
                value: fi.ProductType.__text,
                text: fi.ProductType.__text
              }
            })}
            {...field}
            value={field.value}
            //@ts-ignore
            onChange={(e: any, index: any) => {
              field.onChange(e, index)
              setProduct(
                product_data?.filter((fi: any) => {
                  return fi.ProductType.__text == watch('product_type')
                })
              )
            }}
            sxProps={''}
            selectError={errors?.product_type?.message}
          />
        )}
      />
      <Controller
        name="product_id"
        rules={{
          required: 'Product name is required'
        }}
        control={control}
        defaultValue={''}
        render={({ field }) => (
          <Select
            theme={theme}
            label={'Product Name'}
            items={product?.map((fi: any) => {
              return {
                value: parseInt(fi.ProductID.__text),
                text: fi.Name.__text
              }
            })}
            {...field}
            value={field.value}
            //@ts-ignore
            onChange={(e: any, index: any) => {
              field.onChange(e, index)
              setValue('product_name', index.props.children)
            }}
            sxProps={''}
            selectError={errors?.product_id?.message}
          />
        )}
      />
      <Controller
        name="markup"
        defaultValue={0}
        control={control}
        rules={{
          required: 'Markup value is required'
        }}
        render={({ field: { onChange, value } }) => (
          <Input
            theme={unityTheme}
            fullWidth={true}
            placeholder={'Type here..'}
            label={'Markup Percentage'}
            type="number"
            endAdornment="%"
            startAdornment=""
            id=""
            onChange={onChange}
            max={100}
            min={-100}
            value={value}
            allowNegative={true}
            error={errors?.markup?.message}
          />
        )}
      />
      <Controller
        name="taxable"
        control={control}
        defaultValue={false}
        render={({ field: { onChange, value } }) => (
          <Select
            theme={theme}
            label={'Taxable'}
            items={[
              { text: 'Applicable', value: true },
              { text: 'Not Applicable', value: false }
            ]}
            value={value}
            onChange={(e) => {
              onChange(e)
              if (e.target.value === true) {
                setApplyValidation(true)
              } else {
                setApplyValidation(false)
              }
            }}
            sxProps={''}
          />
        )}
      />
      <Controller
        name="applicable_finance_type"
        control={control}
        render={({ field }) => (
          <MultiSelect
            theme={theme}
            label={'Applicable Finance Type(s)'}
            items={[
              { text: 'Finance', value: 'Finance' },
              { text: 'Lease', value: 'Lease' }
            ]}
            {...field}
            value={financeTypes || []}
            onChange={onFinanceTypeSelection}
            disabled={!applyValidation}
            sxProps={''}
          />
        )}
      />
      <Controller
        name="is_active"
        control={control}
        defaultValue={true}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            sxProps={''}
            label={'Status'}
            placeholder={'Select'}
            items={[
              { text: 'Enabled', value: true },
              { text: 'Disabled', value: false }
            ]}
            value={value}
            onChange={onChange}
          ></Select>
        )}
      />
    </PersistentDrawer>
  )
}

export default NewFinancialInsurance
