import IActions from '../configs'
import { Dispatch, ReducerAction } from 'react'

export const SET_CALCULATION_INIT_STATE = 'Set Calculation Data'

export const initialState: any = {}

const reducer = (state: typeof initialState, action: IActions): typeof initialState => {
  switch (action.type) {
    case SET_CALCULATION_INIT_STATE:
      return action.payload as typeof initialState
  }
  return state
}

export const setCalculationData = (
  data: typeof initialState,
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  if (data) {
    dispatch({ type: SET_CALCULATION_INIT_STATE, payload: data })
  }
}

export default reducer
