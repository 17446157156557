import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const OrderReview: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  fontFamily: theme.typography.fontFamily,
  '&.border-bottom': {
    borderBottom: '1px solid' + theme.palette.grey[100]
  },
  '.container': {
    justifyContent: 'center',
    '.box': {
      paddingTop: '15vh',
      paddingBottom: '15vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '.content': {
        textAlign: 'center',
        '.heading': {
          fontSize: theme.typography.h2.fontSize,
          lineHeight: theme.typography.h2.lineHeight,
          fontWeight: theme.typography.h2.fontWeight,
          color: theme.palette.grey[900],
          marginBottom: '16px'
        },
        '.text': {
          fontSize: theme.typography.body2.fontSize,
          lineHeight: theme.typography.body2.lineHeight,
          fontWeight: theme.typography.body2.fontWeight,
          color: theme.palette.grey[600]
        },
        '.button': {
          marginTop: '32px'
        }
      }
    }
  }
}))
