import { styled } from '@mui/material'
import { Box, IBoxProps } from '@ntpkunity/controls'

const ChatMessagesWindowWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.chats-messages-wrap': {
    backgroundColor: theme.palette.common.white,
    borderRadius: 24,
    width: 400,
    maxWidth: '100%',
    boxShadow:
      '0px 533px 149px 0px rgba(0, 0, 0, 0.00), 0px 341px 137px 0px rgba(0, 0, 0, 0.01), 0px 192px 115px 0px rgba(0, 0, 0, 0.05), 0px 85px 85px 0px rgba(0, 0, 0, 0.09), 0px 21px 47px 0px rgba(0, 0, 0, 0.10);',
    marginBottom: 16,
    border: '1px solid' + theme.palette.grey[50],
    overflow: 'hidden',
    '.all-messages': {
      maxHeight: 'calc(100vh - 340px)',
      overflow: 'auto',
      padding: '16px 24px',
      '&::-webkit-scrollbar': {
        width: 9,
        height: 9
      },
      '&::-webkit-scrollbar-track': {
        padding: '0 0px',
        backgroundColor: 'transparent'
      },
      '&::-webkit-scrollbar-thumb': {
        transition: 'all 0.3s',
        backgroundColor: 'rgba(0, 0, 0, 0.16)',
        borderRadius: 4,
        border: '3px solid transparent',
        backgroundClip: 'content-box'
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.16)'
      }
    }
  }
}))

export default ChatMessagesWindowWrap
