import Http from '@helpers/http-api'
import { useMutation } from 'react-query'
import { EP_Appointment } from '@helpers/endpoints'

export const useAddAppointment = (): any => {
  const { data, isLoading, mutate } = useMutation<any, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.post<any>(EP_Appointment, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useUpdateAppointment = (): any => {
  const { data, isLoading, mutate } = useMutation<any, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.patch<any>(EP_Appointment, body)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}
export const useDeleteAppointment = (): any => {
  const { data, isLoading, mutate } = useMutation<{ id: number }, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.delete<any>(`${EP_Appointment}/${body.id}`)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}
