import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import { PageHeader, ConfirmationDialog, AlertBox, CustomDialogWrap } from 'components'
import { Typography, Button, Grid, Box, Icon, Menu, Dialog } from '@ntpkunity/controls'
import { Status, Reasons, DialogMessages, CreditApplicationStatus } from '@helpers/enums'
import { APP_ROUTES } from '@helpers/links'
import { useNavigate, useParams } from 'react-router-dom'
import { useUpdateOrderStatus } from '@apis/dealer-workqueue.service'
import { useStoreContext } from '@store/storeContext'
import {
  useConvertApplicationToQuotation,
  useCreditResponse,
  useGetOrderInformation,
  useGetSubmittedOrderInformation,
  useOrderSubmit
} from '@apis/order-management.service'
import {
  AssetDetails,
  CustomerDetails,
  InsuranceDetails,
  OrderDetails,
  TradeInDetails,
  PaymentBreakdown,
  PickupAppointment,
  Contracts,
  PaymentDetailsNew,
  CreditApplication
} from '@app/order-management/order-summary'
import { useUpdateDMSCUSTOMER } from '@apis/customer-detail.service'
import { ILender, MultiLenderDialog } from 'controls/multi-lender-dialog'
import { useModal } from 'react-modal-hook'
import DisableLoader from '@public/assets/images/loader-disabled.gif'
import { useQueryClient } from 'react-query'
import { OrderStage } from '@app/desking/desking-context-provider'
import {
  useGetCustomerOrderSubmissions,
  useSendCustomerOrderLink
} from '@apis/customer-management.service'

const OrderSummary: FC = () => {
  const theme = useTheme()
  const queryClient = useQueryClient()

  const { mutate: getOrderInformation, data: orderDetail } = useGetOrderInformation()
  const { mutate: getCustomerOrderSubmissions } = useGetCustomerOrderSubmissions()
  const [orderinformation, setOrderInformation] = useState<any>(orderDetail)
  const [hasError, setError] = useState<boolean>(false)
  const [isDraftOrder, setIsDraftOrder] = useState(false)
  const [isBanner, setIsBanner] = useState(false)
  const { reference_id } = useParams()
  const [confirmationPopupState, setConfirmationPopupState] = useState<boolean>(false)
  const [availableState, setAvailableState] = useState<boolean>(false)
  const { mutate: updateOrderStatus, isLoading: updateStatusLoading } = useUpdateOrderStatus()
  const { mutate: updateOrderStage, isLoading: _orderStageLoading } =
    useConvertApplicationToQuotation()
  const { mutate: submitOrderRequest, isLoading: submitOrderLoading } = useOrderSubmit()
  const { mutate: updateDmsData } = useUpdateDMSCUSTOMER()
  const { mutate: creditResponse, isLoading: creditResponseLoading } = useCreditResponse()
  const { mutate: getSubmittedOrderInformation, isLoading: getSubmittedOrderInformationLoading } =
    useGetSubmittedOrderInformation()
  const [contractDocument, setContractDocument] = useState<any>()
  const [generalDocument, setGeneralDocument] = useState<any>()
  const [submitCustomerPopUp, setSubmitCustomerPopUp] = useState<boolean>(false)
  const { mutate: sendCustomerOrderLink } = useSendCustomerOrderLink()
  const [lenders, setLenders] = useState<ILender[]>([])
  const [selectedLenders, setSelectedLenders] = useState<number[]>([])

  const disableInsuranceEditing = [
    Status.SchedulePickup,
    Status.Appointment,
    Status.Completed
  ].includes(orderDetail?.status)

  const { actions, states } = useStoreContext()
  let navigate = useNavigate()

  useEffect(() => {
    if (reference_id || availableState) {
      getOrderInformationToPopulate(reference_id ?? '')
    }
  }, [reference_id, availableState])

  useEffect(() => {
    if (states?.lendersData?.length > 0 && orderinformation?.order_submissions?.length > 0) {
      const currentLenders = structuredClone(states?.lendersData)
      const filteredLenders = currentLenders?.map((item: ILender) => {
        const existingLender = orderinformation?.order_submissions?.find(
          (lender: { lender_id: number }) => lender.lender_id === item.id
        )

        if (existingLender && existingLender?.status !== CreditApplicationStatus.FAILED) {
          item.is_already_selected = true
        }

        return item || {}
      })
      setLenders(filteredLenders)
    } else if (states?.lendersData?.length > 0) {
      const defaultLender = states?.lendersData?.find(lender => lender.default_lender)
      setSelectedLenders([defaultLender?.id])
      if (orderinformation?.lender_id && orderinformation.lender_id !== defaultLender?.id) {
        setSelectedLenders([
          ...(defaultLender?.id ? [defaultLender.id] : []),
          ...(orderinformation?.lender_id ? [orderinformation.lender_id] : [])
        ])
      }
      setLenders(states?.lendersData)
    }
  }, [orderinformation?.order_submissions, states?.lendersData])

  const getOrderInformationToPopulate = (order_reference_id: string) => {
    getOrderInformation(
      { reference_number: order_reference_id },
      {
        onSuccess(response: any) {
          setIsDraftOrder(Array.isArray(response))
          const orderResponse = Array.isArray(response) ? response?.[0] : response
          if (
            orderResponse?.status === Status.Submitted ||
            orderResponse?.status === Status.AwaitingMore ||
            orderResponse?.status === Status.FullyReceived ||
            orderResponse?.status === Status.Completed ||
            orderResponse?.status === Status.Conditioned ||
            orderResponse?.status === Status.Cancelled ||
            orderResponse?.status === Status.Declined ||
            orderResponse?.status === Status.Withdrawn ||
            orderResponse?.status === Status.ReSubmitted ||
            orderResponse?.status === Status.Reviewed ||
            orderResponse?.status === Status.InReview ||
            orderResponse?.status === Status.PendingConfirmation ||
            orderResponse?.status === Status.Draft ||
            orderResponse?.status === Status.PendingTradeInConfirmation ||
            orderResponse?.status === Status.PendingTradeInInspection
          )
            setOrderInformation(orderResponse)
          if (orderResponse?.documents?.length > 0) {
            const contractDocuments: any = []
            const generalDocuments: any = []
            orderResponse?.documents.forEach((entry: any) => {
              if (entry.type === 'Contract') {
                contractDocuments?.push(entry)
              } else if (entry.type === 'General') {
                generalDocuments?.push(entry)
              }
            })
            setGeneralDocument(generalDocuments)
            setContractDocument(contractDocuments)
          }
          if (orderResponse?.status === Status.Approved) {
            navigate(`/dms/deal-approved/${order_reference_id}`)
          }
        }
      }
    )
  }

  const onDeleteConfirm = (requestPayload: any) => {
    const data = {
      order_id: orderinformation?.reference_number,
      reqBody: {
        status: Status.Cancelled,
        user_id: 0,
        reason:
          orderinformation?.status === Status.PendingConfirmation
            ? requestPayload?.reason
              ? requestPayload?.reason
              : Reasons.ASSET_NOT_AVAILABLE
            : requestPayload?.reason
              ? requestPayload?.reason
              : Reasons.CANCELED_BY_DEALER
      }
    }
    updateOrderStatus(data, {
      onSuccess() {
        actions.setToast({
          toastMessage: 'Deal Cancelled',
          toastState: true
        })
        navigate(APP_ROUTES?.WORKQUEUE)
      },
      onError(error: any) {
        actions.setToast({
          toastMessage: error.error.toString(),
          toastState: true,
          variant: 'error'
        })
      }
    })
  }
  const getOrderStatus = () => {
    switch (orderinformation?.status) {
      case Status.PendingTradeInConfirmation:
      case Status.PendingTradeInInspection:
      case Status.PendingConfirmation:
      case Status.AwaitingMore:
      case Status.FullyReceived:
        return orderinformation?.status || ''
      case Status.Reviewed:
        return `Deal ${orderinformation?.status} Successfully`
      default:
        return orderinformation?.status ? `Deal ${orderinformation?.status}` : ''
    }
  }

  const updateStage = () => {
    const data = {
      order_id: orderinformation?.reference_number,
      reqBody: {
        status: Status.Draft,
        user_id: 0
      }
    }
    updateOrderStatus(data)
    updateOrderStage({
      identifier: orderinformation?.identifier,
      reference_number: orderinformation?.reference_number
    })
    queryClient.setQueryData(['Order', orderinformation?.reference_number], {
      ...orderinformation,
      order_stage: OrderStage.QUOTATION
    })
    navigate(
      `/dms/desking?reference_id=${orderinformation?.reference_number}&customer_reference_id=${orderinformation?.customer_info?.reference_id}`
    )
  }

  const handleCreditApplication = () => {
    if (states?.dealDesk?.recalculation) {
      setIsBanner(true)
      actions.setRecalculation(false)
    } else {
      setIsBanner(false)
      handleShowSelectLenderPopup()
    }
  }
  const submitOrder = () => {
    if (
      orderinformation?.order_asset.mileage &&
      orderinformation?.order_asset.engine_cylinder &&
      orderinformation?.order_asset.transmission_type
    ) {
      const payload = {
        order_id: orderinformation?.reference_number,
        reqBody: {
          status: 'Submitted',
          user_id: 0
        }
      }
      updateOrderStatus(payload, {
        onSuccess(response: any) {
          submitCreditApprover(response.status)
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: 'error'
          })
        }
      })
    } else {
      actions.setToast({
        toastMessage: 'Odometer reading is missing.',
        toastState: true,
        variant: 'error'
      })
      setError(true)
      closeSlModal()
    }
  }
  const handleAvailableClick = () => {
    if (orderinformation?.status === Status.FullyReceived) {
      return submitCreditApprover(orderinformation?.status)
    } else if (orderinformation?.status === Status.PendingConfirmation) {
      return submitOrderResquest()
    } else {
      return submitOrder()
    }
  }

  const submitOrderResquest = () => {
    if (
      orderinformation?.order_asset.mileage &&
      orderinformation?.order_asset.engine_cylinder &&
      orderinformation?.order_asset.transmission_type
    ) {
      submitOrderRequest(
        {
          referenceNumber: orderinformation?.reference_number,
          lender_ids: selectedLenders
        },
        {
          async onSuccess(_response: any) {
            actions.setToast({
              toastMessage: 'Deal Submitted for Credit Response',
              toastState: true
            })
            closeSlModal()
            setAvailableState(true)
          },
          onError(error: any) {
            actions.setToast({
              toastMessage: error.error.toString(),
              toastState: true,
              variant: 'error'
            })
          }
        }
      )
    } else {
      actions.setToast({
        toastMessage: 'Odometer reading is missing.',
        toastState: true,
        variant: 'error'
      })
      setError(true)
      closeSlModal()
    }
  }

  const submitCreditApprover = (status: string) => {
    const bmwDealLenders =
      states.lendersData
        ?.filter(
          (lender) =>
            selectedLenders?.includes(lender.id) &&
            lender.credit_decisioning_provider === 'BMWDeals'
        )
        ?.map((lender) => lender.id) ?? []

    if (bmwDealLenders?.length > 0 && orderDetail?.customer_info.reference_id) {
      getCustomerOrderSubmissions(
        {
          customer_reference_id: orderDetail.customer_info.reference_id,
          submission_status: Status.Submitted,
          lender_ids: bmwDealLenders
        },
        {
          onSuccess: (response) => {
            if (response?.record_exists) {
              actions.setToast({
                toastMessage:
                  'There is already a credit application in progress, please wait until we receive decision of previous credit application of this customer',
                toastState: true
              })
              return
            } else {
              getSubmittedOrderInformation(
                { reference_number: orderinformation?.reference_number, order_status: status },
                {
                  onSuccess(response: any) {
                    const updatedResponse = { ...response, lender_ids: selectedLenders }
                    creditResponse(updatedResponse, {
                      async onSuccess() {
                        actions.setToast({
                          toastMessage: 'Deal Submitted for Credit Response',
                          toastState: true
                        })

                        setSelectedLenders([])
                        closeSlModal()

                        if (orderinformation?.status === Status.FullyReceived) {
                          getOrderInformationToPopulate(orderinformation?.reference_number)
                        } else {
                          setAvailableState(true)
                        }
                      },
                      onError(error: any) {
                        actions.setToast({
                          toastMessage: error.error.toString(),
                          toastState: true,
                          variant: 'error'
                        })
                      }
                    })
                  }
                }
              )
            }
          }
        }
      )
    } else {
      getSubmittedOrderInformation(
        { reference_number: orderinformation?.reference_number, order_status: status },
        {
          onSuccess(response: any) {
            const updatedResponse = { ...response, lender_ids: selectedLenders }
            creditResponse(updatedResponse, {
              async onSuccess() {
                actions.setToast({
                  toastMessage: 'Deal Submitted for Credit Response',
                  toastState: true
                })

                setSelectedLenders([])
                closeSlModal()

                if (orderinformation?.status === Status.FullyReceived) {
                  getOrderInformationToPopulate(orderinformation?.reference_number)
                } else {
                  setAvailableState(true)
                }
              },
              onError(error: any) {
                actions.setToast({
                  toastMessage: error.error.toString(),
                  toastState: true,
                  variant: 'error'
                })
              }
            })
          }
        }
      )
    }
  }
  const updateData = () => {
    const data = {
      dealer_code: states?.dealerInfo.dealer_code,
      reference_id: orderinformation?.customer_info?.reference_id
    }
    updateDmsData(data)
  }
  const handleShowSelectLenderPopup = () => {
      if (lenders?.length > 1) openSlModal()
      else submitOrder()
  }

  const onSendOrderLinkToCustomer = () => {
    sendCustomerOrderLink({
      reference_number: orderinformation?.reference_number
    })
  }

  const [openSlModal, closeSlModal] = useModal(
    () => (
      <CustomDialogWrap theme={theme} className="custom-dialog-wrap footer-separator">
        <Dialog
          disablePortal
          theme={theme}
          variant={undefined}
          open
          size="xl"
          title="Select Lenders"
          onCloseDialog={closeSlModal}
          customFooter={
            <>
              <Button
                theme={theme}
                primary
                text="Submit Application"
                onClick={handleAvailableClick}
                disabled={
                  creditResponseLoading ||
                  submitOrderLoading ||
                  getSubmittedOrderInformationLoading ||
                  selectedLenders?.length < 1
                }
                startIcon={
                  (creditResponseLoading ||
                    getSubmittedOrderInformationLoading ||
                    submitOrderLoading) && <img src={DisableLoader} alt="Loader" />
                }
              />
            </>
          }
        >
          <MultiLenderDialog
            selectedLenders={selectedLenders}
            orderDetail={orderinformation}
            setSelectedLenders={setSelectedLenders}
            lenders={lenders}
          />
        </Dialog>
      </CustomDialogWrap>
    ),
    [
      orderinformation,
      selectedLenders,
      lenders,
      creditResponseLoading,
      submitOrderLoading,
      getSubmittedOrderInformationLoading
    ]
  )

  return (
    <LayoutWithSideNav theme={theme}>
      <Box theme={theme} sx={{ mb: 2.5 }}>
        <PageHeader
          className="main-page-header border-bottom"
          theme={theme}
          container
          item
          spacing={2}
        >
          <Grid theme={theme} container spacing={2}>
            <Grid theme={theme} item lg={7} md={6} sm={12} xs={12}>
              <Typography theme={theme} variant="h2" component="h2">
                {getOrderStatus()}
              </Typography>
              <Typography theme={theme} variant="body2" component="p">
                {(() => {
                  switch (orderinformation?.status) {
                    case Status.Submitted:
                      return (
                        <>
                          This should not take too long. We will notify you once the credit
                          application has been reviewed.
                        </>
                      )
                    case Status.Completed:
                      return (
                        <>This deal has been completed. You can review the deal details below.</>
                      )
                    case Status.Conditioned:
                    case Status.AwaitingMore:
                    case Status.FullyReceived:
                      return (
                        <>
                          Please see the information below regarding the status of your credit
                          application review.
                        </>
                      )
                    case Status.Approved:
                      return (
                        <>
                          The credit application has been approved. Once you receive the payment,
                          please update the payment status below to proceed.
                        </>
                      )
                    case Status.Cancelled:
                      return (
                        <>
                          Sorry, the deal has been cancelled and moved to your cancelled orders
                          queue.
                        </>
                      )
                    case Status.Declined:
                      return (
                        <>
                          Sorry, the deal has been declined and moved to your declined orders
                          queue.
                        </>
                      )
                    case Status.Withdrawn:
                      return (
                        <>
                          Sorry, the deal has been withdrawn by the customer and moved to your
                          withdrawn orders queue.
                        </>
                      )
                    case Status.ReSubmitted:
                      return (
                        <>
                          This should not take too long. We will notify you once the credit
                          application has been reviewed.
                        </>
                      )
                    case Status.Reviewed:
                      return (
                        <>
                          The fraud check for this application is approved. Please proceed with this
                          deal and submit the credit application.
                        </>
                      )
                    case Status.InReview:
                      return (
                        <>
                          This should not take too long. We will notify you once the credit
                          application has been reviewed.
                        </>
                      )
                    case Status.Draft:
                      return (
                        <>Your deal has been saved. You can view the saved deal details below.</>
                      )
                    case Status.PendingConfirmation:
                      return (
                        <>
                          Customer has submitted the deal. Finalize this deal by confirming
                          availability
                        </>
                      )
                    case Status.PendingTradeInInspection:
                      return <>Trade-in asset inspection is pending.</>
                    default:
                      return (
                        <>
                          This should not take too long. We will notify you once the credit
                          application has been reviewed.
                        </>
                      )
                  }
                })()}
              </Typography>
            </Grid>
            <Grid theme={theme} item lg={5} md={6} sm={12} xs={12} textAlign="right">
              <Box
                className="action-area"
                flexShrink={0}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'right'}
                theme={theme}
              >
                {orderinformation &&
                  (orderinformation?.status == Status.Reviewed ||
                    (orderinformation?.status == Status.Draft &&
                      orderinformation?.order_stage === OrderStage.APPLICATION)) ? (
                  <>
                    {isBanner && (
                      <Menu
                        theme={theme}
                        options={[
                          {
                            optionText: 'Edit deal',
                            optionValue: 'Edit'
                          },
                          {
                            optionText: 'Send To Customer',
                            optionValue: 'SendToCustomer'
                          }
                        ]}
                        handleOptionClick={(_event, _key, value) => {
                          switch (value) {
                            case 'Edit':
                              updateStage()
                              break
                            case 'SendToCustomer':
                              onSendOrderLinkToCustomer()
                              break
                          }
                        }}
                        render={(onMenuSelection) => (
                          <Button
                            theme={theme}
                            secondary
                            iconText={<Icon name="MoreIcon" />}
                            onClick={onMenuSelection}
                          ></Button>
                        )}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left'
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left'
                        }}
                      />
                    )}

                    <Button
                      theme={theme}
                      secondary
                      disabled={
                        creditResponseLoading ||
                        getSubmittedOrderInformationLoading ||
                        updateStatusLoading
                      }
                      startIcon={
                        (creditResponseLoading ||
                          getSubmittedOrderInformationLoading ||
                          updateStatusLoading) && <img src={DisableLoader} alt="Loader" />
                      }
                      text="Submit Application"
                      onClick={handleCreditApplication}
                    />
                  </>
                ) : orderinformation &&
                  orderinformation?.status != Status.Completed &&
                  orderinformation?.status != Status.PendingConfirmation &&
                  orderinformation?.status != Status.Cancelled &&
                  orderinformation?.status != Status.Declined &&
                  orderinformation?.status != Status.Withdrawn ? (
                  <Button
                    theme={theme}
                    secondary
                    id="CancelOrder"
                    text={'Cancel Deal'}
                    onClick={(_e) => {
                      setConfirmationPopupState(true)
                    }}
                  />
                ) : (
                  <></>
                )}
                {orderinformation && orderinformation?.status == Status.PendingConfirmation && (
                  <Button
                    theme={theme}
                    secondary
                    id="EditOrder"
                    text={'Edit Deal'}
                    onClick={(_e) => {
                      navigate(
                        `/dms/desking?reference_id=${orderinformation.reference_number}&customer_reference_id=${orderinformation?.customer_info?.reference_id}`
                      )
                    }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </PageHeader>
      </Box>
      <AssetDetails
        isDraftOrder={isDraftOrder}
        hasError={hasError}
        setError={setError}
        order_info={orderinformation}
        setConfirmationPopupState={setConfirmationPopupState}
        setOptionObject={setOrderInformation}
        openSlModal={openSlModal}
        lenders={lenders}
        setSelectedLenders={setSelectedLenders}
      />
      {isBanner && (
        <AlertBox className="dark buttons">
          <span>
            There has been some financial updates in your deal. Please review the financials below
            before you proceed with the deal submission.
          </span>
        </AlertBox>
      )}
      <Grid theme={theme} className="container" container spacing={2}>
        <Grid theme={theme} item xl={8} lg={8} md={8} sm={12} xs={12}>
          {(orderDetail?.status == Status.Appointment || orderDetail?.status == Status.Completed) &&
            orderDetail?.appointment_details ? (
            <PickupAppointment order_info={orderDetail}></PickupAppointment>
          ) : (
            ''
          )}

          {orderDetail?.documents && orderDetail?.documents.length > 0 ? (
            <>
              <Contracts
                orderStatus={orderDetail?.status}
                dealerId={orderDetail?.dealer_id}
                referenceNumber={orderDetail?.reference_number}
                referenceId={orderDetail?.customer_info.reference_id}
                generalDocument={generalDocument}
                contractDocument={contractDocument}
              />
            </>
          ) : (
            ''
          )}

          {orderDetail?.status == Status.SchedulePickup ||
            orderDetail?.status == Status.Appointment ||
            orderDetail?.status == Status.Completed ? (
            <PaymentDetailsNew order_detail={orderDetail} viewMode={true} />
          ) : (
            ''
          )}

          {[
            Status.Submitted,
            Status.AwaitingMore,
            Status.FullyReceived,
            Status.Conditioned
          ]?.includes(orderDetail?.status) &&
            orderinformation?.order_submissions?.length > 0 && (
              <CreditApplication
                order_detail={orderinformation}
                openSlModal={openSlModal}
                lenders={lenders}
                setSelectedLenders={setSelectedLenders}
              />
            )}

          <CustomerDetails customer_info={orderinformation?.customer_info} />

          <OrderDetails isDraftOrder={isDraftOrder} order_detail={orderinformation} />

          {orderinformation?.Insurance_Inforation ? (
            <InsuranceDetails
              insurance={orderinformation?.Insurance_Inforation}
              referenceId={orderDetail?.customer_info.reference_id}
              isInsuranceEditingDisabled={disableInsuranceEditing}
            />
          ) : (
            ''
          )}

          {orderinformation?.order_tradein ? (
            <TradeInDetails orderDetail={orderinformation} setOrderObject={setOrderInformation} />
          ) : (
            ''
          )}
        </Grid>
        {!isDraftOrder && <PaymentBreakdown order_info={orderinformation} />}
      </Grid>
      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={
            orderinformation?.status == Status.PendingConfirmation
              ? DialogMessages.assetUnavailableTitle
              : DialogMessages.confirmationTitle
          }
          confirmationText={
            orderinformation?.status == Status.PendingConfirmation
              ? DialogMessages.assetUnavailableConfirmationText
              : DialogMessages.confirmationText
          }
          primaryBtnText={
            orderinformation?.status == Status.PendingConfirmation
              ? DialogMessages.btnText
              : DialogMessages.cancelBtnText
          }
          isButtonTypeDanger
          icon={<Icon name="ErrorTriangle" />}
          onCancel={function (_action: any): void {
            setConfirmationPopupState(false)
          }}
          optionaldata={true}
          hideCancelButton={false}
        />
      )}
      {submitCustomerPopUp && (
        <ConfirmationDialog
          openPopUp={submitCustomerPopUp}
          onConfirm={function (_action: any): void {
            submitOrder()
            updateData()
          }}
          setPopUpState={setSubmitCustomerPopUp}
          confirmationTitle={DialogMessages.updatedDMSDATA}
          confirmationText={DialogMessages.updatedDMSDATATEXT}
          primaryBtnText="Yes"
          secondaryBtnText="No"
          icon={<Icon name="CircleTickIcon" />}
          onCancel={function (_action: any): void {
            setSubmitCustomerPopUp(false)
            submitOrder()
          }}
          hideCancelButton={false}
        />
      )}
    </LayoutWithSideNav>
  )
}

export default OrderSummary
