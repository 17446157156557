import { FC, useState } from 'react'
import { useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import { Box, IBoxProps } from '@ntpkunity/controls'
import { styled } from '@mui/material/styles'
import InProgressPageHeader from '@app/in-progress/page-header'
import SchedulePickup from '@app/in-progress/schedule-pickup'
import { containsKeyValue } from '@helpers/methods'
import { useForm } from 'react-hook-form'
// import { useForm } from "react-hook-form";

const PageWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '.btn-back': {
    paddingLeft: '5px !important',
    '&:hover': {
      backgroundColor: 'transparent',
      svg: {
        path: {
          transition: 'all 0.3s',
          stroke: theme.palette.grey[700]
        }
      }
    },
    '.MuiTouchRipple-root': {
      display: 'none'
    }
  },
  '.ndt': {
    width: '100%',
    display: 'inline-block',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.caption.fontSize,
    lineHeight: theme.typography.caption.lineHeight,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey[600],
    textAlign: 'right',
    b: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.grey[900]
    }
  },
  '.toggle-btn': {
    position: 'relative',
    top: 5,
    marginLeft: 10,
    cursor: 'pointer'
  },
  '.ch-up': {
    webkitTransform: 'scaleY(-1)',
    transform: 'scaleY(-1)'
  },
  '.text-danger': {
    color: theme.palette.error.main
  }
}))

const DealerVehicleSchedulePickup: FC = () => {
  const theme = useTheme()
  const [checkListReqData, setCheckListRequestData] = useState({})
  const [showSaveButton, setShowSaveButton] = useState(false)
  const [checkListName, setCheckListName] = useState('')
  const [questionsCount, setQuestionsCount] = useState(0)
  const [checklistIdentifier, setCheckListIdentifier] = useState('')
  const [savedOptionAvailable, setSavedChecklistOptions] = useState(false)
  const [description, setDescription] = useState('')
  const [possession_description, setPossessionDescription] = useState('')
  const [keys_description, setKeyDescription] = useState('')
  const [scheduleChecklistData, setScheduleChecklistData] = useState<any>()
  const [checklistErrors, setChecklistErrors] = useState<any>()

  const form = useForm({ mode: 'all' })

  //@ts-ignore
  const pickupCallback = (
    data: any,
    questionCount?: any,
    checklist_name?: any,
    checklist_identifier?: any,
    savedOptionsAvailable?: any,
    description?: any,
    keys_description?: any,
    possession_description?: any,
    checklistData?: any
  ) => {
    setCheckListRequestData(data)
    setCheckListName(checklist_name)
    setQuestionsCount(questionCount)
    setSavedChecklistOptions(savedOptionsAvailable)
    setScheduleChecklistData(checklistData)
    setCheckListIdentifier(checklist_identifier)
    setDescription(description ?? undefined)
    setKeyDescription(keys_description)
    setPossessionDescription(possession_description)
    if (data && Object.keys(data).length > 0) {
      if (
        containsKeyValue(
          data,
          'Did you walk through the vehicle and features with the customer?',
          'No'
        ) &&
        !description
      ) {
        setShowSaveButton(false)
        return
      }
      if (
        Object?.keys(data).includes(
          'How many keys/key cards/FOBs did you provide to the customer ?'
        ) &&
        !keys_description
      ) {
        setShowSaveButton(true)
      }
      if (
        containsKeyValue(data, 'Did the customer take possession of the vehicle?', 'No') &&
        !possession_description
      ) {
        setShowSaveButton(false)
        return
      } else {
        setShowSaveButton(true)
        return
      }
    }
  }
  return (
    <>
      <LayoutWithSideNav theme={theme}>
        <PageWrap theme={theme} sx={{ mb: 2.5 }}>
          <InProgressPageHeader
            showButton={showSaveButton}
            reqData={checkListReqData}
            checkListName={checkListName}
            questionCount={questionsCount}
            savedOptionAvailable={savedOptionAvailable}
            checklistIdentifier={checklistIdentifier}
            description={description}
            keys_description={keys_description}
            possession_description={possession_description}
            checklistData={scheduleChecklistData}
            setChecklistErrors={setChecklistErrors}
            form={form}
          />
          <SchedulePickup
            form={form}
            pickupCallback={pickupCallback}
            checklistErrors={checklistErrors}
          />
        </PageWrap>
      </LayoutWithSideNav>
    </>
  )
}

export default DealerVehicleSchedulePickup
