import IActions from '../configs'
import { Dispatch, ReducerAction } from 'react'

export const SET_QUOTATION_DEFAULT_PARAM = 'Set Quotation Default Param'

export const initialState: any = {
  defaultTerms: undefined,
  defaultCreditRating: undefined
}

const reducer = (state: typeof initialState, action: IActions): typeof initialState => {
  switch (action.type) {
    case SET_QUOTATION_DEFAULT_PARAM:
      return action.payload as typeof initialState
  }
  return state
}

export const setQuotationDefaultParam = (
  data: typeof initialState,
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  if (data) {
    dispatch({ type: SET_QUOTATION_DEFAULT_PARAM, payload: data })
  }
}
export default reducer
