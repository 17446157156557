import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import CompareOrders from '@app/order-management/compare-orders/compare-orders'
import { IQuotationParameters } from 'types/quote-parameters'
import { useGetAllOrdersByReferenceNumber } from '@apis/order-management.service'
import { useParams } from 'react-router-dom'
import { CircleLoader } from '@ntpkunity/controls'

const MultipleQuotes: FC = () => {
  const { customer_email, reference_id } = useParams()
  const [customerEmail, setCustomerEmail] = useState('')
  const { mutate: getOrderByReferenceNumber, data: orderData } = useGetAllOrdersByReferenceNumber()
  const [programCalculationData, setProgramCalculationData] = useState<any[]>([])
  const [quotationParameterData, setQuotationParameterData] = useState<IQuotationParameters>()
  const [calculationPayload, setCalculationPayload] = useState<Array<any>>([])
  const [maxCreditRating, setMaxCreditRating] = useState<any>({})
  const theme = useTheme()
  useEffect(() => {
    if (reference_id != undefined) {
      getOrderByReferenceNumber({ reference_number: `/?${reference_id}` })
    }
    if (customer_email != undefined) {
      setCustomerEmail(customer_email)
    }
  }, [reference_id, customer_email])
  return (
    <>
      <LayoutWithSideNav theme={theme}>
        {orderData?.length > 0 ? (
          <CompareOrders
            maxCreditRating={maxCreditRating}
            setMaxCreditRating={setMaxCreditRating}
            setQuotationParameterData={setQuotationParameterData}
            quotationParameterData={quotationParameterData}
            quotationObject={orderData}
            calculationPayload={calculationPayload}
            setCalculationPayload={setCalculationPayload}
            programCalculationData={programCalculationData}
            setProgramCalculationData={setProgramCalculationData}
            customerEmail={customerEmail}
          ></CompareOrders>
        ) : (
          <CircleLoader theme={theme} />
        )}
      </LayoutWithSideNav>
    </>
  )
}

export default MultipleQuotes
