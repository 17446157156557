import { FC, useEffect, useState } from 'react'
import { ToggleButton, useTheme } from '@mui/material'
import {
  useGetChecklistSavedProgress,
  useGetReadyCheckListRequest
} from '@apis/dealer-workqueue.service'
import { useStoreContext } from '@store/storeContext'
import { Box, Icon, Typography } from '@ntpkunity/controls'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { RETURNS } from '@helpers/enums'
const DealerReturnResponse: FC<{ contractsData: any }> = ({ contractsData }) => {
  const [savedReturnChecklist, setOrderSavedReturnChecklist] = useState<any>()
  const [configreturnChecklist, setConfigReturnChecklist] = useState<any>()

  const [openEpp, setOpenEpp] = useState(false)
  const { states } = useStoreContext()

  const [open, setOpen] = useState(false)
  const { mutate: getEventAssociatedReadyCheckList } = useGetReadyCheckListRequest()
  const { mutate: getChecklistProgressOptions, data: _savedChecklist } =
    useGetChecklistSavedProgress()

  const theme = useTheme()
  const returnEventType = (returnType: any) => {
    // Remove any non-numeric characters from the phone number
    if (returnType === RETURNS.EarlyReturn) {
      return {
        event_name: 'dealer-vehicle-return-event'
      }
    } else if (returnType === RETURNS.StandardReturn) {
      return {
        event_name: 'dealer-vehicle-return-standard-event'
      }
    } else if (returnType === RETURNS.Unwind) {
      return {
        event_name: 'dealer-vehicle-return-unwind-event'
      }
    } else {
      return {
        event_name: 'dealer-vehicle-return-standard-event'
      }
    }
  }

  useEffect(() => {
    const returnType = returnEventType(contractsData?.returns?.return_type)

    if (states?.dealerSelectedOrderData?.customerId && returnType) {
      getEventAssociatedReadyCheckList(
        { ...returnType },
        {
          onSuccess(res: any) {
            setConfigReturnChecklist(res)

            getChecklistProgressOptions(
              {
                customer_reference_id: states?.dealerSelectedOrderData?.customerId,
                checklist_identifier: res[0]?.[0]?.checklist_identifier,
                order_reference_id: states?.dealerSelectedOrderData?.id
              },
              {
                onSuccess(res: any) {
                  setOrderSavedReturnChecklist(res)
                }
              }
            )
          }
        }
      )
    }
  }, [states?.dealerSelectedOrderData?.customerId, contractsData])

  return (
    <Box theme={theme} className="card-body">
      <Box theme={theme} className="summary-row toggle" sx={{ mb: 2 }}>
        <Box theme={theme} className="row">
          <Box theme={theme} className="center">
            <span className="card-text">
              <b> Returns Checklist </b>
            </span>
            {openEpp ? (
              <Icon
                name="ChevronDown"
                className="toggle-btn ch-up"
                onClick={() => setOpenEpp(!openEpp)}
              />
            ) : (
              <Icon
                name="ChevronDown"
                className="toggle-btn"
                onClick={() => setOpenEpp(!openEpp)}
              />
            )}
          </Box>
        </Box>
      </Box>

      {configreturnChecklist &&
        configreturnChecklist?.[0]?.[0]?.questions?.map((ques: any, index: any) => (
          <Box theme={theme} key={index}>
            {savedReturnChecklist?.find((f: any) => f.question_text == ques.question) ? (
              <Accordion key={index} expanded={openEpp}>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" />
                <AccordionDetails sx={{ ml: 2 }}>
                  <Box theme={theme} className="row" sx={{ mb: 2 }}>
                    <Typography
                      theme={theme}
                      className="card-text text"
                      component="span"
                      variant="body2"
                    >
                      {ques?.question}
                      {savedReturnChecklist?.find(
                        (f: { question_text: any }) => f.question_text == ques.question
                      )?.description ? (
                        <ToggleButton
                          value="check"
                          selected={open}
                          onChange={() => {
                            setOpen(!open)
                          }}
                        >
                          <Icon name="ChevronDown" />
                        </ToggleButton>
                      ) : null}
                    </Typography>
                    <Typography
                      theme={theme}
                      className="card-text text"
                      component="span"
                      variant="body2"
                    >
                      {
                        savedReturnChecklist?.find(
                          (f: { question_text: any }) => f.question_text == ques.question
                        )?.primary_answer
                      }
                    </Typography>
                  </Box>
                  {open && (
                    <Box theme={theme} sx={{ mb: 2 }}>
                      <Typography
                        theme={theme}
                        className="card-text text"
                        component="span"
                        variant="body2"
                      >
                        {
                          savedReturnChecklist?.find(
                            (f: { question_text: any }) => f.question_text == ques.question
                          )?.description
                        }
                      </Typography>
                    </Box>
                  )}
                </AccordionDetails>
              </Accordion>
            ) : (
              <>
                <Accordion key={index} expanded={openEpp}>
                  <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" />
                  <AccordionDetails sx={{ ml: 2 }}>
                    <Box theme={theme} className="row" sx={{ mb: 2 }}>
                      <Typography
                        theme={theme}
                        className="card-text text"
                        component="span"
                        variant="body2"
                      >
                        {ques.question}
                      </Typography>
                      <Typography
                        theme={theme}
                        className="card-text text"
                        component="span"
                        variant="body2"
                      >
                        {savedReturnChecklist?.find(
                          (f: { question_text: any }) => f.question_text == ques.question
                        )?.primary_answer || '-'}
                      </Typography>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </>
            )}
          </Box>
        ))}
    </Box>
  )
}
export default DealerReturnResponse
