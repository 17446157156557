import { FC, useEffect, useState } from 'react'
import EarlyReturn from './early-return/early-return'
import StandardReturn from './standard-return/standard-return'
import { useTheme } from '@mui/material'
import { CircleLoader } from '@ntpkunity/controls'
import {
  useGetChecklistSavedProgress,
  useGetReadyCheckListRequest,
  useGetVehicleReturnsRequest
} from '@apis/dealer-workqueue.service'
import { useParams } from 'react-router-dom'
import { useStoreContext } from '@store/storeContext'
import { RETURNS } from '@helpers/enums'
import Buyout from '@app/in-progress/return-vehicle/buyout/buyout'
import UnwindReturn from '@app/in-progress/return-vehicle/unwind-return/unwind-return'
import { IRequestReadyCheckList } from '@models'

const VehicleReturn: FC<any> = () => {
  const { mutate: getOrderRequest, isLoading } = useGetVehicleReturnsRequest()
  const { mutate: getEventAssociatedReadyCheckList } = useGetReadyCheckListRequest()
  const { mutate: getChecklistProgressOptions } = useGetChecklistSavedProgress()
  const { states, actions } = useStoreContext()
  const [schedulePickUpValues, setSchedulePickUpValue] = useState({})
  const theme = useTheme()
  const { identifier } = useParams()
  const reqCheckListObject: IRequestReadyCheckList = {
    event_name: 'Vehicle Pick up Scheduling Confirmation'
  }
  useEffect(() => {
    fetchReturnOrder()
  }, [])
  useEffect(() => {
    if (states?.dealerSelectedOrderData?.customerId) {
      getEventAssociatedReadyCheckList(
        { ...reqCheckListObject },
        {
          onSuccess(res: any) {
            getChecklistProgressOptions(
              {
                customer_reference_id: states?.dealerSelectedOrderData?.customerId,
                checklist_identifier: res[0]?.[0]?.checklist_identifier,
                order_reference_id: states?.dealerSelectedOrderData?.id
              },
              {
                onSuccess(res: any) {
                  setSchedulePickUpValue(res)
                }
              }
            )
          }
        }
      )
    }
  }, [states?.dealerSelectedOrderData?.customerId])
  const fetchReturnOrder = () => {
    const params = { identifier: identifier }
    getOrderRequest(
      { params },
      {
        onSuccess(_response: any) {
          if (_response?.length > 0) {
            actions.setDealerSelectedReturnData(_response[0])
          }
        }
      }
    )
  }

  return (
    <>
      {isLoading && <CircleLoader theme={theme} />}
      {states?.dealerSelectedReturnData?.return_type === RETURNS.EarlyReturn ? (
        <EarlyReturn schedulePickUpValues={schedulePickUpValues} />
      ) : states?.dealerSelectedReturnData?.return_type === RETURNS.StandardReturn ? (
        <StandardReturn schedulePickUpValues={schedulePickUpValues} />
      ) : states?.dealerSelectedReturnData?.return_type === RETURNS.Unwind ? (
        <UnwindReturn schedulePickUpValues={schedulePickUpValues} />
      ) : states?.dealerSelectedReturnData?.return_type === RETURNS.Buyout ? (
        <Buyout />
      ) : null}
    </>
  )
}

export default VehicleReturn
