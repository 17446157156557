import * as React from 'react'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import { Button, Icon } from '@ntpkunity/controls'
import Typography from '../Typography'
import { IDialogProps } from './Dialog.types'
import clsx from 'clsx'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '&.u-dialog': {
    '.MuiDialog-paper': {
      borderRadius: 16,
      color: theme.palette.grey[900],
      maxWidth: 840,
      width: '100%',
      boxShadow: 'none'
    },
    '&.u-dialog-sm': {
      '.MuiDialog-paper': {
        maxWidth: 560
      }
    },
    '.u-dialog-title': {
      padding: '24px 65px',
      textAlign: 'center',
      textTransform: 'capitalize'
    },
    '.u-dialog-close': {
      position: 'absolute',
      right: 24,
      width: 24,
      height: 24,
      padding: 0,
      backgroundColor: 'transparent !important',
      svg: {
        path: {
          stroke: theme.palette.grey[300]
        }
      },
      '.MuiTouchRipple-root': {
        display: 'none'
      }
    },
    '.u-dialog-content': {
      padding: '60px 60px 0 60px',
      borderTopColor: theme.palette.grey[100],
      borderBottom: 'none',
      scrollbarWidth: 'thin',
      '&.has-no-action': {
        padding: '60px'
      },
      '&::-webkit-scrollbar': {
        width: 7,
        height: 7
      },
      '&::-webkit-scrollbar-track': {
        padding: '0 1px',
        backgroundColor: '#f0f0f0'
      },
      '&::-webkit-scrollbar-thumb': {
        transition: 'all 0.3s',
        backgroundColor: '#cdcdcd'
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#a6a6a6'
      }
    },
    '.u-dialog-action': {
      padding: '32px 60px 60px',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        '.btn': {
          width: '100%'
        }
      },
      '>': {
        ':not(:first-of-type)': {
          marginLeft: 16,
          flexGrow: 1,
          [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginTop: 16
          }
        }
      }
    }
  }
}))

const CustomizedDialogs: React.FC<IDialogProps> = ({
  title,
  size,
  children,
  theme,
  open: visible,
  onCloseDialog,
  customFooter,
  noFooter
}) => {
  return (
    <>
      <BootstrapDialog
        theme={theme}
        className={clsx({ 'u-dialog': true, 'u-dialog-sm': size === 'sm' })}
        open={visible || false}
        onClose={onCloseDialog}
      >
        {/*u-dialog-sm class will be added for small size dialog*/}
        <Typography theme={theme} variant="h4" component="h4" className="u-dialog-title">
          {title}
          {onCloseDialog ? (
            <IconButton className="u-dialog-close" aria-label="close" onClick={onCloseDialog}>
              <Icon name="CloseBlack" />
            </IconButton>
          ) : null}
        </Typography>
        <DialogContent
          dividers
          className={clsx({
            'u-dialog-content': true,
            'has-no-action': noFooter
          })}
        >
          {/*has-no-action class will be added if dialog has no action buttons*/}
          {children}
        </DialogContent>
        {!noFooter && !customFooter && (
          <DialogActions className="u-dialog-action">
            <Button theme={theme} primary text="Save Changes" onClick={onCloseDialog} />
          </DialogActions>
        )}
        {customFooter && <DialogActions className="u-dialog-action">{customFooter}</DialogActions>}
      </BootstrapDialog>
    </>
  )
}

export default CustomizedDialogs
