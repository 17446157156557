import * as React from 'react'
import { INestedMultiSelect, NestedMultiSelect } from '@ntpkunity/controls'

const Component: React.FC<INestedMultiSelect> = ({
  theme,
  name,
  items = [],
  label,
  value = [],
  helperText = '',
  sxProps = {},
  onChange
}) => {
  return (
    <NestedMultiSelect
      theme={theme}
      sxProps={sxProps}
      items={items}
      name={name}
      label={label}
      value={value}
      helperText={helperText}
      onChange={onChange}
    ></NestedMultiSelect>
  )
}

export default Component
