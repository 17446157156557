import { useTheme } from '@mui/material'
import { DuoTab, DuoTabProps } from '@ntpkunity/controls'
import { FC } from 'react'

const Component: FC<Partial<DuoTabProps>> = ({ items, defaultTabIndex, varient, ...props }) => {
  const theme = useTheme()

  return (
    <DuoTab
      theme={theme}
      items={items as any}
      defaultTabIndex={defaultTabIndex as number}
      varient={varient}
      {...props}
    />
  )
}

export default Component
