import { FC, useEffect } from 'react'
import { Controller } from 'react-hook-form'
import { unityTheme, Select, IBoxProps } from '@ntpkunity/controls'
import {
  Grid,
  Input,
  Box,
  Typography,
  Button
  // AddressLayout,
} from 'components'
import { styled } from '@mui/material/styles'
import { getUser, validation } from '@helpers/methods'
import { useTheme } from '@mui/material'
import { useGetStates } from '@apis/configurations.service'
import { usePenSetDealer } from '@apis/integrations.service'
import {
  useUpdateDealerProfilebyId,
  useUpdatePenDealerId
} from '@apis/dealer-configurations.service'
import { useStoreContext } from '@store/storeContext'
const TabWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(() => ({
  '&.tab-wrap': {
    width: '100%',
    display: 'inline-block',

    hr: {
      opacity: 0.4
    },

    '.title-icon': {
      width: '100%',
      display: 'inline-block',
      'span, Icon': {
        float: 'left'
      },
      span: {
        marginRight: 10
      }
    },
    '.buttons-row': {
      display: 'inline-block',
      textAlign: 'center',
      width: '100%',

      Button: {
        margin: '0 20px 20px 0',

        '&:last-child': {
          marginRight: 0
        }
      }
    },
    '.label-shrink': {
      '.u-form-group': {
        '.MuiFormControl-root': {
          '.MuiInputLabel-root': {
            transform: 'translate(14px, -9px) scale(0.85)',

            color: 'rgba(0, 0, 0, 0.6)',

            padding: '0 5px 0 5px',

            left: -4,

            top: 0,

            zIndex: 0
          }
        }
      }
    }
  }
}))

const Available_Finance = [
  { text: 'Finance', value: 'Finance' },
  { text: 'Lease', value: 'Lease' },
  {
    text: 'Finance & Lease',
    value: 'Finance&Lease'
  }
]
const Schedule_option = [
  { text: 'Pickup', value: 'pickup' },
  { text: 'Delivery', value: 'delivery' },
  {
    text: 'Pickup & Delivery',
    value: 'Pickup&Delivery'
  }
]
const day_light = [
  { text: 'Yes', value: true },
  { text: 'No', value: false }
]
const timeZone = [
  {
    text: '(GMT-12:00) International Date Line West',
    value: ' International Date Line West'
  },
  { text: '(GMT-11:00) Midway Island, Samoa', value: 'Midway Island, Samoa' },
  { text: '(GMT-10:00) Hawaii', value: 'Hawaii' },
  { text: '(GMT-09:00) Alaska', value: 'Alaska' },
  {
    text: '(GMT-08:00) Pacific Time (US and Canada)',
    value: 'Pacific Time (US and Canada)'
  },
  {
    text: '(GMT-07:00) Mountain Time (US and Canada)',
    value: 'Mountain Time (US and Canada)'
  },
  {
    text: '(GMT-07:00) Chihuahua, La Paz, Mazatlan',
    value: 'Chihuahua, La Paz, Mazatlan'
  },
  { text: '(GMT-07:00) Arizona', value: 'Arizona' },
  {
    text: '(GMT-06:00) Central Time (US and Canada)',
    value: 'Central Time (US and Canada)'
  },
  { text: '(GMT-06:00) Saskatchewan', value: 'Saskatchewan' },
  {
    text: '(GMT-06:00) Guadalajara, Mexico City, Monterrey',
    value: 'Guadalajara, Mexico City, Monterrey'
  },
  {
    text: '(GMT-05:00) Eastern Time (US and Canada)',
    value: 'Eastern Time (US and Canada)'
  },
  { text: '(GMT-06:00) Central America', value: 'Central America' },
  { text: '(GMT-05:00) Indiana (East)', value: 'Indiana (East)' },
  { text: '(GMT-05:00) Bogota, Lima, Quito', value: 'Bogota, Lima, Quito' },
  {
    text: '(GMT-04:00) Atlantic Time (Canada)',
    value: 'Atlantic Time (Canada)'
  },
  { text: '(GMT-04:00) Caracas, La Paz', value: 'Caracas, La Paz' },
  { text: '(GMT-04:00) Santiago', value: '(GMT-04:00)' },
  { text: '(GMT-03:30) Newfoundland and Labrador', value: '(GMT-03:30)' },
  { text: '(GMT-03:00) Brasilia', value: '(GMT-03:00)' },
  { text: '(GMT-03:00) Buenos Aires, Georgetown', value: '(GMT-03:00)' },
  { text: '(GMT-03:00) Greenland', value: '(GMT-03:00)' },
  { text: '(GMT-01:00) Azores', value: '(GMT-01:00)' },
  { text: '(GMT-01:00) Cape Verde Islands', value: '(GMT-01:00)' },
  {
    text: '(GMT) Greenwich Mean Time: Dublin, Edinburgh, Lisbon, London',
    value: '(GMT-00:00)'
  },
  { text: '(GMT) Casablanca, Monrovia', value: '(GMT-00:00)' },
  {
    text: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    value: '(GMT+01:00)'
  },
  {
    text: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
    value: '(GMT+01:00)'
  },
  {
    text: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris',
    value: '(GMT+01:00)'
  },
  {
    text: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    value: '(GMT+01:00)'
  },
  { text: '(GMT+01:00) West Central Africa', value: '(GMT+01:00)' },
  { text: '(GMT+02:00) Bucharest', value: '(GMT+02:00)' },
  { text: '(GMT+02:00) Cairo', value: '(GMT+02:00)' },
  {
    text: '(GMT+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
    value: '(GMT+02:00)'
  },
  { text: '(GMT+02:00) Athens, Istanbul, Minsk', value: '(GMT+02:00)' },
  { text: '(GMT+02:00) Jerusalem', value: '(GMT+02:00)' },
  { text: '(GMT+02:00) Harare, Pretoria', value: '(GMT+02:00)' },
  {
    text: '(GMT+03:00) Moscow, St. Petersburg, Volgograd',
    value: '(GMT+03:00)'
  },
  { text: '(GMT+03:00) Kuwait, Riyadh', value: '(GMT+03:00)' },
  { text: '(GMT+03:00) Nairobi', value: '(GMT+03:00)' },
  { text: '(GMT+03:00) Baghdad', value: '(GMT+03:00)' },
  { text: '(GMT+03:30) Tehran', value: '(GMT+03:00)' },
  { text: '(GMT+04:00) Abu Dhabi, Muscat', value: '(GMT+04:00)' },
  { text: '(GMT+04:00) Baku, Tbilisi, Yerevan', value: '(GMT+04:00)' },
  { text: '(GMT+04:30) Kabul', value: '(GMT+04:00)' },
  { text: '(GMT+05:00) Ekaterinburg', value: '(GMT+05:00)' },
  { text: '(GMT+05:00) Islamabad, Karachi, Tashkent', value: '(GMT+05:00)' },
  {
    text: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
    value: '(GMT+05:30)'
  },
  { text: '(GMT+05:45) Kathmandu', value: '(GMT+05:45)' },
  { text: '(GMT+06:00) Astana, Dhaka', value: '(GMT+06:00)' },
  { text: '(GMT+06:00) Sri Jayawardenepura', value: '(GMT+06:00)' },
  { text: '(GMT+06:00) Almaty, Novosibirsk', value: 'Almaty, Novosibirsk' },
  { text: '(GMT+06:30) Yangon Rangoon', value: 'Yangon Rangoon' },
  { text: '(GMT+07:00) Bangkok, Hanoi, Jakarta', value: 'Bangkok' },
  { text: '(GMT+07:00) Krasnoyarsk', value: 'Krasnoyarsk' },
  {
    text: '(GMT+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi',
    value: 'Beijing, Chongqing, Hong Kong SAR, Urumqi'
  },
  {
    text: '(GMT+08:00) Kuala Lumpur, Singapore',
    value: 'Kuala Lumpur, Singapore'
  },
  { text: '(GMT+08:00) Taipei', value: '(GMT+08:00)' },
  { text: '(GMT+08:00) Perth', value: '(GMT+08:00)' },
  { text: '(GMT+08:00) Irkutsk, Ulaanbaatar', value: '(GMT+08:00)' },
  { text: '(GMT+09:00) Seoul', value: '(GMT+09:00)' },
  { text: '(GMT+09:00) Osaka, Sapporo, Tokyo', value: '(GMT+09:00)' },
  { text: '(GMT+09:00) Yakutsk', value: '(GMT+09:00)' },
  { text: '(GMT+09:30) Darwin', value: '(GMT+09:30)' },
  { text: '(GMT+09:30) Adelaide', value: '(GMT+09:30)' },
  { text: '(GMT+10:00) Canberra, Melbourne, Sydney', value: '(GMT+10:00)' },
  { text: '(GMT+10:00) Brisbane', value: '(GMT+10:00)' },
  { text: '(GMT+10:00) Hobart', value: '(GMT+10:30)' },
  { text: '(GMT+10:00) Vladivostok', value: '(GMT+10:00)' },
  { text: '(GMT+10:00) Guam, Port Moresby', value: '(GMT+10:00)' },
  {
    text: '(GMT+11:00) Magadan, Solomon Islands, New Caledonia',
    value: '(GMT+11:00)'
  },
  {
    text: '(GMT+12:00) Fiji Islands, Kamchatka, Marshall Islands',
    value: '(GMT+12:00)'
  },
  { text: '(GMT+12:00) Auckland, Wellington', value: '(GMT+12:00)' },
  { text: "(GMT+13:00) Nuku'alofa", value: '(GMT+13:00)' }
]
const General: FC<{
  form: any
}> = ({ form }) => {
  const { mutate: updateDealerProfilebyId } = useUpdateDealerProfilebyId()
  const { mutate: updatePenDealerId } = useUpdatePenDealerId()

  const theme = useTheme()
  const {
    mutate: getStates
    // data: StatesData
  } = useGetStates()
  const { mutate: setDealer } = usePenSetDealer()
  const { actions } = useStoreContext()
  const dealerData = getUser()

  const {
    control,
    formState: { isDirty, errors },
    setValue,
    trigger,
    watch
  } = form
  useEffect(() => {
    getStates()
  }, [])
  return (
    <TabWrap className="tab-wrap">
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mb: 2.7 }}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="profile.available_finance_types"
            control={control}
            defaultValue={''}
            rules={validation('Available Finance Type', true)}
            render={({ field }) => (
              <Select
                theme={theme}
                label={'Available Finance Type'}
                items={Available_Finance}
                {...field}
                value={field.value}
                sxProps={''}
                selectError={errors?.profile?.available_finance_types?.message}
                onChange={field.onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="profile.schedule_option"
            control={control}
            defaultValue={''}
            rules={validation('Schedule Option', true)}
            render={({ field }) => (
              <Select
                theme={theme}
                label={'Schedule Options'}
                items={Schedule_option}
                {...field}
                value={field.value}
                sxProps={''}
                selectError={errors?.profile?.schedule_option?.message}
                onChange={field.onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} className="label-shrink">
          <Controller
            name="profile.contact_number"
            control={control}
            rules={validation('Contact Number', true)}
            defaultValue={watch('profile.contact_number')}
            render={({ field }) => (
              <Input
                theme={unityTheme}
                {...field}
                fullWidth
                masking={true}
                inputMask="000-000-0000"
                placeholder={'000 - 000 - 0000'}
                label={'Contact Number'}
                type="text"
                value={watch('profile.contact_number')}
                error={errors?.profile?.contact_number?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} className="label-shrink">
          <Controller
            name="profile.customer_support_number"
            control={control}
            rules={validation('Customer Support Number', true)}
            defaultValue={''}
            render={({ field }) => (
              <Input
                theme={unityTheme}
                fullWidth
                masking={true}
                inputMask="000-000-0000"
                placeholder={'000 - 000 - 0000'}
                label={'Customer Support Number'}
                type="text"
                {...field}
                value={field.value}
                error={errors?.profile?.customer_support_number?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="profile.time_zone"
            control={control}
            defaultValue={''}
            rules={validation('Time Zone', true)}
            render={({ field }) => (
              <Select
                theme={theme}
                label={'Time Zone'}
                items={timeZone}
                {...field}
                value={field.value}
                sxProps={''}
                selectError={errors?.profile?.time_zone?.message}
                onChange={field.onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} className="label-shrink">
          <Controller
            name="profile.fax_number"
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <Input
                theme={unityTheme}
                fullWidth
                masking={true}
                inputMask="000-000-0000"
                placeholder={'000 - 000 - 0000'}
                label={'Fax Number (Optional)'}
                type="text"
                {...field}
                value={field.value}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="profile.email"
            control={control}
            rules={validation('Email', true)}
            defaultValue={''}
            render={({ field }) => (
              <Input
                theme={unityTheme}
                fullWidth
                placeholder={'sample@sample.com'}
                label={'Email Address'}
                type="email"
                {...field}
                error={errors?.profile?.email?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="profile.website"
            control={control}
            rules={validation('Website', true)}
            defaultValue={''}
            render={({ field }) => (
              <Input
                theme={unityTheme}
                fullWidth
                placeholder={'www.sample.com'}
                label={'Website'}
                type="text"
                {...field}
                error={errors?.profile?.website?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="profile.contact_person_name"
            control={control}
            rules={validation('Contact Person Name', true)}
            defaultValue={''}
            render={({ field }) => (
              <Input
                theme={unityTheme}
                fullWidth
                placeholder={'Type here..'}
                label={'Contact Name'}
                type="text"
                {...field}
                error={errors?.profile?.contact_person_name?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="profile.day_light_saving"
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <Select
                theme={theme}
                label={'Daylight Saving Applicable'}
                items={day_light}
                {...field}
                value={field.value}
                sxProps={''}
                onChange={(e) => {
                  field.onChange(e)
                }}
                selectError={errors?.profile?.day_light_saving?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="profile.initials"
            control={control}
            rules={validation('Initials', true)}
            defaultValue={''}
            render={({ field }) => (
              <Input
                theme={unityTheme}
                fullWidth
                placeholder={'Type here..'}
                label={'Initials'}
                type="text"
                {...field}
                error={errors?.profile?.initials?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="profile.market_scan_number"
            control={control}
            rules={validation('Tax Service Provider ID', true)}
            defaultValue={''}
            render={({ field }) => (
              <Input
                theme={unityTheme}
                fullWidth
                placeholder={'Type here..'}
                label={'Tax Service Provider ID'}
                type="text"
                {...field}
                error={errors?.profile?.market_scan_number?.message}
              />
            )}
          />
        </Grid>
      </Grid>
      <Box sx={{ mb: 3 }}>
        <hr />
      </Box>
      <Typography className="title-icon" variant="h4" component="h4" theme={theme}>
        <span>Dealership Address</span>
      </Typography>
      {/* <Grid>
        <Controller
          name="profile.dealership_address"
          control={control}
          defaultValue={watch("profile.dealership_address")}
          render={({ field }) => (
            <AddressLayout
              addressVerificationRequired={false}
              theme={unityTheme}
              states={StatesData?.map((state: any) => {
                return {
                  text: state.name,
                  value: state.id,
                };
              })}
              {...field}
              isContactRequired={false}
              value={watch("profile.dealership_address")}
              onChange={(e: any) => {
                setValue("profile.dealership_address", e);
                trigger("profile.dealership_address");
              }}
              validationProps={errors?.profile?.dealership_address}
            />
          )}
        />
      </Grid> */}

      <Box sx={{ mb: 3 }}>
        <hr />
      </Box>

      {watch('profile.id') ? (
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mb: 2.7 }}>
          <Grid
            item
            xs={
              watch('profile.id') &&
              watch('profile.pen_dealer_id') &&
              watch('profile.pen_dealer_id') !== 'null'
                ? 6
                : 12
            }
            textAlign={
              watch('profile.id') &&
              watch('profile.pen_dealer_id') &&
              watch('profile.pen_dealer_id') !== 'null'
                ? 'right'
                : 'center'
            }
          >
            {watch('profile.pen_dealer_id') && watch('profile.pen_dealer_id') !== 'null' ? (
              <Button
                secondary
                text={'Unregister with F&I Product Provider'}
                onClick={() => {
                  setDealer(
                    {
                      action: 'UNREGISTER',
                      address1: watch('profile.dealership_address.address_line_1'),
                      address2: watch('profile.dealership_address.address_line_2'),
                      city: watch('profile.dealership_address.city'),
                      contactName: watch('profile.contact_person_name'),
                      penDealerId: watch('profile.pen_dealer_id'),
                      dealershipName: dealerData.dealer_name,
                      email: watch('profile.email'),
                      fax: watch('profile.fax_number'),
                      isTestDealer: true,
                      observesDayLightSaving: watch('profile.day_light_saving'),
                      phone: watch('profile.contact_number'),
                      state: watch('profile.dealership_address.state_id'),
                      timeZone: watch('profile.time_zone'),
                      webSite: watch('profile.website'),
                      zipCode: watch('profile.dealership_address.zip_code')
                    },
                    {
                      onSuccess(response: any) {
                        if (
                          response.Envelope.Body.SetDealerResponse.SetDealerResult.Success
                            .__text === 'true'
                        ) {
                          setValue('profile.pen_dealer_id', null)
                          updatePenDealerId({
                            id: watch('profile.id'),
                            pen_dealer_id: watch('profile.pen_dealer_id')
                          })
                          actions.setToast({
                            toastMessage: 'Your account has been unregistered',
                            toastState: true
                          })
                        }
                      }
                    }
                  )
                }}
              ></Button>
            ) : (
              <Button
                secondary
                text={'Register with F&I Product Provider'}
                onClick={() => {
                  trigger('profile')
                  if (!Object.keys(errors).includes('profile')) {
                    setDealer(
                      {
                        action: 'REGISTER',
                        address1: watch('profile.dealership_address.address_line_1'),
                        address2: watch('profile.dealership_address.address_line_2'),
                        city: watch('profile.dealership_address.city'),
                        contactName: watch('profile.contact_person_name'),
                        penDealerId: 0,
                        dealershipName: dealerData.dealer_name,
                        email: watch('profile.email'),
                        fax: watch('profile.fax_number'),
                        isTestDealer: true,
                        observesDayLightSaving: watch('profile.day_light_saving'),
                        phone: watch('profile.contact_number'),
                        state: watch('profile.dealership_address.state_id'),
                        timeZone: watch('profile.time_zone'),
                        webSite: watch('profile.website'),
                        zipCode: watch('profile.dealership_address.zip_code')
                      },
                      {
                        onSuccess(response: any) {
                          if (
                            response.Envelope.Body.SetDealerResponse.SetDealerResult.Success
                              .__text === 'true'
                          ) {
                            setValue(
                              'profile.pen_dealer_id',
                              response.Envelope.Body.SetDealerResponse.SetDealerResult.DealerID
                                .__text
                            )
                            if (isDirty) {
                              updateDealerProfilebyId(watch('profile'), {
                                onSuccess() {
                                  actions.setToast({
                                    toastMessage: 'The account has been registered successfully',
                                    toastState: true
                                  })
                                }
                              })
                            } else {
                              updatePenDealerId(
                                {
                                  id: watch('profile.id'),
                                  pen_dealer_id: watch('profile.pen_dealer_id')
                                },
                                {
                                  onSuccess() {
                                    actions.setToast({
                                      toastMessage: 'The account has been registered successfully',
                                      toastState: true
                                    })
                                  }
                                }
                              )
                            }
                          }
                        }
                      }
                    )
                  }
                }}
              ></Button>
            )}
          </Grid>
          <Grid item xs={6}>
            {watch('profile.pen_dealer_id') && watch('profile.pen_dealer_id') !== 'null' ? (
              <Button
                secondary
                text={'Update Info With F&I Product Provider'}
                onClick={() => {
                  trigger('profile')
                  if (!Object.keys(errors).includes('profile')) {
                    setDealer(
                      {
                        action: 'UPDATE',
                        address1: watch('profile.dealership_address.address_line_1'),
                        address2: watch('profile.dealership_address.address_line_2'),
                        city: watch('profile.dealership_address.city'),
                        contactName: watch('profile.contact_person_name'),
                        penDealerId: watch('profile.pen_dealer_id'),
                        dealershipName: dealerData.dealer_name,
                        email: watch('profile.email'),
                        fax: watch('profile.fax_number'),
                        isTestDealer: true,
                        observesDayLightSaving: watch('profile.day_light_saving'),
                        phone: watch('profile.contact_number'),
                        state: watch('profile.dealership_address.state_id'),
                        timeZone: watch('profile.time_zone'),
                        webSite: watch('profile.website'),
                        zipCode: watch('profile.dealership_address.zip_code')
                      },
                      {
                        onSuccess(response: any) {
                          if (
                            response.Envelope.Body.SetDealerResponse.SetDealerResult.Success
                              .__text === 'true'
                          ) {
                            if (isDirty) {
                              updateDealerProfilebyId(watch('profile'), {
                                onSuccess() {
                                  actions.setToast({
                                    toastMessage: 'The changes have been saved successfully',
                                    toastState: true
                                  })
                                }
                              })
                            } else {
                              actions.setToast({
                                toastMessage: 'The changes have been saved successfully',
                                toastState: true
                              })
                            }
                          }
                        }
                      }
                    )
                  }
                }}
              ></Button>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      ) : (
        ''
      )}
    </TabWrap>
  )
}
export default General
