import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const MultiSelectWrap: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(() => ({
  width: '100%',
  display: 'inline-block',
  marginBottom: 24,

  '&:last-child': {
    marginBottom: 0
  },
  '.u-dropdown-item': {
    '.custom-checkbox-wrap': {
      width: '100%',
      '.u-custom-control-label': {
        margin: 0,
        display: 'inline-block',
        width: '100%',
        '.MuiButtonBase-root': {
          marginRight: 0,
          position: 'absolute',
          width: '97%',
          textAlign: 'left',
          display: 'inline-block'
        },
        '.MuiFormControlLabel-label': {
          marginLeft: '30px',
          paddingTop: '24px',
          '&:hover': {
            '&:after': {
              display: 'none !important'
            }
          }
        }
      }
    }
  }
}))
