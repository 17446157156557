import { IUserProfile } from '@models'
import IActions from '../configs'
import { Dispatch, ReducerAction } from 'react'

export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE'

export const initialState: IUserProfile[] = []

const userProfileRreducer = (state: typeof initialState, action: IActions): typeof initialState => {
  switch (action.type) {
    case UPDATE_USER_PROFILE:
      return action.payload as typeof initialState
  }
  return state
}

export const setUserProfile = (
  data: IUserProfile,
  dispatch: Dispatch<ReducerAction<typeof userProfileRreducer>>
): void => {
  dispatch({ type: UPDATE_USER_PROFILE, payload: data })
}
export default userProfileRreducer
