import { styled } from '@mui/material'
import { StyleVariables } from '@styles/theme'

export const HeaderSearch = styled('div')(({ theme }) => ({
  '.overlay': {
    content: "''",
    visibility: 'hidden',
    opacity: 0,
    transition: 'all 0.3s',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0, .4)',
    width: '100%',
    height: '100%',
    zIndex: 1300
  },
  '.search-wrap': {
    backgroundColor: theme.palette.common.white,
    display: 'inline-block',
    width: '100%',
    position: 'fixed',
    top: 0,
    right: 0,
    padding: '25px 50px',
    borderRadius: '0 0 16px 16px',
    transition: 'all 0.3s',
    zIndex: 1300,
    '.search-area': {
      maxWidth: 'fit-content',
      minWidth: 'fit-content',
      margin: 'auto',
      height: 72,
      borderRadius: 48,
      padding: '15px 15px',
      display: 'flex',
      border: '1px solid' + theme.palette.divider,
      justifyContent: 'space-between',
      '.u-form-group': {
        margin: '-3px 0 0 0',
        '.MuiFormControl-root': {
          width: '100%',
          '.MuiInputBase-formControl': {
            borderRadius: 48,
            width: '100%',
            '.MuiInputBase-input': {
              borderRadius: 48,
              padding: 8,
              '&:placeholder': {
                opacity: 1
              }
            },
            svg: {
              path: {
                stroke: theme.palette.grey[300]
              }
            },
            fieldset: {
              borderRadius: 48,
              border: '0 solid transparent'
            }
          },
          '.u-input-label': {
            opacity: 0.5,
            '&.Mui-focused, &.MuiInputLabel-shrink': {
              opacity: 0,
              display: 'none'
            }
          }
        }
      },
      '.search-dropdown': {
        display: 'flex',
        borderRadius: 48,
        justifyContent: 'space-between',
        maxWidth: 'fit-content',
        minWidth: 'fit-content',
        width: 'auto',
        padding: '5px 0 0 5px',
        backgroundColor: StyleVariables.lightGrey,
        '.MuiTypography-root': {
          margin: '4px 0 0 10px',
          width: 'auto'
        },
        '.u-form-group': {
          width: 203,
          margin: '-9px 0 0 5px',
          '.MuiFormControl-root': {
            '.u-select': {
              '.MuiSelect-select': {
                color: theme.palette.common.black,
                fontWeight: 600,
                padding: '7px 40px 7px 5px'
              }
            }
          }
        }
      },
      '.btn-search': {
        padding: 0 + '!important',
        width: 48,
        height: 48,
        display: 'inline-block',
        borderRadius: 43,
        lineHeight: 0,
        marginTop: -4
      }
    }
  },
  '&.hide-search .search-wrap': {
    marginTop: -122
  },
  '&.show-search': {
    '.overlay': {
      visibility: 'visible',
      opacity: 1
    },
    '.search-wrap': {
      marginTop: 0
    }
  }
}))
