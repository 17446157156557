import { useMutation, useQuery } from 'react-query'
import { EP_AUTH_LOGIN, EP_AUTH_GET_USERS, EP_CHANGE_PASSWORD } from '@helpers/endpoints'
import { LocalStorage } from '@helpers/browserStorage'
import Http from '@helpers/http-api'
import { ACCESS_TOKEN_KEY } from '@helpers/constants'

export const useLogin = (): any => {
  const { data, isLoading, mutate } = useMutation<{ email: string; password: string }, any>(
    (body) => {
      const apiService = Http.createConnection()
      return apiService.post<any>(EP_AUTH_LOGIN, body)
    },
    {
      onSuccess(data: any) {
        if (data['access_token']) {
          var _localStorageService: LocalStorage = new LocalStorage()
          _localStorageService.setItembyKey(ACCESS_TOKEN_KEY, data['access_token'])
        }
      }
    }
  )
  return { data, isLoading, mutate }
}

export const useUsers = () => {
  const { data, isLoading, error } = useQuery(EP_AUTH_GET_USERS, () => {
    const apiService = Http.createConnection({
      withAuthentication: true
    })
    return apiService.get<any>(EP_AUTH_GET_USERS, { pageSize: 10 })
  })
  return { data, isLoading, error }
}

export const useChangePassword = (): any => {
  const { data, isLoading, mutateAsync } = useMutation<
    { id: string; old_password: string; new_password: string },
    any
  >(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.patch<any>(`${EP_CHANGE_PASSWORD}/${body.id}`, body)
    },
    {
      onSuccess(data: any) {
        var _localStorageService: LocalStorage = new LocalStorage()
        _localStorageService.setItembyKey(ACCESS_TOKEN_KEY, data['access_token'])
      }
    }
  )
  return { data, isLoading, mutateAsync }
}
