export const APP_ROUTES = {
  BASE_URL: '/dms/',
  CREATE_ORDER: '/dms/create-deal/:vin/:customer_reference_id?' /* pages/vehicle-selection.tsx */,
  CUSTOMER_DETAILS: '/dms/customer-details' /* pages/customer-details.tsx */,
  QUOTATION: '/dms/quotation' /* pages/quotation.tsx */,
  ORDER_SUMMARY: '/dms/deal-summary/:reference_id?' /* pages/order-summary.tsx */,
  LOGIN: '/dms' /* pages/login.tsx */,
  CHANGE_PASSWORD: '/dms/change-password' /* pages/login.tsx */,
  WORKQUEUE: '/dms/workqueue' /* pages/workqueue.tsx */,
  ORDER_STIPULATIONS: '/dms/deal-stipulation',
  INVENTORY_MANAGEMENT: '/dms/inventory-management' /* pages/inventory-management.tsx */,
  AddOns: '/dms/dealer-add-ons' /*pages/dealer-add-ons.tsx */,
  DEALER_CONFIGURATIONS: '/dms/dealer-configurations' /*pages/dealer-configurations.tsx */,
  FINANCIAL_INSURANCE: '/dms/financial-insurance' /*pages/financial-insurance-product.tsx */,
  CREATE_ORDER_QUOTATION:
    '/dms/create-deal-ui/:vin/:customer_reference_id?/:reference_id?/:reschedule_popup?' /*pages/create-order-ui.tsx */,
  DEALER_WORK_QUEUE: '/dms/dealer/work-queue' /*pages/dealer-work-queue.tsx */,
  DEALER_WORK_QUEUE_VEHICLE_AVAILABLE:
    '/dms/dealer/work-queue/vehicle-check/:id' /*pages/dealer-work-queue.tsx */,
  IN_PROGRESS: '/dms/dealer/in-progress' /* pages/in-progress-ui.tsx */,
  DEALER_GET_READY_CHECKLIST:
    '/dms/dealer/work-queue/checklist/:id' /*pages/dealer-vehicle-ready-checklist.tsx */,
  DEALER_VEHICLE_SCHEDULE_PICKUP:
    '/dms/dealer/work-queue/schedule-pickup/:id' /*pages/dealer-vehicle-schedule-pickup.tsx */,
  DEALER_VEHICLE_RETURN:
    '/dms/dealer/work-queue/vehicle-return/:id/:identifier' /*pages/dealer-vehicle-return.tsx */,
  NEW_HOME_PAGE: process.env.UNITY_FE_BASE_URL + `/market-place`,
  ALLOWED_PRODUCTS: process.env.UNITY_FE_BASE_URL + '/market-place/allowed-products',
  SSO_LOGIN: '/dms/sso-login',
  ORDER_APPROVED: '/dms/deal-approved/:reference_id' /* pages/order-summary.tsx */,
  ORDER_CANCELLED: '/dms/deal-cancelled/:reference_id' /* pages/order-summary.tsx */,
  ORDER_REVIEW: '/dms/deal-review/:reference_id/:customer_reference_id/:vin?',
  QUOTE_COMPARISON: '/dms/quote-comparison/:customer_email/:reference_id',
  FEEDBACK: '/dms/submit-feedback',
  MANAGE_USERS: '/dms/manage-users',
  APP_USER_PROFILE: '/market-place/app-user-profile',
  USER_PROFILE: '/dms/user-profile',
  DASHBOARD: '/dms/product-analytics',
  NOTFOUND: '/dms/not-found',
  DASHBOARDIMAGE: '/dms/business-analytics',
  DEALERSHIP_PERFORMANCE: '/dms/dealership-performance',
  BMW_DASHBOARD_OF_MANHATTAN: '/dms/dealer-performance',
  Customer_Journey_Analytics: '/dms/customer-journey-analytics',
  COBROWSING: '/dms/co-browsing' /* pages/workqueue.tsx */,
  CANCELLED_ORDERS: '/dms/dealer/work-queue/deal-cancelled/:id',
  COMPLETED_ORDERS: '/dms/dealer/work-queue/deal-completed/:id',
  LEAD_SUMMARY: '/dms/customer-journey-analytics/lead-summary',
  TESTINGPAGE: '/dms/testing-page',
  DESKING: '/dms/desking',
  UNAUTHORIZED: '/dms/unauthorized'
}
