import { FC, useState, useEffect } from 'react'
import { useTheme } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Box, Button, Grid, Icon } from '@ntpkunity/controls'
import { BtnTabs, ConfirmationDialog } from '@components'
import {
  DeskingActionTypes,
  OrderStage,
  useDeskingContext,
  VehiclesForComparisonTypes
} from './desking-context-provider'
import { FLButtons } from './fl-buttons'
import { createOrderRequestPayloadFromState } from '@app/desking/utils'
import { useSaveOrderRequest, useUpdateQuotationById } from '@apis/order-management.service'
import { useStoreContext } from '@store/storeContext'
import { ICustomer, IDealerFee, IVehicle } from '@models'
import { useUpdateOrderStatusAndLender } from '@apis/dealer-workqueue.service'
import { useSendCustomerOrderLink } from '@apis/customer-management.service'
import { FeeEvent, FinanceType } from '@helpers/enums/finance-type.enum'
import {
  CONTINUE_DEAL,
  FEE_MONTHLY_PAYMENT_BASED,
  UPDATE_DEAL,
  RESERVE_ASSET,
  SAVE_AND_CONTINUE
} from '@helpers/constants'
import { useGetReservation } from '@apis/configurations.service'
import ReservationDialog from 'controls/reservation-dialog'
import { useGetInventoryByVin } from '@apis/inventory-management.service'
import { useGetVehicleDetails } from '@apis/dealer-addon.service'
import { DialogMessages, InventoryType, Status, Vehicle_Status } from '@helpers/enums'
import { APP_ROUTES } from '@helpers/links'
import { useEditPermissionHook } from '@hooks/useEditPermissionHook'

export const FinanceLeaseHeader: FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [showPayment, setShowPayment] = useState(false)
  const [showVehicleNotAvailable, setShowVehicleNotAvailable] = useState<boolean>(false)
  const orderReferenceId = searchParams.get('reference_id')
  const vsrSelectedVin = searchParams.get('vin')
  const {
    states: {
      dealDesk: { dealerFees },
      dealerInfo
    }
  } = useStoreContext()
  const { states } = useStoreContext()
  const { state, dispatch } = useDeskingContext()
  const {
    vehiclesForComparison,
    order,
    customer,
    isVehicleSelectedAndOrderSaved,
    lender_id,
    submission_tier
  } = state

  const canUserEdit = useEditPermissionHook()

  const { vin } = state?.selection
  const {
    mutate: getVehicleByVin,
    data: vehicle,
    isLoading: vehicleLoading
  } = useGetInventoryByVin()
  const { mutate: getVehicleDetails, isLoading: vehicleDetailsLoading } = useGetVehicleDetails()

  const dealerCode = states?.dealerInfo?.dealer_code
  const vehicleDealerCode = state?.vehiclesData?.get(vin)?.vehicle?.dealer_code
  const isDealerCodeMatch = vehicleDealerCode === dealerCode

  useEffect(() => {
    if (vin) {
      getVehicleByVin(
        { vin: vin },
        {
          onSuccess(_response: any) {}
        }
      )
    }
  }, [vin, getVehicleByVin])

  useEffect(() => {
    if (vsrSelectedVin && states?.dealerInfo?.company_id) {
      getVehicleDetails(
        { vin: vsrSelectedVin },
        {
          onSuccess(response: any) {
            if (response?.vehicle_status === Vehicle_Status.Available) {
              getVehicleByVin(
                { vin: vsrSelectedVin },
                {
                  onSuccess(vehicle: IVehicle) {
                    dispatch({
                      type: DeskingActionTypes.ADD_VEHICLE_VIN,
                      payload: vehicle
                    })
                  }
                }
              )
            } else setShowVehicleNotAvailable(true)
          },
          onError(error: any) {
            const vehicleNotFound = error?.response?.status === 404
            if (vehicleNotFound) setShowVehicleNotAvailable(true)
          }
        }
      )
    }
  }, [vsrSelectedVin, states?.dealerInfo?.company_id])

  useEffect(() => {
    if (vehicleLoading || vehicleDetailsLoading)
      dispatch({
        type: DeskingActionTypes.ADD_VEHICLE_LOADING,
        payload: true
      })
    else
      dispatch({
        type: DeskingActionTypes.ADD_VEHICLE_LOADING,
        payload: false
      })
  }, [vehicleLoading, vehicleDetailsLoading])

  const { data: getReservation } = useGetReservation(states?.dealerInfo?.dealer_code)

  const handleClosePayment = () => {
    setShowPayment(false)
    if (state.proceededWithoutPayment) {
      dispatch({
        type: DeskingActionTypes.SKIP_RESERVATION,
        payload: true
      })
      dispatch({
        type: DeskingActionTypes.UPDATE_PROCEEDED_WITHOUT_PAYMENT,
        payload: false
      })
      dispatch({
        type: DeskingActionTypes.SAVE_ORDER,
        payload: false
      })
    }
  }

  const { mutate: saveOrderRequest, isLoading: saveRequestLodaing } = useSaveOrderRequest()
  const { mutateAsync: updateOrderRequest, isLoading: updateQuotationLoading } =
    useUpdateQuotationById()
  const { mutate: updateOrderStatus } = useUpdateOrderStatusAndLender()
  const { mutate: sendCustomerOrderLink, isLoading: sendCustomerOrderLinkLoading } =
    useSendCustomerOrderLink()

  const updateorderStatus = () => {
    const payload = {
      order_id: order?.reference_number,
      lender_id
    }

    updateOrderStatus(payload, {
      async onSuccess() {
        navigate(`/lane/deal-approved/${order?.reference_number}`)
      }
    })
  }

  const onSendOrderLinkToCustomer = () => {
    sendCustomerOrderLink({
      reference_number: order?.reference_number
    })
  }

  const isContinueDealDisabled = () => {
    const isOtherInventoryWithMatchingDealer =
      getReservation?.reservation?.applicable_to === InventoryType.OtherInventory &&
      isDealerCodeMatch

    return (
      saveRequestLodaing ||
      (isVehicleSelectedAndOrderSaved &&
        !submission_tier &&
        !state.hasUnsavedOrderChanges &&
        !state.proceededWithoutPayment &&
        !state.skipReservation &&
        !isOtherInventoryWithMatchingDealer)
    )
  }
  const isUpdateDealDisabled = () => {
    const vehiclesData = state.vehiclesData.get(vin)
    const program = (vehiclesData?.program ?? {})?.[state.finance_type]?.find(
      (p: any) =>
        p?.contract_term === state.order?.contract_term &&
        (state.finance_type?.toLowerCase() === FinanceType.FINANCE || p?.mileage === state.order?.annual_usage)
    )

    return getPrimaryButtonText() === UPDATE_DEAL && (state.isProgramLoading || !program)
  }

  const getPrimaryButtonText = () => {
    const isReservationApplicable =
      getReservation?.reservation?.applicable_to !== null &&
      getReservation?.reservation?.applicable_to !== undefined
    const isOtherInventoryWithMatchingDealer =
      getReservation?.reservation?.applicable_to === InventoryType.OtherInventory &&
      isDealerCodeMatch

    if (
      isReservationApplicable &&
      getReservation?.reservation?.reservation_amount &&
      isVehicleSelectedAndOrderSaved &&
      !state.skipReservation &&
      !state.proceededWithoutPayment &&
      !submission_tier &&
      !state?.order?.order_payments &&
      !isOtherInventoryWithMatchingDealer
    ) {
      return RESERVE_ASSET
    }
    if (isVehicleSelectedAndOrderSaved) {
      return submission_tier ? UPDATE_DEAL : CONTINUE_DEAL
    }
    return SAVE_AND_CONTINUE
  }

  const shouldShowPayment = () => {
    const isOtherInventoryWithMatchingDealer =
      getReservation?.reservation?.applicable_to === InventoryType.OtherInventory &&
      isDealerCodeMatch

    return (
      getReservation?.reservation?.applicable_to !== null &&
      getReservation?.reservation?.applicable_to !== undefined &&
      getReservation?.reservation?.reservation_amount &&
      isVehicleSelectedAndOrderSaved &&
      !state.skipReservation &&
      !state.proceededWithoutPayment &&
      !state?.order?.order_payments &&
      !submission_tier &&
      !isOtherInventoryWithMatchingDealer
    )
  }

  const isReserveAssetButtonEnabled = () => {
    const isOtherInventoryWithMatchingDealer =
      getReservation?.reservation?.applicable_to === InventoryType.OtherInventory &&
      isDealerCodeMatch

    return (
      isVehicleSelectedAndOrderSaved &&
      getReservation?.reservation?.applicable_to !== null &&
      getReservation?.reservation?.applicable_to !== undefined &&
      !state.skipReservation &&
      !state.proceededWithoutPayment &&
      !isOtherInventoryWithMatchingDealer
    )
  }

  const handlePrimaryButtonClick = async () => {
    if (!vehicle && vin) {
      return
    }

    if (shouldShowPayment()) {
      const orderReqObj = createOrderRequestPayloadFromState(state, dealerInfo, lender_id)
      updateOrderRequest(orderReqObj, {
        onSuccess(_response: any) {
          dispatch({
            type: DeskingActionTypes.SAVE_ORDER,
            payload: false
          })
        }
      })
      setShowPayment(true)
      return
    }

    if (orderReferenceId && isVehicleSelectedAndOrderSaved && !submission_tier) {
      const orderReqObj = createOrderRequestPayloadFromState(state, dealerInfo, lender_id)
      const payload = { ...orderReqObj, order_stage: OrderStage.APPLICATION }
      updateOrderRequest(payload, {
        onSuccess() {
          if (state.order?.status === Status.PendingConfirmation) navigate(`/lane/deal-summary/${orderReferenceId}`)
        }
      })
    } else {
      const orderReqObj = createOrderRequestPayloadFromState(state, dealerInfo, lender_id)
      if (orderReferenceId) {
        updateOrderRequest(orderReqObj, {
          onSuccess(_response: any) {
            if (submission_tier) {
              updateorderStatus()
              return
            }
            dispatch({
              type: DeskingActionTypes.VEHICLE_SELECTED_AND_QUOTATION_SAVED,
              payload: true
            })
          }
        })
      } else {
        const eotFees = dealerFees[state?.finance_type]
          ?.filter((fee: IDealerFee) => fee.is_active && fee.event === FeeEvent.ENDOFTERM)
          ?.map((fee: IDealerFee) => {
            if (fee.calculation_type === FEE_MONTHLY_PAYMENT_BASED) {
              const multipliedValue =
                state?.selection?.calculations?.monthly_payment * fee.multiplication_factor
              if (multipliedValue < (fee?.applied_price ?? fee.default_amount)) {
                fee.applied_price = multipliedValue
              }
            }
            return {
              dealer_fee_id: fee.id,
              fee_name: fee.fee_name,
              applied_price: fee?.applied_price ?? fee.default_amount,
              fee_handling: fee?.fee_handling,
              is_active: fee?.is_active,
              applicable_finance_type: fee?.applicable_finance_type,
              is_taxable: true,
              vendor: fee?.vendor,
              fee_type: fee?.event
            }
          })

        if (eotFees?.length > 0) orderReqObj.order_fees = [...orderReqObj.order_fees, ...eotFees]
        saveOrderRequest([orderReqObj], {
          onSuccess(response: any) {
            const params = new URLSearchParams()
            params.set('reference_id', response[0]?.reference_number ?? '')
            params.set('customer_reference_id', (customer as ICustomer).reference_id ?? '')
            setSearchParams(params)
            dispatch({
              type: DeskingActionTypes.VEHICLE_SELECTED_AND_QUOTATION_SAVED,
              payload: true
            })
          }
        })
      }
    }
  }

  const hasValidationError = () => {
    if (
      state.isVehicleSelectedAndOrderSaved &&
      (state.preview?.validation_errors?.down_payment || state.preview?.validation_errors?.rv)
    ) {
      return true
    }

    const currentCalculations =
      state[state.vehiclesForComparison][state.finance_type]?.validation_errors
    if (
      currentCalculations &&
      [...(currentCalculations?.down_payment ?? []), ...(currentCalculations?.rv ?? [])]?.some(
        (item: boolean) => item === true
      )
    ) {
      return true
    }

    return false
  }

  const hideVehicleNotAvailableHandler = () => {
    setShowVehicleNotAvailable(false)
    navigate(APP_ROUTES.DESKING)
  }
  const isVehicleAndCustomerAdded =
    Object.keys(state.customer).length !== 0 &&
    state.selection &&
    Object.keys(state.selection).length !== 0

  const isDisabled =
    (isContinueDealDisabled() && !isReserveAssetButtonEnabled() && state.hasUnsavedOrderChanges) ||
    !canUserEdit ||
    updateQuotationLoading ||
    isUpdateDealDisabled() ||
    !isVehicleAndCustomerAdded ||
    hasValidationError()

  return (
    <Box theme={theme} className="fl-header-wrap">
      <Grid theme={theme} container columnSpacing={1}>
        <Grid theme={theme} item xl={3} lg={3.5} md={4} sm={4} xs={4} pt={0.5}>
          <FLButtons />
        </Grid>
        <Grid theme={theme} item xl={3} lg={3.5} md={4} sm={4} xs={4} pt={0.5}>
          {!submission_tier && (
            <Box theme={theme} display={'flex'} justifyContent={'end'}>
              <BtnTabs theme={theme} className="btn-tabs rounded">
                <Button
                  theme={theme}
                  type="button"
                  className={
                    vehiclesForComparison === VehiclesForComparisonTypes.SINGLE ? 'selected' : ''
                  }
                  iconText={<Icon name="GridView" />}
                  defaultBtn
                  onClick={() => {
                    dispatch({
                      type: DeskingActionTypes.UPDATE_VEHICLES_FOR_COMPARISON,
                      payload: VehiclesForComparisonTypes.SINGLE
                    })
                  }}
                />
                <Button
                  theme={theme}
                  type="button"
                  className={
                    vehiclesForComparison === VehiclesForComparisonTypes.MULTIPLE ? 'selected' : ''
                  }
                  iconText={<Icon name="ListView" />}
                  defaultBtn
                  onClick={() => {
                    dispatch({
                      type: DeskingActionTypes.UPDATE_VEHICLES_FOR_COMPARISON,
                      payload: VehiclesForComparisonTypes.MULTIPLE
                    })
                  }}
                  disabled={true}
                />
              </BtnTabs>
            </Box>
          )}
        </Grid>
        <Grid theme={theme} item xl={6} lg={5} md={4} sm={4} xs={4}>
          <Box theme={theme} className="fl-action-btn">
            {isVehicleSelectedAndOrderSaved && !submission_tier && (
              <Button
                theme={theme}
                secondary
                size="small"
                text="Save"
                disabled={!canUserEdit || hasValidationError()}
                onClick={() => {
                  const orderReqObj = createOrderRequestPayloadFromState(
                    state,
                    dealerInfo,
                    lender_id
                  )
                  updateOrderRequest(orderReqObj, {
                    onSuccess(_response: any) {
                      dispatch({
                        type: DeskingActionTypes.SAVE_ORDER,
                        payload: false
                      })
                    }
                  })
                }}
              />
            )}
            {isVehicleSelectedAndOrderSaved ? (
              <Button
                theme={theme}
                secondary
                disabled={sendCustomerOrderLinkLoading || !canUserEdit || hasValidationError()}
                size="small"
                text="Send To Customer"
                onClick={onSendOrderLinkToCustomer}
              />
            ) : null}
            <Button
              theme={theme}
              primary
              size="small"
              text={getPrimaryButtonText()}
              disabled={isDisabled}
              onClick={handlePrimaryButtonClick}
            />
          </Box>
        </Grid>
      </Grid>
      {showPayment && <ReservationDialog onClose={handleClosePayment} />}
      <ConfirmationDialog
        openPopUp={showVehicleNotAvailable}
        confirmationTitle={DialogMessages.assetUnavailableDialogueTitle}
        confirmationText={DialogMessages.assetUnavailableDialogueSubTitle}
        primaryBtnText={DialogMessages.assetUnavailableDialogueBtnText}
        icon={<Icon name="DialogCrossIcon" />}
        hideCancelButton={true}
        setPopUpState={setShowVehicleNotAvailable}
        onConfirm={hideVehicleNotAvailableHandler}
        onCancel={hideVehicleNotAvailableHandler}
      />
    </Box>
  )
}
