import { FC } from 'react'
import { useTheme } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Box, Button, Grid, Icon } from '@ntpkunity/controls'
import { BtnTabs } from '@components'
import {
  DeskingActionTypes,
  useDeskingContext,
  VehiclesForComparisonTypes
} from './desking-context-provider'
import { FLButtons } from './fl-buttons'
import { createOrderRequestPayloadFromState } from '@app/desking/utils'
import {
  useConvertQuotationToApplication,
  useSaveOrderRequest,
  useUpdateQuotationById
} from '@apis/order-management.service'
import { useStoreContext } from '@store/storeContext'
import { ICustomer } from '@models'
import { useUpdateOrderStatusAndLender } from '@apis/dealer-workqueue.service'
import { useSendCustomerOrderLink } from '@apis/customer-management.service'

export const FinanceLeaseHeader: FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const orderReferenceId = searchParams.get('reference_id')
  const {
    states: {
      dealerInfo: { company_id, id }
    }
  } = useStoreContext()
  const { state, dispatch } = useDeskingContext()
  const {
    vehiclesForComparison,
    order,
    customer,
    isVehicleSelectedAndOrderSaved,
    lender_id,
    submission_tier
  } = state

  const {
    mutate: saveOrderRequest,
    isLoading: saveRequestLodaing,
    data: _saveResponse,
    error: _saveRequestError
  } = useSaveOrderRequest()
  const {
    mutateAsync: updateOrderRequest,
    isLoading: _updateRequestLoading,
    data: _updateResponse,
    error: _updateRequestError
  } = useUpdateQuotationById()
  const { mutate: convertQuotationRequest } = useConvertQuotationToApplication()
  const { mutate: updateOrderStatus } = useUpdateOrderStatusAndLender()
  const { mutate: sendCustomerOrderLink, isLoading: sendCustomerOrderLinkLoading } =
    useSendCustomerOrderLink()

  const updateorderStatus = () => {
    const payload = {
      order_id: order?.reference_number,
      lender_id
    }

    updateOrderStatus(payload, {
      async onSuccess() {
        navigate(`/dms/deal-approved/${order?.reference_number}`)
      }
    })
  }

  const onSendOrderLinkToCustomer = () => {
    sendCustomerOrderLink({
      reference_number: order?.reference_number
    })
  }

  return (
    <Box theme={theme} className="fl-header-wrap">
      <Grid theme={theme} container columnSpacing={1}>
        <Grid theme={theme} item xl={3} lg={3.5} md={4} sm={4} xs={4} pt={0.5}>
          <FLButtons />
        </Grid>
        <Grid theme={theme} item xl={3} lg={3.5} md={4} sm={4} xs={4} pt={0.5}>
          {!submission_tier && (
            <Box theme={theme} display={'flex'} justifyContent={'end'}>
              <BtnTabs theme={theme} className="btn-tabs rounded">
                <Button
                  theme={theme}
                  type="button"
                  className={
                    vehiclesForComparison === VehiclesForComparisonTypes.SINGLE ? 'selected' : ''
                  }
                  iconText={<Icon name="GridView" />}
                  defaultBtn
                  onClick={() => {
                    dispatch({
                      type: DeskingActionTypes.UPDATE_VEHICLES_FOR_COMPARISON,
                      payload: VehiclesForComparisonTypes.SINGLE
                    })
                  }}
                />
                <Button
                  theme={theme}
                  type="button"
                  className={
                    vehiclesForComparison === VehiclesForComparisonTypes.MULTIPLE ? 'selected' : ''
                  }
                  iconText={<Icon name="ListView" />}
                  defaultBtn
                  onClick={() => {
                    dispatch({
                      type: DeskingActionTypes.UPDATE_VEHICLES_FOR_COMPARISON,
                      payload: VehiclesForComparisonTypes.MULTIPLE
                    })
                  }}
                />
              </BtnTabs>
            </Box>
          )}
        </Grid>
        <Grid theme={theme} item xl={6} lg={5} md={4} sm={4} xs={4}>
          <Box theme={theme} className="fl-action-btn">
            {orderReferenceId && state.isVehicleSelectedAndOrderSaved && !submission_tier && (
              <Button
                theme={theme}
                secondary
                size="small"
                text="Save"
                onClick={() => {
                  const orderReqObj = createOrderRequestPayloadFromState(
                    state,
                    company_id,
                    lender_id,
                    id
                  )
                  updateOrderRequest(orderReqObj, {
                    onSuccess(_response: any) {
                      dispatch({
                        type: DeskingActionTypes.SAVE_ORDER,
                        payload: false
                      })
                    }
                  })
                }}
              />
            )}
            {isVehicleSelectedAndOrderSaved ? (
              <Button
                theme={theme}
                secondary
                disabled={sendCustomerOrderLinkLoading}
                size="small"
                text="Send To Customer"
                onClick={onSendOrderLinkToCustomer}
              />
            ) : null}
            <Button
              theme={theme}
              secondary
              size="small"
              text={
                isVehicleSelectedAndOrderSaved
                  ? submission_tier
                    ? 'Update Deal'
                    : 'Continue Deal'
                  : 'Save & Continue'
              }
              disabled={
                saveRequestLodaing ||
                Object.keys(state.customer).length === 0 ||
                !state.selection ||
                Object.keys(state.selection).length === 0 ||
                (isVehicleSelectedAndOrderSaved && !submission_tier && state.hasUnsavedOrderChanges)
              }
              onClick={() => {
                if (orderReferenceId && isVehicleSelectedAndOrderSaved && !submission_tier) {
                  const updatedOrderPayload = {
                    ...order,
                    changeVehicleStatus: false
                  }
                  convertQuotationRequest(updatedOrderPayload, {
                    onSuccess(res: any) {
                      dispatch({
                        type: DeskingActionTypes.UPDATE_STAGE,
                        payload: res.order_stage
                      })
                    }
                  })
                } else {
                  const orderReqObj = createOrderRequestPayloadFromState(
                    state,
                    company_id,
                    lender_id,
                    id
                  )
                  if (orderReferenceId) {
                    updateOrderRequest(orderReqObj, {
                      onSuccess(_response: any) {
                        if (submission_tier) {
                          updateorderStatus()
                          return
                        }
                        dispatch({
                          type: DeskingActionTypes.VEHICLE_SELECTED_AND_QUOTATION_SAVED,
                          payload: true
                        })
                      }
                    })
                  } else {
                    saveOrderRequest([orderReqObj], {
                      onSuccess(response: any) {
                        const params = new URLSearchParams()
                        params.set('reference_id', response[0]?.reference_number ?? '')
                        params.set(
                          'customer_reference_id',
                          (customer as ICustomer).reference_id ?? ''
                        )
                        setSearchParams(params)
                        dispatch({
                          type: DeskingActionTypes.VEHICLE_SELECTED_AND_QUOTATION_SAVED,
                          payload: true
                        })
                      }
                    })
                  }
                }
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
