import { FC } from 'react'
import { styled } from '@mui/material/styles'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'

import { IPersistentDrawerProps } from './PersistentDrawer.types'
import { Icon } from '@ntpkunity/controls'
import { Box, Typography } from 'components'

const drawerWidth = 424

const DrawerHeader = styled('div')(({ theme }) => ({
  '&.drawer-header': {
    padding: '24px 65px',
    borderBottom: '1px solid' + theme.palette.grey[100],
    '.u-drawer-title': {
      textAlign: 'center',
      textTransform: 'capitalize'
    },
    '.u-drawer-close': {
      position: 'absolute',
      right: 24,
      width: 24,
      height: 24,
      padding: 0,
      backgroundColor: 'transparent !important',
      svg: {
        path: {
          stroke: theme.palette.grey[300]
        }
      },
      '.MuiTouchRipple-root': {
        display: 'none'
      }
    }
  }
}))

const CustomDrawer = styled(Drawer)(({ theme }) => ({
  '&.custom-drawer': {
    width: drawerWidth,
    '.MuiDrawer-paper': {
      width: drawerWidth,
      backgroundColor: theme.palette.common.white,
      border: '1px solid' + theme.palette.grey[50],
      top: 8,
      right: 8,
      height: 'calc(100% - 18px)',
      boxShadow: '0px 32px 64px rgba(0, 0, 0, 0.16)',
      borderRadius: 16,
      color: theme.palette.grey[900],
      overflow: 'hidden'
    },
    '.drawer-content-wrap': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
      '.drawer-body': {
        padding: 40,
        height: '100%',
        flexGrow: 1,
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'relative',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          width: 0,
          height: 0
        },
        '&::-webkit-scrollbar-track': {
          padding: '0 0px',
          backgroundColor: 'transparent'
        },
        '&::-webkit-scrollbar-thumb': {
          transition: 'all 0.3s',
          backgroundColor: 'transparent'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: 'transparent'
        }
      },
      '.drawer-footer': {
        padding: '32px 40px 40px 40px',
        position: 'relative',
        '&::before': {
          display: 'block',
          content: "''",
          borderBottomStyle: 'solid',
          borderBottomWidth: 1,
          height: 0,
          borderBottomColor: theme.palette.grey[100],
          position: 'absolute',
          top: 0,
          width: 'calc(100% - 80px)'
        }
      }
    },
    '.divider-with-text': {
      borderTop: '1px solid' + theme.palette.grey[100],
      position: 'relative',
      textAlign: 'center',
      marginTop: 28,
      display: 'block',
      marginBottom: 8,
      height: 16,
      '.divider-text': {
        fontSize: '10px',
        textTransform: 'uppercase',
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.grey[600],
        backgroundColor: theme.palette.common.white,
        display: 'inline-block',
        position: 'relative',
        top: -12,
        padding: '0 8px'
      }
    },
    hr: {
      opacity: 0.4
    },
    '.spr-title': {
      span: {
        display: 'table',
        fontFamily: theme.typography.fontFamily,
        fontSize: 10,
        color: theme.palette.grey[600],
        textAlign: 'center',
        textTransform: 'uppercase',
        margin: 'auto',
        padding: '0 5px',
        backgroundColor: 'white',
        position: 'relative',
        top: -16
      }
    }
  }
}))

const PersistentDrawer: FC<IPersistentDrawerProps> = ({
  openPopUp,
  setPopUpState,
  title,
  customFooter,
  children,
  theme
}) => {
  const handleDrawerClose = () => {
    setPopUpState(false)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CustomDrawer
        className="custom-drawer"
        theme={theme}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth
          }
        }}
        variant="persistent"
        anchor="right"
        open={openPopUp}
      >
        <DrawerHeader theme={theme} className="drawer-header">
          <Typography variant="h4" component="h4" className="u-drawer-title">
            {title}
            <IconButton className="u-drawer-close" aria-label="close" onClick={handleDrawerClose}>
              <Icon name="CloseBlack" />
            </IconButton>
          </Typography>
        </DrawerHeader>
        <Box className="drawer-content-wrap">
          <Box className="drawer-body">{children}</Box>
          <Box className="drawer-footer">{customFooter}</Box>
        </Box>
      </CustomDrawer>
    </Box>
  )
}

export default PersistentDrawer
