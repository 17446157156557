import { FC, useEffect } from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { Button, Switch, Box, Input } from 'components'
import { unityTheme, Icon, Menu, DataTable, Grid, IGridProps } from '@ntpkunity/controls'
import {
  useDeleteDealerFeeById,
  useGetDealerFee,
  useUpdateDealerFeeById,
  useGetDealerFeeById
} from '@apis/dealer-configurations.service'
import { IDealerFee } from '@models'
import { useStoreContext } from '@store/storeContext'
import { styled } from '@mui/material'

const DataTableWrap = styled(
  Grid,
  {}
)<Partial<IGridProps>>(() => ({
  '.u-table-wrap': {
    '.u-table-container': {
      maxHeight: '50vh !important'
    }
  }
}))
const DealerFeesTable: FC<{ onEdit: (data: any) => unknown }> = ({ onEdit }) => {
  const { mutate: getDealerFee } = useGetDealerFee()
  const { mutate: deleteDealerFee } = useDeleteDealerFeeById()
  const { mutate: updateDealerFee } = useUpdateDealerFeeById()
  const { mutate: getDealerFeeById } = useGetDealerFeeById()

  const { states, actions } = useStoreContext()
  useEffect(() => {
    getDealerFee()
  }, [])
  return (
    <>
      <DataTableWrap theme={unityTheme} sx={{ mb: 3 }}>
        <DataTable
          theme={unityTheme}
          variant={'basic'}
          theadChildren={
            <>
              <TableRow key={0}>
                <TableCell className="checkbox-cell fixed-left"></TableCell>
                <TableCell>Fee Name</TableCell>
                <TableCell>State</TableCell>
                <TableCell>Taxable</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Default Amount</TableCell>
                <TableCell className="action-cell"></TableCell>
              </TableRow>
              <TableRow key={1} className="filters-row">
                <TableCell className="checkbox-cell"></TableCell>
                <TableCell>
                  <Box theme={unityTheme} className="table-filter">
                    <Input
                      theme={unityTheme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={''}
                    />
                    <Button defaultBtn iconText={<Icon name="IconFilter" />}></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={unityTheme} className="table-filter">
                    <Input
                      theme={unityTheme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={''}
                    />
                    <Button defaultBtn iconText={<Icon name="IconFilter" />}></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={unityTheme} className="table-filter">
                    <Input
                      theme={unityTheme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={''}
                    />
                    <Button defaultBtn iconText={<Icon name="IconFilter" />}></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={unityTheme} className="table-filter">
                    <Input
                      theme={unityTheme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={''}
                    />
                    <Button defaultBtn iconText={<Icon name="IconFilter" />}></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={unityTheme} className="table-filter">
                    <Input
                      theme={unityTheme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={''}
                    />
                    <Button defaultBtn iconText={<Icon name="IconFilter" />}></Button>
                  </Box>
                </TableCell>

                <TableCell className="action-cell"></TableCell>
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              {states.dealerFeeData?.map((dealerFee: IDealerFee, index: number) => {
                return (
                  <TableRow key={index} className="child-tr">
                    <TableCell className="checkbox-cell fixed-left"></TableCell>
                    <TableCell>{dealerFee.fee_name}</TableCell>
                    <TableCell>{dealerFee.state_name}</TableCell>
                    <TableCell>
                      <Switch
                        theme={unityTheme}
                        varient={'basic'}
                        label={dealerFee.taxable ? 'Yes' : 'No'}
                        switchEnabled={dealerFee.taxable}
                        onChange={(event) => {
                          updateDealerFee({
                            ...dealerFee,
                            taxable: event.target.checked
                          })
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Switch
                        theme={unityTheme}
                        varient={'basic'}
                        label={dealerFee.is_active ? 'Enabled' : 'Disabled'}
                        switchEnabled={dealerFee.is_active}
                        onChange={(event) => {
                          updateDealerFee({
                            ...dealerFee,
                            is_active: event.target.checked
                          })
                        }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Box sx={{ mr: 3.8 }}>
                        ${dealerFee.default_amount.toLocaleString('en-US')}
                      </Box>
                    </TableCell>
                    <TableCell className="action-cell fixed-cell">
                      <Menu
                        theme={unityTheme}
                        options={[
                          {
                            optionText: (
                              <>
                                <Icon className="menu-icon" name="EditIcon" />
                                {'Edit'}
                              </>
                            ),
                            optionValue: 'edit'
                          },
                          {
                            optionText: (
                              <>
                                <Icon className="menu-icon" name="DeleteIcon" />
                                {'Delete'}
                              </>
                            ),
                            optionValue: 'delete'
                          }
                        ]}
                        handleOptionClick={(_event, _key, value) => {
                          switch (value) {
                            case 'delete':
                              deleteDealerFee({ id: dealerFee.id })
                              actions.deleteDealerFeeRow({
                                ...dealerFee
                              })
                              actions.setToast({
                                toastMessage: 'Record Deleted Sucessfully',
                                toastState: true
                              })
                              break
                            case 'edit':
                              getDealerFeeById(
                                { id: dealerFee.id },
                                {
                                  onSuccess(response: IDealerFee) {
                                    onEdit(response)
                                  }
                                }
                              )
                              break
                          }
                        }}
                        render={(onMenuSelection) => (
                          <Button
                            defaultBtn
                            iconText={<Icon name="MoreIcon" />}
                            onClick={onMenuSelection}
                          ></Button>
                        )}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right'
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right'
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            </>
          }
        ></DataTable>
      </DataTableWrap>
    </>
  )
}

export default DealerFeesTable
