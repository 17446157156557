import { useGetIntegrationByProviderName } from '@apis/customer-detail.service'
import {
  useGetFinancialInsuranceProductsByDealerId,
  useGetFnIProductRate
} from '@apis/financial-insurance.service'
import { IntegrationProvider, LenderIntegrationType } from '@helpers/enums'
import { useTheme } from '@mui/material'
import { DuoTab, Box } from '@ntpkunity/controls'
import { FC, useEffect, useState } from 'react'
import FinancialInsurancePopup from './finance-insurance-popup'

import {
  FinanceInsuranceControlProps,
  FinanceLeaseItem,
  FniPriceState,
  ProductInfo,
  SumState
} from './finance-insurance-types'
import { DialogTotal } from '@components'

const FinanceInsuranceControl: FC<FinanceInsuranceControlProps> = ({
  dealerProfileData,
  defaultCurrency,
  fniData,
  setFniData,
  terms,
  apr,
  selectedFinanceType,
  selectedVehicle,
  isMSP = false
}) => {
  const theme = useTheme()
  const [productTypes, setProductTypes] = useState<Array<any>>([])
  const [mspProductTypes, setMspProductTypes] = useState<Array<any>>([])
  const [products_info, setNewProductsInfo] = useState<ProductInfo[]>([])
  const isFinanceType = selectedFinanceType === 'Finance'
  const [activeTab, _] = useState<any>(isFinanceType ? 0 : 1)
  const [selectedFinanceItems, setSelectedFinanceItems] = useState<FinanceLeaseItem[]>([])
  const [selectedLeaseItems, setSelectedLeaseItems] = useState<FinanceLeaseItem[]>([])
  const [penEnabled, setPenEnabled] = useState<boolean>(false)
  const { mutate: getPenIntegrations } = useGetIntegrationByProviderName(
    IntegrationProvider.PEN,
    LenderIntegrationType.FNI_PRODUCTS
  )

  const [fniPrice, setFniPrice] = useState<FniPriceState>({ finance: [], lease: [] })
  const { mutate: getFandIProducts, data: fniProducts } =
    useGetFinancialInsuranceProductsByDealerId()
  const { mutate: getFinanceProductRates, data: financeProductRates } = useGetFnIProductRate()
  const { mutate: getLeaseProductRates, data: leaseProductRates } = useGetFnIProductRate()
  const [sum, setSum] = useState<SumState>({
    finance: '0.00',
    lease: '0.00',
    fniFinance: '0.00',
    fniLease: '0.00',
    mspFinance: '0.00',
    mspLease: '0.00'
  })
  useEffect(() => {
    setFniPrice({
      finance: [],
      lease: []
    })
    setSelectedFinanceItems(fniData?.finance || [])
    setSelectedLeaseItems(fniData?.lease || [])
    setSum({
      ...sum,
      finance: fniData?.finance?.length > 0 ? getTotal(fniData?.finance) : '0.00',
      lease: fniData?.lease?.length > 0 ? getTotal(fniData?.lease) : '0.00'
    })
  }, [])

  useEffect(() => {
    if (
      products_info?.length == fniProducts?.result?.length &&
      products_info?.length > 0 &&
      dealerProfileData
    ) {
      if (isFinanceType) {
        getFinanceProductRates({
          data: getRateRequestObject(products_info, 'Finance')
        })
      } else {
        getLeaseProductRates({
          data: getRateRequestObject(products_info, 'Lease')
        })
      }
    }
  }, [products_info, dealerProfileData])

  useEffect(() => {
    if (fniProducts) {
      const financeHasOrderIdField = fniData?.finance?.some((item) => 'order_id' in item)
      const leaseHasOrderIdField = fniData?.lease?.some((item) => 'order_id' in item)

      if (financeHasOrderIdField || leaseHasOrderIdField) {
        setSelectedFinanceItems(mapFnIArray(fniData?.finance || []))
        setSelectedLeaseItems(mapFnIArray(fniData?.lease || []))
      }
    }
  }, [fniProducts])

  const mapFnIArray = (array: any) => {
    const mappedArray = array.map((priceItem: any) => {
      const otherProperties = fniProducts?.result?.find(
        (item: any) => item.id === priceItem.financial_insurance_id
      )
      return { ...otherProperties, price: priceItem?.applied_price }
    })
    return mappedArray
  }
  useEffect(() => {
    getPenIntegrations(
      {
        provider_name: IntegrationProvider.PEN,
        integration_type: LenderIntegrationType.FNI_PRODUCTS
      },
      {
        onSuccess(response: any) {
          setPenEnabled(response?.is_active)
        }
      }
    )
  }, [])

  useEffect(() => {
    getFandIProducts({
      data: `dealer_code=${
        dealerProfileData?.dealer_code
      }&page_number=${0}&page_size=${1000}&is_active=true`
    })
  }, [])

  const total_results = fniProducts ? fniProducts.total_results : 0

  useEffect(() => {
    if (fniProducts) {
      let productInfo: Array<any> = []
      fniProducts?.result?.map((obj: any) => {
        const newObj: any = {
          product_id: obj?.product_id,
          starting_mileage: 0,
          ending_mileage: 10000,
          filters: [{ term_months: `${terms}` }]
        }
        productInfo.push(newObj)
      })
      setNewProductsInfo(productInfo)
    }
  }, [fniProducts])

  useEffect(() => {
    if (isFinanceType) {
      const updatedFinanceItems = selectedFinanceItems.map((item) => {
        const fniItem = fniPrice.finance.find((f) => f.id === item.id)
        return {
          ...item,
          mileage: fniItem ? fniItem.mileage : 999999,
          term: fniItem ? fniItem.term : terms
        }
      })

      const fniTotal = getTotal(updatedFinanceItems, false)
      const mspTotal = getTotal(updatedFinanceItems, true)
      const totalSum = (parseFloat(fniTotal) + parseFloat(mspTotal)).toFixed(2)

      setSum((prev) => ({
        ...prev,
        finance: totalSum,
        fniFinance: fniTotal,
        mspFinance: mspTotal
      }))

      setFniData((prev: any) => ({
        ...prev,
        finance: updatedFinanceItems
      }))
    }
  }, [selectedFinanceItems, fniPrice])

  useEffect(() => {
    if (!isFinanceType) {
      const updatedLeaseItems = selectedLeaseItems.map((item) => {
        const fniItem = fniPrice.lease.find((l) => l.id === item.id)
        return {
          ...item,
          mileage: fniItem ? fniItem.mileage : 999999,
          term: fniItem ? fniItem.term : terms
        }
      })

      const fniTotal = getTotal(updatedLeaseItems, false)
      const mspTotal = getTotal(updatedLeaseItems, true)
      const totalSum = (parseFloat(fniTotal) + parseFloat(mspTotal)).toFixed(2)

      setSum((prev) => ({
        ...prev,
        lease: totalSum,
        fniLease: fniTotal,
        mspLease: mspTotal
      }))

      setFniData((prev: any) => ({
        ...prev,
        lease: updatedLeaseItems
      }))
    }
  }, [selectedLeaseItems, fniPrice])
  useEffect(() => {
    if (fniProducts?.result.length > 0 && financeProductRates && isFinanceType) {
      applyMarkupOnCoverages(fniProducts.result, financeProductRates)
    }
  }, [fniProducts, financeProductRates])

  useEffect(() => {
    if (fniProducts?.result.length > 0 && leaseProductRates && !isFinanceType) {
      applyMarkupOnCoverages(fniProducts.result, leaseProductRates)
    }
  }, [fniProducts, leaseProductRates])
  useEffect(() => {
    if (fniProducts) {
      let FnI_Tabs: Array<any> = []
      let MSP_Tabs: Array<any> = []
      const fniProductsList = fniProducts?.result?.filter((x: { MSP: any }) => !x.MSP)
      const mspProductsList = fniProducts?.result?.filter((x: { MSP: any }) => x.MSP)
      let fni_product_types = [
        ...new Set(fniProductsList.map((x: { product_type: any }) => x.product_type))
      ]
      fni_product_types.forEach((pt: any) => {
        const obj = {
          title: pt,
          content: (
            <FinancialInsurancePopup
              data={fniProductsList.filter((x: { product_type: any }) => x.product_type == pt)}
              activeTab={activeTab}
              setSelectedLeaseItems={setSelectedLeaseItems}
              setSelectedFinanceItems={setSelectedFinanceItems}
              selectedFinanceItems={selectedFinanceItems}
              selectedLeaseItems={selectedLeaseItems}
              financeRates={financeProductRates?.rates?.rate}
              leaseRates={leaseProductRates?.rates?.rate}
              defaultCurrency={defaultCurrency}
              terms={terms}
              fniPrice={fniPrice}
              setFniPrice={setFniPrice}
              newProductsInfo={products_info}
              setNewProductsInfo={setNewProductsInfo}
              isMSP={false}
            />
          )
        }
        FnI_Tabs.push(obj)
      })
      let msp_product_types = [
        ...new Set(mspProductsList.map((x: { product_type: any }) => x.product_type))
      ]
      msp_product_types.forEach((pt: any) => {
        const mspProductsOfType = mspProductsList.filter(
          (x: { product_type: any }) => x.product_type === pt
        )
        if (mspProductsOfType.length > 0) {
          MSP_Tabs.push({
            title: `${pt}`,
            content: (
              <FinancialInsurancePopup
                data={mspProductsOfType}
                activeTab={activeTab}
                setSelectedLeaseItems={setSelectedLeaseItems}
                setSelectedFinanceItems={setSelectedFinanceItems}
                selectedFinanceItems={selectedFinanceItems}
                selectedLeaseItems={selectedLeaseItems}
                financeRates={financeProductRates?.rates?.rate}
                leaseRates={leaseProductRates?.rates?.rate}
                defaultCurrency={defaultCurrency}
                terms={terms}
                fniPrice={fniPrice}
                setFniPrice={setFniPrice}
                newProductsInfo={products_info}
                setNewProductsInfo={setNewProductsInfo}
                isMSP={true}
              />
            )
          })
        }
      })
      setProductTypes(FnI_Tabs)
      setMspProductTypes(MSP_Tabs)
    }
  }, [
    fniProducts,
    activeTab,
    financeProductRates,
    leaseProductRates,
    selectedFinanceItems,
    selectedLeaseItems,
    terms,
    fniPrice
  ])

  const getRateRequestObject = (new_products_info: any, finance_type: string) => {
    const currentDate = new Date(Date.now()).toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    })
    const ratesRequestObject = {
      deal_info: {
        pen_dealer_id: dealerProfileData?.pen_dealer_id,
        vin: selectedVehicle?.vin,
        mileage: finance_type == 'Lease' ? selectedVehicle?.mileage ?? 0 : 0,
        car_status: 'NEW',
        effective_date: currentDate,
        in_service_date: currentDate,
        finance_type: finance_type,
        vehicle_msrp: selectedVehicle?.msrp,
        vehicle_purchase_price: selectedVehicle?.internetPrice || 346445,
        deal_type: 'LOAN',
        finance_terms: [terms],
        finance_terms_mileage: [24],
        apr,
        financed_amount: selectedVehicle?.financedAmount || 33718.28,
        language: 'ENGLISH',
        user_role: 'FI_MANAGER'
      },
      products_info: new_products_info,
      test_request: true
    }
    return ratesRequestObject
  }

  const getTotal = (array: Array<any>, isMSPItem: boolean = false) => {
    if (!array?.length) return '0.00'

    const filteredArray = array.filter((obj) => obj.MSP === isMSPItem)
    const sum = filteredArray.reduce((total, obj) => {
      const price = parseFloat(obj.price) || 0
      return total + price
    }, 0)
    return sum.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }
  useEffect(() => {
    if (isFinanceType) {
      const fniTotal = getTotal(selectedFinanceItems, false)
      const mspTotal = getTotal(selectedFinanceItems, true)
      const totalSum = (parseFloat(fniTotal) + parseFloat(mspTotal)).toFixed(2)

      setSum((prev) => ({
        ...prev,
        finance: totalSum,
        fniFinance: fniTotal,
        mspFinance: mspTotal
      }))
    }
  }, [selectedFinanceItems])

  useEffect(() => {
    if (!isFinanceType) {
      const fniTotal = getTotal(selectedLeaseItems, false)
      const mspTotal = getTotal(selectedLeaseItems, true)
      const totalSum = (parseFloat(fniTotal) + parseFloat(mspTotal)).toFixed(2)

      setSum((prev) => ({
        ...prev,
        lease: totalSum,
        fniLease: fniTotal,
        mspLease: mspTotal
      }))
    }
  }, [selectedLeaseItems])

  function applyMarkupOnCoverages(fniProduct: any, fniRates: any) {
    const rates = fniRates?.rates?.rate
    const rateArray = Array.isArray(rates) ? rates : rates ? [rates] : []
    rateArray?.map((rate: any) => {
      let markup = fniProduct.find((x: any) => x.product_id == rate?.product_id)?.markup
      //when only single coverage is found then pen returns object instead of array
      if (rate?.coverages?.coverage && !Array.isArray(rate?.coverages?.coverage)) {
        let price = rate?.coverages?.coverage?.deductibles?.deductible?.retail_price
        price = price + (markup / 100) * price
        rate.coverages.coverage.deductibles.deductible.retail_price = price
        rate.coverages.coverage = [rate?.coverages?.coverage]
      } else {
        rate?.coverages?.coverage?.map((item: any) => {
          let price = item.deductibles.deductible.retail_price
          price = price + (markup / 100) * price
          item.deductibles.deductible.retail_price = price
        })
      }
    })
  }

  return (
    <>
      {total_results && penEnabled ? (
        <>
          {!isMSP && productTypes.length > 0 && (
            <Box theme={theme} className="fi-products">
              <DuoTab
                theme={theme}
                varient={'underline'}
                items={productTypes}
                defaultTabIndex={0}
              />
              <DialogTotal
                theme={theme}
                className="dialog-total"
                display={'flex'}
                gap={2}
                justifyContent={'space-between'}>
                <Box theme={theme}>Total</Box>
                <Box theme={theme} className="primary" textAlign="right">
                  {'$'}
                  {activeTab == 0 ? sum?.fniFinance : sum?.fniLease}
                </Box>
              </DialogTotal>
            </Box>
          )}
          {isMSP && mspProductTypes.length > 0 && (
            <>
              <DuoTab
                theme={theme}
                varient={'underline'}
                items={mspProductTypes}
                defaultTabIndex={0}
              />
              <DialogTotal
                theme={theme}
                className="dialog-total"
                display={'flex'}
                gap={2}
                justifyContent={'space-between'}>
                <Box theme={theme}>Total</Box>
                <Box theme={theme} className="primary" textAlign="right">
                  {'$'}
                  {activeTab == 0 ? sum?.mspFinance : sum?.mspLease}
                </Box>
              </DialogTotal>
            </>
          )}
        </>
      ) : (
        ''
      )}
    </>
  )
}

export default FinanceInsuranceControl
