import { FC, memo, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { Typography, Icon, Grid, Box } from '@ntpkunity/controls'
import { IStipulationResponse } from '@models'
import { QueryKeys } from '@helpers/enums'
import { useStoreContext } from '@store/storeContext'
import { useQueryClient } from 'react-query'
import { FinancialStipType } from '@helpers/enums/stipulation-type.enum'
import { DeskingWrap } from '@components'
import AddVtc from '@app/desking/add-vtc'
import OrderDetails from '@app/desking/order-details'
import CalculationSection from '@app/desking/calculation-section'

interface IFinancialStipulationProps {
  filteredStips: IStipulationResponse[]
  generalizedStipText: string | undefined
}

const FinancialStipulation: FC<IFinancialStipulationProps> = ({
  filteredStips,
  generalizedStipText
}) => {
  const theme = useTheme()
  const queryClient = useQueryClient()
  const { states } = useStoreContext()
  const [toggle, setToggle] = useState<boolean>(true)
  const [defaultCurrency, setDefaultCurrency] = useState('')

  //@ts-ignore
  const currencies: any = queryClient.getQueryData([
    QueryKeys.GET_ALL_CURRENCIES,
    states?.lenderInfo?.id
  ])

  useEffect(() => {
    setToggle(!generalizedStipText)
  }, [generalizedStipText])

  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === states?.lenderInfo?.default_currency
    )
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0 ? filteredCurrency[0].symbol : ''
    )
  }, [currencies])

  return (
    <>
      <Typography
        className="mini-title"
        component={'p'}
        theme={theme}
        children={`${generalizedStipText ? '' : 'Financial'} Stipulations`}
      />
      {generalizedStipText && (
        <Box theme={theme} className="stipulation-wrap">
          <Typography
            className="stipulation-text"
            component={'p'}
            theme={theme}
            children={generalizedStipText}
          />
        </Box>
      )}
      {filteredStips?.map((stip, index) => (
        <Typography
          key={`financial-${index}`}
          className="notify"
          variant="body2"
          component="p"
          theme={theme}
          onClick={() => setToggle(!toggle)}
        >
          <Icon className="icon" name={stip.fulfilled ? 'GreenCheckIcon' : 'InfoIcon'} />
          The {stip.parameter} should be in between{' '}
          {stip.parameter !== FinancialStipType.TERMS && defaultCurrency}
          {stip.parameter !== FinancialStipType.TERMS
            ? stip?.value_from?.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })
            : stip?.value_from}{' '}
          and {stip.parameter !== FinancialStipType.TERMS && defaultCurrency}
          {stip.parameter !== FinancialStipType.TERMS
            ? stip?.value_to?.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })
            : stip?.value_to}
        </Typography>
      ))}
      <DeskingWrap theme={theme} className="desking-wrap" mt={1} hidden={toggle}>
        <Grid theme={theme} container columnSpacing={2} rowSpacing={1}>
          <Grid theme={theme} item xs={12} pt={0}>
            <AddVtc />
          </Grid>
          <Grid theme={theme} item lg={2} md={3} sm={4}>
            <OrderDetails />
          </Grid>
          <Grid theme={theme} item xs>
            <CalculationSection />
          </Grid>
        </Grid>
      </DeskingWrap>
    </>
  )
}

export default memo(FinancialStipulation)
