import { FC, useEffect } from 'react'
import { useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import { IGridProps, Box } from '@ntpkunity/controls'
import { styled } from '@mui/material/styles'
import DealerWorkQueueOrder from '@app/dealer-workqueue-order/dealer-workqueue-order-grid'
import DealerWorkQueueOrderReturn from '@app/dealer-workqueue-order/dealer-workqueue-order-return-grid'
import CancelledAndCompletedOrder from '@app/dealer-workqueue-order/dealer-workqueue-order-list'
import {
  useGetVehiclesAvailableConfirmation,
  useGetReadyCheckList,
  useGetSchedulePickUpRequest,
  useGetVehicleReturnsRequest
} from '@apis/dealer-workqueue.service'
import { useStoreContext } from '@store/storeContext'
import { CustomerColumns, WORKQUEUE_SCREEN_COMPONENTS } from '@helpers/constants'
import {
  dealexPermissions,
  useHasPermissions
  //@ts-ignore
} from '@ntpkunity/controls-ums'
import { RETURNS } from '@helpers/enums'
import { addConditionToRequestBody } from '@helpers/methods'

const PageWrap = styled(
  Box,
  {}
)<Partial<IGridProps>>(({ theme }) => ({
  width: '100%',
  display: 'inline-block',
  '.spr': {
    opacity: 0.35,
    margin: 0
  },
  '.toggle-btn': {
    cursor: 'pointer'
  },
  '.ch-up': {
    webkitTransform: 'scaleY(-1)',
    transform: 'scaleY(-1)'
  },
  '.search': {
    '.MuiOutlinedInput-notchedOutline': {
      border: '0 solid transparent',
      borderRadius: 0
    },
    '.MuiInputBase-root': {
      borderBottom: '1px solid transparent',

      '&.Mui-focused': {
        borderColor: theme.palette.divider,
        borderRadius: 0,
        fieldset: {
          border: '0 solid transparent'
        }
      }
    },
    '.MuiInputAdornment-root': {
      position: 'absolute',
      right: 0,
      zIndex: -1,
      opacity: 0.75
    },
    '.MuiInputBase-input': {
      paddingRight: '15px !important',
      paddingLeft: '0 !important'
    }
  }
}))

const DealerWorkQueuePage: FC = () => {
  const theme = useTheme()
  const { states, actions } = useStoreContext()
  const { mutate: getVehicleConfirmation, isLoading: vehcilceAvailableLoading } =
    useGetVehiclesAvailableConfirmation()
  const { mutate: getReadyCheckList, isLoading: readyChecklistLoading } = useGetReadyCheckList()
  const { mutate: getSchedulePickups, isLoading: schedulePickupLoading } =
    useGetSchedulePickUpRequest()
  const { mutate: getVehicleReturns, isLoading: vehicleReturnsLoading } =
    useGetVehicleReturnsRequest()
  useEffect(() => {
    getConfirmation_ReadyCheckList_SchedulePickups()
  }, [])

  const getConfirmation_ReadyCheckList_SchedulePickups = () => {
    let additionalParams: any[] = []
    const params = { return_status: RETURNS.PENDING_RETURN }
    const checkCondition =
      Object.keys(states?.selectedANDealer)?.length > 0 && states?.selectedANDealer?.id !== ''

    if (checkCondition) {
      addConditionToRequestBody(
        CustomerColumns.DEALER_ID,
        states?.selectedANDealer?.id,
        'Equals',
        additionalParams
      )
    }
    getVehicleConfirmation({ ...additionalParams })
    getReadyCheckList({ ...additionalParams })
    getSchedulePickups({ ...additionalParams })
    getVehicleReturns({ params })
    actions.setDealerSelectedReturnData({})
    actions.setDealerSelectedOrderData({})
  }
  return (
    <LayoutWithSideNav theme={theme}>
      <PageWrap theme={theme}>
        {useHasPermissions(dealexPermissions?.SECTIONS?.VEHICLE_AVIALABLE) && (
          <DealerWorkQueueOrder
            heading={WORKQUEUE_SCREEN_COMPONENTS[0].heading}
            ordersLoading={vehcilceAvailableLoading}
            data={states?.vehicleAvailableConfirmationOrders}
            subheading={WORKQUEUE_SCREEN_COMPONENTS[0].subheading}
          />
        )}
        {useHasPermissions(dealexPermissions?.SECTIONS?.GET_READY_CHECKLIST) && (
          <DealerWorkQueueOrder
            heading={WORKQUEUE_SCREEN_COMPONENTS[1].heading}
            ordersLoading={readyChecklistLoading}
            data={states?.vehicleGetReadyCheckListOrders}
            subheading={WORKQUEUE_SCREEN_COMPONENTS[1].subheading}
          />
        )}
        {useHasPermissions(dealexPermissions?.SECTIONS?.SCHEDULE_PICKUP_CHECKLIST) && (
          <DealerWorkQueueOrder
            heading={WORKQUEUE_SCREEN_COMPONENTS[2].heading}
            ordersLoading={schedulePickupLoading}
            data={states?.vehicleSchedulePickupsData}
            subheading={WORKQUEUE_SCREEN_COMPONENTS[2].subheading}
          />
        )}
        <DealerWorkQueueOrderReturn
          heading={WORKQUEUE_SCREEN_COMPONENTS[3].heading}
          ordersLoading={vehicleReturnsLoading}
          data={states?.vehicleReturnData}
          subheading={WORKQUEUE_SCREEN_COMPONENTS[3].subheading}
        />
        {useHasPermissions(dealexPermissions?.SECTIONS?.COMPLETED_ORDERS) && (
          <CancelledAndCompletedOrder
            heading={WORKQUEUE_SCREEN_COMPONENTS[4].heading}
            subheading={WORKQUEUE_SCREEN_COMPONENTS[4].subheading}
          />
        )}
        <CancelledAndCompletedOrder
          heading={WORKQUEUE_SCREEN_COMPONENTS[5].heading}
          subheading={WORKQUEUE_SCREEN_COMPONENTS[5].subheading}
        />
      </PageWrap>
    </LayoutWithSideNav>
  )
}

export default DealerWorkQueuePage
