export enum LeadKey {
  Customer_Detail = 'customer_details',
  Vehicle_Search = 'vehicle_search_criteria',
  Vehicle_Detail = 'vehicle_details',
  Status = 'status',
  Finance = 'finance',
  Lease = 'lease',
  Trade_In = 'trade_in'
}

export enum LeadStatus {
  Completed = 'Completed',
  Active = 'Active'
}
