import { FC, useEffect } from 'react'
import { useTheme } from '@mui/material'
import { validation } from '@helpers/methods'
import { Controller, useForm } from 'react-hook-form'
import { useStoreContext } from '@store/storeContext'
import { OrderAssetControlProps } from './order_asset-control.types'
import { TRANSMISSION_TYPE, VEHICLE_PRIOR_USE } from '@helpers/constants'
import { useGetDisclaimersDataByName } from '@apis/dealer-configurations.service'
import { useHandleSubmitOdometerForm } from '@apis/order-management.service'
import { OrderInformationDetails } from 'models/order-information.interface'
import { Select, Typography, Button, Radio, Input, Box, Grid, Dialog } from '@ntpkunity/controls'
import { useGetCustomerConsent } from '@apis/customer-management.service'
import { IDisclaimerAcceptance } from 'models/disclaimer_acceptance.interface'
import DisableLoader from '@public/assets/images/loader-disabled.gif'
import { DeskingActionTypes, useDeskingContext } from '@app/desking/desking-context-provider'

const ENGINE_CYLINDER = Array.from({ length: 20 }, (_, i) => ({
  text: (i + 1).toString(),
  value: i + 1
}))

const OrderAssetControl: FC<OrderAssetControlProps> = ({
  dialogOpen,
  setDialogOpen,
  orderObj,
  setOptionObject
}) => {
  const { states, actions } = useStoreContext()
  const { data: disclaimers } = useGetDisclaimersDataByName('odometer')
  const { data: disclaimer_agreement } = useGetCustomerConsent(
    orderObj?.customer_id,
    orderObj?.reference_number
  )
  const { mutate: updateOrderAsset, isLoading: updateLoading } = useHandleSubmitOdometerForm()

  const { dispatch } = useDeskingContext()

  const theme = useTheme()
  const {
    handleSubmit,
    control,
    reset,
    clearErrors,
    getValues,
    watch,
    formState: { errors }
  } = useForm()

  const resetFormValues = (orderObj: any, disclaimer_agreement: IDisclaimerAcceptance[]) => {
    if (orderObj) {
      const disclaimer_acceptance: IDisclaimerAcceptance[] = disclaimer_agreement
      const filteredDisclaimer = disclaimer_acceptance?.filter(
        (d: IDisclaimerAcceptance) => d.disclaimer_name === 'odometer'
      )
      const transmissionTypes = ['Automatic', 'CVT']
      const transmissionType = transmissionTypes.some((type) =>
        orderObj?.Vehicle_details?.transmission_type?.includes(type)
      )
        ? 'Automatic'
        : 'Manual'
      reset({
        order_id: orderObj?.id || '',
        mileage: parseInt(
          orderObj?.order_asset?.mileage || orderObj?.Vehicle_details?.mileage || '0'
        ),
        min_odometer_prior_use: parseInt(states?.lenderInfo?.min_odometer_prior_use || '0'),
        engine_cylinder:
          orderObj?.order_asset?.engine_cylinder || orderObj?.Vehicle_details?.engine_cylinders,
        transmission_type: orderObj?.order_asset?.transmission_type || transmissionType,
        vehicle_uses: orderObj.order_asset?.vehicle_uses || '',
        odometer_disclaimer:
          filteredDisclaimer?.length > 0 ? filteredDisclaimer[0]?.disclaimer_details : ''
      })
    }
  }

  useEffect(() => {
    resetFormValues(orderObj, disclaimer_agreement)
  }, [orderObj, disclaimer_agreement])

  const onSubmit = (values: any) => {
    updateOrderAsset(
      { referenceNumber: orderObj?.reference_number, ...values },
      {
        onSuccess(response: OrderInformationDetails) {
          setOptionObject?.(response)
          dispatch({
            type: DeskingActionTypes.MAP_ORDER_TO_STORE,
            payload: response
          })
          actions.setToast({
            toastMessage: 'Deal Updated Successfully',
            toastState: true
          })
          setDialogOpen(false)
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: 'error'
          })
        }
      }
    )
  }

  return (
    <Dialog
      theme={theme}
      title={'Odometer Reading'}
      size="sm"
      open={dialogOpen}
      onCloseDialog={() => {
        resetFormValues(orderObj, disclaimer_agreement)
        setDialogOpen(false)
      }}
      customFooter={
        <>
          <Button
            theme={theme}
            secondary
            text={'Discard'}
            onClick={() => {
              resetFormValues(orderObj, disclaimer_agreement)
              setDialogOpen(false)
            }}
          />
          <Button
            startIcon={updateLoading && <img src={DisableLoader} alt="Loader" />}
            theme={theme}
            disabled={updateLoading}
            type="submit"
            primary
            text="Save"
            onClick={handleSubmit(onSubmit)}
          />
        </>
      }
    >
      <Box theme={theme}>
        <Controller
          name="mileage"
          control={control}
          defaultValue={''}
          rules={validation('Current Mileage (Odometer)', true)}
          render={({ field }) => (
            <Input
              {...field}
              theme={theme}
              testid="mileage"
              fullWidth
              label={'Current Mileage (Odometer)'}
              placeholder={'Add here..'}
              type={'number'}
              onChange={(val) => {
                clearErrors('vehicle_uses')
                field.onChange(val)
              }}
              error={errors?.mileage?.message?.toString()}
            />
          )}
        />
        <Controller
          name="vehicle_uses"
          control={control}
          defaultValue={''}
          rules={{
            required: {
              value: getValues('mileage') >= states?.lenderInfo?.min_odometer_prior_use,
              message: 'Vehicle Prior Use is required'
            }
          }}
          render={({ field: { value, onChange } }) => (
            <Select
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              disablePortal={false}
              theme={theme}
              label={'Vehicle Prior Use'}
              items={VEHICLE_PRIOR_USE}
              value={value}
              onChange={(e) => {
                onChange(e)
              }}
              selectError={errors?.vehicle_uses?.message as never}
            />
          )}
        />
        <Grid theme={theme} container columnSpacing={2} mb={2}>
          <Grid theme={theme} item xs={6}>
            <Controller
              name="transmission_type"
              control={control}
              defaultValue={''}
              rules={validation('transmission_type', true)}
              render={({ field: { value, onChange } }) => (
                <Select
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  disablePortal={false}
                  theme={theme}
                  label={'Transmission Type'}
                  items={TRANSMISSION_TYPE}
                  value={value}
                  onChange={(e) => {
                    onChange(e)
                  }}
                  selectError={errors?.transmission_type?.message as never}
                />
              )}
            />
          </Grid>
          <Grid theme={theme} item xs={6}>
            <Controller
              name="engine_cylinder"
              control={control}
              defaultValue={''}
              render={({ field: { value, onChange } }) => (
                <Select
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  disablePortal={false}
                  theme={theme}
                  label={'Engine Cylinders'}
                  items={ENGINE_CYLINDER}
                  value={value}
                  onChange={(e) => {
                    onChange(e)
                  }}
                  selectError={errors?.engine_cylinder?.message as never}
                />
              )}
            />
          </Grid>
        </Grid>
        {disclaimers ? (
          <Controller
            name="odometer_disclaimer"
            control={control}
            rules={{
              required: 'Select an option to proceed.'
            }}
            render={({ field }) => (
              <Radio
                {...field}
                defaultValue={watch('odometer_disclaimer')}
                options={disclaimers?.map((disclaimer: any, index: number) => ({
                  label: (
                    <Typography theme={theme} component="p" variant="caption" key={index}>
                      {disclaimer.description}
                    </Typography>
                  ),
                  value: disclaimer.description,
                  disabled: false
                }))}
                radioError={errors?.odometer_disclaimer?.message as never}
                row
                theme={theme}
                fullWidth
              />
            )}
          />
        ) : null}
      </Box>
    </Dialog>
  )
}
export default OrderAssetControl
