import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import {
  Typography,
  AddressLayout,
  Grid,
  Icon,
  Radio,
  Skeleton,
  Textarea,
  Box,
  Button
} from '@ntpkunity/controls'
import { Divider } from 'components'
import CardBanner from './card-banner'
import InsuranceDetails from '../in-progress/schedule-pickup/insurance-details'
import License from './schedule-pickup/license'
import { Controller } from 'react-hook-form'
import {
  useGetCountries,
  useGetStates,
  useGetInsuranceDetails,
  useGetCustomerLicenseInfo,
  useGetReadyCheckListRequest,
  useDonwnloadDocument,
  useGetChecklistSavedProgress
} from '@apis/dealer-workqueue.service'
import { useStoreContext } from '@store/storeContext'
import { IRequestReadyCheckList } from '@models'
//@ts-ignore
import { useHasPermissions } from '@ntpkunity/controls-ums'
import { dealexPermissions } from '@ntpkunity/controls-ums/dealexPermissions'
import {
  compareAnswers,
  containsKeyValue,
  question_check,
  removeApostrophes
} from '@helpers/methods'
import { Documents } from './ready-check-list/documentStyle'
import CustomerDetailsSection from './customer-details'
import { accessToken } from '@helpers/constants'

const SchedulePickup: FC<{ form: any; pickupCallback: any; checklistErrors: any }> = ({
  pickupCallback,
  checklistErrors,
  form
}) => {
  const theme = useTheme()
  const {
    states: { dealerSelectedOrderData, insuranceInformation }
  } = useStoreContext()
  const { mutate: getCountry, data: countriesData } = useGetCountries()
  const { mutate: getStates, data: statesData } = useGetStates()
  const [showAccordionDetail, setShowAccordionDetail] = useState(false)
  const [checklistValuesProps, setChecklistValuesProps] = useState<boolean>(false)
  const [radioValues, setRadioValues] = useState({})
  const [formErrors, setFormErrors] = useState<any>()

  const { mutate: getCustomerLicenseInfo } = useGetCustomerLicenseInfo()
  const {
    mutate: getEventAssociatedReadyCheckList,
    data: checkList,
    isLoading: checkListLoading
  } = useGetReadyCheckListRequest()
  const { mutate: getDocURL } = useDonwnloadDocument()
  const {
    control,
    watch,
    trigger,
    getValues,
    formState: { errors }
  } = form

  const description = watch('description')
  const keys_description = watch('keys_description')
  const possession_description = watch('possession_description')
  const reqCheckListObject: IRequestReadyCheckList = {
    event_name: 'Vehicle Pick up Scheduling Confirmation'
  }
  const { mutate: getInsuranceDetails, data: insuranceData } = useGetInsuranceDetails()
  const justUSA = countriesData?.filter((x: any) => x?.country_code == 'US')
  const {
    states: {
      insuranceInformation: { insurer_address }
    },
    actions
  } = useStoreContext()
  const { states } = useStoreContext()
  const [savedChecklistOptions, setSavedChecklistOptions] = useState<boolean>(false)
  const [savedChecklist, setSavedChecklist] = useState<any>()
  const [questionArray, setQuestionArray] = useState<any>([])
  const { mutate: getChecklistProgressOptions, data: _savedChecklist } =
    useGetChecklistSavedProgress()

  useEffect(() => {
    getCountry()
    getStates()
    if (dealerSelectedOrderData?.customerId) {
      getInsuranceDetails({
        customer_ref_id: dealerSelectedOrderData?.customerId
      })
      getCustomerLicenseInfo({
        customer_ref_id: dealerSelectedOrderData?.customerId
      })
    }
  }, [dealerSelectedOrderData?.customerId])

  useEffect(() => {
    if (statesData && insuranceData) {
      if (
        insurer_address &&
        Object.keys(insurer_address)?.length > 0 &&
        insurer_address?.state_name
      ) {
        const state_id = statesData?.find(
          (x: any) => x?.name?.toLowerCase() == insurer_address?.state_name?.toLowerCase()
        )?.id
        actions.setInsuranceInformation({
          ...insuranceInformation,
          insurer_address: { ...insurer_address, state_id: state_id ?? '' }
        })
      }
    }
  }, [statesData, insuranceData])

  useEffect(() => {
    if (states?.dealerSelectedOrderData?.customerId) {
      getEventAssociatedReadyCheckList(
        { ...reqCheckListObject },
        {
          onSuccess(res: any) {
            setQuestionArray(() => {
              const questions = res.flatMap((checklist: any[]) =>
                checklist.flatMap((item) =>
                  item?.questions.map((question: { question: any }) => question.question)
                )
              )
              return questions.map((question: string) => ({
                originalQuestion: question,
                modifiedQuestion: removeApostrophes(question)
              }))
            })

            getChecklistProgressOptions(
              {
                customer_reference_id: states?.dealerSelectedOrderData?.customerId,
                checklist_identifier: res[0]?.[0]?.checklist_identifier,
                order_reference_id: states?.dealerSelectedOrderData?.id
              },
              {
                onSuccess(res: any) {
                  setSavedChecklist(res)
                  for (let i = 0; i < res.length; i++) {
                    setRadioValues((previousValues) => {
                      return {
                        ...previousValues,
                        [res[i].question_text]: res[i].primary_answer
                      }
                    })
                  }

                  setSavedChecklistOptions(true)
                },
                onError({ error }: any) {
                  if (error?.response?.data?.detail?.code === 404) {
                    setSavedChecklistOptions(false)
                  }
                }
              }
            )
          }
        }
      )
    }
  }, [states?.dealerSelectedOrderData?.customerId])

  useEffect(() => {
    const errors = compareAnswers(radioValues, checkList)
    setFormErrors(errors)
  }, [savedChecklistOptions, checkList, radioValues])

  useEffect(() => {
    if (radioValues && Object.keys(radioValues).length > 0) {
      pickupCallback(
        !checklistValuesProps
          ? question_check(getValues('radio_checklist.'), questionArray)
          : question_check(getValues('schedule_radio_checklist.'), questionArray),
        Object.keys(checkList[0]?.[0].questions)?.length,
        checkList[0]?.[0]?.checklist_name,
        checkList[0]?.[0]?.checklist_identifier,
        savedChecklistOptions,
        description?.length > 0 ? description : '',
        keys_description?.length > 0 ? keys_description : '',
        possession_description?.length > 0 ? possession_description : '',
        checkList
      )
    }
  }, [radioValues, savedChecklistOptions, description, keys_description, possession_description])

  useEffect(() => {
    if (savedChecklist && savedChecklistOptions) {
      const checklistQuestionNames = savedChecklist
        .filter(
          (item: any) => removeApostrophes(item?.question_text) in getValues('radio_checklist.')
        )
        .map((item: any) => `radio_checklist.${removeApostrophes(item?.question_text)}`)

      ;(async () => await trigger(checklistQuestionNames))()
    }
  }, [savedChecklistOptions, savedChecklist, radioValues])

  const onRadioChange = (val: any, question: any) => {
    setRadioValues({ ...radioValues, [question]: val })
  }

  const markChecklistPermisssion = useHasPermissions(
    dealexPermissions?.SCREEN?.SCHEDULE_PICKUP?.MARK_CHECKLIST_OPTIONS
  )

  return (
    <>
      {useHasPermissions(dealexPermissions?.SCREEN?.SCHEDULE_PICKUP?.VEHICLE_DETAILS) && (
        <CardBanner />
      )}
      <Grid theme={theme} spacing={2} container sx={{ mb: 2 }}>
        {checkListLoading &&
          [1, 2, 3, 4]?.map((val) => (
            <Grid theme={theme} item md={4} sm={6} xs={12} key={val}>
              <Skeleton theme={theme} width={'25%'} height={'20px'} />
              <Skeleton theme={theme} width={'20%'} height={'20px'} />
            </Grid>
          ))}
        {checkList?.length > 0 &&
          checkList?.map((innerArray: any) =>
            innerArray?.map((list: any) =>
              list?.questions?.map((obj: any, index: any) => (
                <>
                  <Grid theme={theme} item md={6} sm={6} xs={12} key={index}>
                    {savedChecklist?.find((myObj: any) => myObj?.question_text == obj?.question) &&
                      obj?.question && (
                        <>
                          <Typography
                            theme={theme}
                            variant="subtitle2"
                            component="p"
                            sx={{ mb: 1.5 }}
                            children={obj?.question}
                          />

                          <Controller
                            name={`radio_checklist.${removeApostrophes(obj?.question)}`}
                            control={control}
                            key={index}
                            rules={{
                              required: 'Select an option to proceed.'
                            }}
                            defaultValue={
                              savedChecklist?.find(
                                (myObj: any) => myObj?.question_text === obj?.question
                              )?.primary_answer ?? ''
                            }
                            render={({ field: { onChange, value } }: any) => {
                              return (
                                <Radio
                                  theme={theme}
                                  row={true}
                                  onChange={(val: any) => {
                                    onChange(val)
                                    onRadioChange(val, obj.question)
                                  }}
                                  value={value}
                                  options={obj?.answers.map((obj: any) => ({
                                    label: obj?.answer,
                                    value: obj?.answer,
                                    disabled: markChecklistPermisssion === true ? false : true
                                  }))}
                                  radioError={
                                    (errors?.radio_checklist
                                      ? (
                                          errors.radio_checklist as {
                                            [key: string]: { message?: string }
                                          }
                                        )[removeApostrophes(obj?.question)]?.message
                                      : undefined) ??
                                    checklistErrors?.find(
                                      (error: { question: any }) => error.question === obj?.question
                                    )?.message
                                  }
                                />
                              )
                            }}
                          />

                          {!errors?.[`radio_checklist.${obj?.question}`] &&
                          formErrors.length > 0 &&
                          !checklistErrors?.find(
                            (error: { question: any }) =>
                              error?.question === formErrors[0]?.question
                          ) ? (
                            <Typography
                              className="text-danger"
                              theme={theme}
                              variant="caption"
                              component="small"
                              display="block"
                              // children={obj?.question}
                            >
                              {
                                formErrors?.find(
                                  (error: { question: any }) => error.question === obj?.question
                                )?.message
                              }
                            </Typography>
                          ) : null}
                        </>
                      )}
                    {!savedChecklist?.find((myObj: any) => myObj?.question_text == obj?.question) &&
                      obj?.question && (
                        <>
                          <Typography
                            theme={theme}
                            variant="subtitle2"
                            component="p"
                            sx={{ mb: 1.5 }}
                            children={obj?.question}
                          />
                          <Controller
                            name={`schedule_radio_checklist.${removeApostrophes(obj?.question)}`}
                            control={control}
                            rules={{
                              required: 'Select an option to proceed.'
                            }}
                            defaultValue={''}
                            render={({ field: { onChange } }: any) => (
                              <Radio
                                theme={theme}
                                row={true}
                                onChange={(val: any) => {
                                  onChange(val)
                                  onRadioChange(val, obj.question)
                                  setChecklistValuesProps(true)
                                }}
                                options={obj?.answers.map((obj: any) => ({
                                  label: obj?.answer,
                                  value: obj?.answer,
                                  disabled: markChecklistPermisssion === true ? false : true
                                }))}
                                radioError={
                                  (errors?.schedule_radio_checklist
                                    ? (
                                        errors.schedule_radio_checklist as {
                                          [key: string]: { message?: string }
                                        }
                                      )[removeApostrophes(obj?.question)]?.message
                                    : undefined) ??
                                  checklistErrors?.find(
                                    (error: { question: any }) => error.question === obj?.question
                                  )?.message
                                }
                              />
                            )}
                          />
                          {!errors?.[`schedule_radio_checklist-${obj?.question}`] &&
                          formErrors.length > 0 &&
                          !checklistErrors?.find(
                            (error: { question: any }) =>
                              error?.question === formErrors[0]?.question
                          ) ? (
                            <Typography
                              className="text-danger"
                              theme={theme}
                              variant="caption"
                              component="small"
                              display="block"
                              // children={obj?.question}
                            >
                              {
                                formErrors?.find(
                                  (error: { question: any }) => error.question === obj?.question
                                )?.message
                              }
                            </Typography>
                          ) : null}
                        </>
                      )}
                    {obj?.question ==
                      'Did you walk through the vehicle and features with the customer?' &&
                      ((savedChecklist?.find((myObj: any) => myObj?.question_text == obj?.question)
                        ?.primary_answer == 'No' &&
                        containsKeyValue(
                          radioValues,
                          'Did you walk through the vehicle and features with the customer?',
                          'No'
                        )) ||
                        (radioValues &&
                          Object.keys(radioValues).length > 0 &&
                          containsKeyValue(
                            radioValues,
                            'Did you walk through the vehicle and features with the customer?',
                            'No'
                          ))) && (
                        <Grid theme={theme} item xs={12} sx={{ mt: 2 }}>
                          <Controller
                            name={`description`}
                            control={control}
                            defaultValue={
                              savedChecklist?.find(
                                (myObj: any) => myObj?.question_text == obj?.question
                              )?.description
                            }
                            render={({ field }) => (
                              <Textarea
                                theme={theme}
                                label={'Details'}
                                type="text"
                                id="description"
                                placeholder="Type here..."
                                rows={3}
                                fullWidth
                                {...field}
                                value={field.value}
                              />
                            )}
                          />
                        </Grid>
                      )}
                    {obj?.question ==
                      'How many keys/key cards/FOBs did you provide to the customer ?' &&
                      (savedChecklist?.find((myObj: any) => myObj?.question_text == obj?.question)
                        ?.primary_answer == ('1' || '2' || '3') ||
                        (radioValues &&
                          Object.keys(radioValues).length > 0 &&
                          containsKeyValue(
                            radioValues,
                            'How many keys/key cards/FOBs did you provide to the customer ?',
                            '1'
                          )) ||
                        (radioValues &&
                          Object.keys(radioValues).length > 0 &&
                          containsKeyValue(
                            radioValues,
                            'How many keys/key cards/FOBs did you provide to the customer ?',
                            '2'
                          )) ||
                        (radioValues &&
                          Object.keys(radioValues).length > 0 &&
                          containsKeyValue(
                            radioValues,
                            'How many keys/key cards/FOBs did you provide to the customer ?',
                            '3'
                          ))) && (
                        <Grid theme={theme} item xs={12} sx={{ mt: 2 }}>
                          <Controller
                            name={`keys_description`}
                            control={control}
                            defaultValue={
                              savedChecklist?.find(
                                (myObj: any) => myObj?.question_text == obj?.question
                              )?.description
                            }
                            render={({ field }) => (
                              <Textarea
                                theme={theme}
                                label={'Details'}
                                type="text"
                                id="description"
                                placeholder="Type here..."
                                rows={3}
                                fullWidth
                                {...field}
                                value={field.value}
                              />
                            )}
                          />
                        </Grid>
                      )}
                    {obj?.question == 'Did the customer take possession of the vehicle?' &&
                      ((savedChecklist?.find((myObj: any) => myObj?.question_text == obj?.question)
                        ?.primary_answer == 'No' &&
                        containsKeyValue(
                          radioValues,
                          'Did the customer take possession of the vehicle?',
                          'No'
                        )) ||
                        (radioValues &&
                          Object.keys(radioValues).length > 0 &&
                          containsKeyValue(
                            radioValues,
                            'Did the customer take possession of the vehicle?',
                            'No'
                          ))) && (
                        <Grid theme={theme} item xs={12} sx={{ mt: 2 }}>
                          <Controller
                            name={`possession_description`}
                            control={control}
                            defaultValue={
                              savedChecklist?.find(
                                (myObj: any) => myObj?.question_text == obj?.question
                              )?.description
                            }
                            render={({ field }) => (
                              <Textarea
                                theme={theme}
                                label={'Details'}
                                type="text"
                                id="description"
                                placeholder="Type here..."
                                rows={3}
                                fullWidth
                                {...field}
                                value={field.value}
                              />
                            )}
                          />
                        </Grid>
                      )}
                  </Grid>
                </>
              ))
            )
          )}
      </Grid>
      <CustomerDetailsSection />
      <Divider sx={{ mt: '35px', mb: '35px' }} />
      {useHasPermissions(dealexPermissions?.SCREEN?.SCHEDULE_PICKUP?.INSURANCE_DETAILS) && (
        <Typography theme={theme} variant="h3" component="h3" className="title" sx={{ mb: 3 }}>
          Insurance Details
          <Icon
            name="ChevronDown"
            className={`toggle-btn${showAccordionDetail ? ' ch-up' : ''}`}
            onClick={() => setShowAccordionDetail(!showAccordionDetail)}
          />
        </Typography>
      )}
      {useHasPermissions(dealexPermissions?.SCREEN?.SCHEDULE_PICKUP?.INSURANCE_DETAILS) &&
        showAccordionDetail && (
          <>
            <InsuranceDetails />
            <Divider sx={{ mt: '35px', mb: '35px' }} />
            <Box theme={theme} sx={{ mb: 3 }}>
              <Typography
                theme={theme}
                variant="h4"
                component="h4"
                className="title"
                children="Insurance Provider Address"
              />
              <AddressLayout
                theme={theme}
                state={{
                  data: statesData,
                  selectedItemObject: { text: 'name', value: 'code' },
                  controlName: 'state_name'
                }}
                countries={justUSA}
                value={insurer_address}
                validationProps
                //@ts-ignore
                onChange={(val: any) => {}}
                addressVerificationRequired={false}
                isContactRequired={false}
                makeFieldsDisabled={true}
                verifyAddressURL="https://integration-api-dev.netsolapp.io/api/verify-address/"
                token={JSON.parse(localStorage.getItem(accessToken) as string)?.access_token}
                googleApiKey={'X_GOOGLE_API_KEY'}
                showCountryDropdown={false}
                hideAddressButton
              />
            </Box>
            <Typography
              theme={theme}
              variant="h4"
              component="h4"
              className="title"
              children="Proof of Insurance"
              sx={{ mb: 2 }}
            />
            <>
              <Documents>
                {insuranceData?.insurance_documents?.length > 0 ? (
                  <>
                    <Grid
                      theme={theme}
                      container
                      rowSpacing={3}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      sx={{ mb: 3.5 }}
                    >
                      {insuranceData?.insurance_documents?.map((item: any, index: any) => {
                        return (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            theme={theme}
                            key={item?.identifier}
                          >
                            <Box theme={theme} className="card">
                              <Box theme={theme} className="row">
                                {item?.content_type === ('image/jpeg' || 'image/png') ? (
                                  <Icon name="ImageIcon" />
                                ) : (
                                  <Icon name="PdfFileIcon" />
                                )}
                                <Typography theme={theme} variant="body2" component="p">
                                  {item?.name}
                                </Typography>
                              </Box>

                              <Box theme={theme} className="row" key={index}>
                                <Button
                                  theme={theme}
                                  defaultBtn
                                  onClick={() => {
                                    getDocURL(
                                      {
                                        artifact_id: item?.document_uri
                                      },
                                      {
                                        onSuccess(res: any) {
                                          window.open(res?.document_url)
                                        }
                                      }
                                    )
                                  }}
                                  iconText={<Icon name="IcView" />}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </>
                ) : (
                  <Typography
                    variant="body2"
                    component="span"
                    mb={3}
                    theme={theme}
                    className="title-caption"
                    children={'There is no document added here.'}
                  />
                )}
              </Documents>
            </>
          </>
        )}
      <Divider sx={{ mt: '35px', mb: '35px' }} />
      {useHasPermissions(dealexPermissions?.SCREEN?.SCHEDULE_PICKUP?.DRIVER_LICENSE_DETAILS) && (
        <License />
      )}
    </>
  )
}
export default SchedulePickup
