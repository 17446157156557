import { styled } from '@mui/material'
import { Box, IBoxProps } from '@ntpkunity/controls'

const SingleChatWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.chats-wrap': {
    marginBottom: 8,
    '&.sender-chat': {
      '.message-text': {
        marginLeft: 'auto',
        backgroundColor: 'rgba(85, 40, 237, 0.05)',
        borderRadius: '16px 16px 0 16px'
      },
      '.chat-details': {
        justifyContent: 'end'
      }
    },
    '&.receiver-chat': {
      '.message-text': {
        marginRight: 'auto',
        backgroundColor: 'rgba(13, 13, 13, 0.05)',
        borderRadius: '16px 16px 16px 0'
      },
      '.chat-details': {
        justifyContent: 'start'
      }
    },
    '.message-text': {
      maxWidth: '240px',
      display: 'block',
      width: 'fit-content',
      padding: '16px',
      marginBottom: 8,
      color: theme.palette.grey[600]
    },
    '.chat-details': {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.grey[600],
      '.text-dark': {
        color: theme.palette.grey[900],
        fontWeight: theme.customVariables.fontWeightSemiBold
      },
      '.separator': {
        position: 'relative',
        width: 3,
        height: 3,
        margin: '0 8px',
        ':before': {
          content: '""',
          position: 'absolute',
          width: 3,
          height: 3,
          borderRadius: '50%',
          backgroundColor: '#cccccc'
        }
      }
    }
  }
}))

export default SingleChatWrap
