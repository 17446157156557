import { FC, useState } from 'react'
import { useTheme } from '@mui/material'
import { Box, Button, Icon, Menu, Typography } from '@ntpkunity/controls'
import { DeskingActionTypes, useDeskingContext } from './desking-context-provider'
import TradeInControl from 'controls/TradeInControl/trade-in-control'
import { IOrderTradeIn } from '@models'
import { formatNumber } from './utils'

interface ITradeInDetailProps {
  tradeInDetail: any
  showTradeInModal: () => void
}

const TradeInDetail: FC<ITradeInDetailProps> = ({ tradeInDetail, showTradeInModal }) => {
  const theme = useTheme()
  const {
    state: { submission_tier },
    formatCurrency,
    dispatch
  } = useDeskingContext()

  const { year, make, model, trim, trade_in_amount, payoff_amount, mileage, asset_image } =
    tradeInDetail
  return (
    <>
      <Box theme={theme} className="vl-item h-list">
        <Box theme={theme} className="vl-details">
          <Typography
            theme={theme}
            variant="body2"
            component="p"
            className="text-primary text-overflow vl-main-title"
            children={<b>Trade-In</b>}
          />
          <Typography
            theme={theme}
            variant="body2"
            component="p"
            className=" text-overflow"
            children={
              <b>
                {year} {make} {model} {trim}
              </b>
            }
          />
          <Typography
            theme={theme}
            variant="caption"
            component="p"
            className=" text-overflow vl-title-ul"
            children={
              <>
                Trade Value: <b>{formatCurrency(trade_in_amount ?? 0)}</b>,&nbsp; Payoff:{' '}
                <b>{formatCurrency(payoff_amount ?? 0)}</b>,&nbsp; Miles:{' '}
                <b>{formatNumber(mileage, 0, 0)}</b>
              </>
            }
          />
        </Box>
        {asset_image?.length > 0 && (
          <Box theme={theme} className="vl-img-wrap">
            <img src={asset_image?.[0]} alt="Car" />
          </Box>
        )}
        <Menu
          theme={theme}
          disablePortal
          options={[
            {
              optionText: (
                <>
                  <Icon className="menu-icon" name="EditIcon" /> Edit
                </>
              ),
              optionValue: 'edit',
              disabled: Boolean(submission_tier)
            },
            {
              optionText: (
                <>
                  <Icon className="menu-icon" name="DeleteIcon" /> Delete
                </>
              ),
              optionValue: 'delete',
              disabled: Boolean(submission_tier)
            }
          ]}
          handleOptionClick={(_event, _key, value) => {
            switch (value) {
              case 'delete':
                dispatch({ type: DeskingActionTypes.DELETE_TRADE_IN })
                break
              case 'edit':
                showTradeInModal()
                break
            }
          }}
          render={(cb) => (
            <Button
              theme={theme}
              defaultBtn
              className="vl-menu-btn"
              iconText={<Icon name="MoreIcon" />}
              onClick={cb}
            />
          )}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        />
      </Box>
    </>
  )
}
export const AddTradeIn: FC = () => {
  const theme = useTheme()
  const {
    state: { submission_tier, tradeIn },
    dispatch
  } = useDeskingContext()
  const [openTradeInDialog, setTradeInOpenDialog] = useState(false)

  const handleTradeInData = (data: any) => {
    dispatch({
      type: DeskingActionTypes.ADD_TRADE_IN,
      payload: data
    })
  }

  const openTradeInModal = () => setTradeInOpenDialog(true)

  const isTradeInAdded =
    Object.keys(tradeIn).length > 0 &&
    ((tradeIn as IOrderTradeIn)?.year !== undefined || (tradeIn as IOrderTradeIn)?.year !== null)

  return (
    <>
      {!isTradeInAdded ? (
        <Button
          theme={theme}
          fullWidth
          startIcon={<Icon name="AddCircleIcon" />}
          text="Add Trade-In"
          size="large"
          onClick={openTradeInModal}
          disabled={Boolean(submission_tier)}
        />
      ) : (
        <TradeInDetail tradeInDetail={tradeIn} showTradeInModal={openTradeInModal} />
      )}
      {openTradeInDialog && (
        <TradeInControl
          tradeInData={tradeIn}
          selectTradeInObj={handleTradeInData}
          setTradeInOpenDialog={setTradeInOpenDialog}
          openTradeInDialog={openTradeInDialog}
        />
      )}
    </>
  )
}
