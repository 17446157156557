export enum DialogMessages {
  confirmationTitle = 'Are you sure you want to cancel deal?',
  confirmationText = 'Once cancelled, you cannot undo this action.',
  cancelBtnText = 'Confirm',
  removeBtnText = 'Remove',
  btnText = 'Save Changes',
  insuranceConfirmationTitle = 'Are you sure you want to remove insurance details?',
  paymentConfirmationTitle = 'Are you sure you want to remove payment details?',
  tradeInConfirmationTitle = 'Are you sure you want to remove trade-in details?',
  completeOrderTitle = 'Are you sure you want to complete deal?',
  completeOrderText = 'Once confirm, you cannot undo this action.',
  updatedDMSDATA = 'Do you wish to update information received from Dealer Management System?',
  updatedDMSDATATEXT = 'Once updated, you cannot undo this action.',
  assetUnavailableTitle = 'Are you sure asset is unavailable?',
  assetUnavailableConfirmationText = 'Once the asset is marked as unavailable, this deal will be cancelled. Please mention the reason below to mark it as unavailable.'
}
