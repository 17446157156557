import { FC, useEffect, useState } from 'react'
import {
  AccordionDetails,
  Accordion,
  AccordionSummary,
  ToggleButton,
  useTheme
} from '@mui/material'
import {
  useGetChecklistSavedProgress,
  useGetReadyCheckListRequest
} from '@apis/dealer-workqueue.service'
import { useStoreContext } from '@store/storeContext'
import { Box, Icon, Typography } from '@ntpkunity/controls'
const AvailableCheckResponse: FC = () => {
  const [savedAvailableChecklist, setSavedAvailableChecklist] = useState<any>([])
  const [configAvailableChecklist, setConfigAvailableChecklist] = useState<any>()
  let odometerRendered = false
  const [openEpp, setOpenEpp] = useState(false)
  const [open, setOpen] = useState(false)
  const { mutate: getEventAssociatedReadyCheckList } = useGetReadyCheckListRequest()
  const { mutate: getChecklistProgressOptions, data: _savedChecklist } =
    useGetChecklistSavedProgress()
  const availableCheckListObject = {
    event_name: 'availability-check-checklist-event'
  }
  const theme = useTheme()
  const { states } = useStoreContext()

  let availableVehicle = savedAvailableChecklist?.find(
    (question: { question_text: string }) => question.question_text === 'Is this vehicle available?'
  )

  useEffect(() => {
    if (states?.dealerSelectedOrderData?.customerId) {
      getEventAssociatedReadyCheckList(
        { ...availableCheckListObject },
        {
          onSuccess(res: any) {
            setConfigAvailableChecklist(res)

            getChecklistProgressOptions(
              {
                customer_reference_id: states?.dealerSelectedOrderData?.customerId,
                checklist_identifier: res[0]?.[0]?.checklist_identifier,
                order_reference_id: states?.dealerSelectedOrderData?.id
              },
              {
                onSuccess(res: any) {
                  setSavedAvailableChecklist(res)
                }
              }
            )
          }
        }
      )
    }
  }, [states?.dealerSelectedOrderData?.customerId])

  return (
    <Box theme={theme} className="card-body">
      <Box theme={theme} className="summary-row toggle" sx={{ mb: 2 }}>
        <Box theme={theme} className="row">
          <Box theme={theme} className="center">
            <span className="card-text">
              <b> Vehicle Availability Check</b>
            </span>
            {openEpp ? (
              <Icon
                name="ChevronDown"
                className="toggle-btn ch-up"
                onClick={() => setOpenEpp(!openEpp)}
              />
            ) : (
              <Icon
                name="ChevronDown"
                className="toggle-btn"
                onClick={() => setOpenEpp(!openEpp)}
              />
            )}
          </Box>
          {availableVehicle ? (
            <Box
              theme={theme}
              className={
                availableVehicle.primary_answer === 'Yes' ? 'badge success' : 'badge error'
              }
            >
              <Typography
                theme={theme}
                component="span"
                variant="caption"
                children={availableVehicle.primary_answer === 'Yes' ? 'Available' : 'Unavailable'}
              />
            </Box>
          ) : (
            <Box theme={theme} className="badge-width">
              <Typography theme={theme} component="span" variant="caption" children={'-'} />
            </Box>
          )}
        </Box>
      </Box>
      {configAvailableChecklist &&
        configAvailableChecklist?.[0]?.[0]?.questions?.map((ques: any, index: any) => (
          <Box theme={theme} key={index}>
            {/* <Accordion key={index} expanded={openEpp}> */}

            {savedAvailableChecklist?.find((f: any) => f.question_text == ques.question) ? (
              <Accordion key={index} expanded={openEpp}>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" />
                <AccordionDetails sx={{ ml: 2 }}>
                  {!odometerRendered ? (
                    <Box theme={theme} className="row" sx={{ mb: 2 }}>
                      <>
                        <Typography
                          theme={theme}
                          className="card-text text"
                          component="span"
                          variant="body2"
                        >
                          {'Current Odometer Reading'}
                        </Typography>
                        <Typography
                          theme={theme}
                          className="card-text text"
                          component="span"
                          variant="body2"
                        >
                          {states?.dealerSelectedOrderData?.orderItems[0].metadata
                            .odometerReportedByDealer ?? ''}
                        </Typography>
                        {(odometerRendered = true)}
                      </>
                    </Box>
                  ) : null}

                  <Box theme={theme} className="row" sx={{ mb: 2 }}>
                    <Typography
                      theme={theme}
                      className="card-text text"
                      component="span"
                      variant="body2"
                    >
                      {ques?.question}
                      {savedAvailableChecklist?.find(
                        (f: { question_text: any }) => f.question_text == ques.question
                      )?.description ? (
                        <ToggleButton
                          value="check"
                          selected={open}
                          onChange={() => {
                            setOpen(!open)
                          }}
                        >
                          <Icon name="ChevronDown" />
                        </ToggleButton>
                      ) : null}
                    </Typography>
                    <Typography
                      theme={theme}
                      className="card-text text"
                      component="span"
                      variant="body2"
                    >
                      {savedAvailableChecklist?.find(
                        (f: { question_text: any }) => f.question_text == ques.question
                      )?.primary_answer ?? ''}
                    </Typography>
                  </Box>
                  {open && (
                    <Box theme={theme} sx={{ mb: 2 }}>
                      <Typography
                        theme={theme}
                        className="card-text text"
                        component="span"
                        variant="body2"
                      >
                        {
                          savedAvailableChecklist?.find(
                            (f: { question_text: any }) => f.question_text == ques.question
                          )?.description
                        }
                      </Typography>
                    </Box>
                  )}
                </AccordionDetails>
              </Accordion>
            ) : (
              <>
                <Accordion key={index} expanded={openEpp}>
                  <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" />
                  <AccordionDetails sx={{ ml: 2 }}>
                    <Box theme={theme} className="row" sx={{ mb: 2 }}>
                      <Typography
                        className="card-text text"
                        variant="body2"
                        component="span"
                        theme={theme}
                      >
                        {ques.question}
                      </Typography>
                      <Typography
                        className="card-text text"
                        variant="body2"
                        component="span"
                        theme={theme}
                      >
                        {savedAvailableChecklist?.find(
                          (f: { question_text: any }) => f.question_text == ques.question
                        )?.primary_answer || '-'}
                      </Typography>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </>
            )}
          </Box>
        ))}
    </Box>
  )
}
export default AvailableCheckResponse
