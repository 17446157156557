import { useTheme } from '@mui/material'
import { Button, Dialog } from '@ntpkunity/controls'
import { PreQualificationDialogWrap } from './pre-qualification-dialog.style'
import { PreQualificationResponse } from '@ntpkunity/controls-common'
import { IPreQualificationResponse } from 'types/pre-qualified'
import { PreQualifiedStatus } from '@helpers/enums/pre-qualified-status.enum'
import { PRE_QUALIFICATION_CONSTANTS } from '@helpers/constants'
import {
  formatCurrency,
  formatDateStringPreQualification,
  formatSSNAndPhone
} from '@helpers/methods'

interface IPreQualificationAlertProps {
  values: IPreQualificationResponse | undefined
  onClose: () => void
}

const PreQualificationAlert = ({ values, onClose }: IPreQualificationAlertProps) => {
  const data = values?.data && JSON.parse(values?.data)
  const theme = useTheme()
  return (
    <PreQualificationDialogWrap theme={theme} className="qualification-alert-wrap">
      <Dialog
        variant={undefined}
        size="sm"
        title=""
        open={!!values}
        onCloseDialog={onClose}
        theme={theme}
        disablePortal
        customFooter={
          <>
            <Button theme={theme} secondary text="Send Email" onClick={() => {}} />
            <Button theme={theme} secondary text="Print" onClick={() => {}} />
          </>
        }
      >
        <PreQualificationResponse
          theme={theme}
          title={
            data?.status === PreQualifiedStatus.Accept
              ? PRE_QUALIFICATION_CONSTANTS.customerQualified
              : PRE_QUALIFICATION_CONSTANTS.customerNotQualified
          }
          subTitle={
            data?.status === PreQualifiedStatus.Accept
              ? `The credit score provided by
                            <span class='fw-medium text-dark'> ${
                              data?.sample_provider || '-'
                            } </span>
                            is
                            <span class='fw-medium text-dark'> ${data?.score ?? 0} </span>
                            and the customer is eligible for financing up to
                            <span class='fw-medium text-dark'> $${formatCurrency(
                              data?.finance || 0
                            )} </span>
                            . This qualification will expire on
                            <span class='fw-medium text-dark'> ${
                              data?.expiration &&
                              formatDateStringPreQualification(data?.expiration, false)
                            } </span>.
                            `
              : PRE_QUALIFICATION_CONSTANTS.notQualifiedReason
          }
          refNo={values?.id?.toString() || '-'}
          items={[
            {
              title: 'Response Date & Time',
              value:
                data?.executed_date && formatDateStringPreQualification(data?.executed_date, true)
            },
            {
              title: 'Social Security Number',
              value: data?.ssn && formatSSNAndPhone(data?.ssn, false)
            },
            {
              title: 'Date of Birth',
              value:
                data?.date_of_birth && formatDateStringPreQualification(data?.date_of_birth, false)
            },
            {
              title: 'Customer Name',
              value: data?.customer_name || '-'
            },
            {
              title: 'Email Address',
              value: data?.email || '-'
            },
            {
              title: 'Mobile Phone Number',
              value: data?.ssn && formatSSNAndPhone(data?.mobile_phone_number, true)
            },
            {
              title: 'Address',
              value: data?.address || '-'
            }
          ]}
        />
      </Dialog>
    </PreQualificationDialogWrap>
  )
}

export default PreQualificationAlert
