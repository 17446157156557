import { FC } from 'react'
import { useTheme } from '@mui/material'
import CustomerDetailsSection from '@app/in-progress/customer-details'
import CardBanner from '@app/in-progress/card-banner'
import { Grid } from '@ntpkunity/controls'

const DetailsSections: FC<any> = () => {
  const theme = useTheme()

  return (
    <>
      <Grid theme={theme} item xl={12} lg={12} md={12} sm={12} xs={12}>
        <CardBanner />
      </Grid>
      <Grid theme={theme} item xl={12} lg={12} md={12} sm={12} xs={12}>
        <CustomerDetailsSection />
      </Grid>
    </>
  )
}

export default DetailsSections
