import { Input, unityTheme } from '@ntpkunity/controls'
import { ReactNode } from 'react'

interface Props {
  label: string
  helperText?: string
  disabled?: boolean
  required?: boolean
  type: string
  value?: any
  onChange?(e: any): void
  startAdornment?: ReactNode | string
  endAdornment?: ReactNode | string
  onBlur?(e: any): void
  currency?: boolean
  numeric?: boolean
  masking?: boolean
  mask?: string
  scale?: number
  dynamic?: boolean
  unMaskedValue?: boolean
  error?: string
  id?: string
  testid?: string
  min?: number
  max?: number
  allowNegative?: boolean
}

const TextBox: React.FC<Props> = (props: Props) => {
  let {
    label = '',
    helperText = '',
    disabled = false,
    // required = false,
    type = 'text',
    value = '',
    onChange,
    startAdornment = '',
    endAdornment = '',
    onBlur,
    masking = false,
    currency = false,
    numeric = false,
    mask = ' ',
    scale = 2,
    min = 0,
    max = Number.MAX_VALUE,
    allowNegative = false,
    dynamic = true,
    unMaskedValue = true,
    error = '',
    id = 'textbox',
    testid = ''
  } = props

  return (
    <Input
      label={label}
      helperText={helperText}
      theme={unityTheme}
      type={type}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      fullWidth
      id={id}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      masking={masking}
      inputMask={mask}
      maskCurrency={currency}
      maskDecimalScale={scale}
      min={min}
      max={max}
      allowNegative={allowNegative}
      maskNumeric={numeric}
      // dynamic={!dynamic && (numeric || currency) ? false : dynamic}
      dynamic={dynamic}
      placeholder=" "
      unMaskedValue={unMaskedValue}
      error={error}
      testid={testid}
    />
  )
}

export default TextBox
