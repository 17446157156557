import { FC } from 'react'
import { useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import { Box, IBoxProps } from '@ntpkunity/controls'
import { styled } from '@mui/material/styles'
import VehicleReturn from '@app/in-progress/return-vehicle/vehicle-return'

const PageWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '.btn-back': {
    paddingLeft: '5px !important',
    '&:hover': {
      backgroundColor: 'transparent',
      svg: {
        path: {
          transition: 'all 0.3s',
          stroke: theme.palette.grey[700]
        }
      }
    },
    '.MuiTouchRipple-root': {
      display: 'none'
    }
  },
  '.ndt': {
    width: '100%',
    display: 'inline-block',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.caption.fontSize,
    lineHeight: theme.typography.caption.lineHeight,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey[600],
    textAlign: 'right',
    b: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.grey[900]
    }
  },
  '.anchor': {
    color: 'white',
    cursor: 'pointer'
  }
}))

const DealerVehicleReturn: FC = () => {
  const theme = useTheme()

  return (
    <>
      <LayoutWithSideNav theme={theme}>
        <PageWrap theme={theme} sx={{ mb: 2.5 }}>
          <VehicleReturn />
        </PageWrap>
      </LayoutWithSideNav>
    </>
  )
}

export default DealerVehicleReturn
