import { InputAdornment, useTheme } from '@mui/material'
import { Box, Button, Icon, Input, Typography } from '@ntpkunity/controls'
import { Controller, useForm } from 'react-hook-form'
import { ChatNotificationHeaderWrap } from './ChatNotificationHeader.style'
import { useChatServiceContext } from '@hooks/useChatService'
import { useEffect } from 'react'
import { useDebouncedState } from '@apis/dealer-configurations.service'

const ChatNotificationHeader = () => {
  const { control, watch } = useForm<{ search: string }>()
  const { setOpenChat, clearSelectedChat, getChannels } = useChatServiceContext()
  const searchTerm = watch('search')
  const [debouncedSearchTerm] = useDebouncedState(searchTerm)

  useEffect(() => {
    if (!debouncedSearchTerm && typeof debouncedSearchTerm !== 'string') return
    getChannels(debouncedSearchTerm, 1)
  }, [debouncedSearchTerm])

  // Whenever the search input is unmounted
  // Reset the chat channels
  useEffect(() => {
    return () => {
      getChannels('', 1)
    }
  }, [])

  const theme = useTheme()
  return (
    <ChatNotificationHeaderWrap theme={theme} className="notification-header-wrap">
      <Typography theme={theme} component="h3" variant="h3" className="header-title">
        Conversations
      </Typography>
      <Box theme={theme} className="search-wrap">
        <Controller
          name="search"
          control={control}
          render={({ field }) => (
            <Input
              theme={theme}
              fullWidth
              type="text"
              placeholder="Search"
              startAdornment={
                <InputAdornment position="start">
                  <Icon name="SearchIcon" />
                </InputAdornment>
              }
              {...field}
            />
          )}
        />
      </Box>
      <Button
        theme={theme}
        iconText={<Icon name="CloseBlack" />}
        onClick={() => {
          setOpenChat(false)
          clearSelectedChat()
        }}
        className="menu-close-icon"
      />
    </ChatNotificationHeaderWrap>
  )
}

export default ChatNotificationHeader
