import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import {
  Icon,
  DatePicker,
  Grid,
  Input,
  Radio,
  Select,
  Skeleton,
  Typography,
  Textarea,
  IBoxProps,
  Box
} from '@ntpkunity/controls'
import CommomReturnPageHeader from '@app/in-progress/return-vehicle/PageHeader'
import { ConfirmationDialog, Divider } from '@components'
import {
  useGetChecklistSavedProgress,
  useGetReadyCheckListRequest
} from '@apis/dealer-workqueue.service'
import { useStoreContext } from '@store/storeContext'
import { IRequestReadyCheckList } from '@models'
import { Controller, useForm } from 'react-hook-form'
import DetailsSections from '@app/in-progress/return-vehicle/details-sections/vehicle-and-customer-details-sections'
import { RETURNS } from '@helpers/enums'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import { compareAnswers, utcToLocal } from '@helpers/methods'

const UnwindReturnWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '.text-danger': {
    color: theme.palette.error.main
  },
  '.radio-caption': {
    color: theme.palette.grey[800],
    b: {
      color: theme.palette.common.black
    }
  }
}))
const UnwindReturn: FC<{ schedulePickUpValues?: any }> = ({ schedulePickUpValues }) => {
  const theme = useTheme()
  const { states } = useStoreContext()
  const planned_return_date = states?.dealerSelectedReturnData?.planned_return_date || '' // format=> MM/DD/YY 07/20/2023
  const allowedUsage = states?.dealerSelectedReturnData?.allowed_usage ?? 0
  const check_list_start_date = states?.dealerSelectedReturnData?.check_list_start_date || ''
  const odometervalue = states?.dealerSelectedReturnData?.current_odometer_reading ?? 0
  const actual_return_date = states?.dealerSelectedReturnData?.actual_return_date || '' // format=> MM/DD/YY 07/20/2023
  const overrun_miles =
    odometervalue - allowedUsage - (states?.dealerSelectedReturnData?.starting_odometer ?? 0)
  const defaultValue = {
    return_type: states?.dealerSelectedReturnData?.return_type ?? '',
    planned_return_date: planned_return_date,
    actual_return_date: actual_return_date,
    check_list_start_date: check_list_start_date,
    starting_odometer_reading:
      states?.dealerSelectedReturnData?.starting_odometer?.toString() ?? '0',
    odometer_reading: states?.dealerSelectedReturnData?.current_odometer_reading?.toString(),
    annual_allowed_mileage: states?.dealerSelectedReturnData?.allowed_usage?.toString() ?? '',
    overrun_miles: overrun_miles > 0 ? overrun_miles : 0,
    checklist_questions: {},
    checklist_info: {},
    keys_description: '',
    dealer_comments: states?.dealerSelectedReturnData?.dealer_comments ?? ''
  }
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    register,
    trigger,
    formState: { errors, isDirty }
  } = useForm<any>({
    defaultValues: defaultValue,
    mode: 'all'
  })
  const { mutate: getChecklistProgressOptions, data: _savedChecklist } =
    useGetChecklistSavedProgress()
  const {
    mutate: getEventAssociatedCheckList,
    data: checkList,
    isLoading: checkListLoading
  } = useGetReadyCheckListRequest()
  const [savedChecklist, setSavedChecklist] = useState<any>()
  const [checklistErrors, setChecklistErrors] = useState<any>()
  const [keysData, setKeysData] = useState<boolean>(false)
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false)
  const [showPopup, setShowReturnConfirmationDialogue] = useState<boolean>(false)
  const [checklistStartDate, setchecklistStartDate] = useState<boolean>(false)
  const [radioValues, setRadioValues] = useState({})
  const [formErrors, setFormErrors] = useState<any>()
  const [savedChecklistOptions, setSavedChecklistOptions] = useState<boolean>(false)
  const reqCheckListObject: IRequestReadyCheckList = {
    event_name: 'dealer-vehicle-return-unwind-event'
  }
  let number_of_keys_returned = 'Number of keys returned?'
  let schedulePickUpValue = Array.isArray(schedulePickUpValues)
    ? schedulePickUpValues.find(
        (entry) =>
          entry.question_text === 'How many keys/key cards/FOBs did you provide to the customer ?'
      )
    : ''

  const onRadioChange = (val: any, question: any) => {
    setRadioValues({ ...radioValues, [question]: val })
  }
  useEffect(() => {
    if (states?.dealerSelectedOrderData?.customerId) {
      getEventAssociatedCheckList(
        { ...reqCheckListObject },
        {
          onSuccess(res: any) {
            setValue('checklist_info.checklist_identifier', res[0]?.[0]?.checklist_identifier)
            setValue('checklist_info.checklist_name', res[0]?.[0]?.checklist_name)
            setValue('checklist_info.event_name', 'dealer-vehicle-return-unwind-event')
            setShowSaveButton(true)
            getChecklistProgressOptions(
              {
                customer_reference_id: states?.dealerSelectedOrderData?.customerId,
                checklist_identifier: res[0]?.[0]?.checklist_identifier,
                order_reference_id: states?.dealerSelectedOrderData?.id
              },
              {
                onSuccess(res: any) {
                  setSavedChecklist(res)
                  for (let i = 0; i < res.length; i++) {
                    setRadioValues((previousValues) => {
                      return {
                        ...previousValues,
                        [res[i].question_text]: res[i].primary_answer
                      }
                    })
                    setValue(`checklist_questions.${res[i].question_text}`, res[i].primary_answer)
                    if (res[i].question_text == number_of_keys_returned) {
                      setValue('keys_description', res[i].description)
                    }
                  }
                  setSavedChecklistOptions(true)
                  setShowSaveButton(true)
                },
                onError({ error }: any) {
                  if (error?.response?.data?.detail?.code === 404) {
                    setSavedChecklistOptions(false)
                  }
                }
              }
            )
          }
        }
      )
    }
  }, [states?.dealerSelectedOrderData?.customerId])

  useEffect(() => {
    if (savedChecklist && savedChecklistOptions && showSaveButton) {
      const checklistQuestionNames = savedChecklist
        .filter((item: any) => item?.question_text in getValues('checklist_questions'))
        .map((item: any) => `checklist_questions.${item?.question_text}`)

      ;(async () => await trigger(checklistQuestionNames))()
    }
  }, [savedChecklistOptions, savedChecklist, showSaveButton, radioValues])

  useEffect(() => {
    if (radioValues) {
      const errors = compareAnswers(radioValues, checkList)
      setFormErrors(errors)
      setChecklistErrors(errors)
    }
  }, [savedChecklistOptions, checkList, radioValues])

  const propsForUnwindReturnPageHeader = {
    handleSubmit,
    formValues: getValues,
    formIsDirty: isDirty,
    returnType: RETURNS.Unwind,
    returnStatus: states?.dealerSelectedReturnData?.subscription_status,
    setShowReturnConfirmationDialogue: setShowReturnConfirmationDialogue,
    checklistData: checkList,
    setChecklistErrors: setChecklistErrors,
    trigger: trigger,
    errors: errors,
    setValue: setValue,
    checklistStartDate: checklistStartDate,
    schedulePickUpValue: schedulePickUpValue,
    keysData: keysData,
    setKeysData: setKeysData
  }

  return (
    <>
      <ConfirmationDialog
        openPopUp={showPopup}
        onConfirm={() => {
          setShowSaveButton(true)
          setShowReturnConfirmationDialogue(false)
          setchecklistStartDate(true)
        }}
        onCancel={() => {
          setShowSaveButton(false)
          setShowReturnConfirmationDialogue(false)
        }}
        setPopUpState={() => {}}
        confirmationTitle={'Please confirm that the vehicle has been returned to the store.'}
        primaryBtnText={'Yes'}
        secondaryBtnText={'No'}
        icon={<Icon name="DialogAlertIcon" />}
        hideCancelButton={false}
      />
      <UnwindReturnWrap theme={theme}>
        <Grid container theme={theme}>
          <Grid theme={theme} item xl={12} lg={12} md={12} sm={12} xs={12}>
            <CommomReturnPageHeader {...propsForUnwindReturnPageHeader} />
          </Grid>
          <DetailsSections />
        </Grid>

        <Grid theme={theme} container spacing={2} mt={1} xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid item theme={theme} xs={4}>
            <Controller
              name="return_type"
              control={control}
              defaultValue={''}
              render={({ field: { onChange, value } }) => (
                <Select
                  theme={theme}
                  disabled
                  label={'Return Type'}
                  onChange={(e) => {
                    onChange(e)
                  }}
                  value={value || ''}
                  items={[{ text: 'Unwind', value: states?.dealerSelectedReturnData?.return_type }]}
                />
              )}
            />
          </Grid>
          <Grid item theme={theme} xs={4}>
            <Controller
              name="planned_return_date"
              control={control}
              render={({ field: { value } }) => (
                <>
                  <DatePicker
                    disabled
                    theme={theme}
                    inputFormat="MM/DD/YYYY"
                    label={'Planned Return Date'}
                    value={utcToLocal(value) || ''} // format MM(07)/(25)DD/(2023)YY
                    onKeyDown={(e: any) => e.preventDefault()}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item theme={theme} xs={4}>
            <Controller
              name="actual_return_date"
              control={control}
              render={({ field: { value } }) => (
                <Input
                  theme={theme}
                  type={'text'}
                  fullWidth
                  disabled
                  value={utcToLocal(value, 'MM/DD/YYYY, hh:mm:ss A')}
                  label="Actual Return Date"
                />
              )}
            />
          </Grid>
          <Grid item theme={theme} xs={4}>
            <Controller
              name="check_list_start_date"
              control={control}
              render={({ field: { value } }) => (
                <>
                  <Input
                    disabled
                    fullWidth
                    type="text"
                    theme={theme}
                    label={'Return Checklist Start Date'}
                    value={utcToLocal(value, 'MM/DD/YYYY, hh:mm:ss A')} // format MM(07)/(25)DD/(2023)YY
                    onKeyDown={(e: any) => e.preventDefault()}
                  />
                </>
              )}
            />
          </Grid>
          <Grid theme={theme} item xl={4} lg={4} md={4} sm={6} xs={12}>
            <Controller
              name="starting_odometer_reading"
              control={control}
              render={({ field: { value } }) => (
                <Input
                  theme={theme}
                  type={'text'}
                  disabled
                  fullWidth
                  masking
                  maskNumeric
                  format={'#################'}
                  value={value || ''}
                  label="Starting Odometer"
                  placeholder="Type here.."
                />
              )}
            />
          </Grid>

          <Grid theme={theme} item xl={4} lg={4} md={4} sm={6} xs={12}>
            <Controller
              name="annual_allowed_mileage"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  theme={theme}
                  fullWidth
                  placeholder="0000"
                  disabled
                  onChange={(e) => {
                    onChange(e)
                  }}
                  value={value || ''}
                  label="Allowed Term Miles"
                  type="decimal"
                />
              )}
            />
          </Grid>
          <Grid theme={theme} item xl={4} lg={4} md={4} sm={6} xs={12}>
            <Controller
              name="overrun_miles"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  theme={theme}
                  fullWidth
                  placeholder="0000"
                  disabled
                  onChange={(e) => {
                    onChange(e)
                  }}
                  value={value}
                  label="Mileage Overage"
                  type="number"
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid theme={theme} rowSpacing={3} columnSpacing={3} mt={2} container>
          {checkListLoading &&
            [1, 2, 3, 4]?.map((val) => (
              <Grid theme={theme} item md={4} sm={6} xs={12} key={val}>
                <Skeleton theme={theme} width={'25%'} height={'20px'} />
                <Skeleton theme={theme} width={'20%'} height={'20px'} />
              </Grid>
            ))}
          {checkList?.length > 0 &&
            checkList?.map((innerArray: any) =>
              innerArray?.map((list: any) =>
                list?.questions?.map((obj: any, index: any) => (
                  <>
                    <Grid theme={theme} item md={4} sm={4} lg={4} xs={12} key={index}>
                      {savedChecklist?.find(
                        (myObj: any) => myObj?.question_text == obj?.question
                      ) && (
                        <>
                          <Typography
                            theme={theme}
                            variant="subtitle2"
                            component="p"
                            sx={{ mb: 1.5 }}
                            children={obj?.question}
                          />
                          <Controller
                            name={`checklist_questions.${obj?.question}`}
                            control={control}
                            rules={{
                              required: 'Select an option to proceed.'
                            }}
                            defaultValue={''}
                            render={({ field: { onChange, value } }: any) => (
                              <Radio
                                theme={theme}
                                row={true}
                                onChange={(val: any) => {
                                  onChange(val)
                                  onRadioChange(val, obj.question)
                                  setValue(`checklist_questions.${obj?.question}`, val, {
                                    shouldDirty: true
                                  })
                                  setShowSaveButton(true)
                                  setKeysData(false)
                                }}
                                defaultValue={
                                  savedChecklist?.find(
                                    (myObj: any) => myObj?.question_text == obj?.question
                                  ).primary_answer
                                }
                                value={value}
                                radioError={
                                  (errors?.checklist_questions
                                    ? (
                                        errors.checklist_questions as {
                                          [key: string]: { message?: string }
                                        }
                                      )[obj?.question]?.message
                                    : undefined) ??
                                  checklistErrors?.find(
                                    (error: { question: any }) => error.question === obj?.question
                                  )?.message
                                }
                                options={obj?.answers.map((obj: any) => ({
                                  label: obj?.answer,
                                  value: obj?.answer,
                                  disabled: !watch('check_list_start_date') ? true : false
                                }))}
                              />
                            )}
                          />
                          {!errors?.[`checklist_questions.${obj?.question}`] &&
                          formErrors.length > 0 &&
                          showSaveButton &&
                          !checklistErrors?.find(
                            (error: { question: any }) =>
                              error?.question === formErrors[0]?.question
                          ) ? (
                            <Typography
                              className="text-danger"
                              theme={theme}
                              variant="caption"
                              component="small"
                              display="block"
                              // children={obj?.question}
                            >
                              {
                                formErrors?.find(
                                  (error: { question: any }) => error.question === obj?.question
                                )?.message
                              }
                            </Typography>
                          ) : null}
                          {obj?.question === number_of_keys_returned && (
                            <>
                              <Typography
                                theme={theme}
                                className="radio-caption"
                                variant="caption"
                                component="div"
                                children={
                                  <>
                                    No. of Keys at Delivery:{' '}
                                    <b>
                                      {schedulePickUpValue?.primary_answer
                                        ? schedulePickUpValue?.primary_answer
                                        : ''}
                                    </b>
                                  </>
                                }
                              />
                              <Typography
                                theme={theme}
                                className="radio-caption"
                                variant="caption"
                                component="div"
                                sx={{ mb: 1.5 }}
                                children={
                                  <>
                                    Delivery Details:{' '}
                                    <b>
                                      {schedulePickUpValue?.description
                                        ? schedulePickUpValue?.description
                                        : 'No Details Given'}
                                    </b>
                                  </>
                                }
                              />
                            </>
                          )}
                        </>
                      )}
                      {!savedChecklist?.find(
                        (myObj: any) => myObj?.question_text == obj?.question
                      ) && (
                        <>
                          <Typography
                            theme={theme}
                            variant="subtitle2"
                            component="div"
                            sx={{ mb: 1.5 }}
                            children={obj?.question}
                          />
                          <Controller
                            name={`checklist_questions.${obj?.question}`}
                            control={control}
                            defaultValue={''}
                            rules={{
                              required: 'Select an option to proceed.'
                            }}
                            render={({ field: { onChange } }: any) => (
                              <Radio
                                theme={theme}
                                row={true}
                                onChange={(val: any) => {
                                  onChange(val)
                                  onRadioChange(val, obj.question)
                                  setValue(`checklist_questions.${obj?.question}`, val, {
                                    shouldDirty: true
                                  })
                                  setShowSaveButton(true)
                                  setKeysData(false)
                                }}
                                radioError={
                                  (errors?.checklist_questions
                                    ? (
                                        errors.checklist_questions as {
                                          [key: string]: { message?: string }
                                        }
                                      )[obj?.question]?.message
                                    : undefined) ??
                                  checklistErrors?.find(
                                    (error: { question: any }) => error.question === obj?.question
                                  )?.message
                                }
                                options={obj?.answers.map((obj: any) => ({
                                  label: obj?.answer,
                                  value: obj?.answer,
                                  disabled: !watch('check_list_start_date') ? true : false
                                }))}
                              />
                            )}
                          />
                          {!errors?.[`checklist_questions.${obj?.question}`] &&
                          formErrors.length > 0 &&
                          showSaveButton &&
                          !checklistErrors?.find(
                            (error: { question: any }) =>
                              error?.question === formErrors[0]?.question
                          ) ? (
                            <Typography
                              className="text-danger"
                              theme={theme}
                              variant="caption"
                              component="small"
                              display="block"
                              // children={obj?.question}
                            >
                              {
                                formErrors?.find(
                                  (error: { question: any }) => error.question === obj?.question
                                )?.message
                              }
                            </Typography>
                          ) : null}
                          {obj?.question === number_of_keys_returned && (
                            <>
                              <Typography
                                theme={theme}
                                className="radio-caption"
                                variant="caption"
                                component="div"
                                children={
                                  <>
                                    No. of Keys at Delivery:{' '}
                                    <b>
                                      {schedulePickUpValue?.primary_answer
                                        ? schedulePickUpValue?.primary_answer
                                        : ''}
                                    </b>
                                  </>
                                }
                              />
                              <Typography
                                theme={theme}
                                className="radio-caption"
                                variant="caption"
                                component="div"
                                sx={{ mb: 1.5 }}
                                children={
                                  <>
                                    Delivery Details:{' '}
                                    <b>
                                      {schedulePickUpValue?.description
                                        ? schedulePickUpValue?.description
                                        : 'No Details Given'}
                                    </b>
                                  </>
                                }
                              />
                            </>
                          )}
                        </>
                      )}
                      {obj?.question === number_of_keys_returned &&
                        radioValues.hasOwnProperty(number_of_keys_returned) && (
                          <Controller
                            name={`keys_description`}
                            control={control}
                            defaultValue={
                              savedChecklist?.find(
                                (myObj: any) => myObj?.question_text === number_of_keys_returned
                              )?.description
                            }
                            render={({ field }) => (
                              <Textarea
                                theme={theme}
                                label={'Details'}
                                disabled={!watch('check_list_start_date') ? true : false}
                                type="text"
                                id="description"
                                placeholder="Type here..."
                                onChange={(e) => {
                                  field.onChange(e)
                                  setValue('keys_description', e)
                                }}
                                rows={3}
                                fullWidth
                                // {...field}
                                value={field.value}
                              />
                            )}
                          />
                        )}
                    </Grid>
                  </>
                ))
              )
            )}
        </Grid>
        <Divider sx={{ mt: '32px', mb: '8px' }} />
        <Grid theme={theme} rowSpacing={4} columnSpacing={3} mt={1} container>
          <Grid theme={theme} item xl={4} lg={4} md={4} sm={6} xs={12}>
            <Controller
              name="odometer_reading"
              rules={{
                required: 'Odometer reading is required', // Add the required validation rule
                validate: (value: any) => value > 0 || 'Mileage should be greater than 0'
              }}
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  theme={theme}
                  type={'text'}
                  disabled={!watch('check_list_start_date') ? true : false}
                  fullWidth
                  max={999999}
                  onChange={(e) => {
                    onChange(e)
                    const contract_odometer_reading =
                      states?.dealerSelectedReturnData?.starting_odometer ?? 0
                    setValue(
                      'overrun_miles',
                      Number(e) - allowedUsage - contract_odometer_reading > 0
                        ? Number(e) - allowedUsage - contract_odometer_reading
                        : 0
                    )
                  }}
                  masking
                  maskNumeric
                  format={'######'}
                  value={value || ''}
                  error={error?.message} // Show error if the field has an error
                  label="Return Mileage"
                  placeholder="Type here.."
                />
              )}
            />
          </Grid>

          <Grid theme={theme} item xl={4} lg={4} md={4} sm={6} xs={12}>
            <Controller
              {...register('last_8_digits_of_vin', {
                required: 'Vin is required',
                validate: (value: any) =>
                  value ===
                    (states?.dealerSelectedOrderData?.orderItems?.length > 0 &&
                      states?.dealerSelectedOrderData?.orderItems[0]?.metadata?.vin?.slice(-8)) || // -8 to get last 8 characters
                  'VIN does not match'
              })}
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  theme={theme}
                  fullWidth
                  placeholder="00000000"
                  disabled={watch('check_list_start_date') === '' ? true : false}
                  onChange={(e) => {
                    if (e?.length <= 8) {
                      onChange(e)
                    }
                  }}
                  value={value}
                  error={error?.message}
                  label="Last 8 Digits of VIN"
                  type="text"
                />
              )}
            />
          </Grid>
          <Grid theme={theme} item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Controller
              name={`dealer_comments`}
              control={control}
              render={({ field }) => (
                <Textarea
                  theme={theme}
                  label={'Additional Comments'}
                  disabled={!watch('check_list_start_date') ? true : false}
                  type="text"
                  id="description"
                  placeholder="Type here..."
                  {...field}
                  rows={1}
                  fullWidth
                  // {...field}
                  value={field.value}
                />
              )}
            />
          </Grid>
        </Grid>

        {(showSaveButton === true ||
          states.dealerSelectedReturnData?.subscription_status === RETURNS.PROCESSING_RETURN) && (
          <>
            <Divider sx={{ mt: '32px', mb: '25px' }} />
            <Typography theme={theme} mb={2} component="h4" variant="h4">
              Inspection Tool
            </Typography>
            <Link
              to={process.env.INSPECTION_TOOL_URL?.toString() ?? ''}
              target="_blank"
              rel="noopener noreferrer"
            >
              Please click here to begin the digital inspection for the vehicle return.
            </Link>
          </>
        )}
      </UnwindReturnWrap>
    </>
  )
}

export default UnwindReturn
