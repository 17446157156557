import { FC } from 'react'
import { useTheme } from '@mui/material'
import { Typography, Grid, Button, Box } from '@ntpkunity/controls'
import { BtnGroupList, Divider } from '@components'
import { QueryKeys } from '@helpers/enums'
import { useWatch } from 'react-hook-form'
import { useGetAssetUsage } from '@apis/configurations.service'
import { useQueryClient } from 'react-query'
export const AssetUsage: FC<{ isDisable?: boolean; form: any }> = ({ isDisable, form }) => {
  const theme = useTheme()
  const { control } = form
  useGetAssetUsage()
  const queryClient = useQueryClient()
  const activeAsset: any = queryClient.getQueryData(QueryKeys.GET_ALL_ACTIVE_ASSET_USAGE)
  useWatch({
    control,
    name: ['asset_usage']
  })
  return (
    <>
      <Typography
        theme={theme}
        variant="h4"
        component="h4"
        sx={{ mb: 2.5 }}
        children="Asset Usage"
      />
      <BtnGroupList>
        <Grid theme={theme} container columnSpacing={1} rowSpacing={0}>
          {activeAsset &&
            activeAsset?.map((option: any, index: any) => (
              <Grid theme={theme} key={index} item xs={6} sx={{ mb: 1 }}>
                <Button
                  fullWidth
                  primary={
                    (form.watch('asset_usage') === null && index === 0) ||
                    form.watch('asset_usage') === option?.description ||
                    `${form.watch('asset_usage')}` === option?.description
                  }
                  secondary={
                    (form.watch('asset_usage') === null && index !== 0) ||
                    (form.watch('asset_usage') !== option?.description &&
                      `${form.watch('asset_usage')}` !== option?.description)
                  }
                  theme={theme}
                  text={option?.description}
                  disabled={isDisable}
                  onClick={() => form.setValue('asset_usage', option?.description)}
                />
              </Grid>
            ))}
        </Grid>
      </BtnGroupList>
      <Box theme={theme} sx={{ mb: 4, mt: 3 }}>
        <Divider />
      </Box>
    </>
  )
}
