import { FC, useState } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { Box, Button, Icon } from '@ntpkunity/controls'
import { lightTheme } from '@styles/theme'

const FarabiWrap: any = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: 20,
  right: 40,
  width: 600,
  zIndex: 1100,
  '.iframe-wrap': {
    backgroundColor: theme.palette.common.white,
    display: 'block',
    width: '100%',
    height: 550,
    clear: 'both',
    border: '1px solid' + theme.palette.divider,
    borderRadius: 13,
    overflow: 'hidden',
    marginBottom: 10,

    iframe: {
      width: '100%',
      height: '115%',
      border: 0
    }
  },
  '.btn.with-icon.icon-only': {
    padding: 5,
    float: 'right',
    backgroundColor: theme.palette.common.white,
    borderRadius: '100%',
    border: '1px solid' + theme.palette.divider,
    boxShadow: '-5px 5px 5px rgba(0,0,0, 0.2)',
    svg: {
      height: '35px !important',
      width: '35px !important',
      path: {
        fill: lightTheme.palette.primary.main
      }
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[50]
    }
  }
}))

export const Farabi: FC = () => {
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  return (
    <>
      <FarabiWrap>
        {open && (
          <Box theme={theme} className="iframe-wrap">
            <iframe title=" " src="https://farabi-v6.streamlit.app/?embed=true" />
          </Box>
        )}
        <Button
          theme={theme}
          className="btn-primary-icon-fill"
          iconText={<Icon name="ChatIcon" />}
          onClick={() => setOpen(!open)}
        />
      </FarabiWrap>
    </>
  )
}
