import { FC, useState } from 'react'
import { useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import { Box, IBoxProps } from '@ntpkunity/controls'
import { styled } from '@mui/material/styles'
import ReadyChecklist from '@app/in-progress/ready-check-list/ready-checklist'
import InProgressPageHeader from '@app/in-progress/page-header'
import { AlertBox } from 'components'
import { useForm } from 'react-hook-form'
import { containsKeyValue } from '@helpers/methods'

const PageWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '.btn-back': {
    paddingLeft: '5px !important',
    '&:hover': {
      backgroundColor: 'transparent',
      svg: {
        path: {
          transition: 'all 0.3s',
          stroke: theme.palette.grey[700]
        }
      }
    },
    '.MuiTouchRipple-root': {
      display: 'none'
    }
  },
  '.ndt': {
    width: '100%',
    display: 'inline-block',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.caption.fontSize,
    lineHeight: theme.typography.caption.lineHeight,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey[600],
    textAlign: 'right',
    b: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.grey[900]
    }
  },
  '.anchor': {
    color: 'white',
    cursor: 'pointer'
  },
  '.toggle-btn': {
    position: 'relative',
    top: 5,
    marginLeft: 10,
    cursor: 'pointer'
  },
  '.ch-up': {
    webkitTransform: 'scaleY(-1)',
    transform: 'scaleY(-1)'
  },
  '.text-danger': {
    color: theme.palette.error.main
  }
}))

const DealerGetReadyCheckList: FC = () => {
  const theme = useTheme()
  const [checkListReqData, setCheckListRequestData] = useState({})
  const [checkListName, setCheckListName] = useState('')
  const [checklistIdentifier, setCheckListIdentifier] = useState('')
  const [questionsCount, setQuestionsCount] = useState(0)
  const [savedOptionAvailable, setSavedChecklistOptions] = useState(false)
  const [description, setDescription] = useState('')
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false)
  const [readyChecklistData, setReadyChecklistData] = useState<any>()
  const [checklistErrors, setChecklistErrors] = useState<any>()
  const form = useForm({ mode: 'all' })

  //@ts-ignore
  const checkListCallback = (
    data: any,
    savedOptionsAvailable: any,
    questionCount?: any,
    checklist_name?: any,
    checklist_identifier?: any,
    description?: any,
    checklistData?: any
  ) => {
    setCheckListRequestData(data)
    setCheckListName(checklist_name)
    setQuestionsCount(questionCount)
    setSavedChecklistOptions(savedOptionsAvailable)
    setCheckListIdentifier(checklist_identifier)
    setDescription(description ?? undefined)
    setReadyChecklistData(checklistData)
    if (data && Object.keys(data).length > 0) {
      if (containsKeyValue(data, 'Does the vehicle have any damage?', 'Yes') && !description) {
        setShowSaveButton(false)
      } else {
        setShowSaveButton(true)
      }
    }
  }

  return (
    <>
      <LayoutWithSideNav theme={theme}>
        <PageWrap theme={theme} sx={{ mb: 2.5 }}>
          <InProgressPageHeader
            showButton={showSaveButton}
            reqData={checkListReqData}
            checkListName={checkListName}
            checklistIdentifier={checklistIdentifier}
            questionCount={questionsCount}
            savedOptionAvailable={savedOptionAvailable}
            description={description}
            checklistData={readyChecklistData}
            setChecklistErrors={setChecklistErrors}
            form={form}
          />
          <AlertBox className={'danger'} sx={{ mb: 3 }}>
            This vehicle has been sold. Please set vehicle aside and begin delivery checklist.
          </AlertBox>
          <ReadyChecklist
            form={form}
            checkListCallback={checkListCallback}
            checklistErrors={checklistErrors}
          />
        </PageWrap>
      </LayoutWithSideNav>
    </>
  )
}

export default DealerGetReadyCheckList
