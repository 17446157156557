type IResponseCode = string | number
type ErrorCode = string

export default class AppError extends Error {
  serviceName: string
  message: string = ''
  code: IResponseCode
  errorCode: ErrorCode
  error: any
  requestBody: any

  constructor(
    name: string,
    message: string,
    errorCode?: ErrorCode,
    code?: IResponseCode,
    requestBody?: any
  ) {
    super(message)
    this.serviceName = name || this.name
    this.message = typeof message === 'string' ? message : 'No Details' || this.message
    this.code = code || 500
    this.errorCode = errorCode || 'UNHANDLED_EXCEPTION'
    this.error = typeof message === 'object' ? message : {}
    this.requestBody = requestBody || null
  }
}

export class ApiError extends AppError {}

export class BadRequest extends AppError {
  constructor(name: string, message: string, errorCode?: ErrorCode, code?: IResponseCode) {
    super(name, message, errorCode, code)
    this.code = code || 400
    this.errorCode = errorCode || 'BAD_REQUEST'
  }
}

export class NotFoundError extends AppError {
  constructor(name: string, message: string, errorCode?: ErrorCode, code?: IResponseCode) {
    super(name, message, errorCode, code)
    this.code = code || 404
    this.errorCode = errorCode || 'NOT_FOUND'
  }
}

export class AuthenticationError extends AppError {
  constructor(name: string, message: string, errorCode?: ErrorCode, code?: IResponseCode) {
    super(name, message, errorCode, code)
    this.code = code || 403
    this.errorCode = errorCode || 'OPERATION_NOT_PERMITTED'
  }
}
