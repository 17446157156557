import IActions from '../configs'
import { Dispatch, ReducerAction } from 'react'

import { IPaginatedResponse_AddOns, AddOns } from '@models'

export const SET_ADDON_INIT_STATE = 'GET ADDON DATA FROM SERVER'
export const UPDATE_ADDON_ROW = 'UPDATE_ADDON_ROW'
export const DELETE_ADDON_ROW = 'DELETE_ADDON_ROW'
export const ADD_ADDON_ROW = 'ADD_ADDON_ROW'
export const initialState: IPaginatedResponse_AddOns = {
  pageNum: 0,
  pageSize: 0,
  result: [],
  totalResults: 0
}

const addonReducer = (state: typeof initialState, action: IActions): typeof initialState => {
  switch (action.type) {
    case SET_ADDON_INIT_STATE:
      return action.payload as typeof initialState
    case UPDATE_ADDON_ROW: {
      const index = state.result.findIndex(
        (addon: any) => addon.id === (action.payload as AddOns).id
      )
      state.result[index] = action.payload as any
      return state
    }
    case DELETE_ADDON_ROW: {
      const index = state.result.findIndex(
        (addon: any) => addon.id === (action.payload as AddOns).id
      )
      state.result.splice(index, 1) == (action.payload as any)
      return state
    }
    case ADD_ADDON_ROW: {
      state.result.unshift(action.payload as any)
      return state
    }
  }
  return state
}

export const updateAddonData = (
  data: typeof initialState,
  dispatch: Dispatch<ReducerAction<typeof addonReducer>>
): void => {
  if (data) {
    dispatch({ type: SET_ADDON_INIT_STATE, payload: data })
  }
}

export const updateAddonDataRow = (
  data: AddOns,
  dispatch: Dispatch<ReducerAction<typeof addonReducer>>
): void => {
  if (data) {
    dispatch({ type: UPDATE_ADDON_ROW, payload: data })
  }
}
export const deleteAddonDataRow = (
  data: AddOns,
  dispatch: Dispatch<ReducerAction<typeof addonReducer>>
): void => {
  if (data) {
    dispatch({ type: DELETE_ADDON_ROW, payload: data })
  }
}
export const addAddonDataRow = (
  data: AddOns,
  dispatch: Dispatch<ReducerAction<typeof addonReducer>>
): void => {
  if (data) {
    dispatch({ type: ADD_ADDON_ROW, payload: data })
  }
}
export default addonReducer
