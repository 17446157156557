import IActions, { IBrowserStorage } from '../configs'
import { Dispatch, ReducerAction } from 'react'

export const UPDATE_LOCAL_STORAGE = 'UPDATE_LOCAL_STORAGE'
export const UPDATE_SESSION_STORAGE = 'UPDATE_SESSION_STORAGE'

export const initialState: IBrowserStorage = {
  localStorage: null,
  sessionStorage: null
}

const reducer = (state: typeof initialState, action: IActions): typeof initialState => {
  switch (action.type) {
    case UPDATE_LOCAL_STORAGE:
      return {
        ...state,
        localStorage: action.payload as any
      }
    case UPDATE_SESSION_STORAGE:
      return {
        ...state,
        sessionStorage: action.payload as any
      }
  }
  return state
}

export const updateLocalStorage = (
  data: string,
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  if (data) {
    dispatch({ type: UPDATE_LOCAL_STORAGE, payload: data })
  }
}

export const updateSessionStorage = (
  data: string,
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  if (data) {
    dispatch({ type: UPDATE_SESSION_STORAGE, payload: data })
  }
}

export default reducer
