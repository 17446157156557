import { styled } from '@mui/system'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const DashboardStyle = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.content-wrap': {
    marginBottom: 24,
    '.text-muted': {
      color: theme.palette.grey[600]
    },
    '.text-success': {
      color: '#179942'
    },
    '.text-danger': {
      color: theme.palette.error.main
    },
    '.text-primary': {
      color: theme.palette.primary.main
    },
    '.fw-sbold': {
      fontWeight: theme.customVariables.fontWeightSemiBold
    },
    '.fw-medium': {
      fontWeight: theme.typography.fontWeightMedium
    }
  }
}))
