import { useTheme } from '@mui/material'
import ChatNotificationWrap from './ChatNotification.style'
import ChatNotificationHeader from './ChatNotificationHeader/ChatNotificationHeader.component'
import { Box, Icon, Notification, Skeleton, Typography } from '@ntpkunity/controls'
import { useChatServiceContext } from '@hooks/useChatService'
import { ProfileChannel } from 'types/chat'
import { INotificationItem } from '@ntpkunity/controls/dist/cjs/components/Notification/Notification.types'
import { getUserNameFromChannel } from '@helpers/methods'

const ChatNotificationSkeleton = () => {
  const theme = useTheme()
  return (
    <Box theme={theme} className="notification-skeleton-box">
      <Skeleton className="avatar-skeleton" theme={theme} width={32} height={32} />
      <Box theme={theme} className="skeleton-flex">
        <Skeleton theme={theme} height={21} width="50%" />
        <Skeleton className="mt-4" theme={theme} height={16} width="100%" />
      </Box>
    </Box>
  )
}

const ChatNotification = () => {
  const theme = useTheme()
  const { channels, messageCount, onSelectChat, loadingChannels, onlineUsers } =
    useChatServiceContext()

  const getNotificationItem = (channel: ProfileChannel): INotificationItem => {
    const userInitials = getUserNameFromChannel(channel, true)
    const userName = getUserNameFromChannel(channel)
    const lastMsgDateTime = channel.channel.latestMessage
      ? new Date(channel.channel.latestMessage?.timestamp as number).toLocaleTimeString(undefined, {
          hour: '2-digit',
          minute: '2-digit'
        })
      : ''
    return {
      userInitials: userInitials,
      userStatus: onlineUsers[channel.userID as number] ? 'online' : 'offline',
      notificationMessage: (
        <>
          <Typography theme={theme} component="p" variant="body2" className="highlight">
            {userName} <span className="time-date">{lastMsgDateTime}</span>
          </Typography>
          {channel.channel.latestMessage?.content}
        </>
      ),
      isRead: !messageCount[channel.channel.channel]?.unreadCount,
      onClick: () => {
        onSelectChat(channel)
      }
    }
  }

  return (
    <ChatNotificationWrap theme={theme} className="chats-notification-wrap">
      <ChatNotificationHeader />
      <Box theme={theme} className="all-notifications">
        {loadingChannels ? (
          [...Array(5)].map((_, index) => (
            <ChatNotificationSkeleton key={`chat-notification-skeleton-${index}`} />
          ))
        ) : channels?.length > 0 ? (
          <Notification
            theme={theme}
            items={channels.map((channel) => getNotificationItem(channel))}
          />
        ) : (
          <Box theme={theme} className="chats-body">
            <Box theme={theme}>
              <Box theme={theme} className="no-notifications">
                <Box theme={theme} className="conversation-icon">
                  <Icon name="ConversationIcon" />
                </Box>
                <Typography theme={theme} component="p" variant="body1" className="text-muted">
                  There are no messages in your queue
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </ChatNotificationWrap>
  )
}

export default ChatNotification
