import IActions from '../configs'
import { Dispatch, ReducerAction } from 'react'

export const SET_DEALER_VEHICLE_AVAIALABLE = 'GET DEALER VEHICLE AVAILABLE CONFIRMATION ORDERS'
export const SET_DEALER_GET_READY_CHECKLIST = 'GET DEALER GET READY CHECKLIST'
export const SET_DEALER_DEALER_SCHEDULE_PICKUPS = 'GET DEALER SCHEDULE PICKUPS'
export const SET_DEALER_COMPLETED_ORDERS = 'GET DEALER COMPLETED ORDERS'
export const SET_DEALER_CANCELLED_ORDERS = 'GET DEALER CANCELLED ORDERS'
export const SET_DEALER_SELECTED_ORDER = 'GET DEALER SELECTED ORDER'
export const SET_DEALER_VEHICLE_RETURN_DATA = 'GET DEALER RETURN VEHICLE'
export const SET_DEALER_INSURANCE_INFORMATION = 'SET DEALER INSURANCE INFORMATION'
export const SET_CUSTOMER_LICENSE_INFORMATION = 'SET CUSTOMER LICENSE INFORMATION'
export const SET_ODOMETER_DATA = 'SET ODOMETER DATA'
export const SET_DEALER_SELECTED_RETURN_DATA = 'DEALER SELECTED RETURN DATA'
export const SET_SELECTED_AN_DEALER = 'SELECTED DEALER'

export const initialState: any = {}

const reducer = (state: typeof initialState, action: IActions): typeof initialState => {
  switch (action.type) {
    case SET_DEALER_VEHICLE_AVAIALABLE:
      return action.payload as typeof initialState
    case SET_DEALER_GET_READY_CHECKLIST:
      return action.payload as typeof initialState
    case SET_DEALER_DEALER_SCHEDULE_PICKUPS:
      return action.payload as typeof initialState
    case SET_DEALER_VEHICLE_RETURN_DATA:
      return action.payload as typeof initialState
    case SET_DEALER_COMPLETED_ORDERS:
      return action.payload as typeof initialState
    case SET_DEALER_CANCELLED_ORDERS:
      return action.payload as typeof initialState
    case SET_DEALER_SELECTED_ORDER:
      return action.payload as typeof initialState
    case SET_DEALER_INSURANCE_INFORMATION:
      return action.payload as typeof initialState
    case SET_CUSTOMER_LICENSE_INFORMATION:
      return action.payload as typeof initialState
    case SET_ODOMETER_DATA:
      return action.payload as typeof initialState
    case SET_DEALER_SELECTED_RETURN_DATA:
      return action.payload as typeof initialState
    case SET_SELECTED_AN_DEALER:
      return action.payload as typeof initialState
  }
  return state
}

export const setDealerVehicleAvailableConfirmationOrders = (
  data: typeof initialState,
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  if (data) {
    dispatch({ type: SET_DEALER_VEHICLE_AVAIALABLE, payload: data })
  }
}

export const setDealerGetReadyCheckList = (
  data: typeof initialState,
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  if (data) {
    dispatch({ type: SET_DEALER_GET_READY_CHECKLIST, payload: data })
  }
}

export const setDealerSchedulePickups = (
  data: typeof initialState,
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  if (data) {
    dispatch({ type: SET_DEALER_DEALER_SCHEDULE_PICKUPS, payload: data })
  }
}

export const setDealerCompletedOrders = (
  data: typeof initialState,
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  if (data) {
    dispatch({ type: SET_DEALER_COMPLETED_ORDERS, payload: data })
  }
}

export const setDealerCancelledOrders = (
  data: typeof initialState,
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  if (data) {
    dispatch({ type: SET_DEALER_CANCELLED_ORDERS, payload: data })
  }
}

export const setDealerSelectedOrderData = (
  data: typeof initialState,
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  if (data) {
    dispatch({ type: SET_DEALER_SELECTED_ORDER, payload: data })
  }
}

export const setInsuranceInformation = (
  data: typeof initialState,
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  if (data) {
    dispatch({ type: SET_DEALER_INSURANCE_INFORMATION, payload: data })
  }
}

export const setCustomerLicenseInformation = (
  data: typeof initialState,
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  if (data) {
    dispatch({ type: SET_CUSTOMER_LICENSE_INFORMATION, payload: data })
  }
}

export const setOdometerData = (
  data: typeof initialState,
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  if (data) {
    dispatch({ type: SET_ODOMETER_DATA, payload: data })
  }
}

export const setDealerReturnVehicle = (
  data: typeof initialState,
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  if (data) {
    dispatch({ type: SET_DEALER_VEHICLE_RETURN_DATA, payload: data })
  }
}

export const setDealerSelectedReturnData = (
  data: typeof initialState,
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  if (data) {
    dispatch({ type: SET_DEALER_SELECTED_RETURN_DATA, payload: data })
  }
}

export const setSelectedANDealer = (
  data: typeof initialState,
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  if (data) {
    dispatch({ type: SET_SELECTED_AN_DEALER, payload: data })
  }
}

export default reducer
