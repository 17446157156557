import { getUser } from '@helpers/methods'
import {
  ChatMessage,
  ChatClientMessage,
  ChatMessageCount,
  ChatChannel,
  ChatClientReturn,
  ProfileChannel,
  ChatStatus,
  UseChatServiceReturn,
  ChannelChangeEvent,
  NewThreadEvent,
  ChannelSubscribedEvent,
  ChannelOnlineStatusEvent
} from 'types/chat'
import { ChatClientEvents, MessagePerson, MessageType, MessageStatus } from '@helpers/enums'
import React, { useCallback, useEffect, useMemo, useState, createContext, useContext } from 'react'

const CLIENT_HOST = process.env.CHAT_CLIENT_HOST || ''
const CLIENT_PORT = Number(process.env.CHAT_CLIENT_PORT) || 8080

const initiateChatClient = (username: string | undefined): ChatClientReturn | undefined => {
  if (!window || !username || !window.otoz) return undefined
  const { Client } = window.otoz
  return new Client({
    username,
    host: CLIENT_HOST,
    port: CLIENT_PORT,
    password: '',
    reConnectivity: true
  })
}

export const getMessageFromId = () => `dl_${getUser()?.user_id || ''}` as `dl_{${number}}`
const getId = (channel: string) => Number(channel.split('/')[1])

export const ChatServiceContext = createContext<UseChatServiceReturn | null>(null)
export const ChatServiceProvider = ({
  children,
  ...props
}: UseChatServiceReturn & { children: React.ReactNode }) => {
  return <ChatServiceContext.Provider value={props}>{children}</ChatServiceContext.Provider>
}
export const useChatServiceContext = () => {
  const context = useContext(ChatServiceContext)
  if (!context) {
    throw new Error('useChatServiceContext should be called inside ChatServiceProvider')
  }
  return context
}

export const useChatService = (code?: string): UseChatServiceReturn => {
  const [openChat, setOpenChat] = useState(false)
  const [dealerCode, setDealerCode] = useState<string | undefined>(code)
  const [loadingMessages, setLoadingMessages] = useState(false)
  const [loadingChannels, setLoadingChannels] = useState(false)
  const [selectedUser, setSelectedUser] = useState<ProfileChannel | null>(null)
  const [messages, setMessages] = useState<Record<string, ChatClientMessage | undefined>>({})
  const [users, setUsers] = useState<ProfileChannel[]>([])
  const [onlineUsers, setOnlineUsers] = useState<Record<string, boolean>>({})
  const [messageCount, setMessageCount] = useState<Record<string, ChatMessageCount>>({})
  const [error, setError] = useState<string>('')
  const [isLoadingLoadMore, setIsLoadingLoadMore] = useState(false)
  const [channelIsLoadingLoadMore, setChannelIsLoadingLoadMore] = useState(false)
  const [channelsTotalCount, setTotalChannels] = useState(0)
  const [status, setStatus] = useState<ChatStatus>('idle')
  const [otozClient, setOtozClient] = useState<ChatClientReturn | undefined>()
  const [page, setPage] = useState(1)
  const [channelPage, setChannelPage] = useState(1)
  const user = getUser()
  const fromId = getMessageFromId()
  const messageViewLimit = 10

  useEffect(() => {
    setOtozClient(initiateChatClient(fromId))
  }, [])

  useEffect(() => {
    if (code && dealerCode && dealerCode !== code) {
      users.forEach((usr) => {
        otozClient?.UnSubscribe(usr.channel)
      })
      otozClient?.Disconnect()
      setStatus('disconnected')
      onDealerChange()
    }
    setDealerCode(code)
  }, [code])

  useEffect(() => {
    if (otozClient) {
      otozClient.Connect()
      otozClient.on(ChatClientEvents.CHANNELS, listenOnChannels)
      otozClient.on(ChatClientEvents.CONNECT, listenOnConnect)
      otozClient.on(ChatClientEvents.MESSAGE, listenOnMessage)
      otozClient.on(ChatClientEvents.DISCONNECT, listenOnDisconnect)
      otozClient.on(ChatClientEvents.ERROR, listenOnError)
      otozClient.on(ChatClientEvents.MESSAGE_LIST, listenOnMessagelist)
      otozClient.on(ChatClientEvents.OFFLINE, listenOnOffline)
      otozClient.on(ChatClientEvents.ONLINE, listenOnOnline)
      otozClient.on(ChatClientEvents.SUBSCRIBED, listenOnSubscribed)
      otozClient.on(ChatClientEvents.MESSAGE_COUNT, listenMessageCount)
      otozClient.on(ChatClientEvents.NEW_THREAD, listenOnNewTread)
    }
    return () => otozClient?.Disconnect()
  }, [otozClient])

  const channelMsgs = useMemo(() => {
    if (!selectedUser) return { channel: '', messages: [] }
    if (messages[selectedUser.channel.channel]) {
      return messages[selectedUser.channel.channel]
    }
  }, [selectedUser, messages])

  const getChannels = useCallback(
    (searchText?: string, pg?: number) => {
      if (pg === 1) {
        setUsers([])
        setMessageCount({})
        setOnlineUsers({})
      }
      setLoadingChannels(true)
      const channelRequest = {
        dealer: dealerCode as string,
        limit: '10',
        page: `${pg || channelPage}`,
        type: 'dealer',
        search: searchText || ''
      }
      otozClient?.GetChannels(channelRequest)
    },
    [dealerCode, channelPage, otozClient]
  )

  useEffect(() => {
    if (dealerCode && dealerCode !== '0' && otozClient && status !== 'connected') {
      otozClient?.Connect()
    }
    if (dealerCode && dealerCode !== '0' && otozClient && status === 'connected') {
      getChannels()
    }
  }, [dealerCode, status, otozClient, getChannels])

  const chatFrame =
    typeof window !== 'undefined' && document.querySelector(`#${'message_chat_frame'}`)
  const scrollToBottom = useCallback(
    (time?: number, frame?: false | Element | null) => {
      const ele = frame || chatFrame
      setTimeout(() => {
        if (ele) ele.scrollTop = ele.scrollHeight
      }, time || 500)
    },
    [chatFrame]
  )

  useEffect(() => {
    if (page === 1 && chatFrame) scrollToBottom()
  }, [page, chatFrame, scrollToBottom])

  const listenOnConnect = () => {
    setStatus('connected')
  }
  const listenMessageCount = (e: ChatMessageCount) => {
    if (e && e.channel) setMessageCount((msgCount) => ({ ...msgCount, [e.channel]: e }))
  }

  const listenOnChannels = (e: ChannelChangeEvent) => {
    setTotalChannels(e.totalCount)
    const channels = e.channels.filter(
      (f) => f.user?.firstName && getId(f.channel) !== user?.user_id
    )
    subscribeToAllChannels(channels)
    onGetCustomers(channels)
    setLoadingChannels(false)
  }
  const listenOnNewTread = (e: NewThreadEvent) => {
    setTotalChannels((totalCount) => totalCount + 1)
    const channels = e.channels.filter((f) => getId(f.channel) !== user?.user_id)
    subscribeToAllChannels(channels)
    onGetCustomers(channels, true)
  }
  const listenOnSubscribed = (e: ChannelSubscribedEvent) => {
    const customers = e.who.filter((item) => item.username.includes('cu_'))
    customers.forEach((customer) => {
      const customerId = customer.username
      if (customerId && customerId.includes('cu_')) {
        setOnlineUsers((usr) => ({ ...usr, [customerId.split('_')[1]]: true }))
      }
    })
  }
  /** when recieve a new message */
  const listenOnMessage = (message: ChatMessage) => {
    if (!user?.user_id) return
    if (message.type === MessageType.TYPING) {
      if (message.from.includes(user?.user_id)) return
      if (message.content === '0') {
        setMessages((chnls: Record<string, ChatClientMessage | undefined>) => ({
          ...chnls,
          [message.to]: { ...(chnls[message.to] as ChatClientMessage), typing: false }
        }))
      } else {
        setMessages((chnls) => ({
          ...chnls,
          [message.to]: { ...(chnls[message.to] as ChatClientMessage), typing: true }
        }))
      }

      return
    }

    setMessageCount((msgCount) => {
      return {
        ...msgCount,
        [message.to]: {
          ...msgCount[message.to],
          latestMessage: message,
          unreadCount: !message.from.includes(user?.user_id || '')
            ? (msgCount[message.to]?.unreadCount || 0) + 1
            : msgCount[message.to].unreadCount,
          totalCount: (msgCount[message.to]?.totalCount || 0) + 1
        }
      }
    })

    setMessages((chnls) => {
      const msgs = chnls[message.to]
      if (!msgs) return chnls

      const updatedMessages = [...(msgs?.messages || [])].concat({
        ...message,
        who: message.from.includes('cu_') ? MessagePerson.OTHER : MessagePerson.ME,
        status: 1
      })
      return { ...chnls, [message.to]: { ...msgs, messages: updatedMessages } }
    })
    const frame =
      typeof window !== 'undefined' && document.querySelector(`#${'message_chat_frame'}`)
    scrollToBottom(100, frame)
    if (message.from.includes(user?.user_id)) return
    setUsers((usrs) => {
      const updatedUsers: ProfileChannel[] = []
      let newMsgUser: ProfileChannel | null = null
      usrs.forEach((usr) => {
        if (usr.channel.channel === message.to) {
          newMsgUser = { ...usr, channel: { ...usr.channel, latestMessage: message } }
        } else {
          updatedUsers.push(usr)
        }
      })
      if (newMsgUser) {
        const newMsgUsers: ProfileChannel[] = [newMsgUser]
        return newMsgUsers.concat(updatedUsers)
      }
      return updatedUsers
    })
  }

  const onGetMessages = useCallback(
    (e: ChatClientMessage) => {
      const msgs = (chnls: Record<string, ChatClientMessage | undefined>) => ({
        channel: e.channel,
        messages: e.messages
          .map((f) => {
            return { ...f, who: f.from.includes('cu_') ? MessagePerson.OTHER : MessagePerson.ME }
          })
          .reverse()
          .concat(chnls[e.channel]?.messages || [])
      })
      setMessages((chnls) => ({ ...chnls, [e.channel]: msgs(chnls) }))
    },
    [setMessages]
  )
  /** when recieve message list of a customer */
  const listenOnMessagelist = useCallback(
    (e: ChatClientMessage) => {
      if (!e.channel) return
      if (page === 1) scrollToBottom()
      setLoadingMessages(false)
      setIsLoadingLoadMore(false)
      onGetMessages(e)
    },
    [page, onGetMessages, scrollToBottom]
  )
  const listenOnDisconnect = () => {
    setStatus('disconnected')
    onDealerChange()
  }
  const listenOnError = (e: string) => {
    setError(e)
  }
  const listenOnOnline = (e: ChannelOnlineStatusEvent) => {
    const customerId = e.who.username
    if (customerId && customerId.includes('cu_')) {
      setOnlineUsers((usr) => ({ ...usr, [customerId.split('_')[1]]: true }))
    }
  }
  const listenOnOffline = (e: ChannelOnlineStatusEvent) => {
    const customerId = e.who.username
    if (customerId && customerId.includes('cu_')) {
      setOnlineUsers((usr) => ({ ...usr, [customerId.split('_')[1]]: false }))
    }
  }

  const getCustomerMessages = (profileChannel: ProfileChannel) => {
    const client = {
      channel: profileChannel.channel.channel,
      page: '1',
      limit: `${messageViewLimit}`
    }
    otozClient?.GetMessages(client)
  }

  const sendMessage = useCallback(
    (text: string, type?: MessageType) => {
      if (!selectedUser) return
      if (type === MessageType.TYPING) {
        otozClient?.SendMessage({
          messageId: (Math.random() * 100).toString(36),
          to: selectedUser?.channel?.channel,
          from: fromId,
          key: selectedUser?.channel?.key,
          type: MessageType.TYPING,
          content: text,
          status: MessageStatus.SENT,
          timestamp: Date.now()
        })
        return
      }
      const msgObj = {
        messageId: (Math.random() * 100).toString(36),
        to: selectedUser?.channel?.channel,
        from: fromId,
        key: selectedUser?.channel?.key,
        type: MessageType.TEXT,
        content: text,
        status: MessageStatus.SENT,
        timestamp: Date.now()
      }
      otozClient?.SendMessage(msgObj)
    },
    [selectedUser, fromId, otozClient]
  )
  const subscribeToAllChannels = (channels: ChatChannel[]) => {
    channels.forEach((channel) => {
      subscribeToChannel({ ...channel, _id: undefined })
    })
  }
  const subscribeToChannel = (channel: ChatChannel) => {
    otozClient?.Subscribe(channel)
  }

  const onDealerChange = () => {
    setUsers([])
    setMessages({})
    setSelectedUser(null)
    setPage(1)
    setChannelPage(1)
    setMessageCount({})
    setOnlineUsers({})
  }

  const onSelectChat = (selected: ProfileChannel | null) => {
    if (selected === null) return setSelectedUser(null)
    if (!selected?.channel) return
    if (selected?.channel === selectedUser?.channel) return
    scrollToBottom()
    setSelectedUser(selected)
    setPage(1)
    setLoadingMessages(true)
    setMessages(() => ({
      [selected.channel.channel]: { channel: selected.channel.channel, messages: [] }
    }))
    getCustomerMessages(selected)
  }

  const onGetCustomers = (channels: ChatChannel[], top?: boolean) => {
    const profiles: ProfileChannel[] = channels.map((channel) => {
      return {
        userID: channel.user?.userID,
        email: channel.user?.email,
        phoneNumber: channel.user?.phoneNumber,
        firstName: channel.user?.firstName,
        lastName: channel.user?.lastName,
        lastActiveVIN: channel.user?.lastActiveVIN,
        channel: channels.find((f) => channel.user?.userID === getId(f.channel)) || {
          channel: '',
          key: ''
        }
      }
    })
    channels.forEach((channel) => {
      otozClient?.GetMessageCount?.({ channel: channel.channel, type: 'cu_' })
    })
    if (top) setUsers((usrs) => profiles.concat(usrs || []))
    else setUsers((usrs) => (usrs || []).concat(profiles))
    setChannelIsLoadingLoadMore(false)
  }

  const readMessage = useCallback(() => {
    const currentChannel = selectedUser?.channel?.channel || ''
    setMessageCount((msgCount) => ({
      ...msgCount,
      [currentChannel]: { ...msgCount[currentChannel], unreadCount: 0 }
    }))
    channelMsgs?.messages.forEach((message) => {
      if (message.status && message.status === 1 && message.from !== fromId) {
        otozClient?.ReadMessage(message.messageId)
      }
    })
  }, [selectedUser, fromId, channelMsgs, otozClient])

  const loadMoreMessages = useCallback(() => {
    const currentChannel = channelMsgs?.channel
    if (currentChannel) {
      setIsLoadingLoadMore(true)
      const client = { channel: currentChannel, page: `${page + 1}`, limit: `${messageViewLimit}` }
      setPage(page + 1)
      otozClient?.GetMessages(client)
    }
  }, [channelMsgs, page])

  const loadMoreChannels = useCallback(
    (searchText?: string) => {
      if (dealerCode) {
        setChannelIsLoadingLoadMore(true)
        const client = {
          dealer: dealerCode,
          page: `${channelPage + 1}`,
          limit: '50',
          type: 'dealer',
          search: searchText
        }
        setChannelPage(channelPage + 1)
        otozClient?.GetChannels(client)
      }
    },
    [channelPage]
  )

  const clearSelectedChat = () => {
    setSelectedUser(null)
  }

  const totalUnreadCount = useMemo(() => {
    if (!messageCount) {
      return 0
    }
    return Object.values(messageCount).reduce((sum, cur) => sum + cur.unreadCount, 0)
  }, [messageCount])

  return {
    client: otozClient,
    openChat,
    setOpenChat,
    setClient: setOtozClient,
    selectedUser,
    getCustomerMessages,
    sendMessage,
    subscribeToAllChannels,
    subscribeToChannel,
    getChannels,
    onSelectChat,
    channels: users,
    messages: channelMsgs,
    error,
    status,
    loadingChannels,
    loadingMessages,
    setChannels: setUsers,
    messageCount,
    readMessage,
    loadMoreMessages,
    isLoadingMore: isLoadingLoadMore,
    channelIsLoadingMore: channelIsLoadingLoadMore,
    setChannelIsLoadingMore: setChannelIsLoadingLoadMore,
    loadMoreChannels,
    totalChannels: channelsTotalCount,
    onlineUsers,
    clearSelectedChat,
    totalUnreadCount
  }
}
