export enum ChatClientEvents {
  CONNECT = 'connect',
  MESSAGE = 'message',
  DISCONNECT = 'disconnect',
  ERROR = 'error',
  MESSAGE_SENT = 'messagesent',
  SUBSCRIBED = 'subscribed',
  ONLINE = 'online',
  OFFLINE = 'offline',
  MESSAGE_LIST = 'messagelist',
  CHANNELS = 'channels',
  UNREAD_MESSAGES = 'unreadmessages',
  MESSAGE_COUNT = 'messagecount',
  NEW_THREAD = 'newthread'
}

export enum MessagePerson {
  ME = 'me',
  OTHER = 'other'
}

export enum MessageType {
  TEXT = 'text',
  IMAGE = 'image',
  URL = 'url',
  VIDEO = 'video',
  AUDIO = 'audio',
  FILE = 'file',
  BADGE = 'bage',
  RECEIPT = 'receipt',
  TYPING = 'typing',
  AUTO = 'auto'
}

export enum MessageStatus {
  SENT = 1,
  RECEIVED = 2,
  SEEN = 3
}
