import IActions from '../configs'
import { Dispatch, ReducerAction } from 'react'
import { IInventory, IPaginatedResponse } from '@models'

export const SET_INVENTORY_INIT_STATE = 'GET INVENTORY DATA FROM SERVER'
export const UPDATE_INVENTORY_ROW = 'UPDATE_INVENTORY_ROW'

export const initialState: IPaginatedResponse = {
  pageNum: 0,
  pageSize: 0,
  result: [],
  totalResults: 0
}

const reducer = (state: typeof initialState, action: IActions): typeof initialState => {
  switch (action.type) {
    case SET_INVENTORY_INIT_STATE:
      return action.payload as typeof initialState
    case UPDATE_INVENTORY_ROW: {
      const index = state.result.findIndex(
        (vehicle: { vin: any }) => vehicle.vin === (action.payload as IInventory).vin
      )
      state.result[index] = action.payload as any
      return state
    }
  }
  return state
}

export const updateInventoryData = (
  data: typeof initialState,
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  if (data) {
    dispatch({ type: SET_INVENTORY_INIT_STATE, payload: data })
  }
}

export const updateInventoryDataRow = (
  data: IInventory,
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  if (data) {
    dispatch({ type: UPDATE_INVENTORY_ROW, payload: data })
  }
}

export default reducer
