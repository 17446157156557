import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { Controller } from 'react-hook-form'
import {
  Typography,
  Box,
  Radio,
  Input,
  Select,
  Grid,
  Skeleton,
  Textarea
} from '@ntpkunity/controls'
import { Divider } from 'components'
import CardBanner from './card-banner'
import { useStoreContext } from '@store/storeContext'
import {
  useGetChecklistSavedProgress,
  useGetReadyCheckListRequest,
  useGetReasonsByLender
} from '@apis/dealer-workqueue.service'
import {
  containsKeyValue,
  getANToken,
  dateTime12HourFormat,
  compareAnswers,
  removeApostrophes
} from '@helpers/methods'
import jwt_decode from 'jwt-decode'
//@ts-ignore
import { useHasPermissions } from '@ntpkunity/controls-ums'
import { dealexPermissions } from '@ntpkunity/controls-ums/dealexPermissions'

const ChecklistUi: FC<{ form: any; reasonOrOdometer: any; checklistErrors: any }> = ({
  form,
  reasonOrOdometer,
  checklistErrors
}) => {
  const {
    control,
    watch,
    setValue,
    trigger,
    getValues,
    formState: { errors }
  } = form
  const odometerWatch = watch('odometer')
  const other_reason = watch('other_reason')
  const damage_details = watch('damage_details')
  const open_repair_orders = watch('open_repair_orders')
  const { states } = useStoreContext()
  const decodedToken: any = jwt_decode(getANToken())
  const theme = useTheme()
  const location = useLocation()
  const [selectedReason, setSelectedReason] = useState('')
  const [reasons, setReasons] = useState([])
  const [checklistRadioValues, setRadioValues] = useState<any>({})
  const [formErrors, setFormErrors] = useState<any>()
  const [radioState, setRadioValue] = useState({
    yes: location?.state?.clickedOn == 'available' && 1,
    no: location?.state?.clickedOn == 'X' && 1
  })
  //@ts-ignore
  const [savedChecklist, setSavedChecklist] = useState<any>()
  //@ts-ignore
  const [showDamageDetailsTextArea, setDamageDetailsTextArea] = useState(false)
  const [savedChecklistOptions, setSavedChecklistOptions] = useState<boolean>(false)
  const [_questionArray, setQuestionArray] = useState<any>([])
  const [checklistValuesProps, setChecklistValuesProps] = useState<boolean>(false)

  const {
    mutate: getEventAssociatedReadyCheckList,
    data: checkList,
    isLoading
  } = useGetReadyCheckListRequest()
  const { mutate: getChecklistProgressOptions, data: _savedChecklist } =
    useGetChecklistSavedProgress()
  const clickedOn = location?.state?.clickedOn
  const { mutate: getReasonsByLenderId } = useGetReasonsByLender()
  const onRadioChange = (val: any) => {
    if (val == 1) {
      setRadioValue({ no: 0, yes: 1 })
      setValue('odometer', null)
      reasonOrOdometer('available', odometerWatch)
    } else {
      setRadioValue({ no: 1, yes: 0 })
      reasonOrOdometer('not available', selectedReason)
    }
  }
  const onChecklistRadioChange = (val: any, question: any) => {
    setRadioValues({ ...checklistRadioValues, [question]: val })
    if (radioState?.no == 1) {
      reasonOrOdometer(
        'not available',
        selectedReason,
        { ...checklistRadioValues, [question]: val },
        other_reason,
        damage_details,
        checkList[0]?.[0]?.checklist_name,
        checkList[0]?.[0]?.checklist_identifier,
        savedChecklistOptions,
        checkList
      )
    }
    if (question === 'Does the vehicle have any damage?') {
      if (val === 'Yes') {
        //@ts-ignore
        setDamageDetailsTextArea((prevValue: any) => {
          return true
        })
        reasonOrOdometer(
          radioState?.no == 1 ? 'not available' : 'available',
          odometerWatch,
          { ...checklistRadioValues, [question]: val },
          null,
          null,
          checkList[0]?.[0]?.checklist_name,
          checkList[0]?.[0]?.checklist_identifier,
          savedChecklistOptions,
          open_repair_orders,
          checkList
        )
      } else {
        // setValue("damage_details", null);
        //@ts-ignore
        setDamageDetailsTextArea((prevValue: any) => {
          return false
        })
      }
    }
  }
  const handleSelectChange = (val: any) => {
    setSelectedReason(val?.target?.value)
    reasonOrOdometer(
      'not available',
      val?.target?.value,
      checklistRadioValues ? checklistRadioValues : savedChecklistOptions,
      null,
      damage_details,
      checkList[0]?.[0]?.checklist_name,
      checkList[0]?.[0]?.checklist_identifier,
      savedChecklistOptions,
      checkList
    )
    if (containsKeyValue(checklistRadioValues, 'Does the vehicle have any damage?', 'Yes')) {
      //@ts-ignore
      setDamageDetailsTextArea((prevValue: any) => {
        return true
      })
    } else {
      //@ts-ignore
      setDamageDetailsTextArea((prevValue: any) => {
        return false
      })
    }
  }
  useEffect(() => {
    if (radioState?.yes == 1) {
      setSelectedReason('')
      if (containsKeyValue(checklistRadioValues, 'Does the vehicle have any damage?', 'Yes')) {
        //@ts-ignore
        setDamageDetailsTextArea((prevValue: any) => {
          return true
        })
      } else {
        // setValue("damage_details", null);
      }
      reasonOrOdometer(
        'available',
        odometerWatch,
        checklistRadioValues ? checklistRadioValues : savedChecklistOptions,
        null,
        damage_details,
        checkList[0]?.[0]?.checklist_name,
        checkList[0]?.[0]?.checklist_identifier,
        savedChecklistOptions,
        open_repair_orders,
        checkList
      )
      if (odometerWatch && odometerWatch?.length > 0) {
        reasonOrOdometer(
          'available',
          odometerWatch,
          checklistRadioValues ? checklistRadioValues : savedChecklistOptions,
          null,
          damage_details,
          checkList[0]?.[0]?.checklist_name,
          checkList[0]?.[0]?.checklist_identifier,
          savedChecklistOptions,
          open_repair_orders,
          checkList
        )
      } else if (odometerWatch == '') {
        reasonOrOdometer('available', '')
      } else {
        reasonOrOdometer('available', '')
      }
    } else if (radioState?.no == 1) {
      reasonOrOdometer(
        'not available',
        selectedReason,
        checklistRadioValues ? checklistRadioValues : savedChecklistOptions,
        other_reason,
        damage_details,
        checkList[0]?.[0]?.checklist_name,
        checkList[0]?.[0]?.checklist_identifier,
        savedChecklistOptions,
        checkList
      )
      if (other_reason) {
        reasonOrOdometer(
          'not available',
          other_reason,
          null,
          other_reason,
          damage_details,
          checkList[0]?.[0]?.checklist_name,
          checkList[0]?.[0]?.checklist_identifier,
          savedChecklistOptions,
          checkList
        )
      }
      if (containsKeyValue(checklistRadioValues, 'Does the vehicle have any damage?', 'Yes')) {
        //@ts-ignore
        setDamageDetailsTextArea((prevValue: any) => {
          return true
        })
      } else {
        //@ts-ignore
        setDamageDetailsTextArea((prevValue: any) => {
          return false
        })
      }
    }
  }, [odometerWatch, radioState, other_reason, damage_details, checklistRadioValues])

  useEffect(() => {
    if (radioState?.yes == 1) {
      reasonOrOdometer(
        'available',
        odometerWatch,
        checklistRadioValues ? checklistRadioValues : savedChecklistOptions,

        null,
        damage_details,
        checkList[0]?.[0]?.checklist_name,
        checkList[0]?.[0]?.checklist_identifier,
        savedChecklistOptions,
        open_repair_orders,
        checkList
      )
    }
  }, [
    open_repair_orders,
    checklistRadioValues,
    damage_details,
    odometerWatch,
    checklistValuesProps
  ])

  useEffect(() => {
    if (states?.odometerData && Object.keys(states?.odometerData)?.length > 0) {
      if (radioState?.yes == 1) {
        reasonOrOdometer('available', '')
      }
    }
  }, [states?.odometerData])
  useEffect(() => {
    const errors = compareAnswers(checklistRadioValues, checkList)
    setFormErrors(errors)
  }, [savedChecklistOptions, checkList, checklistRadioValues])

  useEffect(() => {
    if (
      states?.dealerAndLenderInfo &&
      (states?.dealerAndLenderInfo?.company_id || states?.dealerAndLenderInfo?.id)
    ) {
      getReasonsByLenderId(
        {
          company_id: states?.dealerAndLenderInfo?.company_id
            ? states?.dealerAndLenderInfo?.company_id
            : states?.dealerAndLenderInfo?.id
        },
        {
          onSuccess(response: any) {
            const newArray: any = []
            response?.map((obj: any) => {
              return newArray.push({
                text: obj?.reason_name,
                value: obj?.reason_description
              })
            })
            newArray.push({
              text: 'Other',
              value: '0'
            })
            setReasons(newArray)
          }
        }
      )
    }
  }, [states?.dealerAndLenderInfo])

  const reqCheckListObject = {
    event_name: 'availability-check-checklist-event'
  }
  useEffect(() => {
    if (states?.dealerSelectedOrderData?.customerId) {
      getEventAssociatedReadyCheckList(
        { ...reqCheckListObject },
        {
          onSuccess(res: any) {
            setQuestionArray(() => {
              const questions = res.flatMap((checklist: any[]) =>
                checklist.flatMap((item) =>
                  item?.questions.map((question: { question: any }) => question.question)
                )
              )
              return questions.map((question: string) => ({
                originalQuestion: question,
                modifiedQuestion: removeApostrophes(question)
              }))
            })
            getChecklistProgressOptions(
              {
                customer_reference_id: states?.dealerSelectedOrderData?.customerId,
                checklist_identifier: res[0]?.[0]?.checklist_identifier,
                order_reference_id: states?.dealerSelectedOrderData?.id
              },
              {
                onSuccess(res: any) {
                  setSavedChecklist(res)
                  for (let i = 0; i < res.length; i++) {
                    setRadioValues((previousValues: any) => {
                      return {
                        ...previousValues,
                        [res[i].question_text]: res[i].primary_answer
                      }
                    })
                  }
                  setSavedChecklistOptions(true)
                  setChecklistValuesProps(true)
                },
                onError({ error }: any) {
                  if (error?.response?.data?.detail?.code === 404) {
                    setSavedChecklistOptions(false)
                  }
                }
              }
            )
          }
        }
      )
    }
  }, [states?.dealerSelectedOrderData?.customerId])
  useEffect(() => {
    if (savedChecklist && savedChecklistOptions) {
      const checklistQuestionNames = savedChecklist
        .filter((item: any) => {
          const questionText = removeApostrophes(item?.question_text)
          // Check if getValues('radio_checklist.') is defined before accessing properties
          return getValues('radio_checklist.') && questionText in getValues('radio_checklist.')
        })
        .map((item: any) => `radio_checklist.${removeApostrophes(item?.question_text)}`)

      ;(async () => await trigger(checklistQuestionNames))()
    }
  }, [savedChecklistOptions, savedChecklist, checklistRadioValues, radioState])
  const hasPermissionMarkAvailability = useHasPermissions(
    dealexPermissions?.SCREEN?.VEHICLE_AVAILABILTIY_CONFIRMATION?.MARK_AVAILABILITY_CONFIRMATION
  )
  const hasPermissionCaptureOdometerReading = useHasPermissions(
    dealexPermissions?.SCREEN?.VEHICLE_AVAILABILTIY_CONFIRMATION?.CAPTURE_ODOMETER_READING
  )

  return (
    <>
      {useHasPermissions(
        dealexPermissions?.SCREEN?.VEHICLE_AVAILABILTIY_CONFIRMATION?.VEHICLE_DETAILS
      ) && <CardBanner />}
      <>
        <>
          {isLoading ? (
            [1]?.map((val) => (
              <Grid theme={theme} item md={4} sm={6} xs={12} key={val}>
                <Skeleton theme={theme} width={'25%'} height={'20px'} />
                <Skeleton theme={theme} width={'25%'} height={'20px'} />
              </Grid>
            ))
          ) : (
            <>
              <Typography
                theme={theme}
                variant="h3"
                component="h3"
                className="title"
                children="Vehicle Availability"
                sx={{ mb: 3 }}
              />
              <Grid
                container
                theme={theme}
                direction={'row'}
                alignItems={'flex-start'}
                justifyContent={'space-between'}
              >
                <Grid item theme={theme} sm={6} md={6} lg={6} xs={12}>
                  <Typography
                    theme={theme}
                    variant="subtitle2"
                    component="p"
                    sx={{ mb: 1.5 }}
                    children="Is this vehicle available?"
                  />
                </Grid>
                <Grid item theme={theme} sm={6} md={6} lg={6} xs={12}>
                  {(clickedOn == 'available' || radioState?.yes == 1) && (
                    <Box
                      theme={theme}
                      // sx={{}}
                      className="ndt"
                    >
                      Availability confirmed by <b>{decodedToken?.email} </b> at&nbsp;
                      <b>{dateTime12HourFormat()}</b>
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Radio
                theme={theme}
                row={true}
                defaultValue={clickedOn == 'available' ? 1 : clickedOn == 'X' ? 0 : ''}
                onChange={onRadioChange}
                options={[
                  {
                    label: 'Yes',
                    value: 1,
                    disabled: hasPermissionMarkAvailability === true ? false : true
                  },
                  {
                    label: 'No',
                    value: 0,
                    disabled: hasPermissionMarkAvailability === true ? false : true
                  }
                ]}
              />
            </>
          )}
        </>
        <Grid container theme={theme} spacing={2}>
          <Grid theme={theme} item xs={12} md={6} lg={4}>
            <Box theme={theme}>
              {radioState?.no == 1 ? (
                <>
                  <Box theme={theme} sx={{ width: 410, mb: 1.5, mt: 1.5 }}>
                    <Typography
                      theme={theme}
                      variant="subtitle1"
                      component="div"
                      children="Please select the reason for the car is unavailable"
                    />
                  </Box>
                  <Box theme={theme} sx={{ width: 410, mt: 3 }}>
                    <Select
                      theme={theme}
                      label={'Reason'}
                      disablePortal
                      onChange={handleSelectChange}
                      items={reasons}
                    />
                  </Box>
                </>
              ) : radioState?.yes == 1 ? (
                <Box theme={theme} sx={{ width: 410, mb: 1.5, mt: 1.5 }}>
                  <Controller
                    name="odometer"
                    control={control}
                    rules={{
                      required: 'Odometer Reading is requred',
                      validate: (value: any) => {
                        const metaData = states?.dealerSelectedOrderData?.orderItems?.find(
                          (obj: any) => obj?.type === 'vehicle'
                        )?.metadata
                        const orderOdometerValue = metaData?.odometerReportedByDealer
                          ? metaData?.odometerReportedByDealer
                          : metaData?.odometerAtOrderCreation
                        if (value && !/^\d+$/.test(value)) {
                          return 'Decimals are not allowed.'
                        }
                        if (value && value !== orderOdometerValue) {
                          const difference = Math.abs(value - orderOdometerValue)
                          if (difference > 200) {
                            return `Mileage is +/- 200mi from our records, please check`
                          }
                        }

                        return true
                      }
                    }}
                    render={({ field }: any) => (
                      <Input
                        theme={theme}
                        fullWidth
                        type="number"
                        disabled={!hasPermissionCaptureOdometerReading ? true : false}
                        placeholder="0000"
                        format={'#################'}
                        label="Current Odometer Reading"
                        endAdornment={'Miles'}
                        {...field}
                        error={errors?.odometer?.message}
                      />
                    )}
                  />
                </Box>
              ) : (
                ''
              )}
            </Box>
          </Grid>
        </Grid>

        <Grid theme={theme} container item sm={12} xs={12} md={12} lg={12} xl={12}>
          {/* <Box theme={theme}> */}
          {radioState?.no == 1 && selectedReason === '0' ? (
            <Box theme={theme} sx={{ width: '100%', mt: 3 }}>
              <Controller
                name="other_reason"
                control={control}
                defaultValue={''}
                render={({ field }) => (
                  <Textarea
                    id="reason_1"
                    theme={theme}
                    fullWidth
                    type="text"
                    rows={3}
                    placeholder="Enter reason"
                    label="Type here..."
                    {...field}
                  />
                )}
              />
            </Box>
          ) : null}
          {/* </Box> */}
        </Grid>

        <Divider sx={{ mt: '25px', mb: '25px' }} />
        <Grid container theme={theme} spacing={2}></Grid>
        <Grid theme={theme} container direction={'column'} spacing={1} sx={{ mb: 1 }}>
          {checkList?.length > 0 &&
            checkList?.map((innerArray: any, index: any) => (
              <span key={index} style={{ padding: '25px 10px 10px 10px' }}>
                {radioState?.yes == 1 && (
                  <Grid container theme={theme} direction={'column'} spacing={1.5}>
                    {innerArray?.map((list: any) =>
                      list?.questions?.map((obj: any, index: any) => (
                        <>
                          <Grid theme={theme} item md={4} sm={6} xs={12} key={index}>
                            {savedChecklist?.find(
                              (myObj: any) => myObj?.question_text == obj?.question
                            ) &&
                              obj?.question && (
                                <>
                                  <Typography
                                    theme={theme}
                                    variant="subtitle2"
                                    component="p"
                                    sx={{ mb: 1.5 }}
                                    children={obj?.question}
                                  />
                                  <Controller
                                    name={`radio_checklist.${removeApostrophes(obj?.question)}`}
                                    control={control}
                                    rules={{
                                      required: 'Select an option to proceed.'
                                    }}
                                    defaultValue={
                                      savedChecklist?.find(
                                        (myObj: any) => myObj?.question_text === obj?.question
                                      )?.primary_answer ?? ''
                                    }
                                    render={({ field: { onChange, value } }: any) => (
                                      <Radio
                                        theme={theme}
                                        row={true}
                                        onChange={(val: any) => {
                                          onChange(val)
                                          onChecklistRadioChange(val, obj.question)
                                        }}
                                        value={value}
                                        options={obj?.answers.map((obj: any) => ({
                                          label: obj?.answer,
                                          value: obj?.answer
                                          // disabled:
                                          //   hasPermission === true ? false : true,
                                        }))}
                                        radioError={
                                          (errors?.radio_checklist
                                            ? (
                                                errors.radio_checklist as {
                                                  [key: string]: { message?: string }
                                                }
                                              )[removeApostrophes(obj?.question)]?.message
                                            : undefined) ??
                                          checklistErrors?.find(
                                            (error: { question: any }) =>
                                              error.question === obj?.question
                                          )?.message
                                        }
                                      />
                                    )}
                                  />
                                  {!errors?.[`radio_checklist.${obj?.question}`] &&
                                  formErrors.length > 0 &&
                                  !checklistErrors?.find(
                                    (error: { question: any }) =>
                                      error?.question === formErrors[0]?.question
                                  ) ? (
                                    <Typography
                                      className="text-danger"
                                      theme={theme}
                                      variant="caption"
                                      component="small"
                                      display="block"
                                      // children={obj?.question}
                                    >
                                      {
                                        formErrors?.find(
                                          (error: { question: any }) =>
                                            error.question === obj?.question
                                        )?.message
                                      }
                                    </Typography>
                                  ) : null}
                                </>
                              )}
                            {!savedChecklist?.find(
                              (myObj: any) => myObj?.question_text == obj?.question
                            ) && (
                              <>
                                <Typography
                                  theme={theme}
                                  variant="subtitle2"
                                  component="p"
                                  sx={{ mb: 1.5 }}
                                  children={obj?.question}
                                />
                                <Controller
                                  name={`available_radio_checklist.${removeApostrophes(
                                    obj?.question
                                  )}`}
                                  control={control}
                                  rules={{
                                    required: 'Select an option to proceed.'
                                  }}
                                  defaultValue={''}
                                  render={({ field: { onChange } }: any) => (
                                    <Radio
                                      theme={theme}
                                      row={true}
                                      onChange={(val: any) => {
                                        onChange(val)
                                        onChecklistRadioChange(val, obj.question)
                                        setChecklistValuesProps(true)
                                      }}
                                      options={obj?.answers.map((obj: any) => ({
                                        label: obj?.answer,
                                        value: obj?.answer
                                        // disabled:
                                        //   hasPermission === true ? false : true,
                                      }))}
                                      radioError={
                                        (errors?.available_radio_checklist
                                          ? (
                                              errors.available_radio_checklist as {
                                                [key: string]: { message?: string }
                                              }
                                            )[removeApostrophes(obj?.question)]?.message
                                          : undefined) ??
                                        checklistErrors?.find(
                                          (error: { question: any }) =>
                                            error.question === obj?.question
                                        )?.message
                                      }
                                    />
                                  )}
                                />
                                {!errors?.[`available_radio_checklist.${obj?.question}`] &&
                                formErrors.length > 0 &&
                                !checklistErrors?.find(
                                  (error: { question: any }) =>
                                    error?.question === formErrors[0]?.question
                                ) ? (
                                  <Typography
                                    className="text-danger"
                                    theme={theme}
                                    variant="caption"
                                    component="small"
                                    display="block"
                                    // children={obj?.question}
                                  >
                                    {
                                      formErrors?.find(
                                        (error: { question: any }) =>
                                          error.question === obj?.question
                                      )?.message
                                    }
                                  </Typography>
                                ) : null}
                              </>
                            )}
                          </Grid>

                          {((savedChecklist?.find(
                            (myObj: any) => myObj?.question_text == obj?.question
                          )?.primary_answer === 'Yes' &&
                            containsKeyValue(
                              checklistRadioValues,
                              'Does the vehicle have any damage?',
                              'Yes'
                            )) ||
                            (checklistRadioValues &&
                              Object.keys(checklistRadioValues).length > 0 &&
                              containsKeyValue(
                                checklistRadioValues,
                                'Does the vehicle have any damage?',
                                'Yes'
                              ))) &&
                            index == 0 && (
                              <Grid
                                theme={theme}
                                item
                                xs={12}
                                md={12}
                                sx={{ mb: 1 }}
                                sm={12}
                                lg={12}
                              >
                                <Controller
                                  name="damage_details"
                                  control={control}
                                  defaultValue={
                                    savedChecklist?.find(
                                      (myObj: any) => myObj?.question_text == obj?.question
                                    )?.description
                                  }
                                  render={({ field }) => (
                                    <Textarea
                                      id={'damage-details'}
                                      theme={theme}
                                      fullWidth
                                      type="text"
                                      rows={3}
                                      placeholder="Type Here"
                                      label="Enter damage"
                                      {...field}
                                    />
                                  )}
                                />
                              </Grid>
                            )}

                          {((savedChecklist?.find(
                            (myObj: any) => myObj?.question_text == obj?.question
                          )?.primary_answer === 'Yes' &&
                            containsKeyValue(
                              checklistRadioValues,
                              'Are there any open Repair Deals against this vehicle?',
                              'Yes'
                            )) ||
                            (checklistRadioValues &&
                              Object.keys(checklistRadioValues).length > 0 &&
                              containsKeyValue(
                                checklistRadioValues,
                                'Are there any open Repair Deals against this vehicle?',
                                'Yes'
                              ))) &&
                            index == 2 && (
                              <Grid
                                theme={theme}
                                item
                                xs={12}
                                md={12}
                                sx={{ mb: 1 }}
                                sm={12}
                                lg={12}
                              >
                                <Controller
                                  name="open_repair_orders"
                                  control={control}
                                  defaultValue={
                                    savedChecklist?.find(
                                      (myObj: any) => myObj?.question_text == obj?.question
                                    )?.description
                                  }
                                  render={({ field }) => (
                                    <Textarea
                                      id={'open-repair-orders'}
                                      theme={theme}
                                      fullWidth
                                      type="text"
                                      rows={3}
                                      placeholder="Type Here"
                                      label="Estimated Cost & Description"
                                      {...field}
                                    />
                                  )}
                                />
                              </Grid>
                            )}
                        </>
                      ))
                    )}
                  </Grid>
                )}
              </span>
            ))}
        </Grid>
      </>
    </>
  )
}

export default ChecklistUi
