import { FC } from 'react'
import { useTheme } from '@mui/material'
import { Grid } from '@ntpkunity/controls'
import { BtnAddWrap, VehicleList } from '@components'
import { AddCustomer } from './add-customer'
import { AddTradeIn } from './add-tradein'

const AddVtc: FC = () => {
  const theme = useTheme()
  return (
    <>
      <BtnAddWrap theme={theme} className="btn-add-wrap">
        <VehicleList theme={theme} className="vehicle-list">
          <Grid theme={theme} container columnSpacing={1}>
            <Grid theme={theme} item xs>
              <AddCustomer />
            </Grid>
            {/* ********************************* */}
            <Grid theme={theme} item xs>
              <AddTradeIn />
            </Grid>
          </Grid>
        </VehicleList>
      </BtnAddWrap>
    </>
  )
}

export default AddVtc
