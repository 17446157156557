import { styled } from '@mui/material/styles'

export const HeaderHiddenSearch: any = styled('div')(({ theme }) => ({
  '.MuiOutlinedInput-notchedOutline': {
    border: '0 solid transparent',
    borderRadius: 0
  },
  '.MuiInputBase-root': {
    borderBottom: '1px solid transparent',

    '&.Mui-focused': {
      borderColor: theme.palette.divider,
      borderRadius: 0,
      fieldset: {
        border: '0 solid transparent'
      }
    }
  },
  '.MuiInputAdornment-root': {
    position: 'absolute',
    right: 0,
    zIndex: 2,
    width: 24,
    height: 24,
    cursor: 'pointer',
    opacity: 0.75,
    top: 12
  },
  '.MuiInputBase-input': {
    paddingRight: '15px !important',
    paddingLeft: '0 !important'
  }
}))
