import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
// import { useForm, SubmitHandler, Controller } from "react-hook-form";

// import { useTheme } from "@mui/material";
// import { styled } from "@mui/system";
// import { IBoxProps, Icon } from "@ntpkunity/controls";
// import { Grid, Box, Button, Input, Typography } from "shared/components";
// import { useLogin } from "@apis/Auth";

// import { getUser, validation } from "@helpers/methods";
// import { APP_ROUTES } from "@helpers/links";
// import { EMAIL_REGEX } from "@helpers/constants";

// const loginImg = `url(${require("../public/assets/images/login-img.jpg")})`;

// const LoginScreen = styled(
//   Box,
//   {}
// )<Partial<IBoxProps>>(({ theme }) => ({
//   "&.auth-layout": {
//     ".layout-img": {
//       backgroundImage: loginImg,
//       height: "calc(100% - 80px)",
//       position: "fixed",
//       left: 40,
//       top: 40,
//       width: "calc(50% - 80px)",
//       backgroundRepeat: "no-repeat",
//       backgroundPosition: "center center",
//       borderRadius: 24,
//       backgroundSize: "cover",
//       [theme.breakpoints.down("md")]: {
//         display: "none",
//       },
//     },
//     ".content-area": {
//       maxWidth: "calc(100% - 395px)",
//       margin: "auto",
//       height: "calc(100vh - 110px)",
//       paddingTop: 68,
//       [theme.breakpoints.down("lg")]: {
//         maxWidth: "calc(100% - 100px)",
//       },
//       [theme.breakpoints.down("sm")]: {
//         maxWidth: "calc(100% - 70px)",
//       },
//     },
//     ".u-form-group": {
//       ".u-form-control": {
//         "&.MuiInputBase-adornedEnd": {
//           svg: {
//             path: {
//               stroke: theme.palette.grey[300],
//             },
//           },
//         },
//       },
//     },
//     ".decoration-none": {
//       textDecoration: "none",
//     },
//     ".fp-link": {
//       a: {
//         color: "rgba(0,0,0,0.6)",
//         paddingLeft: 15,
//       },
//     },
//     ".auth-footer": {
//       color: theme.palette.grey[600],
//       a: {
//         color: theme.palette.grey[600],
//       },
//     },
//   },
// }));

// interface IFormInputs {
//   email: string;
//   password: string;
// }
const Login: FC = () => {
  let navigate = useNavigate()
  useEffect(() => {
    navigate('/dms/workqueue')
  }, [])

  // const { mutate: authenticateUser } = useLogin();
  // const theme = useTheme();
  // const {
  //   handleSubmit,
  //   control,
  //   formState: { errors },
  //   setError,
  // } = useForm<IFormInputs>();

  // const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
  //   await authenticateUser(data, {
  //     onSuccess(data: any) {
  //       if (data["access_token"]) {
  //         const userData: any = getUser();
  //         if (userData.is_default_password_changed) {
  //           navigate(APP_ROUTES.WORKQUEUE);
  //         } else {
  //           navigate(APP_ROUTES.CHANGE_PASSWORD);
  //         }
  //       } else {
  //         setError("email", { type: "server", message: "" });
  //         setError("password", {
  //           type: "server",
  //           message: "Username or Password Incorrect",
  //         });
  //       }
  //     },
  //   });
  // };

  // const [showPassword, setShowPassword] = React.useState(false);
  // const handlePasswordChange = () => {
  //   setShowPassword(!showPassword);
  // };
  return (
    <></>
    // <LoginScreen theme={theme} className="auth-layout">
    //   <Grid
    //     className="main-content-section"
    //     theme={theme}
    //     container
    //     spacing={2}
    //   >
    //     <Grid item xs={12} md={6} lg={6}>
    //       <Box className="layout-img"></Box>
    //     </Grid>
    //     <Grid item xs={12} md={6} lg={6}>
    //       <Box
    //         className="content-area"
    //         flexDirection={"column"}
    //         display={"flex"}
    //         justifyContent={"space-between"}
    //       >
    //         <Box className="logo-area">
    //           <img
    //             src={require("../public/assets/images/unity-logo.svg")}
    //             alt="Unity"
    //           />
    //         </Box>
    //         <Box className="auth-form">
    //           <Typography mb={5} display={"block"} variant="h2" component="h2">
    //             Welcome to Unity
    //           </Typography>
    //           <form onSubmit={handleSubmit(onSubmit)}>
    //             <Box>
    //               <Controller
    //                 name="email"
    //                 control={control}
    //                 defaultValue={""}
    //                 rules={validation(
    //                   "Email",
    //                   true,
    //                   true,
    //                   EMAIL_REGEX,
    //                   "Valid Email is Required."
    //                 )}
    //                 render={({ field }) => (
    //                   <Input
    //                     inputWrapperSx={{ marginBottom: "34px !important" }}
    //                     fullWidth
    //                     label={"Email"}
    //                     type={"text"}
    //                     {...field}
    //                     error={errors?.email?.message}
    //                   />
    //                 )}
    //               />
    //               <Controller
    //                 name="password"
    //                 control={control}
    //                 defaultValue={""}
    //                 rules={validation("Password", true)}
    //                 render={({ field }) => (
    //                   <Input
    //                     fullWidth
    //                     label={"Password"}
    //                     type={showPassword ? "text" : "password"}
    //                     {...field}
    //                     error={errors?.password?.message}
    //                     endAdornment={
    //                       <Icon name="IcView" onClick={handlePasswordChange} />
    //                     }
    //                   />
    //                 )}
    //               />
    //             </Box>
    //             <Box mt={0.5} className="fp-link" mb={4}>
    //               <Link to="#" className="decoration-none">
    //                 <Typography variant="caption" component="small">
    //                   Forgot your password?
    //                 </Typography>
    //               </Link>
    //             </Box>
    //             <Button
    //               type={"submit"}
    //               primary
    //               text={"login"}
    //               fullWidth
    //             ></Button>
    //           </form>
    //         </Box>
    //         <Box className="auth-footer">
    //           <Typography variant="caption" component="small" display={"block"}>
    //             Support: 0-000-000-0000 |{" "}
    //             <a className="decoration-none" href="mailto:sample@sample.com">
    //               sample@sample.com
    //             </a>
    //           </Typography>
    //           <Typography variant="caption" component="small" display={"block"}>
    //             Weekdays 6am-6pm PST - Weekends 10am-6pm PST
    //           </Typography>
    //         </Box>
    //       </Box>
    //     </Grid>
    //   </Grid>
    // </LoginScreen>
  )
}

export default Login
