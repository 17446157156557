import { IOffer } from '@models'
import IActions from '@store/configs'
import { Dispatch, ReducerAction } from 'react'

export const SET_ORDER_INIT_STATE = 'GET ORDER'
export const UPDATE_ORDER_INIT_STATE = 'UPDATE_ORDER_INIT_STATE'
export const initialState: IOffer = {
  selected_vehicle: undefined,
  general_prefernece: undefined,
  order_fees: [],
  order_options: [],
  order_fnI: [],
  order_tradein: undefined
}

const OrderReducer = (state: typeof initialState, action: IActions): typeof initialState => {
  switch (action.type) {
    case SET_ORDER_INIT_STATE:
      return { ...state, ...(action.payload as typeof initialState) }
  }
  return state
}
export const setOfferInitialData = (
  data: typeof initialState,
  dispatch: Dispatch<ReducerAction<typeof OrderReducer>>
): void => {
  if (data) {
    dispatch({ type: SET_ORDER_INIT_STATE, payload: data })
  }
}
export default OrderReducer
