import { FC, useEffect, useMemo, useState } from 'react'
import { Divider, useTheme } from '@mui/material'
import {
  Typography,
  FileDragDrop,
  FilePreview,
  DatePicker,
  Box,
  Grid,
  Input,
  Dialog
} from '@ntpkunity/controls'
import { Controller, useForm } from 'react-hook-form'
import AddressDetailsComponent from '@app/customer-details/address-detail'
import { useGetDealerProfileById } from '@apis/dealer-configurations.service'
import { useStoreContext } from '@store/storeContext'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import dayjs from 'dayjs'
import { InsuranceControlProps, InsuranceFormData } from './insurance-control.types'
import { Button } from '@components'
import { base64ToFile, createFileUploadUtils } from '@helpers/methods'

const schema = yup.object().shape({
  insurer_name: yup.string().nullable().required('Insurance Provider Name is required'),
  agent_name: yup.string().nullable().required('Insurance Agent Name is required'),
  policy_number: yup.string().nullable().required('Policy Number is required'),
  expiration_date: yup
    .string()
    .nullable()
    .required('Expiration is required')
    .test(
      'notFutureOrCurrentDate',
      'Expiration Date cannot be equal to or lesser than current date.',
      function (value) {
        const selectedDate = dayjs(value).startOf('day')
        const currentDate = dayjs().startOf('day')
        return selectedDate.isAfter(currentDate)
      }
    ),
  insurance_documents: yup
    .array()
    .of(
      yup.object().shape({
        document: yup.string().required('Document is required'),
        content_type: yup.string().required('Content type is required'),
        name: yup.string().required('Name is required'),
        file_size: yup.string().required('File size is required'),
        created_by: yup.string().nullable()
      })
    )
    .min(1, 'At least one document is required')
    .required('At least one document is required'),
  insurer_address: yup.object().shape({
    address_line_1: yup.string().nullable().required('Address Line 1 is required'),
    city: yup.string().nullable().required('City is required'),
    state_name: yup.string().nullable().required('State Name is required'),
    zip_code: yup.string().nullable().required('Zip Code is required')
  })
})

const InsuranceControl: FC<InsuranceControlProps> = ({
  insuranceFormData,
  setInsuranceFormData,
  setInsuranceOpenDialog,
  openInsuranceDialog
}) => {
  const theme = useTheme()

  const { states } = useStoreContext()

  const { mutate: getDealerProfile } = useGetDealerProfileById()

  useEffect(() => {
    if (states?.dealerInfo?.dealer_code) {
      getDealerProfile({ dealer_id: states?.dealerInfo?.dealer_code }, {})
    }
  }, [states?.dealerInfo?.dealer_code])
  const form = useForm<InsuranceFormData>({
    defaultValues: insuranceFormData,
    resolver: yupResolver(schema)
  })

  const {
    handleSubmit,
    formState: { errors },
    getValues,
    clearErrors,
    setValue,
    control
  } = form

  const [imageFiles, setImageFiles] = useState<any[]>([])
  const fileUploadUtils = useMemo(
    () =>
      createFileUploadUtils(
        'insurance_documents',
        {
          setValue,
          getValues,
          setImageFiles,
          clearErrors
        },
        true,
        ['jpg', 'jpeg', 'png', 'pdf']
      ),
    [setValue, getValues, setImageFiles, clearErrors]
  )

  useEffect(() => {
    const insuranceDocuments = getValues('insurance_documents') || []
    if (insuranceDocuments && insuranceDocuments.length > 0) {
      const filesArray = base64ToFile(insuranceDocuments)
      setImageFiles(filesArray.length > 0 ? filesArray : insuranceDocuments)
    }
  }, [getValues('insurance_documents')])
  useEffect(() => {
    if (Object.keys(insuranceFormData?.insurer_address ?? {}).length > 0) {
      setValue('insurer_address', insuranceFormData?.insurer_address)
    }
  }, [insuranceFormData?.insurer_address])
  const handleInsuranceClose = () => {
    setInsuranceOpenDialog(false)
  }

  const onSubmit = (data: any) => {
    setInsuranceOpenDialog(false)
    setInsuranceFormData({
      ...data,
      expiration_date: dayjs(form.getValues('expiration_date')).format('YYYY-MM-DD'),
      created_by: 'lender'
    })
  }

  return (
    <>
      <Dialog
        variant={undefined}
        size="md"
        title="Insurance"
        open={openInsuranceDialog}
        onCloseDialog={handleInsuranceClose}
        customFooter={
          <Button theme={theme} primary text="Continue" onClick={handleSubmit(onSubmit)} />
        }
        theme={theme}
        children={
          <>
            <Grid theme={theme} container rowSpacing={3} columnSpacing={3}>
              <Grid item theme={theme} xs={12} sm={6}>
                <Controller
                  name="insurer_name"
                  control={control}
                  render={({ field }) => (
                    <Input
                      theme={theme}
                      fullWidth
                      label="Insurance Provider Name"
                      placeholder="Add here . . "
                      type="text"
                      {...field}
                      error={errors?.insurer_name?.message}
                    ></Input>
                  )}
                />
              </Grid>
              <Grid item theme={theme} xs={12} sm={6}>
                <Controller
                  name="agent_name"
                  control={control}
                  render={({ field }) => (
                    <Input
                      theme={theme}
                      fullWidth
                      label="Insurance Agent Name"
                      placeholder="Add here . . "
                      type="text"
                      {...field}
                      error={errors?.agent_name?.message}
                    ></Input>
                  )}
                />
              </Grid>
              <Grid item theme={theme} xs={12} sm={6}>
                <Controller
                  name="policy_number"
                  control={control}
                  render={({ field }) => (
                    <Input
                      theme={theme}
                      fullWidth
                      label="Policy Number"
                      placeholder="Add here . . "
                      type="text"
                      {...field}
                      error={errors?.policy_number?.message}
                    ></Input>
                  )}
                />
              </Grid>
              <Grid item theme={theme} xs={12} sm={6}>
                <Controller
                  name="expiration_date"
                  control={control}
                  defaultValue=""
                  render={({ field: { value, onChange } }) => (
                    <DatePicker
                      label="Expiration"
                      disablePast
                      disablePortal
                      theme={theme}
                      value={value ?? ''}
                      onChange={(e: any) => {
                        if (
                          e instanceof Date &&
                          e.getFullYear() === 1970 &&
                          e.getMonth() === 0 &&
                          e.getDate() === 1
                        ) {
                          onChange(null)
                        } else {
                          onChange(e.toISOString())
                        }
                      }}
                      error={errors.expiration_date?.message}
                      inputReadOnly
                    />
                  )}
                />
              </Grid>
              <Grid item theme={theme} xs={12}>
                <Divider />
              </Grid>
              <Grid item theme={theme} xs={12}>
                <Typography
                  theme={theme}
                  variant="subtitle1"
                  component="h5"
                  children="Insurance Provider Address"
                />
              </Grid>
              <Grid item theme={theme} xs={12}>
                <AddressDetailsComponent
                  form={form}
                  isGoogleEnabled={false}
                  addressValue={'insurer_address'}
                  moveIn={false}
                  errorProps={errors.insurer_address}
                  control={control}
                  showContactNumber
                />
              </Grid>
            </Grid>
            <Divider sx={{ mb: 3, mt: 3 }} />
            <Box theme={theme} sx={{ mb: 1 }}>
              <Typography
                theme={theme}
                variant="subtitle1"
                component="h5"
                children="Proof of Insurance"
              />
              <Typography
                theme={theme}
                variant="caption"
                component="span"
                children="Supported formats: JPG, JPEG, PNG, PDF."
              />
            </Box>
            <Controller
              name="insurance_documents"
              control={control}
              render={({ field }) => (
                <FileDragDrop
                  {...field}
                  accept=".jpg,.jpeg,.png,.pdf"
                  theme={theme}
                  width="300"
                  height="200px"
                  backgroundColor="white"
                  onDrop={fileUploadUtils.onImageDrop}
                  onChange={fileUploadUtils.onImageUpload}
                  allowMultiple={true}
                  error={!!errors?.insurance_documents?.message}
                />
              )}
            />
            {imageFiles && imageFiles.length > 0 && imageFiles[0] != null && (
              <FilePreview
                files={imageFiles.length > 0 ? imageFiles : []}
                onRemoveFile={fileUploadUtils.onRemoveFile}
                theme={theme}
              />
            )}
            <Divider sx={{ mt: 4 }} />
          </>
        }
      />
    </>
  )
}
export default InsuranceControl
