import { Link, useTheme } from '@mui/material'
import { FC, useState } from 'react'
import { Button, Dialog, Radio, Textarea, Box } from '@ntpkunity/controls'
import { AgreementPopupWrap } from './agreement-popup.style'
import { Controller, useForm } from 'react-hook-form'
import { DeskingActionTypes, useDeskingContext } from '@app/desking/desking-context-provider'
import { useUpdateIndividualizedAgreement } from '@apis/configurations.service'
import { useStoreContext } from '@store/storeContext'

export const AgreementPopup: FC<{
  setAgreementPopUp: (open: boolean) => void
  individualAgreement: any[]
  order: any
  setOptionObject?: any
}> = ({ setAgreementPopUp, individualAgreement, order, setOptionObject }) => {
  const { dispatch } = useDeskingContext()
  const theme = useTheme()
  const {
    mutate: updateIndividualizedAgreement,
    isLoading: _updateRequestLoading,
    data: _updateResponse,
    error: _updateRequestError
  } = useUpdateIndividualizedAgreement()

  const [editingAgreementId, setEditingAgreementId] = useState<number | null>(null)
  const [, setIsDirty] = useState(false)
  const selectedDealer = individualAgreement?.find((data: any) => data.selected)
  const selectedId = selectedDealer ? selectedDealer.id : null

  const { control, handleSubmit, setValue, getValues, watch } = useForm({
    defaultValues: {
      value: selectedId,
      editTextValue: ''
    }
  })
  const { actions } = useStoreContext()

  const handleClose = () => {
    setAgreementPopUp(false)
  }

  const handleEditClick = (agreementId: number, currentText: string) => {
    selectedTextValue(agreementId)
    setValue('editTextValue', currentText)
    setValue('value', agreementId)
    setEditingAgreementId(agreementId)
    setIsDirty(false)
  }

  const handleSave = async () => {
    const values = getValues()
    const index = individualAgreement?.findIndex((item: any) => item?.id === values?.value)
    if (index !== -1) {
      individualAgreement[index].agreement_clause = values?.editTextValue
    }
    updateIndividualizedAgreement(
      {
        reference_number: order?.order_asset?.reference_number,
        individualized_agreement: values?.editTextValue
      },
      {
        onSuccess(_response: any) {
          setOptionObject(_response)
          dispatch({
            type: DeskingActionTypes.MAP_ORDER_TO_STORE,
            payload: _response
          })
          actions.setToast({
            toastMessage: 'Deal Updated Successfully.',
            toastState: true,
            showIcon: false
          })
          setAgreementPopUp(false)
        }
      }
    )
  }

  const handleDiscardChanges = (currentText: string) => {
    setIsDirty(false)
    setValue('editTextValue', currentText)
    setEditingAgreementId(null)
  }

  const selectedTextValue = (id: number) => {
    setEditingAgreementId(null)
    const foundAgreement = individualAgreement?.find((item: any) => item?.id == id)
    setValue('editTextValue', foundAgreement?.agreement_clause ?? '')
  }
  const radioValue = watch('value')
  const radioOptions = individualAgreement?.map((agreement: any) => ({
    label: (
      <>
        {editingAgreementId === agreement?.id ? (
          <>
            <Controller
              name="editTextValue"
              control={control}
              render={({ field }) => (
                <Textarea
                  theme={theme}
                  type="text"
                  id="description"
                  placeholder="Type here..."
                  {...field}
                  fullWidth
                  rows={10}
                  onChange={(e) => {
                    setValue('editTextValue', e)
                    setIsDirty(true)
                  }}
                />
              )}
            />
            <Box theme={theme} mt={1}>
              <Link
                className="link text-danger"
                onClick={() => handleDiscardChanges(agreement?.agreement_clause)}
              >
                Discard Changes
              </Link>
            </Box>
          </>
        ) : (
          <>
            {agreement.agreement_clause}
            <Box theme={theme} mt={1}>
              <Link
                className="link"
                onClick={() => handleEditClick(agreement?.id, agreement?.agreement_clause)}
              >
                Edit Agreement
              </Link>
            </Box>
          </>
        )}
      </>
    ),
    value: agreement?.id
  }))

  return (
    <Dialog
      disablePortal
      theme={theme}
      size="lg"
      title="Individualized Agreement"
      open={true}
      onCloseDialog={handleClose}
      customFooter={
        <Button theme={theme} primary text={'Save Changes'} onClick={handleSubmit(handleSave)} />
      }
    >
      <AgreementPopupWrap theme={theme} className="agreement-popup-wrap">
        <Controller
          name="value"
          control={control}
          defaultValue={radioValue}
          render={({ field }) => (
            <Radio
              className="custom-radio"
              defaultValue={field.value}
              {...field}
              theme={theme}
              row={true}
              options={radioOptions}
              onChange={(val) => {
                field.onChange(val)
                selectedTextValue(val)
              }}
            />
          )}
        />
      </AgreementPopupWrap>
    </Dialog>
  )
}
