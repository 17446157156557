export enum Call_Type {
  MANUAL = 'manual',
  AUTO = 'auto'
}

export enum LICENSE_STATUS {
  MANUAL_REVIEW = 'Manual Review',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  VERIFIED = 'Verified'
}
