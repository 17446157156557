import Http from '@helpers/http-api'
import { useMutation, useQuery } from 'react-query'
import {
  EP_UPLOAD_DOCUMENT,
  EP_FINANCIAL_STIPULATION,
  EP_CREDIT_RESPONSE,
  EP_STIPULATION,
  EP_GET_SINGLE_ORDER_SUBMISSION
} from '@helpers/endpoints'
import { type AppError } from 'single-spa'
import { IFinancialStipulation } from '@models'

export const useGetNonFinancialStips = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.get<any>(`${EP_UPLOAD_DOCUMENT}?external_reference_id=${body.order_id}`, {})
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useGetFinancialStips = (
  orderId: string,
  customer_reference_id: string
): {
  data: IFinancialStipulation[] | undefined
  isLoading: boolean
  error: AppError
} => {
  const { data, isLoading, error } = useQuery<any, any, IFinancialStipulation[]>(
    EP_FINANCIAL_STIPULATION,
    async () => {
      const apiService = Http.createConnection()
      return await apiService.get(
        `${EP_FINANCIAL_STIPULATION}?external_reference_id=${orderId}&customer_reference_id=${customer_reference_id}`
      )
    }
  )
  return { data, isLoading, error }
}

export const useGetCreditResponse = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection()
      return apiService.get<any>(`${EP_CREDIT_RESPONSE}?reference_number=${body.reference_number}`)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}

export const useGetStipulationsByLenderId = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    (body: any) => {
      const apiService = Http.createConnection()

      return apiService.get(`${EP_STIPULATION}?company_id=${body.company_id}`)
    },

    {
      onSuccess() {}
    }
  )

  return { data, isLoading, mutate }
}

export const useGetOrderSubmissionResponse = (): any => {
  const { data, isLoading, mutate } = useMutation<any>(
    ({ submission_id }: any) => {
      const apiService = Http.createConnection()
      return apiService.get<any>(`${EP_GET_SINGLE_ORDER_SUBMISSION}/${submission_id}`)
    },
    {
      onSuccess() {}
    }
  )
  return { data, isLoading, mutate }
}
