import IActions from '../configs'
import { Dispatch, ReducerAction } from 'react'

export const SET_CUSTOMER_INIT_STATE = 'GET INVENTORY DATA FROM SERVER'
export const SET_CUSTOMER_INFORMATION = 'SET CUSTOMER INFORMATION'

export const initialState: any = {}

const reducer = (state: typeof initialState, action: IActions): typeof initialState => {
  switch (action.type) {
    case SET_CUSTOMER_INIT_STATE:
      return action.payload as typeof initialState
    case SET_CUSTOMER_INFORMATION:
      return action.payload as typeof initialState
  }
  return state
}

export const setCustomerData = (
  data: typeof initialState,
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  if (data) {
    dispatch({ type: SET_CUSTOMER_INIT_STATE, payload: data })
  }
}

export const setCustomerInformation = (
  data: typeof initialState,
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  if (data) {
    dispatch({ type: SET_CUSTOMER_INFORMATION, payload: data })
  }
}

export default reducer
