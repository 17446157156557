import { unityTheme } from '@ntpkunity/controls'
import IActions, { IThemeConfig } from '../configs'
import { Dispatch, ReducerAction } from 'react'
import { lightTheme } from '@styles/theme'

export const UPDATE_THEME = 'UPDATE_THEME'

export const initialState: IThemeConfig = {
  theme: unityTheme
}

const reducer = (state: typeof initialState, action: IActions): typeof initialState => {
  switch (action.type) {
    case UPDATE_THEME:
      return {
        theme: (action.payload as IThemeConfig).theme as any
      }
  }
  return state
}

export const updateThemeConfig = (
  data: 'default' | 'dark',
  dispatch: Dispatch<ReducerAction<typeof reducer>>
): void => {
  if (data) {
    if (data === 'dark') {
      dispatch({ type: UPDATE_THEME, payload: { theme: lightTheme } })
    }
    if (data === 'default') {
      dispatch({ type: UPDATE_THEME, payload: { theme: unityTheme } })
    }
  }
}

export default reducer
