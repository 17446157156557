import { useTheme } from '@mui/material'
import { ITypgraphyProps, Typography } from '@ntpkunity/controls'
import { FC } from 'react'

const Component: FC<Partial<ITypgraphyProps>> = ({ children, variant, component, ...props }) => {
  const theme = useTheme()

  return (
    <Typography
      variant={variant}
      component={component ?? 'span'}
      children={children}
      theme={theme}
      {...props}
    />
  )
}

export default Component
