import { styled } from '@mui/material'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const PreQualificationDialogWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.qualification-alert-wrap': {
    '.u-dialog': {
      '.u-dialog-action': {
        '>': {
          ':not(:first-of-type)': {
            flexGrow: 0
          }
        }
      },
      '.MuiDialogContent-dividers': {
        border: 'none'
      },
      '.u-dialog-title': {
        paddingBottom: 0
      }
    }
  },
  '&.qualification-dialog-wrap': {
    '.u-dialog': {
      '.stack-wrap.bg-light': {
        backgroundColor: 'transparent',
        padding: 0,
        borderRaduis: 0
      },
      '.line': {
        borderTop: '1px solid',
        borderTopColor: theme.palette.divider,
        margin: '24px 0'
      },
      '.u-dialog-content': {
        marginBottom: 24
      }
    }
  }
}))
