import { FC } from 'react'
import { useTheme } from '@mui/material'
import { Box } from '@ntpkunity/controls'
import {
  CalculationBox,
  DownPayment,
  Label,
  QuoteParam,
  SellingPrice,
  Value
} from './finance-lease-calculator'
import { AddVehicle } from './add-vehicle'
import { useDeskingContext, VehiclesForComparisonTypes } from './desking-context-provider'

const DealPreviewComponent: FC = () => {
  const theme = useTheme()
  const {
    state: { preview, vehiclesData, selection }
  } = useDeskingContext()

  const { vin, vinIndex, down_payments, quote_params, calculationsBoxes } = preview
  const financedAmount = calculationsBoxes?.adjusted_capitalized_cost
  const vehicleData = vehiclesData.get(vin)

  const carryPercent =
    (vin ?? '').length > 0 && !!financedAmount && Object.keys(vehicleData ?? {}).length > 0
      ? (calculationsBoxes?.adjusted_capitalized_cost / vehicleData.vehicle.msrp) * 100
      : null

  return (
    <>
      <Box theme={theme} className="flc-wrap">
        <Box theme={theme} className="flc-ul">
          <Box theme={theme} className="flc-li">
            <AddVehicle vin={vin} index={vinIndex} />
          </Box>

          <Box theme={theme} className="flc-li">
            <Box theme={theme} className="flc-li-left-col">
              <Box theme={theme} className="flc-labels-wrap">
                <Box theme={theme} className="label-row">
                  <Label name={'Selling Price'} />
                </Box>
              </Box>
            </Box>
            <Box theme={theme} className="flc-li-selfStretch-col">
              <SellingPrice vin={vin} />
            </Box>
          </Box>
          <Box theme={theme} className="flc-li">
            <Box theme={theme} className="flc-li-left-col">
              <Box theme={theme} className="flc-labels-wrap">
                <Box theme={theme} className="label-row">
                  <Label name={'Down Payment'} />
                </Box>
              </Box>
            </Box>
            <Box theme={theme} className="flc-li-selfStretch-col">
              <DownPayment vin={vin} rowIndex={0} colIndex={0} value={down_payments} />
            </Box>
          </Box>
          <Box theme={theme} className="flc-li">
            <QuoteParam vin={vin} rowIndex={0} colIndex={0} value={quote_params} />
            <CalculationBox
              key={`${VehiclesForComparisonTypes.SINGLE}-${selection.row}-${selection.col}`}
              comparison={VehiclesForComparisonTypes.PREVIEW}
              vin={vin}
              value={calculationsBoxes}
              rowIndex={selection.row}
              colIndex={selection.col}
              selected
            />
          </Box>
          <Box theme={theme} className="flc-li flc-footer">
            <Box theme={theme} className="flc-li-left-col">
              <Box theme={theme} className="flc-labels-wrap">
                <Box theme={theme} className="label-row">
                  <Label name={'Financed Amount'} />
                </Box>
              </Box>
            </Box>
            <Box theme={theme} className="flc-li-selfStretch-col border-bottom">
              <Value key={''} value={financedAmount ?? '00000.00'} />
            </Box>
          </Box>
          <Box theme={theme} className="flc-li flc-footer">
            <Box theme={theme} className="flc-li-left-col">
              <Box theme={theme} className="flc-labels-wrap">
                <Box theme={theme} className="label-row">
                  <Label name={'LTV%'} />
                </Box>
              </Box>
            </Box>
            <Box theme={theme} className="flc-li-selfStretch-col border-bottom">
              <Value key={''} value={carryPercent ?? '00.00'} isCurrency={false} />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default DealPreviewComponent
