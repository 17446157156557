import { ToggleButton, useTheme } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { Box, Button, Grid, Icon, Typography } from '@ntpkunity/controls'
import { useStoreContext } from '@store/storeContext'
import { useGetMonthlyPayments } from '@apis/configurations.service'
import { useNavigate } from 'react-router-dom'

export const AssetDetailCardView: FC<{
  vehicleDetail?: any
  programData: any
  finalTerms: any
  defaultCurrency: string
  balloonPayment: string
  creditScore?: string
}> = ({ vehicleDetail, programData, defaultCurrency, finalTerms, creditScore }) => {
  const theme = useTheme()
  const { states } = useStoreContext()
  let navigate = useNavigate()
  const { actions } = useStoreContext()
  const [open, setOpen] = useState(false)
  // const [vehiclePopup, setVehiclePopup] = useState<any>()
  const [vehicleMonthlyPayment, setVehicleMonthlyPayment] = useState<any>()
  const { mutate: getVehicleMonthlyPayments } = useGetMonthlyPayments()
  const termsValue = Number(finalTerms[1]) - Number(finalTerms[0])
  const generateDecrementTerms = (arr: number[]) => {
    const [min, max] = arr
    const resultArray = []
    for (let i = max - 12; i >= min; i -= 12) {
      resultArray.push(i)
    }
    return resultArray
  }
  useEffect(() => {
    if (open) {
      setOpen(false)
    }
  }, [vehicleDetail])
  const CalculateMonthlyPayments = (vehicleData: any) => {
    const terms = generateDecrementTerms(finalTerms)
    const request = {
      company_id: states?.dealerAndLenderInfo?.company_id,
      rental_mode: programData?.rental_mode,
      asset_make_name: vehicleData?.vehicle?.make,
      asset_model_name: vehicleData?.vehicle?.model,
      asset_trim_name: vehicleData?.vehicle?.trim_description,
      dealer_id: states?.dealerAndLenderInfo?.id,
      terms: terms,
      financed_amount: vehicleData?.vehicle?.NFA
    }
    getVehicleMonthlyPayments(request, {
      onSuccess(response: any) {
        setVehicleMonthlyPayment(response)
        setOpen(!open)
      }
    })
  }

  const orderInitiation = (vin: string, terms: any) => {
    actions?.setQuotationDefaultParam({ defaultCreditRating: creditScore, defaultTerms: terms })
    navigate('/dms/create-deal/' + vin)
  }
  return (
    <>
      {termsValue > 0 && (
        <Box theme={theme} className="card-list-item with-toggle">
          <ToggleButton
            className="btn btn-secondary"
            value="check"
            selected={open}
            onChange={() => {
              open ? setOpen(!open) : CalculateMonthlyPayments(vehicleDetail)
            }}
          >
            <Icon name="ChevronDown" />
          </ToggleButton>
        </Box>
      )}
      <Box theme={theme} className="sub-item-wrap">
        {vehicleMonthlyPayment?.map(
          (paymentDetail: any, index: number) =>
            open && (
              <Box theme={theme} key={index}>
                <Box theme={theme} className="card-list-item with-bg">
                  <Grid theme={theme} container columnSpacing={1}>
                    <Grid theme={theme} item xs={4}>
                      <Typography
                        theme={theme}
                        component="span"
                        variant="body2"
                        children={
                          <>
                            Type:{' '}
                            <b>
                              {paymentDetail?.terms?.toString()} Months {programData?.finance_type}
                            </b>
                          </>
                        }
                      />
                    </Grid>
                    <Grid theme={theme} item xs={8}>
                      <Box theme={theme} className="btn-order-wrap">
                        <Typography
                          theme={theme}
                          component="span"
                          variant="body2"
                          children={
                            <>
                              Estimated Payment:&nbsp;
                              <b>
                                {defaultCurrency}
                                {paymentDetail?.estimated_monthly_payment?.toLocaleString(
                                  undefined,
                                  {
                                    maximumFractionDigits: 4,
                                    minimumFractionDigits: 2
                                  }
                                )}
                              </b>
                            </>
                          }
                        />
                        <Button
                          theme={theme}
                          sx={{ ml: 2 }}
                          className="btn-order"
                          primary
                          text="Initiate Deal"
                          onClick={() => {
                            orderInitiation(vehicleDetail?.vehicle?.vin, paymentDetail?.terms)
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )
        )}
      </Box>
    </>
  )
}
