import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'
import Http from '@helpers/http-api'
import { useStoreContext } from '@store/storeContext'
import { EP_GET_WORK_QUEUE, EP_CANCEL_ORDER } from '@helpers/endpoints'

import { getMarketPlaceToken } from '@helpers/methods'
import { QueryKeys } from '@helpers/enums'
import { IWorkOrderData } from 'models/work-order.interface'

const token = getMarketPlaceToken()
const headers = {
  Authorization: `Bearer ${token}`
}
export const useGetWorkOrder = (): any => {
  const { actions } = useStoreContext()
  const { data, isLoading, error, mutate } = useMutation<{}, any>(
    (body: any) => {
      const apiService = Http.createConnection()
      let params = body.data ? `${body.data}` : ''
      return apiService.get<any>(
        params ? `${EP_GET_WORK_QUEUE}?${params}` : EP_GET_WORK_QUEUE,
        {},
        { headers }
      )
    },
    {
      onSuccess(_response: any) {
        actions.setDealerFeeInitialData(_response)
      }
    }
  )
  return { data, isLoading, error, mutate }
}

const getAllWQOrders = async (columnFilters: string, dealer_email?: string, admin_id?: string) => {
  let userRole = null
  if (localStorage.getItem('settings') !== null) {
    const access_token_local = JSON.parse(localStorage.getItem('settings') || '')
    userRole = access_token_local?.role
  }
  if (dealer_email) {
    columnFilters = `${columnFilters}&dealer_email=${dealer_email}`
  } else if (admin_id) {
    columnFilters = `${columnFilters}`
  } else if (userRole?.name === 'Admin') {
    columnFilters = `${columnFilters}`
  }

  const apiService = Http.createConnection()
  return apiService.get(`${EP_GET_WORK_QUEUE}?${columnFilters}`, {}, { headers })
}

export const UseQuery_Get_Work_Order = (
  columnFilters: string
): UseQueryResult<IWorkOrderData, unknown> => {
  const { states } = useStoreContext()
  const dealer_email = states?.dealerInfo?.email
  const admin_id = states?.lenderInfo?.id
  return useQuery(
    dealer_email
      ? [QueryKeys.GET_ALL_WORK_ORDERS, columnFilters, dealer_email]
      : [QueryKeys.GET_ALL_ADMIN_WORK_ORDERS, columnFilters],
    () =>
      dealer_email ? getAllWQOrders(columnFilters, dealer_email) : getAllWQOrders(columnFilters),
    {
      keepPreviousData: false,
      enabled: !!(dealer_email || admin_id)
    }
  )
}
export const UseQuery_Get_ScheduledPickupOrder = (
  columnFilters: string
): UseQueryResult<IWorkOrderData, unknown> => {
  const { states } = useStoreContext()
  const dealer_email = states?.dealerInfo?.email
  return useQuery(
    [QueryKeys.GET_ALL_SCHEDULE_PICKUP_ORDERS, columnFilters, dealer_email],
    () => getAllWQOrders(columnFilters, dealer_email),
    {
      keepPreviousData: false,
      enabled: !!dealer_email
    }
  )
}

export const UseQuery_Get_PendingConfirmationOrder = (
  columnFilters: string
): UseQueryResult<IWorkOrderData, unknown> => {
  const { states } = useStoreContext()
  const dealer_email = states?.dealerInfo?.email
  return useQuery(
    [QueryKeys.GET_ALL_PENDING_CONFIRMATION_ORDERS, columnFilters, dealer_email],
    () => getAllWQOrders(columnFilters, dealer_email),
    {
      keepPreviousData: false,
      enabled: !!dealer_email
    }
  )
}

export const UseQuery_Get_CancelledOrder = (
  columnFilters: string
): UseQueryResult<IWorkOrderData, unknown> => {
  const { states } = useStoreContext()
  const dealer_email = states?.dealerInfo?.email
  return useQuery(
    [QueryKeys.GET_ALL_CANCELLED_ORDERS, columnFilters, dealer_email],
    () => getAllWQOrders(columnFilters, dealer_email),
    {
      keepPreviousData: false,
      enabled: !!dealer_email
    }
  )
}

export const UseQuery_Get_CompletedOrder = (
  columnFilters: string
): UseQueryResult<IWorkOrderData, unknown> => {
  const { states } = useStoreContext()
  const dealer_email = states?.dealerInfo?.email
  return useQuery(
    [QueryKeys.GET_ALL_COMPLETED_ORDERS, columnFilters, dealer_email],
    () => getAllWQOrders(columnFilters, dealer_email),
    {
      keepPreviousData: false,
      enabled: !!dealer_email
    }
  )
}

export const UseQuery_Get_AppointmentOrders = (
  columnFilters: string
): UseQueryResult<IWorkOrderData, unknown> => {
  const { states } = useStoreContext()
  const dealer_email = states?.dealerInfo?.email
  return useQuery(
    [QueryKeys.GET_ALL_APPOINTMENT_ORDERS, columnFilters, dealer_email],
    () => getAllWQOrders(columnFilters, dealer_email),
    {
      keepPreviousData: false,
      enabled: !!dealer_email
    }
  )
}

export const useMutationCancelOrder = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (reference_number: any) => {
      const apiService = Http.createConnection()
      return apiService.patch<any>(EP_CANCEL_ORDER + reference_number)
    },
    {
      onSuccess: async (_response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_WORK_ORDERS)
      }
    }
  )
}

export const useCreditDecision = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (body: any) => {
      const { reference_number, data } = body
      const apiService = Http.createConnection()
      return apiService.patch(`${EP_CANCEL_ORDER}/${reference_number}`, data)
    },
    {
      onSuccess: async (_response: any) => {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_WORK_ORDERS)
      }
    }
  )
}
