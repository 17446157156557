import { FC, Key, useEffect, useState } from 'react'
import { Link, ToggleButton, useTheme, styled } from '@mui/material'
import { DetailCard, ResponsiveCarousel, ToggleCollapse, ToggleCollapseWrap } from 'components'
import { Typography, Grid, Box, Icon, IBoxProps } from '@ntpkunity/controls'
import { useQueryClient } from 'react-query'
import { QueryKeys, InspectionStatus, Status, TradeInMessage } from '@helpers/enums'
import { IOrderTradeIn } from '@models'
import { useStoreContext } from '@store/storeContext'
import { useSetTradeInInspectionStatus } from '@apis/order-management.service'
import { convertToAmountWithOutSign } from '@helpers/methods'

const ReactCarouselWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.react-carousel-wrap': {
    position: 'relative',
    '.react-multi-carousel-list': {
      padding: 0,
      '.carousel-button-group': {
        opacity: 0,
        visibility: 'hidden',
        transition: 'all 0.3s'
      },
      '&:hover': {
        '.carousel-button-group': {
          opacity: 1,
          visibility: 'visible'
        }
      }
    },
    '.carousel-button-group': {
      '.carousel-control': {
        top: '50%',
        '&.prev-btn': {
          left: -48
        },
        '&.next-btn': {
          right: -48
        }
      }
    },
    '.react-multi-carousel-dot-list': {
      bottom: -8
    },
    '.react-multi-carousel-item': {
      '.aspect-ratio': {
        objectFit: 'contain',
        backgroundColor: theme.customVariables.lightGrey,
        borderRadius: theme.shape.borderRadius
      }
    }
  }
}))

const TradeInDetails: FC<{ orderDetail?: any; setOrderObject?: any }> = ({
  orderDetail,
  setOrderObject
}) => {
  const theme = useTheme()
  const [defaultCurrency, setDefaultCurrency] = useState('')
  const queryClient = useQueryClient()
  const { states } = useStoreContext()
  const currencies: any = queryClient.getQueryData([
    QueryKeys.GET_ALL_CURRENCIES,
    states?.lenderInfo?.id
  ])
  const payoff_amount = orderDetail?.order_tradein?.payoff_amount
    ? parseFloat(orderDetail?.order_tradein?.payoff_amount)
    : 0
  const { mutate: setTradeInInspectionStatus } = useSetTradeInInspectionStatus()
  const { actions } = useStoreContext()
  const trade_in_amount = orderDetail?.order_tradein?.trade_in_amount
    ? parseFloat(orderDetail?.order_tradein?.trade_in_amount)
    : 0
  let tradeInEquity: any = trade_in_amount - payoff_amount
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }
  if (tradeInEquity && tradeInEquity > 0) {
    tradeInEquity =
      defaultCurrency +
      Math.abs(tradeInEquity).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
  } else if (tradeInEquity < 0) {
    tradeInEquity =
      `-${defaultCurrency}` +
      Math.abs(tradeInEquity).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
  }
  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === states?.lenderInfo?.default_currency
    )
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0 ? filteredCurrency[0].symbol : ''
    )
  }, [currencies])

  const handleInspectionClick = () => {
    const value =
      orderDetail?.order_tradein?.inspection_status === InspectionStatus.Pending
        ? InspectionStatus.Completed
        : InspectionStatus.Pending
    setTradeInInspectionStatus(
      { orderId: orderDetail?.order_tradein?.order_id, inspectionStatus: value },
      {
        onSuccess(response: IOrderTradeIn) {
          const message =
            response?.inspection_status === InspectionStatus.Pending
              ? TradeInMessage.InspectionPending
              : TradeInMessage.InspectionCompleted
          actions.setToast({
            toastMessage: message,
            toastState: true
          })
          setOrderObject({
            ...orderDetail,
            status:
              response?.inspection_status == InspectionStatus.Pending
                ? Status.PendingTradeInInspection
                : Status.PendingConfirmation,
            order_tradein: {
              ...response // updating order trade-in
            }
          })
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: 'error'
          })
        }
      }
    )
  }
  const [ToggleCollapseOpen, ToggleCollapseSetOpen] = useState(true)
  const renderInspectionStatus = () => {
    if (
      (orderDetail?.status == Status.PendingConfirmation ||
        orderDetail?.status == Status.PendingTradeInInspection) &&
      orderDetail?.order_tradein?.inspection_status !== InspectionStatus.Completed
    ) {
      return (
        <Typography
          theme={theme}
          component="span"
          variant="body2"
          className="fw-500"
          children={
            <>
              <Link
                className={
                  orderDetail?.status === Status.PendingTradeInConfirmation
                    ? 'link disabled'
                    : 'link'
                }
                onClick={
                  orderDetail?.status !== Status.PendingTradeInConfirmation
                    ? handleInspectionClick
                    : () => {}
                }
              >
                {orderDetail?.order_tradein?.inspection_status === InspectionStatus.Pending
                  ? 'Mark Inspection Completed'
                  : 'Request Physical Inspection'}
              </Link>
            </>
          }
        />
      )
    }
    if (orderDetail?.order_tradein?.inspection_status == InspectionStatus.Completed) {
      return (
        <Typography
          theme={theme}
          component="span"
          variant="body2"
          className="fw-500"
          children={'Inspection Completed'}
        />
      )
    }
  }

  return (
    <DetailCard className="detail-card" mb={2}>
      <Box className="card-body" theme={theme}>
        <Box theme={theme} className="row" mb={2}>
          <Typography theme={theme} component="h4" variant="h4" children="Trade-In" />
          <Box theme={theme}>{renderInspectionStatus()}</Box>
        </Box>
        <Grid theme={theme} className="container" container>
          <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
            <Typography
              theme={theme}
              variant="body2"
              component="h3"
              className="label"
              children={
                <>
                  {orderDetail?.order_tradein?.year} {orderDetail?.order_tradein?.make}{' '}
                  {orderDetail?.order_tradein?.model}
                </>
              }
            />
            <Typography
              theme={theme}
              variant="subtitle1"
              component="div"
              className="card-title"
              children={orderDetail?.order_tradein?.trim}
              mb={2}
            />
            <Typography
              theme={theme}
              variant="body2"
              component="h3"
              className="label"
              children={
                <>
                  <ToggleCollapse theme={theme} className="toggle-collapse link">
                    <ToggleButton
                      value="check"
                      selected={ToggleCollapseOpen}
                      onChange={() => {
                        ToggleCollapseSetOpen(!ToggleCollapseOpen)
                      }}
                    >
                      <Typography
                        theme={theme}
                        variant="body2"
                        component="span"
                        className="text-primary"
                        children="View More Details"
                      />
                      <Icon name="ChevronDown" />
                    </ToggleButton>
                  </ToggleCollapse>
                </>
              }
            />
          </Grid>
          <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
          {orderDetail?.order_tradein?.asset_image && (
              <ReactCarouselWrap className="react-carousel-wrap" theme={theme}>
                <ResponsiveCarousel
                  responsive={responsive}
                  infinite
                  autoPlay={false}
                  renderDotsOutside>
                  {orderDetail?.order_tradein?.asset_image?.map(
                    (assetImage: string, index: number) => (
                      <Box key={index} theme={theme}>
                        <img src={assetImage ?? ''} alt="Product" className="aspect-ratio" />
                      </Box>
                    )
                  )}
                </ResponsiveCarousel>
              </ReactCarouselWrap>
            )}
          </Grid>
          <Grid theme={theme} item xl={4} lg={4} md={4} sm={4} xs={12}>
            <Box theme={theme} className="priceList">
              <Typography
                theme={theme}
                className="card-title"
                mb={1}
                component="h4"
                variant="h4"
                children={tradeInEquity ? tradeInEquity : '-'}
              />
              <Box theme={theme} className="price-detail">
                <Typography
                  theme={theme}
                  className="detail offer"
                  component="span"
                  variant="caption"
                  children="Our Offer:&nbsp;"
                />
                <Typography
                  theme={theme}
                  className="detail price"
                  component="span"
                  variant="caption"
                  children={
                    orderDetail?.order_tradein?.trade_in_amount
                      ? `${defaultCurrency}${convertToAmountWithOutSign(
                          orderDetail.order_tradein.trade_in_amount
                        )}`
                      : '-'
                  }
                />
              </Box>
              <Box theme={theme} className="price-detail">
                <Typography
                  theme={theme}
                  className="detail offer"
                  component="span"
                  variant="caption"
                  children={<>Lease Balance:&nbsp; </>}
                />
                <Typography
                  theme={theme}
                  className="detail price"
                  component="span"
                  variant="caption"
                  children={
                    orderDetail?.order_tradein?.payoff_amount
                      ? `${defaultCurrency}${convertToAmountWithOutSign(
                          orderDetail.order_tradein.payoff_amount
                        )}`
                      : '-'
                  }
                />
              </Box>
              <Box theme={theme} className="price-detail">
                <Typography
                  theme={theme}
                  className="detail offer"
                  component="span"
                  variant="caption"
                  children="Net Trade-In Amount:&nbsp;"
                />
                <Typography
                  theme={theme}
                  className="detail price"
                  component="span"
                  variant="caption"
                  children={<> {tradeInEquity ? tradeInEquity : '-'} </>}
                />
              </Box>
              <Box theme={theme} className="price-detail">
                <Typography
                  theme={theme}
                  className="detail offer"
                  component="span"
                  variant="caption"
                  children="Estimated Mileage:&nbsp;"
                />
                <Typography
                  theme={theme}
                  className="detail price"
                  component="span"
                  variant="caption"
                  children={
                    orderDetail?.order_tradein?.mileage?.toLocaleString()
                      ? orderDetail?.order_tradein?.mileage?.toLocaleString()
                      : '-'
                  }
                />
              </Box>
              <Box theme={theme} className="price-detail">
                <Typography
                  theme={theme}
                  className="detail offer"
                  component="span"
                  variant="caption"
                  children="Condition:&nbsp;"
                />
                <Typography
                  theme={theme}
                  className="detail price"
                  component="span"
                  variant="caption"
                  children={
                    orderDetail?.order_tradein?.condition
                      ? orderDetail?.order_tradein?.condition
                      : '-'
                  }
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <ToggleCollapseWrap theme={theme} className="toggle-collapse-wrap">
          <Box theme={theme} className={ToggleCollapseOpen ? 'hide-slide-top' : 'show-slide-top'}>
            <Box className="line" theme={theme}>
              <hr />
            </Box>
            <Grid theme={theme} className="container" container rowSpacing={0} columnSpacing={1}>
              <Grid theme={theme} item xs={6}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Contract No./Credit Reference"
                />
              </Grid>
              <Grid theme={theme} item xs={6}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={
                    orderDetail?.order_tradein?.lender_contract_number
                      ? orderDetail?.order_tradein?.lender_contract_number
                      : '-'
                  }
                />
              </Grid>
              {/* **************** */}
              <Grid theme={theme} item xs={6}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Lender Name"
                />
              </Grid>
              <Grid theme={theme} item xs={6}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={
                    orderDetail?.order_tradein?.lender_name
                      ? orderDetail?.order_tradein?.lender_name
                      : '-'
                  }
                />
              </Grid>
              {/* **************** */}
              <Grid theme={theme} item xs={6}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Lender Physical Address"
                />
              </Grid>
              <Grid theme={theme} item xs={6}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={
                    orderDetail?.order_tradein?.lender_address
                      ? orderDetail?.order_tradein?.lender_address
                      : '-'
                  }
                />
              </Grid>
              {/* **************** */}
              <Grid theme={theme} item xs={6}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Lender Phone Number"
                />
              </Grid>
              <Grid theme={theme} item xs={6}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={
                    orderDetail?.order_tradein?.lender_contact_number
                      ? orderDetail?.order_tradein?.lender_contact_number
                      : '-'
                  }
                />
              </Grid>
              {/* **************** */}
              <Grid theme={theme} item xs={6}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="h3"
                  className="label"
                  children="Payoff Amount"
                />
              </Grid>
              <Grid theme={theme} item xs={6}>
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  children={
                    orderDetail?.order_tradein?.payoff_amount
                      ? `${defaultCurrency}${convertToAmountWithOutSign(
                          orderDetail.order_tradein.payoff_amount
                        )}`
                      : '-'
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </ToggleCollapseWrap>
        {/* ************************************************************** */}
        {(orderDetail?.order_tradein?.question_answers ?? []).length > 0 ? (
          <Box theme={theme} mt={2}>
            <Typography
              theme={theme}
              component="h4"
              variant="h4"
              children="Asset Condition Details"
              mb={2}
            />
            {orderDetail?.order_tradein?.question_answers?.map(
              (item: { question: any; answer: any }, index: Key | null | undefined) => (
                <Grid
                  theme={theme}
                  className="container"
                  container
                  rowSpacing={0}
                  columnSpacing={1}
                  key={index}
                >
                  <Grid theme={theme} item xs={6}>
                    <Typography
                      theme={theme}
                      variant="body2"
                      component="h3"
                      className="label"
                      children={item?.question || '-'}
                    />
                  </Grid>
                  <Grid theme={theme} item xs={6}>
                    <Typography
                      theme={theme}
                      variant="body2"
                      component="span"
                      children={item?.answer || '-'}
                    />
                  </Grid>
                </Grid>
              )
            )}
          </Box>
        ) : null}
      </Box>
    </DetailCard>
  )
}

export default TradeInDetails
