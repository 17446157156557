import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const ImgCard: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  width: '100%',
  height: 224,
  backgroundColor: theme.palette.grey[50],
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  borderRadius: 8,

  img: {
    maxWidth: '100%',
    maxHeight: 224,
    display: 'table',
    margin: 'auto'
  },
  '.notImage': {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center'
  }
}))

export const NoPreviewImage: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '.image-card': {
    textAlign: 'center',
    '.image-box': {
      backgroundColor: theme.palette.grey[50],
      borderRadius: theme.shape.borderRadius,
      height: '224px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 16,
      '.image-response': {
        width: '100%',
        height: '224px',
        objectFit: 'contain'
      },
      '&.image-show': {
        // backgroundColor: "transparent",
      },
      '&.image-hide': {
        backgroundColor: theme.palette.grey[50]
      }
    },
    p: {
      textAlign: 'center',
      color: theme.palette.grey[900],
      fontWeight: theme.typography.fontWeightMedium
    },
    '&.disabled': {
      opacity: 0.7,
      cursor: 'not-allowed'
    }
  }
}))
