import { useTheme } from '@mui/material'
import { Avatar, IAvatarProps } from '@ntpkunity/controls'
import { FC } from 'react'

const Component: FC<IAvatarProps> = (props) => {
  const theme = useTheme()

  return <Avatar {...props} theme={theme} />
}

export default Component
