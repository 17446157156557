import { FC } from 'react'
import { useTheme } from '@mui/material'
import { ScrollableTabs } from '@ntpkunity/controls'
import VehicleAmountDraft from './vehicle-amount-draft'
import { ScrollableTabsWrap } from '@components'
import AddOpptions, { TabName } from './add-options'

const OrderDetails: FC = () => {
  const theme = useTheme()
  return (
    <>
      <VehicleAmountDraft />
      <ScrollableTabsWrap theme={theme} className="scrollable-tabs-wrap sm">
        <ScrollableTabs
          theme={theme}
          items={[
            { title: 'F&I', content: <AddOpptions tabName={TabName.FNI} /> },
            { title: 'Options', content: <AddOpptions tabName={TabName.OPTIONS} /> },
            { title: 'Fees', content: <AddOpptions tabName={TabName.FEES} /> },
            { title: 'Maint. & Service Products', content: <AddOpptions tabName={TabName.MSP} /> }
            // { title: 'Rebates', content: <AddOpptions tabName={"rebates"} /> }
          ]}
          defaultTabIndex={0}
          scrollButtons={'auto'}
          onChange={() => {}}
        />
      </ScrollableTabsWrap>
    </>
  )
}

export default OrderDetails
