import { FC } from 'react'
import { styled, useTheme } from '@mui/material'
import { Typography, Box, Icon, IBoxProps } from '@ntpkunity/controls'

const NotFoundWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({}) => ({
  '&.result-not-found': {
    height: 'calc(100vh - 178px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    svg: {
      width: '100%',
      height: 'calc(100vh - 420px)'
    }
  },
  '@media screen and (max-width: 525px)': {
    '&.result-not-found': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 'auto',
      svg: {
        width: '100%',
        height: 'auto'
      }
    }
  }
}))

const NotInventory: FC = () => {
  const theme = useTheme()
  return (
    <>
      <NotFoundWrap theme={theme} className="result-not-found">
        <Box theme={theme}>
          <Icon name="NoInventoyIcon" />
          <Typography theme={theme} variant="h3" component="h3" sx={{ mt: 4, mb: 1 }}>
            Looks like there is nothing in your inventory!
          </Typography>
          <Typography theme={theme} variant="body2" component="p">
            Please add some assets in your inventory to proceed with the deal.
          </Typography>
        </Box>
      </NotFoundWrap>
    </>
  )
}

export default NotInventory
