import { useTheme } from '@mui/material'
import { IInputProps, Input } from '@ntpkunity/controls'
import { FC } from 'react'

const Component: FC<Partial<IInputProps>> = ({
  fullWidth,
  type,
  startAdornment,
  endAdornment,
  error,
  inputClass,
  formControlClass,
  inputWrapperSx,
  formControlSx,
  wrapperClass,
  InputSx,
  InputLableSx,
  errorSx,
  helperSx,
  label,
  onChange,
  variant,
  id,
  onBlur,
  helperText,
  placeholder,
  ...props
}) => {
  const theme = useTheme()
  const typographySx = { fontFamily: theme.typography.fontFamily }

  return (
    <Input
      theme={theme}
      fullWidth={fullWidth as boolean}
      type={type as string}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      error={error}
      inputClass={inputClass}
      formControlClass={formControlClass}
      inputWrapperSx={{ ...inputWrapperSx, ...typographySx }}
      formControlSx={{ ...formControlSx, ...typographySx }}
      wrapperClass={wrapperClass}
      InputSx={{ ...InputSx, ...typographySx }}
      InputLableSx={{ ...InputLableSx, ...typographySx }}
      errorSx={{ ...errorSx, ...typographySx }}
      helperSx={{ ...helperSx, ...typographySx }}
      label={label}
      onChange={onChange}
      variant={variant}
      id={id ?? 'span'}
      onBlur={onBlur}
      helperText={helperText}
      placeholder={placeholder}
      {...props}
    />
  )
}

export default Component
