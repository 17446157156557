import { FC } from 'react'
import { useTheme } from '@mui/material'

import { LayoutWithSideNav } from '@styles/layout'

const CoBrowsing: FC = () => {
  const theme = useTheme()

  return (
    <LayoutWithSideNav theme={theme}>
      <iframe
        width="100%"
        height="650"
        title="upscope"
        style={{ border: '1px solid #EBECF2', borderRadius: '8px' }}
        src="https://app.upscope.io/embed/"
      ></iframe>
    </LayoutWithSideNav>
  )
}

export default CoBrowsing
