import { FC } from 'react'
import { Controller } from 'react-hook-form'
import { IBoxProps } from '@ntpkunity/controls'
import { Grid, Box, TextBox } from 'components'
import { styled } from '@mui/material/styles'

const TabWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(() => ({
  '&.tab-wrap': {
    width: '100%',
    display: 'inline-block',

    hr: {
      opacity: 0.4
    },

    '.title-icon': {
      width: '100%',
      display: 'inline-block',
      'span, Icon': {
        float: 'left'
      },
      span: {
        marginRight: 10
      }
    },
    '.buttons-row': {
      display: 'inline-block',
      textAlign: 'center',
      width: '100%',

      Button: {
        margin: '0 20px 20px 0',

        '&:last-child': {
          marginRight: 0
        }
      }
    }
  }
}))

const Payment: FC<{
  form: any
}> = ({ form }) => {
  const {
    control,
    formState: { errors }
  } = form

  return (
    <TabWrap className="tab-wrap">
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mb: 2.7 }}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="payment.bank_account_limit"
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <TextBox
                label="Bank Account Limit"
                {...field}
                value={field.value ? parseFloat(field.value) : undefined}
                type="text"
                startAdornment="$"
                masking
                scale={2}
                numeric
                error={errors?.payment?.bank_account_limit?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="payment.credit_card_limit"
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <TextBox
                label="Credit Card Limit"
                type="text"
                {...field}
                value={field.value ? parseFloat(field.value) : undefined}
                startAdornment="$"
                masking
                scale={2}
                numeric
                error={errors?.payment?.credit_card_limit?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="payment.debit_card_limit"
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <TextBox
                label="Debit Card Limit"
                {...field}
                value={field.value ? parseFloat(field.value) : undefined}
                type="text"
                startAdornment="$"
                masking
                scale={2}
                numeric
                error={errors?.payment?.debit_card_limit?.message}
              />
            )}
          />
        </Grid>
      </Grid>
    </TabWrap>
  )
}
export default Payment
