import { Theme } from '@mui/system'
import {
  AddOns,
  IDealerFee,
  IInventory,
  IPaginatedResponse,
  IPaginatedResponse_AddOns,
  IToast,
  IFinancialInsurance,
  IOffer,
  // IOrderWorkQueue,
  IWorkQueueOrderReturn,
  ICustomerLicense,
  IOdometer,
  IContractTerms,
  IUserProfile,
  IStipStoreState,
  IWorkqueueOrdersData
} from '@models'
import { DealDeskingState } from './ducks/desking'

export default interface IActions {
  payload: unknown
  type: string
}

export interface IThemeConfig {
  theme: Theme
}

export interface IBrowserStorage {
  localStorage: any
  sessionStorage: any
}

// TODO: add relevant types
export type StoreContextType = {
  actions: {
    setTheme: (data: 'default' | 'dark') => unknown
    setSessionStorage: (data: string) => void
    setLocalStorage: (data: string) => void
    setAssetInventoryInitialData: (data: IPaginatedResponse) => void
    updateInventoryRow: (data: IInventory) => void
    setToast: (data: IToast) => void
    setAddonInitialData: (data: IPaginatedResponse_AddOns) => void
    updateAddonRow: (data: AddOns) => void
    deleteAddonRow: (data: AddOns) => void
    addAddonRow: (data: AddOns) => void
    setDealerFeeInitialData: (data: IDealerFee[]) => void
    deleteDealerFeeRow: (data: IDealerFee) => void
    updateDealerFeeRow: (data: IDealerFee) => void
    addDealerFeeRow: (data: IDealerFee) => void
    setFinancialInsuranceProductData: (data: any) => void
    setFinancialInsuranceInitialData: (data: IFinancialInsurance[]) => void
    addFinancialInsuranceRow: (data: IFinancialInsurance) => void
    updateFinancialInsuranceRow: (data: IFinancialInsurance) => void
    deleteFinancialInsuranceRow: (data: IFinancialInsurance) => void
    setOfferInitialData: (data: IOffer) => void
    setStipulationsInitialData: (data: IStipStoreState) => void
    setCustomerData: (data: any) => void
    setCalculationData: (data: any) => void
    setDealerPreferenceData: (data: any) => void
    setDealerProfileData: (data: any) => void
    setTradeInEquityData: (data: any) => void
    setSelectedOrderOptions: (data: any) => void
    setDealerVehicleAvailableConfirmationOrders: (data: any) => void
    setDealerGetReadyCheckList: (data: any) => void
    setDealerSchedulePickups: (data: any) => void
    setDealerReturnVehicle: (data: any) => void
    setDealerCompletedOrders: (data: any) => void
    setDealerCancelledOrders: (data: any) => void
    setDealerSelectedOrderData: (data: any) => void
    setInsuranceInformation: (data: any) => void
    setCustomerLicenseInformation: (data: ICustomerLicense) => void
    setCustomerInformation: (data: any) => void
    setDealerInfo: (data: any) => void
    setDealerAndLenderInfo: (data: any) => void
    setLenderInfo: (data: any) => void
    setOdometerData: (data: any) => void
    setDealerSelectedReturnData: (data: any) => void
    setContractTermsData: (data: IContractTerms[]) => void
    setCreditRatingData: (data: any[]) => void
    setUserProfile: (data: IUserProfile[]) => void
    setSelectedANDealer: (data: any) => void
    setQuotationDefaultParam: (data: any) => void
    setMakeModelTrimData: (data: any) => void
    setStateCode: (data: string) => void
    setDefaultCurrency: (data: any) => void
    setContractTermsAndOptions: (data: any[]) => void
    setCreditTerms: (data: any[]) => void
    setLendersData: (data: any[]) => void
    setAllowedMilleageAndOptions: (data: any[]) => void
    setDealerProfile: (data: any) => void
    setDealerPreferences: (data: any) => void
    setDealerFees: (data: any[]) => void
    setRecalculation: (data: boolean) => void
  }
  states: {
    theme: IThemeConfig | null
    localStorage: any
    sessionStorage: any
    inventoryData: IPaginatedResponse
    toastData: IToast
    customerData: any
    calculationData: any
    dealerProfileData: any
    tradeInEquityData: any
    selectedOrderOptions: any
    vehicleAvailableConfirmationOrders: IWorkqueueOrdersData
    vehicleGetReadyCheckListOrders: IWorkqueueOrdersData
    vehicleSchedulePickupsData: IWorkqueueOrdersData
    vehicleReturnData: IWorkQueueOrderReturn[]
    dealerSelectedReturnData: any
    vehicleCompletedOrdersData: IWorkqueueOrdersData
    vehicleCancelledOrdersData: IWorkqueueOrdersData
    odometerData: IOdometer
    dealerSelectedOrderData: any
    dealerAndLenderInfo: any
    lenderInfo: any
    dealerInfo: any
    licenseInformation: any //ICustomerLicense;
    insuranceInformation: any //IInsuranceInformation;
    customerInformation: any
    addonData: IPaginatedResponse_AddOns
    dealerFeeData: IDealerFee[]
    // dealerTradeInData: IDealerTradeIn[];
    dealerPreferenceData: any
    financialInsuranceProductData: any
    financialInsuranceInitialData: IFinancialInsurance[]
    orderData: IOffer
    contractTermsData: IContractTerms[]
    creditRatingData: any[]
    lendersData: any[]
    userProfile: any
    stipulationsData: IStipStoreState
    selectedANDealer: any
    quotationDefaultParams: any
    makeModelTrim: any
    dealDesk: DealDeskingState
  }
}

// TODO: add relevant default values
export const defaultStoreValues = {
  states: {
    theme: null,
    sessionStorage: null,
    localStorage: null,
    inventoryData: { pageNum: 0, pageSize: 0, result: [], totalResults: 0 },
    addonData: { pageNum: 0, pageSize: 0, result: [], totalResults: 0 },
    customerData: {},
    calculationData: {},
    dealerPreferenceData: {},
    selectedOrderOptions: [],
    vehicleAvailableConfirmationOrders: {
      result: [],
      page_number: 0,
      page_size: 0,
      total_results: 0,
      total_pages: 0
    },
    vehicleGetReadyCheckListOrders: {
      result: [],
      page_number: 0,
      page_size: 0,
      total_results: 0,
      total_pages: 0
    },
    dealerAndLenderInfo: {},
    dealDesk: {},
    lenderInfo: {},
    dealerInfo: {},
    vehicleSchedulePickupsData: {
      result: [],
      page_number: 0,
      page_size: 0,
      total_results: 0,
      total_pages: 0
    },
    vehicleReturnData: [],
    vehicleCompletedOrdersData: {
      result: [],
      page_number: 0,
      page_size: 0,
      total_results: 0,
      total_pages: 0
    },
    vehicleCancelledOrdersData: {
      result: [],
      page_number: 0,
      page_size: 0,
      total_results: 0,
      total_pages: 0
    },
    dealerSelectedOrderData: {},
    dealerSelectedReturnData: {},
    selectedANDealer: {},
    odometerData: {
      countries: [],
      default_currency: null,
      email: '',
      id: 0,
      initials: '',
      is_active: false,
      is_deleted: false,
      key_id: null,
      mileage_unit: null,
      minimum_financed_amount: null,
      name: '',
      odometer_variance: null,
      preferred_currency_ids: '',
      tax_id: '',
      trading_name: '',
      url: ''
    },
    insuranceInformation: {},
    customerInformation: {},
    licenseInformation: {},
    toastData: {
      toastMessage: '',
      toastState: false,
      toastLength: undefined,
      variant: undefined
    },
    tradeInEquityData: {},
    dealerProfileData: {},
    dealerFeeData: [],
    financialInsuranceInitialData: [],
    financialInsuranceProductData: [],
    orderData: {
      selected_vehicle: undefined,
      general_prefernece: undefined,
      order_fees: [],
      order_options: [],
      order_fnI: [],
      order_tradein: undefined
    },
    contractTermsData: [],
    creditRatingData: [],
    lendersData: [],
    userProfile: [],
    stipulationsData: {
      stipulations: [],
      net_finance_amount: 0,
      monthly_payment: 0,
      terms: 0
    },
    quotationDefaultParams: {},
    makeModelTrim: {
      asset_make_id: undefined,
      asset_trim_id: undefined,
      asset_model_id: undefined
    }
  },
  actions: {
    setTheme: (): void => undefined,
    setSessionStorage: (): void => undefined,
    setLocalStorage: (): void => undefined,
    setAssetInventoryInitialData: (): void => undefined,
    updateInventoryRow: (): void => undefined,
    setToast: (): void => undefined,
    setAddonInitialData: (): void => undefined,
    updateAddonRow: (): void => undefined,
    deleteAddonRow: (): void => undefined,
    addAddonRow: (): void => undefined,
    setDealerFeeInitialData: (): void => undefined,
    deleteDealerFeeRow: (): void => undefined,
    updateDealerFeeRow: (): void => undefined,
    addDealerFeeRow: (): void => undefined,
    setFinancialInsuranceProductData: (): void => undefined,
    setFinancialInsuranceInitialData: (): void => undefined,
    addFinancialInsuranceRow: (): void => undefined,
    updateFinancialInsuranceRow: (): void => undefined,
    deleteFinancialInsuranceRow: (): void => undefined,
    setOfferInitialData: (): void => undefined,
    setStipulationsInitialData: (): void => undefined,
    setCustomerData: (): void => undefined,
    setCalculationData: (): void => undefined,
    setDealerPreferenceData: (): void => undefined,
    setDealerProfileData: (): void => undefined,
    setTradeInEquityData: (): void => undefined,
    setSelectedOrderOptions: (): void => undefined,
    setDealerVehicleAvailableConfirmationOrders: (): void => undefined,
    setDealerGetReadyCheckList: (): void => undefined,
    setDealerSchedulePickups: (): void => undefined,
    setDealerReturnVehicle: (): void => undefined,
    setDealerCompletedOrders: (): void => undefined,
    setDealerCancelledOrders: (): void => undefined,
    setDealerSelectedOrderData: (): void => undefined,
    setInsuranceInformation: (): void => undefined,
    setCustomerLicenseInformation: (): void => undefined,
    setCustomerInformation: (): void => undefined,
    setDealerAndLenderInfo: (): void => undefined,
    setDealerInfo: (): void => undefined,
    setLenderInfo: (): void => undefined,
    setOdometerData: (): void => undefined,
    setDealerSelectedReturnData: (): void => undefined,
    setContractTermsData: (): void => undefined,
    setCreditRatingData: (): void => undefined,
    setUserProfile: () => undefined,
    setSelectedANDealer: () => undefined,
    setQuotationDefaultParam: () => undefined,
    setMakeModelTrimData: () => undefined,
    setStateCode: (): void => undefined,
    setDefaultCurrency: (): void => undefined,
    setContractTermsAndOptions: (): void => undefined,
    setCreditTerms: (): void => undefined,
    setLendersData: (): void => undefined,
    setAllowedMilleageAndOptions: (): void => undefined,
    setDealerProfile: (): void => undefined,
    setDealerPreferences: (): void => undefined,
    setDealerFees: (): void => undefined,
    setRecalculation: (): void => undefined
  }
}
