import { Grid, Typography } from '@ntpkunity/controls'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
export const LayoutWithBreadCrumbs: FC<{
  theme: any
  breadcrumbs: { item: string; link: string }[]
}> = ({ theme, breadcrumbs }) => {
  const navigate = useNavigate()
  const handleClick = (link: string) => {
    navigate(link)
  }
  return (
    <Grid theme={theme} direction="row" container>
      <Grid theme={theme} item xs={6} md={6} className="breadcrumbs">
        {breadcrumbs?.map((item, key: any) => {
          return (
            <Typography
              key={key}
              theme={theme}
              component="span"
              variant="body2"
              sx={{
                fontWeight: breadcrumbs.length - 1 === key ? 600 : '',
                cursor: 'pointer'
              }}
              children={item.item}
              onClick={() => {
                item.link && handleClick(item.link)
              }}
            />
          )
        })}
      </Grid>
    </Grid>
  )
}
