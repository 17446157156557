import { useTheme } from '@mui/material'
import { IButtonProps, Button } from '@ntpkunity/controls'
import { FC } from 'react'

const Component: FC<Partial<IButtonProps>> = (props) => {
  const theme = useTheme()

  return <Button theme={theme} {...props} />
}

export default Component
