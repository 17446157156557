import { IContractTerms } from '@models'
import IActions from '../configs'
import { Dispatch, ReducerAction } from 'react'

export const SET_CONTRACT_TEMRS_INIT_STATE = 'Set Terms Data'

export const initialState: IContractTerms[] = []

const contractTermsRreducer = (
  state: typeof initialState,
  action: IActions
): typeof initialState => {
  switch (action.type) {
    case SET_CONTRACT_TEMRS_INIT_STATE:
      return action.payload as typeof initialState
  }
  return state
}

export const setContractTermsData = (
  data: typeof initialState,
  dispatch: Dispatch<ReducerAction<typeof contractTermsRreducer>>
): void => {
  if (data) {
    dispatch({ type: SET_CONTRACT_TEMRS_INIT_STATE, payload: data })
  }
}

export default contractTermsRreducer
