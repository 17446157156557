import { FC } from 'react'
import { useTheme } from '@mui/material'
import { Box, Typography, Button } from '@ntpkunity/controls'
import { styled } from '@mui/material/styles'
import Img from '../../public/assets/images/thinker.svg'
import { useNavigate } from 'react-router-dom'

const CreateNewOrderWrap = styled('div')(({ theme }) => ({
  '.u-steps-header': {
    '.steps-wrap': {
      marginRight: -8,
      '.action-area': {
        display: 'none !important'
      }
    }
  },
  '.status-wrap': {
    textAlign: 'center',
    padding: '5vh 0',
    margin: 'auto',
    maxWidth: 520,

    img: {
      marginBottom: 33,
      height: '35vh'
    },

    p: {
      color: theme.palette.grey[500]
    }
  }
}))

const NoCalculationData: FC = () => {
  const theme = useTheme()
  let navigate = useNavigate()

  return (
    <>
      <CreateNewOrderWrap theme={theme}>
        <Box theme={theme} sx={{ mb: 3 }} className="status-wrap">
          <img src={Img} alt="Looks like there is no quote added here!" />
          <Typography
            theme={theme}
            component="h3"
            variant="h3"
            children="Looks like there is no quote added here!"
            sx={{ mb: 1 }}
          />
          <Typography
            theme={theme}
            component="p"
            variant="body2"
            sx={{ mb: 2.5 }}
            children="The vehicle you are looking for does not have any quotation added to it. Please look for some other vehicle to proceed with the purchase."
          />
          <Button
            theme={theme}
            primary
            text="Select Another Vehicle"
            onClick={() => navigate('/dms/create-deal')}
          />
        </Box>
      </CreateNewOrderWrap>
    </>
  )
}

export default NoCalculationData
