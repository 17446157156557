import { styled } from '@mui/material/styles'

export const SliderWrap = styled('div')(({ theme }) => ({
  '&.slider-wrap': {
    '.slider-title': {
      marginBottom: 8,
      display: 'block',
      fontWeight: theme.typography.fontWeightBold
    },
    '.slider-values': {
      fontFamily: theme.typography.fontFamily,
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 18,
      fontSize: theme.typography.caption.fontSize,
      lineHeight: theme.typography.caption.lineHeight,
      color: theme.palette.grey[800]
    },
    '.range-slider': {
      height: 8,
      borderRadius: 4,
      padding: 0,
      marginTop: -10,
      '.MuiSlider-root': {
        width: '93%',
        margin: 'auto 13px',
        display: 'table'
      },
      '.MuiSlider-rail': {
        height: 8,
        backgroundColor: theme.palette.grey[100]
      },
      '.MuiSlider-track': {
        height: 8,
        backgroundColor: theme.palette.primary.main
      },
      '.MuiSlider-thumb': {
        width: 24,
        height: 24,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)',
        '&::before': {
          width: 8,
          height: 8,
          boxShadow: 'none',
          backgroundColor: theme.palette.common.white,
          zIndex: 1
        },
        '&::after': {
          width: 24,
          height: 24,
          backgroundColor: theme.palette.primary.main
        },
        '&:hover': {
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16) !important'
        },
        '.MuiSlider-valueLabel': {
          display: 'none'
        }
      }
    }
  }
}))
