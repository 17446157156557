import { FC, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTheme } from '@mui/material'
import { Select, IBoxProps } from '@ntpkunity/controls'
import { Grid, Box, TextBox } from 'components'
import {} from '@models'
import { styled } from '@mui/material/styles'

const TabWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(() => ({
  '&.tab-wrap': {
    width: '100%',
    display: 'inline-block',

    hr: {
      opacity: 0.4
    },

    '.title-icon': {
      width: '100%',
      display: 'inline-block',
      'span, Icon': {
        float: 'left'
      },
      span: {
        marginRight: 10
      }
    },
    '.buttons-row': {
      display: 'inline-block',
      textAlign: 'center',
      width: '100%',

      Button: {
        margin: '0 20px 20px 0',

        '&:last-child': {
          marginRight: 0
        }
      }
    }
  }
}))

const DealerTradeIn: FC<{
  form: any
}> = ({ form }) => {
  const theme = useTheme()

  const [partner] = useState([
    {
      text: 'KBB',
      value: 1
    }
  ])

  const [applyValidation, setApplyValidation] = useState<boolean>(false)

  const {
    control,
    formState: { errors },
    getValues,
    watch
  } = form
  function setError(): boolean {
    let status = false
    if (getValues('tradein.partner_name') == null && getValues('tradein.percentage') == null) {
      status = false
    } else {
      status = true
    }
    return status
  }
  return (
    <TabWrap className="tab-wrap">
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mb: 2.7 }}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="tradein.partner_name"
            control={control}
            defaultValue={''}
            rules={{
              required: {
                value: applyValidation,
                message: 'Provider is required'
              }
            }}
            render={({ field: { onChange, value } }) => (
              <Select
                theme={theme}
                label={'Provider'}
                items={partner}
                value={value}
                onChange={(e) => {
                  onChange(e)
                  setApplyValidation(setError)
                }}
                sxProps={''}
                selectError={errors?.tradein?.partner_name?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="tradein.percentage"
            control={control}
            render={({ field }) => (
              <TextBox
                label="Percentage"
                type="text"
                {...field}
                value={field.value ? parseFloat(field.value) : undefined}
                endAdornment="%"
                masking
                scale={2}
                numeric
                error={errors?.tradein?.percentage?.message}
                disabled={!watch('tradein.partner_name')}
              />
            )}
          />
        </Grid>
      </Grid>
    </TabWrap>
  )
}
export default DealerTradeIn
