import { useTheme } from '@mui/material'
import { Box, Button, Textarea } from '@ntpkunity/controls'
import { Controller, useForm, SubmitHandler } from 'react-hook-form'
import ChatBoxWrap from './ChatBox.style'
import { useChatServiceContext } from '@hooks/useChatService'

type MessageForm = {
  content: string
}

const ChatBox = () => {
  const theme = useTheme()
  const { sendMessage } = useChatServiceContext()
  const { control, handleSubmit, reset, watch } = useForm<MessageForm>()

  const onSubmit: SubmitHandler<MessageForm> = (values) => {
    sendMessage(values.content.replace(/[\n\r]/gm, '').trim())
    reset()
  }

  const messageContent = watch('content')

  return (
    <ChatBoxWrap theme={theme} className="chat-box">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="content"
          control={control}
          render={({ field }) => (
            <Textarea
              id="message-content"
              theme={theme}
              fullWidth
              type="text"
              rows={2}
              placeholder="Message"
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.preventDefault()
                  handleSubmit(onSubmit)()
                }
              }}
              {...field}
            />
          )}
        />
        <Box theme={theme} className="inner-btn-wrap">
          <Button
            primary
            theme={theme}
            text="Send"
            type="submit"
            className="main-btn"
            disabled={!messageContent}
          />
        </Box>
      </form>
    </ChatBoxWrap>
  )
}

export default ChatBox
