import { FC, useEffect } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { ITable, Icon, unityTheme, Menu } from '@ntpkunity/controls'
import { styled } from '@mui/material/styles'
import {
  useAddonsAccessories,
  useDeleteAddonById,
  useGetAddonById,
  useUpdateAddonById
} from '@apis/dealer-addon.service'
import { Checkbox, Button, Input, Box, Switch } from 'components'

import { AddOns } from '@models'
import { Vehicle_Type } from '@helpers/enums'
import { useStoreContext } from '@store/storeContext'
import { getUser } from '@helpers/methods'

const CustomTable = styled(
  'div',
  {}
)<Partial<ITable>>(({ theme }) => ({
  '&.u-table-wrap': {
    '.u-table-container': {
      boxShadow:
        '0px 0px 0px 0px transparent, 0px 0px 0px 0px transparent, 0px 0px 0px 0px transparent',
      border: '1px solid',
      borderColor: theme.palette.divider,
      overflow: 'auto',
      maxHeight: 'calc(100vh - 220px)',
      [theme.breakpoints.up('xl')]: { maxHeight: 'calc(100vh - 200px)' },
      scrollbarWidth: 'thin',
      '&::-webkit-scrollbar': {
        width: 7,
        height: 7
      },
      '&::-webkit-scrollbar-track': {
        padding: '0 1px',
        backgroundColor: '#f0f0f0'
      },
      '&::-webkit-scrollbar-thumb': {
        transition: 'all 0.3s',
        backgroundColor: '#cdcdcd'
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#a6a6a6'
      }
    },
    '.u-table': {
      borderCollapse: 'inherit',
      tableLayout: 'auto',
      width: '100%',
      '.u-table-head': {
        position: 'sticky',
        top: 0,
        zIndex: 1,
        th: {
          fontWeight: theme.customVariables.fontWeightSemiBold,
          color: theme.palette.grey[900],
          lineHeight: theme.typography.subtitle2.lineHeight,
          padding: '12px 4px',
          borderColor: theme.palette.divider,
          backgroundColor: theme.palette.common.white,
          minWidth: 190,

          '&.checkbox-cell': {
            display: 'none',
            padding: 12,
            width: 55
          },
          '&.action-cell': {
            padding: 12,
            width: 24,
            minWidth: 24,
            '&.fixed-cell': {
              position: 'sticky',
              right: 0
            }
          },
          '&.img-cell': {
            width: 80,
            padding: '4px 4px 4px 15px',
            textAlign: 'center',
            lineHeight: 0
          }
        },
        '.filters-row': {
          th: {
            padding: 4
          },
          '.table-filter': {
            display: 'flex',
            alignItems: 'center',
            '.u-form-group': {
              flexGrow: 1,
              margin: 0,
              '.u-form-control': {
                height: 36,
                borderRadius: 4,
                '.MuiInputBase-input': {
                  padding: '8px 12px',
                  '&::placeholder': {
                    opacity: 0.26
                  }
                }
              }
            },
            '.btn': {
              padding: 0,
              marginLeft: 8
            }
          }
        }
      },
      '.u-table-body': {
        tr: {
          td: {
            backgroundColor: 'transparent',
            color: theme.palette.grey[600],
            lineHeight: theme.typography.subtitle2.lineHeight,
            padding: '2px 4px',
            borderTop: '1px solid',
            borderTopColor: theme.palette.divider,
            borderBottom: 'none',
            maxWidth: 190,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',

            '.thumb': {
              height: 40
            },

            '&.checkbox-cell': {
              display: 'none',
              padding: 12,
              width: 24
            },
            '&.action-cell': {
              padding: 12,
              width: 24,
              minWidth: 24,
              '&.fixed-cell': {
                position: 'sticky',
                right: 0
              }
            },
            '&.img-cell': {
              width: 80,
              padding: '4px 4px 4px 15px',
              textAlign: 'center',
              lineHeight: 0,
              minWidth: 80
            },
            '&.indent-cell': {
              width: '30%',
              minWidth: '30%',
              maxWidth: '30%'
            }
          },
          '&:nth-child(even)': {
            td: {
              backgroundColor: theme.palette.common.white
            }
          },
          '&:nth-child(odd)': {
            td: {
              backgroundColor: theme.customVariables.tableStripedBg
            }
          },
          '&.child-tr': {
            '&:hover': {
              td: {
                backgroundColor: theme.customVariables.tableHoverBg
              }
            }
          },
          '&:first-child': {
            td: {
              borderTopColor: 'transparent'
            }
          }
        }
      },
      '.toggle-btn': {
        cursor: 'pointer',
        verticalAlign: -6,
        marginRight: 8
      },
      '.checkbox-cell': {
        display: 'none',
        width: 24,
        '.custom-checkbox-wrap': {
          width: 24,
          height: 24,
          margin: 'auto',
          '.u-custom-control-label': {
            marginBottom: 0
          }
        }
      },
      '.action-cell': {
        '.btn': {
          padding: 0
        }
      },
      '.img-cell': {
        '.prd-img': {
          maxHeight: 40,
          maxWidth: '100%'
        }
      }
    },
    '&.basic-table': {
      display: 'grid'
    },
    '.fixed-left': {
      position: 'sticky',
      left: 0
    },
    '.fixed-right': {
      position: 'sticky',
      right: 0
    },
    '.status': {
      width: 8,
      height: 8,
      borderRadius: 8,
      marginRight: 8,
      display: 'inline-block',

      '&.inquiry': {
        backgroundColor: '#FF3333'
      },
      '&.available': {
        backgroundColor: '#33CC59'
      },
      '&.in-contract': {
        backgroundColor: '#007BFF'
      }
    }
  }
}))

const AddOnsTable: FC<{ onEdit: (data: any) => unknown }> = ({ onEdit }) => {
  const { states, actions } = useStoreContext()
  const { mutate: getAddonAccessories } = useAddonsAccessories()
  const { mutate: updateDealerAddon } = useUpdateAddonById()
  const { mutate: deleteDealerAddon } = useDeleteAddonById()
  const { mutate: getAddonById } = useGetAddonById()
  const dealerData = getUser()

  useEffect(() => {
    getAddonAccessories({
      data: `dealer_code=${dealerData.dealer_code}&page_number=0&page_size=10`
    })
  }, [])
  return (
    <CustomTable className="u-table-wrap basic-table" theme={unityTheme}>
      <TableContainer className="u-table-container" component={Paper}>
        <Table stickyHeader className="u-table">
          <TableHead className="u-table-head">
            <TableRow>
              <TableCell className="checkbox-cell fixed-left">
                <Checkbox label=""></Checkbox>
              </TableCell>
              <TableCell align="center" valign="middle" className="img-cell">
                <img src={require('../../public/assets/images/unknown.svg')} alt="Car" />
              </TableCell>
              <TableCell>Product Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Catergory</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Vehicle Condition</TableCell>
              <TableCell>Offered By</TableCell>
              <TableCell>Supplier</TableCell>
              <TableCell>Part No.</TableCell>
              <TableCell>Installation Mode</TableCell>
              <TableCell>Compatible Trims</TableCell>
              <TableCell>Price</TableCell>
              <TableCell> RV On Option</TableCell>
              <TableCell className="action-cell fixed-cell"></TableCell>
            </TableRow>
            <TableRow className="filters-row">
              <TableCell className="checkbox-cell"></TableCell>
              <TableCell className="img-cell"></TableCell>
              <TableCell>
                <Box theme={unityTheme} className="table-filter">
                  <Input
                    theme={unityTheme}
                    fullWidth={true}
                    placeholder={'Search...'}
                    type="text"
                  />
                  <Button defaultBtn iconText={<Icon name="IconFilter" />}></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={unityTheme} className="table-filter">
                  <Input
                    theme={unityTheme}
                    fullWidth={true}
                    placeholder={'Search...'}
                    type="text"
                  ></Input>
                  <Button defaultBtn iconText={<Icon name="IconFilter" />}></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={unityTheme} className="table-filter">
                  <Input
                    theme={unityTheme}
                    fullWidth={true}
                    placeholder={'Search...'}
                    type="text"
                  ></Input>
                  <Button defaultBtn iconText={<Icon name="IconFilter" />}></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={unityTheme} className="table-filter">
                  <Input
                    theme={unityTheme}
                    fullWidth={true}
                    placeholder={'Search...'}
                    type="text"
                  ></Input>
                  <Button defaultBtn iconText={<Icon name="IconFilter" />}></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={unityTheme} className="table-filter">
                  <Input
                    theme={unityTheme}
                    fullWidth={true}
                    placeholder={'Search...'}
                    type="text"
                  ></Input>
                  <Button defaultBtn iconText={<Icon name="IconFilter" />}></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={unityTheme} className="table-filter">
                  <Input
                    theme={unityTheme}
                    fullWidth={true}
                    placeholder={'Search...'}
                    type="text"
                  ></Input>
                  <Button defaultBtn iconText={<Icon name="IconFilter" />}></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={unityTheme} className="table-filter">
                  <Input
                    theme={unityTheme}
                    fullWidth={true}
                    placeholder={'Search...'}
                    type="text"
                  ></Input>
                  <Button defaultBtn iconText={<Icon name="IconFilter" />}></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={unityTheme} className="table-filter">
                  <Input
                    theme={unityTheme}
                    fullWidth={true}
                    placeholder={'Search...'}
                    type="text"
                  ></Input>
                  <Button defaultBtn iconText={<Icon name="IconFilter" />}></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={unityTheme} className="table-filter">
                  <Input
                    theme={unityTheme}
                    fullWidth={true}
                    placeholder={'Search...'}
                    type="text"
                  ></Input>
                  <Button defaultBtn iconText={<Icon name="IconFilter" />}></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={unityTheme} className="table-filter">
                  <Input
                    theme={unityTheme}
                    fullWidth={true}
                    placeholder={'Search...'}
                    type="text"
                  ></Input>
                  <Button defaultBtn iconText={<Icon name="IconFilter" />}></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={unityTheme} className="table-filter">
                  <Input
                    theme={unityTheme}
                    fullWidth={true}
                    placeholder={'Search...'}
                    type="text"
                  ></Input>
                  <Button defaultBtn iconText={<Icon name="IconFilter" />}></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={unityTheme} className="table-filter">
                  <Input
                    theme={unityTheme}
                    fullWidth={true}
                    placeholder={'Search...'}
                    type="text"
                  ></Input>
                  <Button defaultBtn iconText={<Icon name="IconFilter" />}></Button>
                </Box>
              </TableCell>
              <TableCell className="action-cell fixed-cell"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="u-table-body">
            {states.addonData.result?.map((addOnDetail: AddOns, index: number) => {
              return (
                <TableRow key={index} className="child-tr">
                  <TableCell className="checkbox-cell fixed-left">
                    <Checkbox label=""></Checkbox>
                  </TableCell>
                  <TableCell className="img-cell">
                    <img className="prd-img" src={addOnDetail?.image} alt="Car" />
                  </TableCell>
                  <TableCell>{addOnDetail?.product_name}</TableCell>
                  <TableCell>{addOnDetail?.description}</TableCell>
                  <TableCell>{addOnDetail?.category}</TableCell>

                  <TableCell>
                    <Switch
                      theme={unityTheme}
                      varient={'basic'}
                      label={addOnDetail.is_active ? 'Enabled' : 'Disabled'}
                      switchEnabled={addOnDetail.is_active}
                      onChange={(event) => {
                        updateDealerAddon({
                          id: addOnDetail.id,
                          is_active: event.target.checked
                        })
                        actions.updateAddonRow({
                          ...addOnDetail,
                          is_active: event.target.checked
                        })
                        actions.setToast({
                          toastMessage: 'Data Updated Sucessfully',
                          toastState: true
                        })
                      }}
                    />
                  </TableCell>
                  <TableCell>{Vehicle_Type[Number(addOnDetail?.vehicle_type)]}</TableCell>
                  <TableCell>{addOnDetail?.offered_by}</TableCell>
                  <TableCell>{addOnDetail?.supplier}</TableCell>
                  <TableCell>{addOnDetail?.part_no}</TableCell>
                  <TableCell>{addOnDetail.installation_mode}</TableCell>
                  <TableCell>
                    {addOnDetail?.compatible_models
                      .reduce<string[]>((previous: any, model: any) => {
                        return [...previous, model.trim_name]
                      }, [])
                      .join(', ')}
                  </TableCell>
                  <TableCell>${addOnDetail?.price.toLocaleString()}</TableCell>
                  <TableCell>{addOnDetail?.rv_adder ? `${addOnDetail?.rv_adder}%` : ''}</TableCell>
                  <TableCell className="action-cell fixed-cell">
                    <Menu
                      theme={unityTheme}
                      options={[
                        {
                          optionText: 'Edit',
                          optionValue: 'edit'
                        },
                        {
                          optionText: 'Delete',
                          optionValue: 'delete'
                        }
                      ]}
                      handleOptionClick={(_event, _key, value) => {
                        switch (value) {
                          case 'delete':
                            deleteDealerAddon({ id: addOnDetail.id })
                            actions.deleteAddonRow({
                              ...addOnDetail
                            })
                            break
                          case 'edit':
                            getAddonById(
                              { id: addOnDetail.id },
                              {
                                onSuccess(response: AddOns) {
                                  onEdit(response)
                                }
                              }
                            )
                            break
                        }
                      }}
                      render={(onMenuSelection) => (
                        <Button
                          defaultBtn
                          iconText={<Icon name="MoreIcon" />}
                          onClick={onMenuSelection}
                        ></Button>
                      )}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </CustomTable>
  )
}

export default AddOnsTable
