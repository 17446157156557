import { FC, useState, useEffect } from 'react'
import { Link, useTheme } from '@mui/material'
import { Dialog, Box, Button, Grid, Typography } from '@ntpkunity/controls'
import { VehicleCardUi } from 'components'
import ProductDetailtPopup from './product-details-popup'
import { useQueryClient } from 'react-query'
import { useStoreContext } from '@store/storeContext'
import { QueryKeys } from '@helpers/enums'

const OptionsListPopup: FC<{
  data: any
  callBack: any
  addedItems: any
  setAddedItems: any
  preInstalledOptions?: any
}> = ({ data, callBack, addedItems, setAddedItems, preInstalledOptions }) => {
  const theme = useTheme()
  const queryClient = useQueryClient()
  const { states } = useStoreContext()
  const [cardDetails, setCardDetails] = useState({ product_name: '' })
  const [openProductDialog, setOpenProductDialog] = useState(false)
  const [defaultCurrency, setDefaultCurrency] = useState('')
  const currencies: any = queryClient.getQueryData([
    QueryKeys.GET_ALL_CURRENCIES,
    states?.lenderInfo?.id
  ])
  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === states?.lenderInfo?.default_currency
    )
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0 ? filteredCurrency[0].symbol : ''
    )
  }, [currencies])
  const handleProductDetailsView = (cardData: any) => {
    setOpenProductDialog(true)
    setCardDetails(cardData)
  }

  const handleCloseProductDetails = () => {
    setOpenProductDialog(false)
  }
  const calculateSum = (obj: any) => {
    if (!addedItems.includes(obj.option_id || obj.id)) {
      setAddedItems([...addedItems, obj.option_id || obj.id])
      callBack('add', obj)
    }
  }

  const removeItem = (data: any) => {
    setAddedItems(addedItems.filter((id: any) => id !== (data.option_id ?? data.id)))
    callBack('remove', data)
  }
  const isAdded = (id: any) => addedItems.includes(id)
  const OptionListCard: FC<{ cardData: any }> = ({ cardData }) => {
    const isPreInstalled = preInstalledOptions
    return (
      <Grid theme={theme} item lg={3} md={4} sm={6} xs={12}>
        <VehicleCardUi className="vehicle-card full-width" theme={theme}>
          <Typography
            theme={theme}
            className="tag"
            component="span"
            children={cardData?.offered_by}
          />
          <Box theme={theme} className="vc-img-wrap h-120">
            <img src={cardData?.image?.[0]?.location || ''} alt="Product" />
          </Box>
          <Typography
            theme={theme}
            className="title"
            component="span"
            children={cardData?.product_name}
          />
          <Typography
            theme={theme}
            className="price"
            component="span"
            children={
              cardData
                ? `${defaultCurrency}${parseFloat(cardData.price).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })} ${
                    preInstalledOptions ? (cardData.is_price_inclusive ? '(Inc.) ' : '(Exc.)') : ''
                  }`
                : ''
            }
          />
          <Link className="link" onClick={() => handleProductDetailsView(cardData)}>
            View Details
          </Link>
          {!isPreInstalled &&
            (isAdded(cardData.option_id ?? cardData.id) ? (
              <Button
                fullWidth
                secondary
                theme={theme}
                onClick={() => removeItem(cardData)}
                text="Remove"
              />
            ) : (
              <Button
                fullWidth
                secondary
                theme={theme}
                onClick={() => calculateSum(cardData)}
                text="Add"
              />
            ))}
        </VehicleCardUi>
      </Grid>
    )
  }

  return (
    <>
      <Grid theme={theme} spacing={2} container sx={{ mb: 5 }}>
        {data?.map((object: any, index: any) => (
          <OptionListCard cardData={object} key={index} />
        ))}
      </Grid>
      <Dialog
        disablePortal
        variant={undefined}
        size="xl"
        title={cardDetails && cardDetails.product_name}
        open={openProductDialog}
        onBackBtn={handleCloseProductDetails}
        theme={theme}
        children={<ProductDetailtPopup details={cardDetails && cardDetails} />}
        noFooter
      />
    </>
  )
}

export default OptionsListPopup
