import React from 'react'
import { Box, Button, Typography, unityTheme } from '@ntpkunity/controls'
import { ControlWrap } from './defaultUnAuthResponseStyles'
import UnAUthImage from 'public/assets/images/unauth-image.svg'
import { IUnAuthResponseProps } from './defaultUnAuthResponse.types'

export const DefaultUnAuthComponent: React.FC<IUnAuthResponseProps> = ({ homePageUrl }) => {
  const [appliedTheme, _setAppliedTheme] = React.useState(unityTheme)

  const handleSubmit = (): any => {
    window?.location?.replace(homePageUrl)
  }

  return (
    <ControlWrap theme={appliedTheme} className="control-wrap">
      <Box theme={appliedTheme} className="img-area" mb={5}>
        <img src={UnAUthImage} alt="Unauthorised Access!" />
      </Box>
      <Box theme={appliedTheme} className="content-area">
        <Typography theme={appliedTheme} variant="h2" component="h2" mb={3} className="text-h2">
          Unauthorised Access!
        </Typography>
        <Typography
          className="text-muted"
          theme={appliedTheme}
          variant="body2"
          component="span"
          display={'block'}
          mb={3}
        >
          You are not authorised to access this page. Please click below to go to the homepage.
        </Typography>
      </Box>
      <Box theme={appliedTheme}>
        <Button primary theme={appliedTheme} text="Go To Homepage" onClick={handleSubmit} />
      </Box>
    </ControlWrap>
  )
}
export default DefaultUnAuthComponent
