import { useTheme } from '@mui/material'
import { IListProps, List } from '@ntpkunity/controls'
import { FC } from 'react'

const Component: FC<IListProps> = (props) => {
  const theme = useTheme()

  return <List {...props} theme={theme} />
}

export default Component
