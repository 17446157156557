// @ts-nocheck
import { FC, useState, useEffect } from 'react'
import { useTheme, Link, ToggleButton } from '@mui/material'
import { Box, Chip, Typography, Select, Autocomplete, Icon } from '@ntpkunity/controls'
import { TableList, AutoSearch } from '@components'
import { useStoreContext } from '@store/storeContext'
import { TextField } from '@mui/material'
import {
  DeskingActionTypes,
  useDeskingContext,
  VehiclesForComparisonTypes
} from './desking-context-provider'
import { formatNumber } from './utils'
import clsx from 'clsx'
import { getAllSettingsFromLocalStorage } from '@helpers/methods'
import { DefaulUserReadOnly } from '@helpers/enums'
import { useEditPermissionHook } from '@hooks/useEditPermissionHook'

const VehicleAmountDraft: FC = () => {
  const theme = useTheme()
  const { states } = useStoreContext()
  const { state, formatCurrency, dispatch } = useDeskingContext()
  const [lenderItems, setLenderItems] = useState([])
  const isVehicleSelectedAndOrderSaved = state.isVehicleSelectedAndOrderSaved
  const isVehicleSelected = Object.keys(state.selection ?? {}).length > 0
  const defaultCurrency = states?.dealDesk?.defaultCurrency?.symbol
  const settings = getAllSettingsFromLocalStorage()
  const canUserEdit = useEditPermissionHook()
  const finance_type = isVehicleSelectedAndOrderSaved
    ? state.preview.finance_type
    : state.selection.finance_type

  const calculationsData = isVehicleSelectedAndOrderSaved
    ? state.preview.calculationsBoxes
    : isVehicleSelected
    ? state.selection?.vehicleForComparison === VehiclesForComparisonTypes.SINGLE
      ? state[state?.selection?.vehicleForComparison]?.[state.selection?.finance_type]?.calculationsBoxes[
          state.selection?.row
        ][state.selection?.col]
      : state[state.selection?.vehicleForComparison]?.[state.selection?.finance_type][
          state.selection?.col
        ].calculationsBoxes
    : {}

  const quoteParams = isVehicleSelectedAndOrderSaved
    ? state.preview.quote_params
    : isVehicleSelected
    ? state.selection?.vehicleForComparison === VehiclesForComparisonTypes.SINGLE
      ? state[state.selection?.vehicleForComparison]?.[state.selection?.finance_type]?.calculationsBoxes[
          state.selection?.row
        ]?.[0]
      : state[state.selection?.vehicleForComparison]?.[state.selection?.finance_type][
          state.selection?.col
        ].quote_params
    : {}

  const [lenderData, setLenderData] = useState<any>('')

  useEffect(() => {
    let lenders = []
    if (states?.lendersData?.length > 0) {
      states?.lendersData?.forEach((x: { id: any; name: any; finance_type: string[] }) => {
        if (x.finance_type.includes(state.finance_type)) {
          lenders.push({
            value: x.id,
            text: x.name,
            id: x?.id
          })
        }
      })
      setLenderItems(lenders)
      if (!lenders?.some((lender) => lender.text === showLenderName()))
        setLenderData(lenders?.[0]?.id)
    }
  }, [state.finance_type])

  useEffect(() => {
    if (states.lendersData && states.lendersData.length > 0) {
      if (state?.isStipulationScreen || state?.isVehicleSelectedAndOrderSaved || state?.lender_id) {
        if (state?.lender_id) {
          setLenderData(state?.lender_id)
        } else if (state?.order?.lender_id) {
          const lenderCode = states?.lendersData?.find((ldr) => ldr.id == state?.order?.lender_id)?.external_code
          setLenderData(state?.order?.lender_id)
          dispatch({
            type: DeskingActionTypes.SELECTED_LENDER,
            payload: { lenderId: state?.order?.lender_id, lenderCode: lenderCode }
          })
        }
      } else {
        const lenderData = states?.lendersData?.find((ldr) => ldr.default_lender) ?? states?.lendersData[0]
        setLenderData(lenderData.id)
        dispatch({
          type: DeskingActionTypes.SELECTED_LENDER,
          payload: { lenderId: lenderData.id, lenderCode: lenderData.external_code }
        })
      }
    }
  }, [
    state?.isStipulationScreen,
    state?.order?.lender_id,
    states.lendersData,
    state?.isVehicleSelectedAndOrderSaved
  ])

  const showLenderName = () => {
    const selectedLenderName = Array.isArray(states?.lendersData)
      ? states?.lendersData?.find((st) => st?.id === lenderData)
      : null
    return selectedLenderName?.name
  }

  const [open, setOpen] = useState(false)
  const handleLenderChange = async (_event: any, newValue: any) => {
    const selectedLender = states.lendersData?.find((st) => st.id === newValue?.value)
    setLenderData(selectedLender?.id)
    dispatch({
      type: DeskingActionTypes.SELECTED_LENDER,
      payload: { lenderId: selectedLender?.id, lenderCode: selectedLender?.external_code }
    })
    setOpen(true)
  }

  return (
    <Box theme={theme} className="vehicle-amount-wrap grey-bg">
      <Chip
        theme={theme}
        label={state?.preview?.status ?? 'DRAFT'}
        component="div"
        clickable={false}
        variant="filled"
        href=""
      />
      <Typography
        theme={theme}
        component="p"
        variant="caption"
        sx={{ mb: 1 }}
        className="text-overflow"
        children={
          <>
            {defaultCurrency}{' '}
            <Typography
              theme={theme}
              component="span"
              variant="h3"
              sx={{ verticalAlign: 'middle' }}
            >
              {formatNumber(calculationsData?.monthly_payment ?? 0)}
            </Typography>{' '}
            /mo
          </>
        }
      />
      <TableList
        withBg
        items={[
          {
            title: 'Type',
            value: finance_type ?? state.finance_type
          },
          {
            title: 'Lender',
            value: (
              <AutoSearch theme={theme} className="auto-search">
                <Box theme={theme} className="btn-toggle">
                  <ToggleButton
                    value="check"
                    className="text-overflow"
                    fullWidth
                    selected={open}
                    onChange={() => {
                      setOpen(!open)
                    }}
                    disabled={
                      state?.isStipulationScreen || Boolean(state?.submission_tier)
                        ? true
                        : false || !canUserEdit
                    }
                  >
                    <span className="btn-label text-overflow">{showLenderName()}</span>
                    <Icon name="ChevronDown" />
                  </ToggleButton>
                </Box>
                {open && (
                  <>
                    <Link className="overlay" onClick={() => setOpen(false)} />
                    <Box theme={theme} className="list-wrap" textAlign="left">
                      <Autocomplete
                        theme={theme}
                        id="select_dealers"
                        disablePortal
                        open={open}
                        disabled={state?.isStipulationScreen || Boolean(state?.submission_tier)}
                        endAdornment={<Icon name="SearchIcon" />}
                        items={lenderItems}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Search"
                            className={clsx('u-form-group')}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            <span>{option.text}</span>
                          </li>
                        )}
                        getOptionLabel={(option) => option.text}
                        onChange={(event, newValue) => {
                          handleLenderChange(event, newValue)
                          setOpen(false)
                        }}
                        onBlur={() => setOpen(false)}
                      />
                    </Box>
                  </>
                )}
              </AutoSearch>
            )
          },
          {
            title: 'Down Payment',
            value: formatCurrency(calculationsData?.down_payment ?? 0)
          },
          {
            title: 'Term',
            value: `${quoteParams?.contract_term ?? '0'}`
          },
          {
            title: 'Rate',
            value: `${parseFloat(quoteParams?.apr ?? 0)?.toFixed(5)}%`
          }
        ]}
      />
    </Box>
  )
}

export default VehicleAmountDraft
