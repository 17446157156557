import { FC } from 'react'
import { useTheme, styled } from '@mui/material'
import { IBoxProps, Dialog } from '@ntpkunity/controls'
import { Box } from 'components'

const DetailImg = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({}) => ({
  width: '100%',
  display: 'flex',
  verticalAlign: 'middle',
  textAlign: 'center',
  overFlow: 'hidden',
  img: {
    maxWidth: '100%',
    maxHeight: '69vh',
    width: 'auto',
    height: 'auto',
    display: 'block',
    margin: 'auto'
  }
}))

export const ImageDialog: FC<{ hide: () => void; selectedImage: string }> = ({
  hide,
  selectedImage
}) => {
  const theme = useTheme()
  return (
    <Dialog
      theme={theme}
      disablePortal
      open
      onCloseDialog={hide}
      noFooter
      children={
        <>
          <DetailImg theme={theme}>
            <img src={selectedImage} />
          </DetailImg>
        </>
      }
    />
  )
}
