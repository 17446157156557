import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import {
  unityTheme,
  Box,
  Icon,
  Button,
  Input,
  Checkbox,
  DataTable,
  Grid,
  IGridProps
} from '@ntpkunity/controls'
import { styled } from '@mui/material/styles'
import { useGetInventoryOptions } from '@apis/inventory-management.service'
import { IOptions, IVehicleDetail } from '@models'
const DataTableWrap = styled(
  Grid,
  {}
)<Partial<IGridProps>>(({ theme }) => ({
  position: 'relative',
  '.scroll-hide': {
    backgroundColor: theme.palette.common.white,
    borderBottom: '1px solid' + theme.palette.divider,
    position: 'absolute',
    width: 7,
    height: 94.5,
    right: 1,
    top: 1,
    display: 'block',
    borderRadius: '0 3px 0 0',
    span: {
      display: 'block',
      backgroundColor: theme.palette.divider,
      width: 7,
      height: 1,
      position: 'absolute',
      top: 49
    }
  },
  '.u-table-wrap': {
    '.u-table-container': {
      maxHeight: '48vh !important',
      '.u-table': {
        'th, td': {
          '&.img-cell': {
            width: 72,
            minWidth: '72px !important',
            textAlign: 'center',
            verticalAlign: 'middle',
            img: {
              height: 40
            },
            svg: {
              height: 20,
              marginTop: 3,
              path: {
                stroke: theme.palette.grey[300]
              }
            }
          },
          '&.text-right': {
            textAlign: 'right'
          }
        }
      }
    }
  }
}))

const InventoryOptionsTable: FC<{
  setDeleteData: any
  vehicleData: IVehicleDetail
  addMoreOptionPopupState: boolean
  setlistChanged: any
}> = ({ setDeleteData, vehicleData, addMoreOptionPopupState, setlistChanged }) => {
  const theme = useTheme()
  const { mutate: getInventoryOptions, data: inventoryOptionsData } = useGetInventoryOptions()
  const [deleteOption, setDeleteOption] = useState<any[]>([inventoryOptionsData])
  const [inventoryState, setInventoryState] = useState<IOptions[]>([])
  const deleteItem = (index: any) => {
    setDeleteOption(inventoryState.filter((item: any) => item.id !== index))
    setInventoryState(inventoryState.filter((item: any) => item.id !== index))
  }
  useEffect(() => {
    setDeleteData(deleteOption)
  }, [deleteOption])
  useEffect(() => {
    var c = ''
    c = vehicleData?.dealer_options.map((option) => `dealer_options=${option}`).join('&')
    if (vehicleData?.dealer_options) {
      getInventoryOptions({
        data: c
      })
    }
  }, [vehicleData?.dealer_options])
  useEffect(() => {
    if (!addMoreOptionPopupState) {
      setInventoryState(inventoryOptionsData)
    }
  }, [inventoryOptionsData, addMoreOptionPopupState])
  return (
    <>
      <DataTableWrap theme={unityTheme}>
        <Box className="scroll-hide" theme={theme}>
          <span />
        </Box>
        <DataTable
          theme={unityTheme}
          variant="basic"
          theadChildren={
            <>
              <TableRow>
                <TableCell className="checkbox-cell fixed-cell">
                  <Checkbox label="" theme={unityTheme} />
                </TableCell>
                <TableCell>Product Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Installation Mode</TableCell>
                <TableCell>Supplier</TableCell>
                <TableCell className="action-cell fixed-cell" />
              </TableRow>
              <TableRow className="filters-row">
                <TableCell className="checkbox-cell fixed-cell" />
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={''}
                    />
                    <Button defaultBtn iconText={<Icon name="IconFilter" />}></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={''}
                    />
                    <Button defaultBtn iconText={<Icon name="IconFilter" />}></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={''}
                    />
                    <Button defaultBtn iconText={<Icon name="IconFilter" />}></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={''}
                    />
                    <Button defaultBtn iconText={<Icon name="IconFilter" />}></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={''}
                    />
                    <Button defaultBtn iconText={<Icon name="IconFilter" />}></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={''}
                    />
                    <Button defaultBtn iconText={<Icon name="IconFilter" />}></Button>
                  </Box>
                </TableCell>
                <TableCell className="action-cell fixed-cell" />
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              {inventoryState?.map((inventoryOptions: IOptions, index: number) => (
                <TableRow key={index} className="child-tr">
                  <TableCell className="checkbox-cell fixed-cell">
                    <Checkbox label="" theme={unityTheme} />
                  </TableCell>
                  <TableCell>{inventoryOptions.product_name}</TableCell>
                  <TableCell>{inventoryOptions.description}</TableCell>
                  <TableCell>{inventoryOptions.category}</TableCell>
                  <TableCell className="text-right">
                    <Box theme={theme} sx={{ mr: 4 }}>
                      {inventoryOptions.price}
                    </Box>
                  </TableCell>
                  <TableCell>{inventoryOptions.installation_mode}</TableCell>
                  <TableCell>{inventoryOptions.supplier}</TableCell>
                  <TableCell className="action-cell fixed-cell">
                    <Button
                      iconText={<Icon name="DeleteIcon" />}
                      onClick={(_e: any) => {
                        deleteItem(inventoryOptions.id)
                        setlistChanged(true)
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </>
          }
        />
      </DataTableWrap>
    </>
  )
}

export default InventoryOptionsTable
