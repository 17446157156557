import { FC, useEffect, useState } from 'react'
import { ToggleButton, useTheme } from '@mui/material'
import {
  useGetChecklistSavedProgress,
  useGetReadyCheckListRequest
} from '@apis/dealer-workqueue.service'
import { useStoreContext } from '@store/storeContext'
import { Box, Icon, Typography } from '@ntpkunity/controls'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { IRequestReadyCheckList } from '@models'
const ReadyCheckResponse: FC = () => {
  const [savedReadyChecklist, setOrderSavedReadyChecklist] = useState<any>()
  const [configReadyChecklist, setConfigReadyChecklist] = useState<any>()
  const [openEpp, setOpenEpp] = useState(false)
  const { states } = useStoreContext()

  const [open, setOpen] = useState(false)
  const { mutate: getEventAssociatedReadyCheckList } = useGetReadyCheckListRequest()
  const { mutate: getChecklistProgressOptions, data: _savedChecklist } =
    useGetChecklistSavedProgress()
  const reqCheckListObject: IRequestReadyCheckList = {
    event_name: 'get_ready_checklist_event',
    ...(states?.dealerSelectedOrderData &&
      Object.keys(states?.dealerSelectedOrderData).length > 0 &&
      states?.dealerSelectedOrderData?.fulfillment &&
      (states?.dealerSelectedOrderData?.fulfillment?.dealerAddress?.state === 'FL' ||
        states?.dealerSelectedOrderData?.fulfillment?.dealerAddress?.state === 'CA') && {
        criteria: {
          state: states?.dealerSelectedOrderData?.fulfillment?.dealerAddress?.state
        }
      })
  }
  const theme = useTheme()

  useEffect(() => {
    if (states?.dealerSelectedOrderData?.customerId) {
      getEventAssociatedReadyCheckList(
        { ...reqCheckListObject },
        {
          onSuccess(res: any) {
            setConfigReadyChecklist(res)

            getChecklistProgressOptions(
              {
                customer_reference_id: states?.dealerSelectedOrderData?.customerId,
                checklist_identifier: res[0]?.[0]?.checklist_identifier,
                order_reference_id: states?.dealerSelectedOrderData?.id
              },
              {
                onSuccess(res: any) {
                  setOrderSavedReadyChecklist(res)
                }
              }
            )
          }
        }
      )
    }
  }, [states?.dealerSelectedOrderData?.customerId])

  return (
    <Box theme={theme} className="card-body">
      <Box theme={theme} className="summary-row toggle" sx={{ mb: 2 }}>
        <Box theme={theme} className="row">
          <Box theme={theme} className="center">
            <span className="card-text">
              <b> Get Ready Checklist</b>
            </span>
            {openEpp ? (
              <Icon
                name="ChevronDown"
                className="toggle-btn ch-up"
                onClick={() => setOpenEpp(!openEpp)}
              />
            ) : (
              <Icon
                name="ChevronDown"
                className="toggle-btn"
                onClick={() => setOpenEpp(!openEpp)}
              />
            )}
          </Box>
        </Box>
      </Box>
      {configReadyChecklist &&
        configReadyChecklist?.[0]?.[0]?.questions?.map((ques: any, index: any) => (
          <Box theme={theme} key={index}>
            {savedReadyChecklist?.find((f: any) => f.question_text == ques.question) ? (
              <Accordion key={index} expanded={openEpp}>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" />
                <AccordionDetails sx={{ ml: 2 }}>
                  <Box theme={theme} className="row" sx={{ mb: 2 }}>
                    <Typography
                      theme={theme}
                      className="card-text text"
                      component="span"
                      variant="body2"
                    >
                      {ques?.question}
                      {savedReadyChecklist?.find(
                        (f: { question_text: any }) => f.question_text == ques.question
                      )?.description ? (
                        <ToggleButton
                          value="check"
                          selected={open}
                          onChange={() => {
                            setOpen(!open)
                          }}
                        >
                          <Icon name="ChevronDown" />
                        </ToggleButton>
                      ) : null}
                    </Typography>
                    <Typography
                      theme={theme}
                      className="card-text text"
                      component="span"
                      variant="body2"
                    >
                      {
                        savedReadyChecklist?.find(
                          (f: { question_text: any }) => f.question_text == ques.question
                        )?.primary_answer
                      }
                    </Typography>
                  </Box>
                  {open && (
                    <Box theme={theme} sx={{ mb: 2 }}>
                      <Typography
                        theme={theme}
                        className="card-text text"
                        component="span"
                        variant="body2"
                      >
                        {
                          savedReadyChecklist?.find(
                            (f: { question_text: any }) => f.question_text == ques.question
                          )?.description
                        }
                      </Typography>
                    </Box>
                  )}
                </AccordionDetails>
              </Accordion>
            ) : (
              <>
                <Accordion key={index} expanded={openEpp}>
                  <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" />
                  <AccordionDetails sx={{ ml: 2 }}>
                    <Box theme={theme} className="row" sx={{ mb: 2 }}>
                      <Typography
                        theme={theme}
                        className="card-text text"
                        component="span"
                        variant="body2"
                      >
                        {ques.question}
                      </Typography>

                      <Typography
                        theme={theme}
                        className="card-text text"
                        component="span"
                        variant="body2"
                      >
                        {savedReadyChecklist?.find(
                          (f: { question_text: any }) => f.question_text == ques.question
                        )?.primary_answer || '-'}
                      </Typography>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </>
            )}
          </Box>
        ))}
    </Box>
  )
}
export default ReadyCheckResponse
