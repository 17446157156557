import Http from '../helpers/http-api'
import { useQuery } from 'react-query'
import { GET_USER_PROFILE } from '@helpers/endpoints'

export const useGetProfile = (enabled: any): any => {
  const { data, error } = useQuery(
    GET_USER_PROFILE,
    async () => {
      const apiService = Http.createConnection({
        baseUrl: process.env.UMS_BASE_URL
      })
      return await apiService.get<any>(GET_USER_PROFILE)
    },
    { enabled: enabled }
  )
  return { data, error }
}
