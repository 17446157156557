import { FC, useState, useEffect, useContext } from 'react'
import {
  UserListingComponent,
  UMSStoreContext,
  useHasPermissions,
  dealexPermissions
  //@ts-ignore
} from '@ntpkunity/controls-ums'
import { unityTheme, Snackbar, Icon, Button, Grid } from '@ntpkunity/controls'
import { Typography, PageHeader } from 'components'
import { HttpStatus } from '@helpers/enums'
import { useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import { getAllSettingsFromLocalStorage } from '@helpers/methods'
import { APP_ROUTES } from '@helpers/links'
import { useGetDealers } from '@apis/dealer-configurations.service'
import { useStoreContext } from '@store/storeContext'

const ManageUser: FC = () => {
  const theme = useTheme()
  const [open, setOpen] = useState<boolean>(false)
  const [response, setResponse] = useState<any>(undefined)
  const [Success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const { states } = useStoreContext()
  const adminPermission = useHasPermissions(dealexPermissions?.MENUS?.DEALER_SELECTION)
  const {
    states: { userRole: userRole, productId: productId }
  } = useContext(UMSStoreContext) as any

  const settings = getAllSettingsFromLocalStorage()
  var tenant_id = settings.tenant_id

  const { data } = useGetDealers('Dealer')

  useEffect(() => {
    if (response?.status === HttpStatus.OK) {
      setSuccess(true)
    } else if (response?.status === HttpStatus.Conflict) {
      setError(true)
    }
  }, [response])

  const getSuccessToast = () => {
    if (Success == true) {
      return (
        <div>
          <Snackbar
            theme={unityTheme}
            autoHideDuration={3000}
            message={response.data.message}
            open={Success}
            onClose={() => setSuccess(false)}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          />
        </div>
      )
    } else {
      return <div></div>
    }
  }
  const getErrorToast = () => {
    if (error == true) {
      return (
        <div>
          <Snackbar
            theme={unityTheme}
            autoHideDuration={3000}
            message={response.message}
            open={error}
            onClose={() => setError(false)}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          />
        </div>
      )
    } else {
      return <div></div>
    }
  }
  return (
    <LayoutWithSideNav theme={theme}>
      <PageHeader className="main-page-header">
        <Grid theme={theme} item container spacing={2}>
          <Grid theme={theme} item xs={12} sm={12} lg={7} md={6}>
            <Typography variant="h2" component="h2" theme={theme}>
              Manage Users
            </Typography>
          </Grid>
          <Grid theme={theme} item xs={12} lg={5} sm={12} md={6} textAlign="right">
            <Button
              secondary
              theme={theme}
              startIcon={<Icon name="AddIcon" />}
              text={'Add New User'}
              onClick={() => setOpen(true)}
            />
          </Grid>
        </Grid>
      </PageHeader>
      {(states?.dealerInfo?.id && adminPermission) || !adminPermission ? (
        <UserListingComponent
        actionType={'add'}
          setopenAddComponent={setOpen}
          openAddComponent={open}
          selectedEntityId={states?.dealerInfo?.id}
          entities={
            data
              ? data.map((item: { id: number; dealer_name: string }) => ({
                value: item?.id,
                text: item?.dealer_name || `Dealer ${item?.id}`
              }))
              : []
          }
          entityType={'Dealer'}
          productId={productId?.productId}
          userRole={userRole?.userRole}
          theme={unityTheme}
          setResponse={setResponse}
          tenantId={tenant_id}
          value={''}
          componentBehavior={'drawer'}
          roleDisabled={false}
          nextScreenUrl={`${APP_ROUTES.APP_USER_PROFILE}`}
          layoutText={{
            nameLabel: 'First/Last Name',
            emailAddressLabel: 'Email Address',
            productsLabel: 'Product',
            rolesLabel: 'Role',
            contactNumLabel: 'Contact Number',
            statusLabel: 'Status'
          }}
          openPopup={false}
          close={function (): void {
            throw new Error('Function not implemented.')
          }}
        />
        ) : (
        'Loading or No Dealer Info Available'
      )}
      {getSuccessToast()}
      {getErrorToast()}
    </LayoutWithSideNav>
  )
}

export default ManageUser
