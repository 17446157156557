import { FC, useEffect, useState } from 'react'
import {
  UpdateUserProfileComponent,
  ChangePasswordComponent
  //@ts-ignore
} from '@ntpkunity/controls-ums'
import { Box, IBoxProps } from '@ntpkunity/controls'
import { useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import { styled } from '@mui/material/styles'
import { useStoreContext } from '../store/storeContext'
import { ToastMessages } from '@helpers/enums'

const UserProfileWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  display: 'table',
  margin: '10vh auto',
  maxWidth: 464,

  '.section': {
    marginBottom: 40,
    paddingBottom: 32,
    borderBottom: '1px solid' + theme.palette.divider,
    display: 'inline-block'
  }
}))

const Userprofile: FC = () => {
  const { actions } = useStoreContext()
  const {
    states: { userProfile },
    actions: { setUserProfile }
  } = useStoreContext()

  const theme = useTheme()
  const [profileResponse, setProfileResponse] = useState<any>(undefined)
  const [changePasswordResponse, setChangePasswordResponse] = useState<any>(undefined)

  useEffect(() => {
    if (profileResponse?.status == 200) {
      setUserProfile({
        ...userProfile,
        first_name: profileResponse?.data.data.first_name,
        last_name: profileResponse?.data.data.last_name
      })
      actions.setToast({
        toastMessage: ToastMessages.PROFILE_UPDATED_SUCCESS,
        toastState: true
      })
    } else if (profileResponse?.status) {
      actions.setToast({
        toastMessage: profileResponse?.message,
        toastState: true,
        variant: 'error'
      })
    }
  }, [profileResponse])

  useEffect(() => {
    if (changePasswordResponse?.status == 200) {
      actions.setToast({
        toastMessage: ToastMessages.CHANGE_PASSWORD_SUCCESS,
        toastState: true
      })
    } else if (changePasswordResponse?.status) {
      actions.setToast({
        toastMessage: changePasswordResponse?.message,
        toastState: true,
        variant: 'error'
      })
    }
  }, [changePasswordResponse])

  return (
    <LayoutWithSideNav theme={theme}>
      <UserProfileWrap theme={theme}>
        <Box className="section" theme={theme}>
          <UpdateUserProfileComponent
            theme={theme}
            setResponse={setProfileResponse}
            layoutText={{
              title: 'Your Profile',
              lastNameFieldLabel: 'Last Name (Optional)',
              contactNumFieldLabel: 'Contact Number (Optional)'
            }}
          />
        </Box>
        <ChangePasswordComponent
          theme={theme}
          setResponse={setChangePasswordResponse}
          next_screen_url={''}
        />
      </UserProfileWrap>
    </LayoutWithSideNav>
  )
}

export default Userprofile
