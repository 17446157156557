import { FC } from 'react'
import { useTheme } from '@mui/material'
import { ChatHeaderWrap } from './ChatHeader.style'
import { Avatar, Box, Button, Icon, Typography } from '@ntpkunity/controls'
import { useChatServiceContext } from '@hooks/useChatService'
import { getUserNameFromChannel } from '@helpers/methods'
import clsx from 'clsx'

const ChatHeader: FC = () => {
  const theme = useTheme()
  const { setOpenChat, clearSelectedChat, selectedUser, onlineUsers } = useChatServiceContext()
  const userName = getUserNameFromChannel(selectedUser)
  const userIsOnline = onlineUsers[selectedUser?.userID as number]

  return (
    <ChatHeaderWrap theme={theme} className="messages-header-wrap">
      <Button
        theme={theme}
        defaultBtn
        iconText={<Icon name="IconLeftArrow" onClick={clearSelectedChat} />}
      ></Button>
      <Box theme={theme} className="sender-wrap">
        <Box theme={theme} className="sender-avatar">
          <Avatar theme={theme} src="https://www.w3schools.com/howto/img_avatar.png" />
          <Typography
            theme={theme}
            component="span"
            variant="subtitle1"
            className={clsx('status', {
              online: userIsOnline,
              offline: !userIsOnline
            })}
          ></Typography>
        </Box>
        <Box theme={theme} className="sender-details">
          <Typography theme={theme} component="p" variant="subtitle1" className="sender-name">
            {userName}
          </Typography>
          <Typography theme={theme} component="p" variant="caption" className="sender-status">
            {userIsOnline ? 'Online' : 'Offline'}
          </Typography>
        </Box>
      </Box>
      <Button
        theme={theme}
        iconText={<Icon name="CloseBlack" />}
        onClick={() => {
          clearSelectedChat()
          setOpenChat(false)
        }}
        className="menu-close-icon"
      />
    </ChatHeaderWrap>
  )
}

export default ChatHeader
