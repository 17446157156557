import { useTheme } from '@mui/material'
import ChatWrap from './Chat.style'
import { Box, Button, Icon, Typography } from '@ntpkunity/controls'
import ChatNotification from './ChatNotification/ChatNotification.component'
import ChatMessagesWindow from './ChatMessagesWindow/ChatMessagesWindow.component'
import { useChatService, ChatServiceProvider } from '@hooks/useChatService'
import { useStoreContext } from '@store/storeContext'

const ChatButton = () => {
  const { states } = useStoreContext()
  const chatEnabled = states.dealerInfo?.chat

  if (!chatEnabled) return <></>

  return <ChatService />
}

const ChatService = () => {
  const theme = useTheme()
  const { states } = useStoreContext()
  const selectedDealerCode = states?.dealerInfo?.dealer_code

  const chatMethods = useChatService(selectedDealerCode)

  const { client, selectedUser, openChat, setOpenChat, clearSelectedChat, totalUnreadCount } =
    chatMethods
  const showChatDialog = !!selectedUser

  return (
    <ChatServiceProvider {...chatMethods}>
      <ChatWrap theme={theme} className="chats-button-wrap">
        {openChat && (
          <>
            {showChatDialog ? (
              <Box theme={theme}>
                <ChatMessagesWindow />
              </Box>
            ) : (
              <ChatNotification />
            )}
          </>
        )}
        <Box theme={theme} className="chat-icon-wrap">
          {totalUnreadCount > 0 && !openChat && (
            <Typography theme={theme} component="span" variant="subtitle1" className="chats-count">
              {totalUnreadCount}
            </Typography>
          )}
          {client && (
            <Button
              theme={theme}
              iconText={<Icon name={openChat ? 'CloseBlack' : 'InboxIcon'} />}
              className="chat-icon"
              onClick={() => {
                if (openChat) {
                  clearSelectedChat()
                }
                setOpenChat(!openChat)
              }}
            />
          )}
        </Box>
      </ChatWrap>
    </ChatServiceProvider>
  )
}

export default ChatButton
