import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const ControlWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.control-wrap': {
    height: 'calc(100vh - 32px)',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    textAlign: 'center',
    '.text-primary': {
      color: theme.palette.primary.main
    },
    '.text-muted': {
      color: theme.palette.grey[600]
    },
    '.text-dark': {
      color: theme.palette.grey[900]
    },
    '.decoration-none': {
      textDecoration: 'none'
    },
    '.img-area': {
      maxHeight: 320,
      img: {
        maxHeight: 320,
        maxWidth: '100%'
      }
    },
    [theme.breakpoints.down('md')]: {
      '.text-h2': {
        fontSize: theme.typography.h3.fontSize,
        lineHeight: theme.typography.h3.lineHeight
      },
      '.text-body1': {
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body2.lineHeight
      }
    }
  }
}))
