// @ts-nocheck
import { FC, useEffect } from 'react'
import { Theme, useTheme } from '@mui/material'
import {
  Box,
  Typography,
  Select,
  Button,
  Input,
  CircleLoader,
  Skeleton,
  Tooltip
} from '@ntpkunity/controls'
import {
  DeskingActionTypes,
  FinanceTypes,
  QuoteParams,
  useDeskingContext,
  VehiclesForComparisonTypes
} from './desking-context-provider'
import {
  useDebouncedState,
  useGetPricingCalculation,
  useGetProgramInformation
} from '@apis/dealer-configurations.service'
import { AddVehicle } from './add-vehicle'
import { formatNumber } from './utils'
import { useStoreContext } from '@store/storeContext'

const VehicleLoader: FC<{ width: string | number; height?: string }> = ({
  width,
  height = '30px'
}) => {
  const theme = useTheme()

  return (
    <Skeleton
      theme={theme}
      variant="rectangular"
      width={width}
      height={height}
      sx={{ borderRadius: '8px' }}
    />
  )
}

export const Label: FC<{ name: string }> = ({ name }) => {
  const theme = useTheme()

  return <Typography theme={theme} variant="caption" component="div" children={name} />
}

export const Value: FC<{ value: string | number; isCurrency?: boolean }> = ({
  value,
  isCurrency = true
}) => {
  const theme = useTheme()
  const { formatCurrency } = useDeskingContext()

  return (
    <Typography
      theme={theme}
      variant="caption"
      component="div"
      className="amount"
      textAlign="center"
      children={isCurrency ? formatCurrency(value) : formatNumber(value)}
    />
  )
}

export const DownPayment: FC<{
  vin: string | undefined
  rowIndex: number
  colIndex: number
  value: string | number
}> = ({ vin, rowIndex, colIndex, value }) => {
  const theme = useTheme()
  const {
    states: {
      dealDesk: { defaultCurrency }
    }
  } = useStoreContext()
  const {
    state: { submission_tier },
    dispatch
  } = useDeskingContext()
  const [debouncedState, setState, state] = useDebouncedState(value)
  const { isLoading: programLoading } = useGetProgramInformation(vin, submission_tier, 0)

  useEffect(() => {
    if (Number(value) !== Number(debouncedState)) {
      dispatch({
        type: DeskingActionTypes.UPDATE_QUOTE_PARAM_DOWN_PAYMENT,
        payload: {
          vinQPDP: vin as string,
          rowIndex,
          colIndex,
          value: Number(debouncedState ?? value)
        }
      })
    }
  }, [debouncedState])

  return programLoading ? (
    <VehicleLoader width={'100%'} />
  ) : (
    <Input
      theme={theme}
      type="text"
      fullWidth
      startAdornment={defaultCurrency?.symbol}
      value={state}
      masking
      maskNumeric
      maskDecimalScale={2}
      onChange={(e) => setState(Number(e))}
      disabled={Boolean(submission_tier)}
    />
  )
}

export const QuoteParam: FC<{
  vin: string | undefined
  rowIndex: number
  colIndex: number
  value: QuoteParams
}> = ({ vin, rowIndex, colIndex, value }) => {
  const theme = useTheme()
  const { states } = useStoreContext()
  const { state, dispatch } = useDeskingContext()
  const [debouncedState, setState, _state] = useDebouncedState(value)
  const { contract_term, apr, rv_value, annual_usage } = _state ?? {}
  const { finance_type } = state
  const { contractTermOptions, milleageOptions } = states?.dealDesk

  useEffect(() => {
    const hasChanged =
      Object.keys(debouncedState ?? {}).length > 0
        ? Number(value.contract_term) !== Number(debouncedState.contract_term) ||
          Number(value.apr) !== Number(debouncedState.apr) ||
          Number(value.rv_value) !== Number(debouncedState.rv_value) ||
          ((value.annual_usage !== undefined || value.annual_usage !== null) &&
            Number(value.annual_usage) !== Number(debouncedState.annual_usage))
        : true

    if (hasChanged) {
      dispatch({
        type: DeskingActionTypes.UPDATE_QUOTE_PARAM_DOWN_PAYMENT,
        payload: {
          vinQPDP: vin as string,
          rowIndex,
          colIndex,
          value: debouncedState ?? value,
          field: 'quote_params'
        }
      })
    }
  }, [
    debouncedState?.annual_usage,
    debouncedState?.apr,
    debouncedState?.contract_term,
    debouncedState?.rv_value
  ])

  const contractTermsOptions =
    (vin ?? '').length > 0
      ? contractTermOptions
      : [{ text: `${contract_term}mo`, value: contract_term }]
  const milesOptions =
    (vin ?? '').length > 0
      ? milleageOptions
      : [{ text: `${parseInt(annual_usage) / 1000}k`, value: annual_usage }]

  const { isLoading: programLoading } = useGetProgramInformation(vin, state?.submission_tier, 0)

  return (
    <Box theme={theme} className="flc-li-left-col">
      <Box theme={theme} className="flc-labels-wrap">
        <Box theme={theme} className="label-row">
          <Typography
            theme={theme}
            variant="caption"
            component="div"
            className="label text-overflow"
            children="Term"
          />
          {programLoading ? (
            <VehicleLoader width={'125px'} />
          ) : (
            <Select
              theme={theme}
              fullWidth
              disablePortal
              items={contractTermsOptions}
              value={contract_term ?? '00'}
              onChange={(e) => setState({ ..._state, contract_term: e.target.value })}
              disabled={Boolean(state?.submission_tier)}
            />
          )}
        </Box>
        {finance_type === FinanceTypes.LEASE ? (
          <Box theme={theme} className="label-row">
            <Typography
              theme={theme}
              variant="caption"
              component="div"
              className="label text-overflow"
              children="Miles"
            />
            {programLoading ? (
              <VehicleLoader width={'125px'} />
            ) : (
              <Select
                theme={theme}
                fullWidth
                disablePortal
                items={milesOptions}
                value={annual_usage ?? '00'}
                onChange={(e) => setState({ ..._state, annual_usage: e.target.value })}
                disabled={Boolean(state?.submission_tier)}
              />
            )}
          </Box>
        ) : null}
        <Box theme={theme} className="label-row">
          <Typography
            theme={theme}
            variant="caption"
            component="div"
            className="label text-overflow"
            children="Rate"
          />
          {programLoading ? (
            <VehicleLoader width={'125px'} />
          ) : (
            <Tooltip theme={theme} title="Please add range (0 - 100)" placement="right">
              <Input
                theme={theme}
                fullWidth
                type="text"
                value={apr}
                onChange={(e) =>
                  setState({
                    ..._state,
                    apr: formatNumber(Number(Math.max(0, Math.min(100, Number(e)))), 5, 5)
                  })
                }
                masking
                maskNumeric
                maskDecimalScale={5}
                endAdornment="%"
                disabled={Boolean(state?.submission_tier)}
              />
            </Tooltip>
          )}
        </Box>
        <Box theme={theme} className="label-row">
          <Typography
            theme={theme}
            variant="caption"
            component="div"
            className="label text-overflow"
            children={`${finance_type === FinanceTypes.FINANCE ? 'Balloon' : 'RV'}`}
          />
          {programLoading ? (
            <VehicleLoader width={'125px'} />
          ) : (
            <Tooltip theme={theme} title="Please add range (0 - 100)" placement="right">
              <Input
                theme={theme}
                fullWidth
                type="text"
                value={rv_value}
                onChange={(e) =>
                  setState({ ..._state, rv_value: Number(Math.max(0, Math.min(100, Number(e)))) })
                }
                masking
                maskNumeric
                maskDecimalScale={2}
                endAdornment="%"
                disabled={Boolean(state?.submission_tier)}
              />
            </Tooltip>
          )}
        </Box>
      </Box>
    </Box>
  )
}

const Calculation: FC<{
  comparison: string
  vin: string | undefined
  value: string | number | Object
  rowIndex: number
  colIndex: number
  selected: boolean
}> = ({ comparison, vin, value, rowIndex, colIndex, selected }) => {
  const theme = useTheme()
  const { state, dispatch } = useDeskingContext()
  const {
    data: calculations,
    isLoading: calcReqLoading,
    error: calcReqError
  } = useGetPricingCalculation(vin ?? '', rowIndex, colIndex, comparison)
  const { states } = useStoreContext()
  const defaultCurrency = states?.dealDesk?.defaultCurrency?.symbol

  useEffect(() => {
    if (Object.keys(calculations ?? {}).length > 0) {
      dispatch({
        type: DeskingActionTypes.UPDATE_CALCULATION_GRID_CELL,
        payload: {
          vinCGC: vin as string,
          rowIndexCGC: rowIndex,
          colIndexCGC: colIndex,
          vehicleForComparisonCGC: comparison,
          finance_typeCGC: state.finance_type,
          valueCGC: calculations
        }
      })
    }
  }, [calculations])

  const _value = formatNumber(typeof value === 'object' ? value?.monthly_payment : value, 0, 0)
  const _selected = selected
    ? selected
    : Object.keys(state.selection ?? {}).length > 0
    ? state.selection.finance_type === state.finance_type &&
      state.selection.vehicleForComparison === state.vehiclesForComparison &&
      state.selection.row === rowIndex &&
      state.selection.col === colIndex
    : false

  return (
    <Button
      theme={theme}
      title={`${defaultCurrency}${_value}`}
      defaultBtn
      disabled={!(typeof value === 'object')}
      className={`amount grey-bg flc-li-selfStretch-col ${_selected ? 'selected' : ''}`}
      text={
        calcReqLoading ? (
          <Skeleton
            theme={theme}
            variant="text"
            width={'100%'}
            height={'4vh'}
            sx={{ borderRadius: '4vh' }}
          />
        ) : (
          <>
            <span>{defaultCurrency}</span>
            {_value}
          </>
        )
      }
      onClick={() => {
        if (typeof value === 'object' && !_selected) {
          dispatch({
            type: DeskingActionTypes.UPDATE_SELECTION_DATA,
            payload: {
              vinUSD: vin as string,
              stateUSD: state,
              rowIndexUSD: rowIndex,
              colIndexUSD: colIndex
            }
          })
        }
      }}
    />
  )
}

export const CalculationBox: FC<{
  comparison: string
  vin: string | undefined
  value: string | number | Object
  rowIndex: number
  colIndex: number
  selected?: boolean
}> = ({ comparison, vin, value, rowIndex, colIndex, selected = false }) => {
  return (
    <Calculation
      comparison={comparison}
      vin={vin}
      value={value}
      rowIndex={rowIndex}
      colIndex={colIndex}
      selected={selected}
    />
  )
}

export const SellingPrice: FC<{
  vin: string
}> = ({ vin }) => {
  const theme = useTheme()
  const {
    states: {
      dealDesk: { defaultCurrency }
    }
  } = useStoreContext()
  const { state, dispatch } = useDeskingContext()
  const { vehiclesData, order, isVehicleSelectedAndOrderSaved } = state
  const vehicleData = vehiclesData.get(vin)
  const doesVehicleDataExist = Object.keys(vehicleData ?? {}).length > 0
  const sellingPrice =
    isVehicleSelectedAndOrderSaved || order?.selling_price
      ? order?.selling_price
      : vehicleData?.vehicle?.selling_price ?? vehicleData?.vehicle?.internet_price ?? '00,000.00'

  const [debouncedState, setState, _state] = useDebouncedState(sellingPrice)

  const { isLoading: programLoading } = useGetProgramInformation(vin, state?.submission_tier, 0)

  useEffect(() => {
    if (doesVehicleDataExist && Number(sellingPrice) !== Number(debouncedState)) {
      dispatch({
        type: DeskingActionTypes.UPDATE_SELLING_PRICE,
        payload: {
          vinUSP: vin as string,
          valueUSP: Number(debouncedState ?? sellingPrice)
        }
      })
    }
  }, [debouncedState])

  return programLoading ? (
    <VehicleLoader width={'100%'} />
  ) : (
    <Input
      theme={theme}
      type="text"
      fullWidth
      startAdornment={defaultCurrency?.symbol}
      value={_state}
      masking
      maskNumeric
      maskDecimalScale={2}
      onChange={(e) => setState(Number(e))}
      disabled={Boolean(state?.submission_tier)}
    />
  )
}

const renderRow = (row: any[], rowIndex: number, vin: string | undefined, theme: Theme) => {
  const firstItem = row[0]

  if (firstItem === 'Financed Amount' || firstItem === 'LTV%') {
    return (
      <Box theme={theme} key={rowIndex} className="flc-li flc-footer" sx={{ m: '0 !important' }}>
        <Box theme={theme} className="flc-li-left-col">
          <Box theme={theme} className="flc-labels-wrap">
            <Box theme={theme} className="label-row">
              <Label name={firstItem} />
            </Box>
          </Box>
        </Box>
        {row.slice(1).map((value, index) => (
          <Box key={index} theme={theme} className="flc-li-selfStretch-col border-bottom">
            <Value value={value} isCurrency={firstItem !== 'LTV%'} />
          </Box>
        ))}
      </Box>
    )
  }

  if (typeof firstItem === 'string') {
    return (
      <Box theme={theme} key={rowIndex} className="flc-li">
        <Box theme={theme} className="flc-li-left-col">
          <Box theme={theme} className="flc-labels-wrap">
            <Box theme={theme} className="label-row">
              <Label name={firstItem} />
            </Box>
          </Box>
        </Box>
        {row.slice(1).map((value, colIndex) => (
          <Box key={colIndex} theme={theme} className="flc-li-selfStretch-col">
            <DownPayment vin={vin} rowIndex={rowIndex} colIndex={colIndex + 1} value={value} />
          </Box>
        ))}
      </Box>
    )
  }

  if (typeof firstItem === 'object') {
    return (
      <Box theme={theme} key={rowIndex} className="flc-li">
        <QuoteParam vin={vin} rowIndex={rowIndex} colIndex={0} value={firstItem} />
        {row.slice(1).map((value, colIndex) => (
          <CalculationBox
            key={`${VehiclesForComparisonTypes.SINGLE}-${rowIndex}-${colIndex}`}
            comparison={VehiclesForComparisonTypes.SINGLE}
            vin={vin}
            value={value}
            rowIndex={rowIndex}
            colIndex={colIndex + 1}
          />
        ))}
      </Box>
    )
  }
  return null
}

const CalculationGrid: FC = () => {
  const theme = useTheme()
  const { state } = useDeskingContext()
  const { finance_type, vehiclesVins, vehiclesForComparison } = state
  const calculationBoxes = state[vehiclesForComparison][finance_type].calculationsBoxes
  const vin = vehiclesVins[0]

  return (
    <>
      <Box theme={theme} className="flc-wrap">
        <Box theme={theme} className="flc-ul">
          {/********************** Add Vehicle **********************/}
          <Box theme={theme} className="flc-li">
            <AddVehicle vin={vin} index={0} />
          </Box>
          {/********************** Header **********************/}
          <Box theme={theme} className="flc-li">
            <Box theme={theme} className="flc-li-left-col">
              <Box theme={theme} className="flc-labels-wrap">
                <Box theme={theme} className="label-row">
                  <Label name={'Selling Price'} />
                </Box>
              </Box>
            </Box>
            <Box theme={theme} className="flc-li-selfStretch-col">
              <SellingPrice vin={vin} />
            </Box>
          </Box>
          {calculationBoxes.map((row: any[], rowIndex: number) =>
            renderRow(row, rowIndex, vin, theme)
          )}
        </Box>
      </Box>
    </>
  )
}

export default CalculationGrid
