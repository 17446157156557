import { FC, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { Autocomplete, TextField, useTheme } from '@mui/material'
import { Input, MultiSelect } from 'components'
import { unityTheme, Select, Box } from '@ntpkunity/controls'
import { validation } from '@helpers/methods'
import {
  useSaveDealerFee,
  useUpdateDealerFeeById,
  useGetFees
} from '@apis/dealer-configurations.service'

import { useStoreContext } from '@store/storeContext'
import { useGetStates } from '@apis/configurations.service'
import { IDealerFee } from '@models'

interface IFormInputs {
  id?: number
  fee_name: string
  states: number[]
  default_amount: number
  taxable: boolean
  is_active: boolean
  dealer_id: number
}

const DealerFeeDrawer: FC<{
  form: any
  formData?: IFormInputs
  isEditMode: boolean
}> = ({ form, formData, isEditMode }) => {
  const { actions } = useStoreContext()
  const theme = useTheme()
  const { mutate: SaveDealeFee } = useSaveDealerFee()
  const { mutate: updateDealerFee } = useUpdateDealerFeeById()
  const { mutate: getStates, data: statesData } = useGetStates()
  const { mutate: getFees, data: feeData } = useGetFees()
  const [stateNames, setStateNames] = useState<string[]>([])
  const onSubmit = async (data: any) => {
    if (data) {
      data.fee_name = feeName
      if (isEditMode) {
        updateDealerFee(
          {
            ...data
          },
          {
            onError(error: any) {
              actions.setToast({
                toastMessage: error.error.toString(),
                toastState: true,
                variant: 'error'
              })
            }
          }
        )
      } else {
        SaveDealeFee(
          {
            ...data,
            default_amount: +data.default_amount
          },
          {
            onSuccess(response: IDealerFee) {
              actions.addDealerFeeRow({
                ...response,
                state_name: stateNames.join(',')
              })
              actions.setToast({
                toastMessage: 'Data Saved Sucessfully',
                toastState: true
              })
            },
            onError(error: any) {
              actions.setToast({
                toastMessage: error.error.toString(),
                toastState: true,
                variant: 'error'
              })
            }
          }
        )
      }
    }
  }
  const {
    control,
    formState: { errors },
    setValue
  } = form

  const [feeName, setFeeName] = useState<string>()
  useEffect(() => {
    getStates()
    getFees()
  }, [])
  useEffect(() => {
    onSubmit(formData)
  }, [formData])
  return (
    <>
      {feeData && (
        <Box theme={unityTheme} sx={{ mb: 3 }}>
          <Controller
            name="fee_name"
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <Autocomplete
                freeSolo
                {...field}
                inputValue={field.value}
                options={feeData.map((option: any) => {
                  return { label: option.fee_name }
                })}
                getOptionLabel={(option: any) => (option.label ? option.label : field.value)}
                onInputChange={(_e: any, value) => {
                  setFeeName(value)
                  setValue('fee_name', value)
                  field.onChange(_e)
                }}
                onChange={(_e: any, value: any) => {
                  setFeeName(value?.label)
                  setValue('fee_name', value?.label)
                }}
                sx={{ mb: 2 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Fee Name"
                    variant="outlined"
                    defaultValue={field.value}
                    fullWidth
                  />
                )}
              />
            )}
          />
        </Box>
      )}
      <Controller
        name="states"
        control={control}
        rules={validation('State Name', true)}
        render={({ field: { value, onChange } }) => (
          <MultiSelect
            theme={theme}
            label={'State Name'}
            placeholder={''}
            items={statesData?.map((state: any) => {
              return { value: state.id, text: state.name }
            })}
            value={value}
            onChange={(e: any) => {
              setStateNames(
                e.target.value.map(
                  (val: number) => statesData.find((state_id: any) => state_id.id === val)?.name
                )
              )
              onChange(e)
            }}
            error={errors?.states?.message}
            sxProps={''}
          />
        )}
      />
      <Controller
        name="default_amount"
        control={control}
        rules={validation('Default Amount', true)}
        render={({ field }) => (
          <Input
            theme={theme}
            label={'Default Amount'}
            fullWidth
            {...field}
            type="number"
            error={errors?.default_amount?.message}
          />
        )}
      />
      <Controller
        name="taxable"
        control={control}
        defaultValue={''}
        rules={{
          validate: (value) => {
            if (value == null) {
              return 'Taxable is required'
            }
            return true
          }
        }}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            label={'Taxable'}
            items={[
              { text: 'Yes', value: true },
              { text: 'No', value: false }
            ]}
            value={value}
            sxProps={''}
            onChange={(e) => {
              onChange(e)
            }}
            selectError={errors?.taxable?.message}
          ></Select>
        )}
      />
      <Controller
        name="is_active"
        control={control}
        defaultValue={true}
        render={({ field: { value, onChange } }) => (
          <Select
            theme={theme}
            label={'Status'}
            items={[
              { text: 'Enabled', value: true },
              { text: 'Disabled', value: false }
            ]}
            value={value}
            sxProps={''}
            onChange={onChange}
          ></Select>
        )}
      />
    </>
  )
}

export default DealerFeeDrawer
