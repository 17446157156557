import { FC, useState, memo, useEffect } from 'react'
import { useTheme } from '@mui/material'
import { Icon, FileDragDrop, FilePreview, Typography } from '@ntpkunity/controls'
import { Grid, Box } from 'components'
import { getBase64 } from '@helpers/methods'
import { ICustomer, INonFinancialStipulation, IStipulationResponse } from '@models'
import { useModal } from 'react-modal-hook'
import { ImageDialog } from 'components/Dialog/ImageDialog'
import { FileDragDropWrap } from './order-stipulations-style'
import { useStoreContext } from '@store/storeContext'

interface INonFinancialStipulationProps {
  stipulations: IStipulationResponse[]
  customer: ICustomer
  nonStips: INonFinancialStipulation[]
  setNonStips: (data: any) => void
  setTempNonStip: (data: any) => void
  orderReference: any
  documents: any
  setDocuments: (data: any) => void
  generalizedStipText: string | undefined
  orderId: number
  saveOrderDocumentsHandler: (docs: any) => void
  deleteOrderDocumentsHandler: (identifier: string) => void
}

const NonFinancialStipulation: FC<INonFinancialStipulationProps> = ({
  stipulations,
  customer,
  nonStips,
  setDocuments,
  documents,
  orderReference,
  generalizedStipText,
  orderId,
  saveOrderDocumentsHandler,
  deleteOrderDocumentsHandler
}) => {
  const theme = useTheme()
  const { states } = useStoreContext()
  const [selectedImage, setSelectedImage] = useState<string>()
  const [toggle, setToggle] = useState<boolean>(false)
  const [stipCode, setStipCode] = useState('')
  const [groupedDocumentUrls, setGroupedDocumentUrls] = useState<
    Array<{
      identifier: string
      external_reference_id: any
      orderReference: any
      document_type: string
      document_urls: Array<{
        location: string
        name: string
        size: number
      }>
    }>
  >([])

  const uploadDocumentHandler = async (
    e: any,
    identifier: string,
    order_reference_number: string,
    document_type: string
  ) => {
    const selectedFile = e.type === 'drop' ? e.dataTransfer.files[0] : e.target.files?.[0]
    const base64 = await getBase64(selectedFile)

    const data = {
      customer_reference_id: customer?.reference_id,
      external_reference_id: order_reference_number,
      document_type: document_type,
      document_name: selectedFile.name,
      document_size: selectedFile.size,
      created_by: states.lenderInfo.id,
      document: base64,
      category: 'STIP',
      stipulation_code: stipCode,
      content_type: 'Application',
      updated_by: states?.lenderInfo.id
    }
    const filteredDocuments = documents.filter((doc: any) => doc.document_size !== null)
    const docs = [...filteredDocuments, data]
    saveOrderDocumentsHandler(docs)
    setDocuments(docs)

    setGroupedDocumentUrls((prevGroupedDocumentUrls) => {
      const updatedGroupedDocumentUrls = [...prevGroupedDocumentUrls]
      const filteredGroupedDocumentUrls = updatedGroupedDocumentUrls.map((group) => ({
        ...group,
        document_urls: group.document_urls.filter((url) => url.location !== null)
      }))
      const existingGroupIndex = filteredGroupedDocumentUrls.findIndex(
        (group) =>
          group.external_reference_id === order_reference_number &&
          group.identifier === identifier &&
          group.orderReference === orderReference
      )
      if (existingGroupIndex !== -1) {
        filteredGroupedDocumentUrls[existingGroupIndex].document_urls.push({
          location: 'LOCATION',
          name: selectedFile.name,
          size: selectedFile.size
        })
      } else {
        filteredGroupedDocumentUrls.push({
          document_type: selectedFile.type,
          document_urls: [
            {
              location: 'LOCATION',
              name: selectedFile.name,
              size: selectedFile.size
            }
          ],
          identifier,
          external_reference_id: order_reference_number,
          orderReference
        })
      }
      return filteredGroupedDocumentUrls
    })
  }

  const uploadGeneralizedDocuments = async (
    e: any,
    order_reference_number: number,
    document_type: string
  ) => {
    const selectedFile = e.type === 'drop' ? e.dataTransfer.files[0] : e.target.files?.[0]
    const base64 = await getBase64(selectedFile)

    const data = {
      customer_reference_id: customer?.reference_id,
      external_reference_id: order_reference_number,
      document_type: document_type,
      document_name: selectedFile.name,
      document_size: selectedFile.size,
      created_by: states.lenderInfo.id,
      document: base64,
      category: 'STIP',
      stipulation_code: stipCode,
      content_type: 'Application',
      updated_by: states?.lenderInfo.id
    }
    const filteredDocuments = documents.filter((doc: any) => doc.document_size !== null)
    const docs = [...filteredDocuments, data]
    saveOrderDocumentsHandler(docs)
    setDocuments(docs)
  }

  const onCrossFile = (_identifier: string, _order_id: any, index: any, isGeneralized: boolean) => {
    const newArray = [...documents]
    const item = newArray[index]
    newArray.splice(index, 1)
    setDocuments(newArray)
    deleteOrderDocumentsHandler(item?.identifier)
    if (!isGeneralized) {
      const newStateArray = [...groupedDocumentUrls]
      newStateArray[0].document_urls.splice(index, 1)
      setGroupedDocumentUrls(newStateArray)
    }
  }

  const [openOptionModal, closeOptionModal] = useModal(
    () => (
      <>
        <ImageDialog hide={closeOptionModal} selectedImage={selectedImage as any} />
      </>
    ),
    [selectedImage]
  )
  useEffect(() => {
    const groupedUrls: {
      [key: string]: {
        document_type: string
        document_urls: Array<{
          location: string
          name: string
          size: number
        }>
        identifier: string
        external_reference_id: any
        orderReference: any
      }
    } = {}
    nonStips?.forEach((stipulation) => {
      setStipCode(stipulation.stipulation_code)
      const {
        document_type,
        document_url,
        identifier,
        external_reference_id,
        document_name,
        document_size
      } = stipulation
      const existingGroup = groupedUrls[document_type]
      if (existingGroup) {
        existingGroup.document_urls.push({
          location: document_url,
          name: document_name,
          size: document_size
        })
      } else {
        groupedUrls[document_type] = {
          document_type,
          document_urls: [
            {
              location: document_url,
              name: document_name,
              size: document_size
            }
          ],
          identifier,
          external_reference_id,
          orderReference
        }
      }
    })
    const groupedArray = Object.values(groupedUrls)
    setGroupedDocumentUrls(groupedArray)
  }, [nonStips])

  return (
    <>
      {!generalizedStipText && (
        <Typography className="mini-title" component={'div'} theme={theme}>
          Non-Financial Stipulations
        </Typography>
      )}
      {stipulations?.map((stipulation, index) => {
        const doc_url = nonStips?.find(
          (stip) => (stip as any).stipulation_code === stipulation.stipulation_code
        )?.document_url
        return (
          <div key={`non-financial-${index}`}>
            <Typography
              theme={theme}
              className="notify"
              variant="body2"
              component="p"
              onClick={() => setToggle(!toggle)}
            >
              <Icon
                className="icon"
                name={`${
                  doc_url !== null && doc_url !== undefined ? 'GreenCheckIcon' : 'InfoIcon'
                }`}
              />
              The {stipulation.description} are not valid, Please upload again.
            </Typography>
          </div>
        )
      })}
      {toggle && (
        <FileDragDropWrap theme={theme} className="fileDragDropWrap">
          <Box className="order-detail" sx={{ p: 2 }}>
            <Grid theme={theme} spacing={2} container>
              {groupedDocumentUrls?.map((stipulation: any, index) => (
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  key={`file-upload-${index}`}
                  className="file-row"
                >
                  <FileDragDrop
                    id={stipulation.identifier}
                    theme={theme}
                    hoverLabel="Drag and drop image file, or browse"
                    onChange={(e) => {
                      uploadDocumentHandler(
                        e,
                        stipulation.identifier,
                        stipulation.external_reference_id,
                        stipulation.document_type
                      )
                    }}
                    onDrop={(e) =>
                      uploadDocumentHandler(
                        e,
                        stipulation.identifier,
                        stipulation.external_reference_id,
                        stipulation.document_type
                      )
                    }
                  />
                  <Typography
                    className="file-caption"
                    children={stipulation.document_type}
                    theme={theme}
                    component="p"
                    variant="body2"
                  />
                  {stipulation?.document_urls.find((item: any) => item.location !== null) &&
                    stipulation?.document_urls?.length > 0 && (
                      <FilePreview
                        files={stipulation?.document_urls}
                        onRemoveFile={(_e, index: any) =>
                          onCrossFile(
                            stipulation.identifier,
                            stipulation.external_reference_id,
                            index,
                            false
                          )
                        }
                        theme={theme}
                        onClickIcon={() => {
                          setSelectedImage(stipulation.document_url)
                          openOptionModal()
                        }}
                      />
                    )}
                </Grid>
              ))}
            </Grid>
          </Box>
        </FileDragDropWrap>
      )}

      {generalizedStipText && (
        <FileDragDropWrap theme={theme} className="fileDragDropWrap">
          <Box className="order-detail" sx={{ p: 2 }}>
            <Grid theme={theme} spacing={2} container>
              <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className="file-row">
                <FileDragDrop
                  id="generalized_stip_docs"
                  theme={theme}
                  hoverLabel="Drag and drop image file, or browse"
                  onChange={(e) => {
                    uploadGeneralizedDocuments(e, orderId, 'general_stip')
                  }}
                  onDrop={(e) => uploadGeneralizedDocuments(e, orderId, 'general_stip')}
                />
                {documents?.length > 0 && (
                  <FilePreview
                    files={documents?.map((doc: any) => {
                      doc.name = doc?.document_name
                      doc.size = doc?.document_size
                      return doc
                    })}
                    preview="fileIcon"
                    onRemoveFile={(_e, index: any) => onCrossFile('', '', index, true)}
                    theme={theme}
                    onClickIcon={() => {}}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </FileDragDropWrap>
      )}
    </>
  )
}

export default memo(NonFinancialStipulation)
