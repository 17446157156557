import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { Grid, Input, Select } from '@ntpkunity/controls'
import { useStoreContext } from '@store/storeContext'

const Address: FC = () => {
  //@ts-ignore
  const {
    states: { licenseInformation }
  } = useStoreContext()
  const [licenseStateSelect, setLicenseStateSelect] = useState([{ value: '', text: '' }])
  const theme = useTheme()
  useEffect(() => {
    if (licenseInformation) {
      setLicenseStateSelect([
        {
          value: licenseInformation?.address?.state_name,
          text: licenseInformation?.address?.state_name
        }
      ])
    }
  }, [licenseInformation])
  return (
    <Grid theme={theme} container columnSpacing={3} rowSpacing={4}>
      <Grid theme={theme} item xs={12}>
        <Input
          theme={theme}
          type={'text'}
          disabled
          value={licenseInformation?.address?.address_line_1}
          fullWidth
          label="Address Line 1"
          placeholder="House number, street name"
        />
      </Grid>
      <Grid theme={theme} item xs={12}>
        <Input
          theme={theme}
          type={'text'}
          disabled
          value={licenseInformation?.address?.address_line_2}
          fullWidth
          label="Address Line 2 (Optional)"
          placeholder="e.g. company, appartment, building..."
        />
      </Grid>
      <Grid theme={theme} item md={3} sm={6} xs={12}>
        <Input
          theme={theme}
          type={'text'}
          disabled
          fullWidth
          label="City"
          value={licenseInformation?.address?.city}
          placeholder="Type here.."
        />
      </Grid>
      <Grid theme={theme} item md={3} sm={6} xs={12}>
        <Select
          theme={theme}
          label={'State'}
          disabled
          items={licenseStateSelect}
          value={licenseStateSelect[0]?.text}
        />
      </Grid>
      <Grid theme={theme} item md={3} sm={6} xs={12}>
        <Input
          theme={theme}
          fullWidth
          placeholder="0000"
          disabled
          value={licenseInformation?.address?.zip_code}
          label="Zip Code"
          type="text"
        />
      </Grid>
      {/* <Grid theme={theme} item md={3} sm={6} xs={12}>
                  <Select theme={theme} label={"County"} disabled items={select} />
            </Grid> */}
    </Grid>
  )
}

export default Address
