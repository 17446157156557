import { QueryKeys } from '@helpers/enums'
import { useQuery } from 'react-query'
import { GetCountries, GetStates } from './ServiceHelper'

export const useGetCountries = () => {
  return useQuery(QueryKeys.GET_ALL_COUTRIES, async () => {
    return GetCountries()
  })
}

export const useGetStates = () => {
  return useQuery(QueryKeys.GET_ALL_STATES, async () => {
    return GetStates()
  })
}

export const useGetCustomer = () => {
  return useQuery(QueryKeys.GET_CUSTOMER, async () => {
    return {}
  })
}
