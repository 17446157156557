import { FC } from 'react'
import { useTheme } from '@mui/material'

import { LayoutWithSideNav } from '@styles/layout'
import { DashboardStyle } from './dashboard.style'
import { Box, Grid, Typography } from '@ntpkunity/controls'
// import { GraphWidget } from 'pages/Dashboard/GraphWidget/graphWidget'
// import { ListWidget } from 'pages/Dashboard/ListWidget/listWidget'
// import Img from '../../public/assets/images/no-img.svg'
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title
} from 'chart.js'
// import { Doughnut } from 'react-chartjs-2'
// import { Line } from 'react-chartjs-2'
// import faker from 'faker';
// import * as faker from '@faker-js/faker';
import { faker } from '@faker-js/faker'

export const data = {
  labels: ['Red', 'Blue', 'Yellow', 'Green'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 1
    }
  ]
}
export const options = {
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      position: 'top' as const
    },
    title: {
      display: false
      // text: 'Chart.js Line Chart',
    }
  }
}

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July']

export const lineChart = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)'
    },
    {
      label: 'Dataset 2',
      data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)'
    }
  ]
}

const Dashboard: FC = () => {
  const theme = useTheme()
  ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Title,
    Legend
  )

  return (
    <LayoutWithSideNav theme={theme}>
      <DashboardStyle theme={theme} className="content-wrap">
        <Typography theme={theme} component="h2" variant="h2" sx={{ mt: 3 }}>
          Product Analytics
        </Typography>
        <Box theme={theme} sx={{ opacity: 0.3, mt: 3, mb: 3 }}>
          <hr />
        </Box>
        <Grid theme={theme} container spacing={3}>
          {/* <Grid theme={theme} item lg={8} md={12} sm={12}>
            <GraphWidget
              theme={theme}
              tabs={true}
              widgetTitle="Total Revenue: $12,345.67"
              children={
                <>
                  <Box theme={theme} className="graph-widget-container">
                    <Line options={options} data={lineChart} />
                  </Box>
                </>
              }
            />
          </Grid>
          <Grid theme={theme} item lg={4} md={6} sm={12}>
            <GraphWidget
              theme={theme}
              select={true}
              widgetTitle="Active Orders"
              children={
                <>
                  <Box theme={theme} className="graph-widget-container">
                    <Doughnut options={options} data={data} />
                  </Box>
                </>
              }
            />
          </Grid>
          <Grid theme={theme} item lg={4} md={6} sm={12}>
            <GraphWidget
              theme={theme}
              select={true}
              widgetTitle="Approved Orders"
              children={
                <>
                  <Box theme={theme} className="graph-widget-container">
                    <Doughnut data={data} />
                  </Box>
                </>
              }
            />
          </Grid>
          <Grid theme={theme} item lg={8} md={12} sm={12}>
            <GraphWidget
              theme={theme}
              tabs={true}
              widgetTitle="Total Number Of Orders"
              children={
                <>
                  <Box theme={theme} className="graph-widget-container">
                    <Line options={options} data={lineChart} />
                  </Box>
                </>
              }
            />
          </Grid>
          <Grid theme={theme} item lg={8} md={12} sm={12}>
            <GraphWidget
              theme={theme}
              select={true}
              widgetTitle="Unavailable Assets"
              children={
                <>
                  <Box theme={theme} className="graph-widget-container">
                    <Line options={options} data={lineChart} />
                  </Box>
                </>
              }
            />
          </Grid>
          <Grid theme={theme} item lg={4} md={6} sm={12}>
            <ListWidget theme={theme} widgetTitle="Top Selling Models">
              <Box theme={theme} className="list-item-wrap">
                <Box theme={theme} className="list-item">
                  <Box theme={theme} className="list-img">
                    <img src={Img} alt={'products.product_name'} />
                  </Box>
                  <Box theme={theme} className="list-content">
                    <Typography theme={theme} variant="body1" component="p" className="fw-medium">
                      Make / Model
                    </Typography>
                    <Box
                      theme={theme}
                      className="counter-text"
                      display={'flex'}
                      alignItems={'center'}
                    >
                      <Typography
                        theme={theme}
                        variant="body2"
                        component="span"
                        display={'block'}
                        className="text-muted"
                      >
                        Total Sold: 5640
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box theme={theme} className="list-item">
                  <Box theme={theme} className="list-img">
                    <img src={Img} alt={'products.product_name'} />
                  </Box>
                  <Box theme={theme} className="list-content">
                    <Typography theme={theme} variant="body1" component="p" className="fw-medium">
                      Make / Model
                    </Typography>
                    <Box
                      theme={theme}
                      className="counter-text"
                      display={'flex'}
                      alignItems={'center'}
                    >
                      <Typography
                        theme={theme}
                        variant="body2"
                        component="span"
                        display={'block'}
                        className="text-muted"
                      >
                        Total Sold: 5640
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </ListWidget>
          </Grid> */}
          <Grid theme={theme} item lg={12} md={12} sm={12}>
            <iframe
              width="100%"
              height="2150"
              title="dashboard"
              style={{ border: '1px solid #EBECF2', borderRadius: '8px' }}
              src="https://lookerstudio.google.com/embed/reporting/bca8fc4b-31dd-4b53-ae30-4e5f8a3e5c6c/page/4VDGB"
            ></iframe>
          </Grid>
        </Grid>
      </DashboardStyle>
    </LayoutWithSideNav>
  )
}

export default Dashboard
