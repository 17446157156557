import { styled } from '@mui/material'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const ChatHeaderWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.messages-header-wrap': {
    padding: 24,
    position: 'relative',
    display: 'flex',
    gap: 24,
    alignItems: 'center',
    borderBottom: '1px solid' + theme.palette.grey[50],
    '.sender-wrap': {
      display: 'flex',
      gap: 8,
      flexGrow: 1,
      alignItems: 'center',
      '.sender-avatar': {
        position: 'relative',
        '.status': {
          position: 'absolute',
          bottom: 0,
          right: 0,
          width: 11,
          height: 11,
          borderRadius: 8,
          border: '2px solid' + theme.palette.common.white,
          '&.online, ': {
            backgroundColor: theme.palette.success.main
          },
          '&.offline': {
            backgroundColor: theme.palette.error.main
          },
          '&.away': {
            backgroundColor: theme.palette.warning.main
          }
        }
      },
      '.sender-details': {
        flexGrow: 1,
        maxWidth: 165
      }
    },
    '.btn.with-icon.icon-only': {
      padding: 0,
      svg: {
        path: {
          stroke: theme.palette.grey[400]
        }
      },
      '&:hover': {
        backgroundColor: 'initial',
        svg: {
          path: {
            stroke: theme.palette.grey[400]
          }
        }
      },
      '&.phone-icon': {
        svg: {
          path: {
            stroke: 'transparent',
            fill: theme.palette.grey[400]
          }
        }
      }
    }
  }
}))
