import { FC } from 'react'
import { styled, useTheme } from '@mui/material'
// import { PageHeader } from 'components'
import { Typography, Box, Icon, IBoxProps } from '@ntpkunity/controls'

const NotFoundWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({}) => ({
  '&.result-not-found': {
    height: 'calc(100vh - 260px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    svg: {
      width: '100%',
      height: 'calc(100vh - 475px)'
    }
  },
  '@media screen and (max-width: 525px)': {
    '&.result-not-found': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 'calc(100vh - 70px)',
      svg: {
        width: '100%',
        height: 'auto'
      }
    }
  }
}))

const NotFound: FC = () => {
  const theme = useTheme()
  return (
    <>
      <NotFoundWrap theme={theme} className="result-not-found">
        <Box theme={theme}>
          <Icon name="NoInventoyIcon" />
          <Typography theme={theme} variant="h3" component="h3" sx={{ mt: 4, mb: 1 }}>
            No Results Found!
          </Typography>
          <Typography theme={theme} variant="body2" component="p">
            The asset you are looking for is not available.
          </Typography>
          <Typography theme={theme} variant="body2" component="p">
            Please look for some other asset to proceed with the deal.
          </Typography>
        </Box>
      </NotFoundWrap>
    </>
  )
}

export default NotFound
