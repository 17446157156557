import { FC, useEffect, useMemo, useState } from 'react'
import { useTheme } from '@mui/material'
import { Box, Button, PersistentDrawer } from 'components'

import DealerFeeDrawer from './dealer-fee-drawer'
import DealerFeesTable from './dealer-fee-table'
import { useForm } from 'react-hook-form'

interface IFormInputs {
  id?: number
  fee_name: string
  states: number[]
  default_amount: number
  taxable: boolean
  is_active: boolean
  dealer_id: number
}
const DealerFees: FC<{
  form: any
  onPopupStateChange: (popUpState: any) => unknown
}> = ({ onPopupStateChange }) => {
  const [popUpState, setPopUpState] = useState(false)
  const [formData, setFormData] = useState<IFormInputs>()
  const [formDefaultData, setFormDefaultData] = useState<IFormInputs>()
  const [isEditMode, setIsEditMode] = useState(false)
  onPopupStateChange(popUpState)
  const onSubmit = (formValues: IFormInputs): void => {
    setFormData(formValues)
    setPopUpState(false)
    setIsEditMode(false)
    feeForm.reset()
  }
  const handleshow = () => {
    setFormDefaultData({} as IFormInputs)
    feeForm.reset({} as IFormInputs)
    setPopUpState(true)
    setIsEditMode(false)
  }
  const feeForm: {
    handleSubmit: any
    reset: any
  } = useForm<IFormInputs>()

  const theme = useTheme()

  const defaultValues: IFormInputs = useMemo(() => {
    return formDefaultData
  }, [formDefaultData]) as unknown as IFormInputs

  const onEdit = (formData: IFormInputs) => {
    setFormDefaultData(formData)
    setIsEditMode(true)
    setPopUpState(true)
  }
  useEffect(() => {
    feeForm.reset(defaultValues)
  }, [defaultValues])
  useEffect(() => {
    if (!popUpState) {
      setIsEditMode(false)
    }
  }, [popUpState])

  return (
    <>
      <DealerFeesTable onEdit={(data) => onEdit(data)}></DealerFeesTable>
      <Box theme={theme} sx={{ mt: 2 }}>
        <Button secondary fullWidth text={'Add New Fees'} onClick={handleshow}></Button>
      </Box>
      <PersistentDrawer
        title={'Add Dealer Fees'}
        openPopUp={popUpState}
        setPopUpState={setPopUpState}
        theme={theme}
        customFooter={
          <Button
            primary
            type="submit"
            text="Save Dealer Fees"
            fullWidth
            onClick={feeForm.handleSubmit(onSubmit)}
          />
        }
      >
        {popUpState && (
          <form onSubmit={(e) => e.preventDefault()}>
            <DealerFeeDrawer
              form={feeForm}
              formData={formData}
              isEditMode={isEditMode}
            ></DealerFeeDrawer>
          </form>
        )}
        {/* <DealerFeeDrawer></DealerFeeDrawer> */}
      </PersistentDrawer>
    </>
  )
}

export default DealerFees
