import { FC } from 'react'
import { useTheme } from '@mui/material'

import { LayoutWithSideNav } from '@styles/layout'
import { DashboardStyle } from './dashboard.style'
import { Box, Grid, Typography } from '@ntpkunity/controls'

const DashboardImage: FC = () => {
  const theme = useTheme()

  return (
    <LayoutWithSideNav theme={theme}>
      <DashboardStyle theme={theme} className="content-wrap">
        <Typography theme={theme} component="h2" variant="h2" sx={{ mt: 3 }}>
          Business Analytics
        </Typography>
        <Box theme={theme} sx={{ opacity: 0.3, mt: 3, mb: 3 }}>
          <hr />
        </Box>
        <Grid theme={theme} container spacing={3}>
          <Grid theme={theme} item lg={12} md={12} sm={12}>
            <iframe
              width="100%"
              height="2150"
              title="Business Analytics"
              style={{ border: '1px solid #EBECF2', borderRadius: '8px' }}
              src="https://lookerstudio.google.com/embed/reporting/6058d3bf-6487-4a23-8865-83b4224c5a14/page/lSwbD"
            ></iframe>
          </Grid>
        </Grid>
      </DashboardStyle>
    </LayoutWithSideNav>
  )
}

export default DashboardImage
