import { useStoreContext } from '@store/storeContext'
import { useMutation } from 'react-query'
import { IFeedback } from '@models'
import { ToastMessages } from '@helpers/enums'
import Http from '@helpers/http-api'

export const UseMutation_sendFeedbackEmail = (): any => {
  const { actions } = useStoreContext()
  return useMutation<IFeedback>(
    (feedback) => {
      const apiService = Http.createConnection({
        baseUrl: process.env.INTEGRATION_API_GATEWAY
      })
      return apiService.post<any>('email/send-feedback-email', feedback)
    },
    {
      onSuccess: async () => {
        actions.setToast({
          toastMessage: ToastMessages.Feedback_Submitted_Successfully,
          toastState: true
        })
      }
    }
  )
}
