import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const ProductBanner: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.product-banner': {
    backgroundColor: theme.customVariables.tableStripedBg,
    padding: 32,
    marginBottom: 24,
    borderRadius: 8,
    fontFamily: theme.typography.fontFamily,

    '.pb-wrap': {
      width: '100%',
      display: 'flex',
      gap: 32
    },

    '.thumbnail': {
      width: 185,
      height: 104,
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      verticalAlign: 'middle',
      overflow: 'hidden',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: 'rgba(0,0,0,0.05)',
      img: {
        maxWidth: '100%',
        maxHeight: 104,
        width: 'auto',
        height: 'auto',
        display: 'block',
        margin: 'auto'
      }
    },

    '.product-details': {
      width: '100%',
      '.title-md': {
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body2.lineHeight,
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.grey[600],
        width: '100%',
        display: 'block',
        clear: 'both'
      },

      '.title-lg': {
        fontSize: theme.typography.h3.fontSize,
        lineHeight: theme.typography.h3.lineHeight,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.grey[900],
        width: '100%',
        display: 'block',
        clear: 'both',
        marginBottom: 16
      },

      '.title-sm': {
        fontSize: theme.typography.caption.fontSize,
        lineHeight: theme.typography.caption.lineHeight,
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.grey[600],
        width: '100%',
        display: 'block',
        clear: 'both',
        marginBottom: '4px',

        '&:last-child': {
          marginBottom: 0
        },

        b: {
          fontWeight: theme.typography.fontWeightBold,
          color: theme.palette.grey[900]
        }
      },
      '.title-xs': {
        fontSize: '10px'
      },
      '.top-hide': {
        opacity: 0,
        visibility: 'hidden'
      },

      '.bottom': {
        position: 'relative',
        top: 35
      },

      a: {
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline'
        }
      },
      '.text-danger': {
        color: theme.palette.error.main + '!important'
      },
      '.actions-wrap': {
        display: 'flex',
        width: '100%',
        justifyContent: 'end',
        gap: 16,

        '.action-details': {
          display: 'inline-block'
        },

        '.actions': {
          width: 200,
          '.btn': {
            width: 200,
            paddingLeft: 5,
            paddingRight: 5,
            '&:first-child': {
              marginBottom: 6
            }
          }
        }
      }
    },
    '.product-opions': {
      display: 'inline-flex',
      flexDirection: 'column',
      Button: {
        clear: 'both',
        '&:first-child': {
          marginBottom: 16
        }
      },

      [theme.breakpoints.down('md')]: {
        width: '100%',
        flexDirection: 'row',
        Button: {
          flex: 1,
          '&:first-child': {
            marginBottom: 0,
            marginRight: 16
          }
        }
      }
    }
  }
}))
