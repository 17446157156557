import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import { Button, Typography, Grid, DuoTab, DrawerWrap, PageHeader } from 'components'
import { Box, IBoxProps } from '@ntpkunity/controls'
import { styled } from '@mui/material/styles'
import {
  DealerFees,
  DealerProfile,
  DealerTradeIn,
  Preferences,
  DealerPayment
} from '@app/dealer-configurations'
import { useForm } from 'react-hook-form'
import {
  useGetPreferenceByDealerCode,
  useSaveDealerPreference,
  useUpdateDealerPreference,
  useSaveDealerTradeIn,
  useUpdateDealerTradeIn,
  useGetTradeInByDealerId,
  useSaveDealerProfile,
  useGetProfilebyDealerId,
  useUpdateDealerProfilebyId,
  useSaveDealerPayment,
  useGetPaymentByDealerId,
  useUpdateDealerPaymentById
} from '@apis/dealer-configurations.service'
import {
  IAddress,
  IDealerPreference,
  IDealerProfile,
  IDealerTradeIn,
  IDealerPayment
} from '@models'
import { getUser } from '@helpers/methods'
import { useStoreContext } from '@store/storeContext'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { EMAIL_REGEX, WEBSITE_REGEX } from '@helpers/constants'

const ErrorTypography = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.error': {
    color: theme.palette.error.main
  }
}))

interface IFormInputs {
  preferences?: {
    id?: number | null
    default_mileage_id?: number | null
    default_term_id?: number | null
    minimum_down_payment_id?: number | null
    maximum_down_payment_id?: number | null
    default_down_payment_id?: number | null
    quotation_expiry_days?: number | null
    application_expiry_days?: number | null
    dealer_id?: number | null
  }
  profile?: {
    id?: number | null
    available_finance_types: string | null
    schedule_option: string | null
    contact_number: string | null
    customer_support_number: string | null
    time_zone: string | null
    fax_number?: string | null
    email: string | null
    website: string | null
    contact_person_name: string | null
    day_light_saving: boolean | null
    initials: string | null
    dealership_address: IAddress | undefined
    dealer_id: number | null
    market_scan_number: string | null
    pen_dealer_id?: string | null
  }
  payment?: {
    id?: number | null
    stripe_registered_email?: string | null
    bank_account_limit?: number | null
    credit_card_limit?: number | null
    debit_card_limit?: number | null
    dealer_id?: number | null
    email_verified?: boolean | null
  }

  tradein?: {
    id?: number | null
    partner_name?: string
    dealer_id?: number | null
    percentage?: number | null
  }
}

const schema = yup.object().shape({
  preferences: yup
    .object()
    .shape({
      default_mileage_id: yup.string().nullable().required('Default Mileage is required'),
      default_term_id: yup.string().nullable().required('Default Term is required'),
      minimum_down_payment_id: yup
        .number()
        .typeError('Minimum Down Payment is required')
        .positive()
        .min(0, 'Minimum Down Payment is invalid')
        .max(yup.ref('maximum_down_payment_id'), 'Minimum Down Payment is invalid')
        .required('Minimum Down Payment is required'),
      maximum_down_payment_id: yup
        .number()
        .typeError('Maximum Down Payment is required')
        .positive()
        .min(yup.ref('minimum_down_payment_id'), 'Maximum Down Payment is invalid')
        .max(100, 'Maximum Down Payment is invalid')
        .required('Maximum Down Payment is required'),
      default_down_payment_id: yup
        .number()
        .typeError('Default Down Payment is required')
        .positive()
        .min(yup.ref('minimum_down_payment_id'), 'Default Down Payment is invalid')
        .max(yup.ref('maximum_down_payment_id'), 'Default Down Payment is invalid')
        .required('Default Down Payment is required')
    })
    .required(),
  profile: yup
    .object()
    .shape({
      available_finance_types: yup
        .string()
        .nullable()
        .required('Available Finance Type is required'),
      schedule_option: yup.string().nullable().required('Schedule Option is required'),
      contact_number: yup.string().nullable().required('Contact Number is required'),
      customer_support_number: yup
        .string()
        .nullable()
        .required('Customer Support Number is required'),
      time_zone: yup.string().nullable().required('Time Zone is required'),
      email: yup
        .string()
        .nullable()
        .matches(EMAIL_REGEX, 'Valid Email is Required.')
        .required('Email is required'),
      website: yup
        .string()
        .nullable()
        .matches(WEBSITE_REGEX, 'Valid Website is Required.')
        .required('Website is required'),
      contact_person_name: yup.string().nullable().required('Contact Person Name is required'),
      day_light_saving: yup
        .boolean()
        .required('Day Light Saving is required')
        .oneOf([true, false], 'Day Light Saving is required'),
      initials: yup.string().nullable().required('Initials is required'),
      dealership_address: yup
        .object()
        .shape({
          address_line_1: yup.string().nullable().required('Address Line 1 is required'),
          state_id: yup.number().required('State is required').typeError('State is required'),
          zip_code: yup.string().nullable().required('Zip Code is required'),
          county: yup.string().nullable().required('County is required'),
          city: yup.string().nullable().required('City is required')
        })
        .required(),
      market_scan_number: yup.string().nullable().required('Tax Service Provider ID is required')
    })
    .required(),
  payment: yup
    .object()
    .shape({
      //stripe_registered_email: yup.string().required(""),
      bank_account_limit: yup
        .number()
        .required('Bank Account Limit is required')
        .typeError('Bank Account Limit is required'),
      credit_card_limit: yup
        .number()
        .required('Credit Card Limit is required')
        .typeError('Credit Card Limit is required'),
      debit_card_limit: yup
        .number()
        .required('Debit Card Limit is required')
        .typeError('Debit Card Limit is required')
    })
    .required(),
  tradein: yup.object().shape({
    partner_name: yup.string().nullable().notRequired(),
    percentage: yup.number().when('partner_name', {
      is: undefined,
      then: yup.number().notRequired(),
      otherwise: yup
        .number()
        .required('Percentage is required')
        .typeError('Percentage is required')
        .min(0, 'Minimum Percentage is invalid')
        .max(100.0, 'Maximum Percentage should be less than or equal to 100.00')
    })
  })
})
const DealerConfigurations: FC = () => {
  const [popUpState, setPopUpState] = useState(false)
  const { actions } = useStoreContext()
  const theme = useTheme()
  const [isEditMode, setIsEditMode] = useState(false)
  const formDefaultData = useRef<IFormInputs>({})
  const { mutate: addDealerPreference } = useSaveDealerPreference()
  const { mutate: getDealerPreference } = useGetPreferenceByDealerCode()
  const { mutate: updateDealerPreference } = useUpdateDealerPreference()
  const { mutate: addDealerTradeIn } = useSaveDealerTradeIn()
  const { mutate: getDealerTradeIn } = useGetTradeInByDealerId()
  const { mutate: updateDealerTradeIn } = useUpdateDealerTradeIn()

  const { mutate: saveDealerProfile } = useSaveDealerProfile()
  const { mutate: getDealerProfile } = useGetProfilebyDealerId()
  const { mutate: updateDealerProfile } = useUpdateDealerProfilebyId()
  const { mutate: saveDealerPayment } = useSaveDealerPayment()
  const { mutate: getDealerPayment } = useGetPaymentByDealerId()
  const { mutate: updateDealerPayment } = useUpdateDealerPaymentById()
  const dealerData = getUser()
  const [preferencesData, setPreferencesData] = useState<any>()
  const [profileData, setProfileData] = useState<any>()
  const [paymentData, setPaymentData] = useState<any>()
  const [tradeInData, setTradeInData] = useState<any>()
  const [partner] = useState([
    {
      text: 'KBB',
      value: 1
    }
  ])

  const defaultValues: IFormInputs = useMemo(() => {
    setPreferencesData(formDefaultData.current.preferences)
    setProfileData(formDefaultData.current.profile)
    setPaymentData(formDefaultData.current.payment)
    setTradeInData(formDefaultData.current.tradein)
    return JSON.parse(JSON.stringify(formDefaultData.current))
  }, [formDefaultData.current]) as unknown as IFormInputs

  const form = useForm<IFormInputs>({
    defaultValues,
    shouldUnregister: false,
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })
  const {
    trigger,
    reset,
    formState: { errors }
  } = form

  const tabs = [
    {
      title: (
        <ErrorTypography
          className={Object.keys(errors).includes('profile') ? 'error' : ''}
          theme={theme}
        >
          General
        </ErrorTypography>
      ),
      content: <DealerProfile form={form} />
    },
    {
      title: (
        <ErrorTypography
          className={Object.keys(errors).includes('preferences') ? 'error' : ''}
          theme={theme}
        >
          Preferences
        </ErrorTypography>
      ),
      content: <Preferences form={form} />
    },
    {
      title: (
        <ErrorTypography
          className={Object.keys(errors).includes('payment') ? 'error' : ''}
          theme={theme}
        >
          Payment
        </ErrorTypography>
      ),
      content: <DealerPayment form={form} />
    },
    {
      title: (
        <ErrorTypography
          className={Object.keys(errors).includes('tradein') ? 'error' : ''}
          theme={theme}
        >
          Trade-In
        </ErrorTypography>
      ),
      content: <DealerTradeIn form={form} />
    },
    {
      title: 'Dealer Fees',
      content: (
        <DealerFees
          form={form}
          onPopupStateChange={(popUpState) => {
            setPopUpState(popUpState)
          }}
        />
      )
    }
  ]

  const beforeSubmit = () => {
    let required_validation_failed = false
    let other_validation_failed = false
    Object.entries(errors).map((val) => {
      val.forEach((entry) => {
        if (typeof entry !== typeof '') {
          Object.values(entry)
            .filter((errorVal) => errorVal.valueOf())
            .map((error) => {
              if ((error as { [key: string]: any })['type'] === 'required') {
                required_validation_failed = true
              } else {
                other_validation_failed = true
              }
            })
        }
      })
    })

    if (required_validation_failed && !other_validation_failed) {
      onSubmit(form.getValues())
      actions.setToast({
        toastMessage:
          'Changes have been saved but mandatory information required to activate your profile.',
        toastState: true
      })
    } else if (other_validation_failed) {
      actions.setToast({
        toastMessage: 'Some of the fields has invalid data.',
        toastState: true,
        variant: 'error'
      })
    } else if (!required_validation_failed && !other_validation_failed) {
      onSubmit(form.getValues())
      actions.setToast({
        toastMessage: 'Record Saved Successfully',
        toastState: true
      })
    }
  }

  const onSubmit = (formValues: IFormInputs): void => {
    if (formValues) {
      formValues.preferences = {
        id: formValues.preferences?.id ? formValues.preferences?.id : null,
        default_mileage_id: formValues.preferences?.default_mileage_id
          ? formValues.preferences?.default_mileage_id
          : null,
        default_term_id: formValues.preferences?.default_term_id
          ? formValues.preferences?.default_term_id
          : null,
        minimum_down_payment_id: formValues.preferences?.minimum_down_payment_id
          ? formValues.preferences?.minimum_down_payment_id
          : null,
        maximum_down_payment_id: formValues.preferences?.maximum_down_payment_id
          ? formValues.preferences?.maximum_down_payment_id
          : null,
        default_down_payment_id: formValues.preferences?.default_down_payment_id
          ? formValues.preferences?.default_down_payment_id
          : null,
        quotation_expiry_days: formValues.preferences?.quotation_expiry_days
          ? parseInt(formValues.preferences?.quotation_expiry_days.toString())
          : null,
        application_expiry_days: formValues.preferences?.application_expiry_days
          ? parseInt(formValues.preferences?.application_expiry_days.toString())
          : null,

        dealer_id: dealerData.id
      }
      formValues.profile = {
        id: formValues.profile?.id ? formValues.profile?.id : null,
        available_finance_types: formValues.profile?.available_finance_types
          ? formValues.profile?.available_finance_types
          : null,
        schedule_option: formValues.profile?.schedule_option
          ? formValues.profile?.schedule_option
          : null,
        contact_number: formValues.profile?.contact_number
          ? formValues.profile?.contact_number
          : null,
        customer_support_number: formValues.profile?.customer_support_number
          ? formValues.profile?.customer_support_number
          : null,
        time_zone: formValues.profile?.time_zone ? formValues.profile?.time_zone : null,
        fax_number: formValues.profile?.fax_number ? formValues.profile?.fax_number : null,
        email: formValues.profile?.email ? formValues.profile?.email : null,
        website: formValues.profile?.website ? formValues.profile?.website : null,
        contact_person_name: formValues.profile?.contact_person_name
          ? formValues.profile?.contact_person_name
          : null,
        day_light_saving: formValues.profile ? formValues.profile?.day_light_saving : null,
        initials: formValues.profile?.initials ? formValues.profile?.initials : null,
        dealer_id: dealerData.id,
        market_scan_number: formValues.profile?.market_scan_number
          ? formValues.profile?.market_scan_number
          : null,
        dealership_address: formValues.profile?.dealership_address
          ? formValues.profile?.dealership_address
          : ({} as IAddress)
      }
      formValues.tradein = {
        id: formValues.tradein?.id ? formValues.tradein?.id : null,
        partner_name: partner.find(
          (x) =>
            x.value == +(formValues?.tradein?.partner_name ? formValues.tradein.partner_name : '')
        )?.text,
        percentage: formValues.tradein?.percentage ? formValues.tradein?.percentage : null,
        dealer_id: dealerData.id
      }
      formValues.payment = {
        id: formValues.payment?.id ? formValues.payment?.id : null,
        stripe_registered_email: 'sample@gmail.com',
        bank_account_limit: formValues.payment?.bank_account_limit
          ? formValues.payment?.bank_account_limit
          : null,
        credit_card_limit: formValues.payment?.credit_card_limit
          ? formValues.payment?.credit_card_limit
          : null,
        debit_card_limit: formValues.payment?.debit_card_limit
          ? formValues.payment?.debit_card_limit
          : null,
        dealer_id: dealerData.id,
        email_verified: false
      }
    }
    if (isEditMode) {
      updateDealerPreference(formValues.preferences, {
        onSuccess() {
          // actions.setToast({
          //   toastMessage: "Record Updated Successfully",
          //   toastState: true,
          // });
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: 'error'
          })
        }
      })
      updateDealerProfile(formValues.profile, {
        onSuccess(response: IDealerProfile) {
          // actions.setToast({
          //   toastMessage: "Record Updated Successfully",
          //   toastState: true,
          // });
          setIsEditMode(true)
          if (response) {
            formDefaultData.current = {
              ...formDefaultData.current,
              profile: response
            }
          }
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: 'error'
          })
        }
      })

      updateDealerTradeIn(formValues.tradein, {
        onSuccess(response: any) {
          // actions.setToast({
          //   toastMessage: "Record Updated Successfully",
          //   toastState: true,
          // });
          setIsEditMode(true)
          if (response) {
            formDefaultData.current = {
              ...formDefaultData.current,
              tradein: response
            }
          }
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: 'error'
          })
        }
      })

      updateDealerPayment(formValues.payment, {
        onSuccess(response: IDealerPayment) {
          // actions.setToast({
          //   toastMessage: "Record Updated Successfully",
          //   toastState: true,
          // });
          setIsEditMode(true)
          if (response) {
            formDefaultData.current = {
              ...formDefaultData.current,
              payment: response
            }
          }
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: 'error'
          })
        }
      })
    } else {
      addDealerPreference(formValues.preferences, {
        onSuccess(response: IDealerPreference) {
          // actions.setToast({
          //   toastMessage: "Record Saved Successfully",
          //   toastState: true,
          // });
          setIsEditMode(true)
          if (response) {
            formDefaultData.current = {
              ...formDefaultData.current,
              preferences: response
            }
          }
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: 'error'
          })
        }
      })
      addDealerTradeIn(formValues.tradein, {
        onSuccess(response: IDealerTradeIn) {
          // actions.setToast({
          //   toastMessage: "Record Saved Successfully",
          //   toastState: true,
          // });
          setIsEditMode(true)
          if (response) {
            formDefaultData.current = {
              ...formDefaultData.current,
              tradein: response
            }
          }
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: 'error'
          })
        }
      })

      saveDealerProfile(formValues.profile, {
        onSuccess(response: IDealerProfile) {
          // actions.setToast({
          //   toastMessage: "Record Saved Successfully",
          //   toastState: true,
          // });
          setIsEditMode(true)
          if (response) {
            formDefaultData.current = {
              ...formDefaultData.current,
              profile: response
            }
          }
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: 'error'
          })
        }
      })
      saveDealerPayment(formValues.payment, {
        onSuccess(response: IDealerPayment) {
          // actions.setToast({
          //   toastMessage: "Record Saved Successfully",
          //   toastState: true,
          // });
          setIsEditMode(true)
          if (response) {
            formDefaultData.current = {
              ...formDefaultData.current,
              payment: response
            }
          }
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error.error.toString(),
            toastState: true,
            variant: 'error'
          })
        }
      })
    }
  }
  useEffect(() => {
    getDealerPreference(
      { id: dealerData.id },
      {
        onSuccess(data: IDealerPreference) {
          if (data) {
            formDefaultData.current = {
              ...formDefaultData.current,
              preferences: data
            }
            setIsEditMode(true)
          } else {
            formDefaultData.current = {
              ...formDefaultData.current,
              preferences: undefined
            }
            setIsEditMode(false)
          }
        }
      }
    )
    getDealerProfile(
      { id: dealerData.id },
      {
        onSuccess(data: IDealerProfile) {
          if (data) {
            formDefaultData.current = {
              ...formDefaultData.current,
              profile: data
            }
            setIsEditMode(true)
          } else {
            formDefaultData.current = {
              ...formDefaultData.current,
              profile: undefined
            }
            setIsEditMode(false)
          }
        }
      }
    )

    getDealerTradeIn(
      { id: dealerData.id },
      {
        onSuccess(data: IDealerTradeIn) {
          if (data) {
            formDefaultData.current = {
              ...formDefaultData.current,
              tradein: data
            }
            setIsEditMode(true)
          } else {
            formDefaultData.current = {
              ...formDefaultData.current,
              tradein: undefined
            }
            setIsEditMode(false)
          }
        }
      }
    )
    getDealerPayment(
      { id: dealerData.id },
      {
        onSuccess(data: IDealerPayment) {
          if (data) {
            formDefaultData.current = {
              ...formDefaultData.current,
              payment: data
            }
            setIsEditMode(true)
          } else {
            formDefaultData.current = {
              ...formDefaultData.current,
              payment: undefined
            }
            setIsEditMode(false)
          }
        }
      }
    )
  }, [])
  useEffect(() => {
    if (defaultValues?.tradein?.partner_name)
      defaultValues.tradein.partner_name = partner
        .find((x) => x.text == defaultValues.tradein?.partner_name)
        ?.value?.toString()
    reset(defaultValues, { keepErrors: true, keepDirty: true })
  }, [preferencesData, profileData, paymentData, tradeInData])
  return (
    <DrawerWrap open={popUpState}>
      <LayoutWithSideNav theme={theme}>
        <PageHeader className="main-page-header" theme={theme} container item spacing={2}>
          <Grid container spacing={2}>
            <Grid item lg={7} md={6} sm={12} xs={12}>
              <Typography variant="h2" component="h2" theme={theme}>
                Dealer Profile
              </Typography>
            </Grid>
            <Grid item lg={5} md={6} sm={12} xs={12} textAlign="right">
              <Button
                primary
                text={'Save Changes'}
                type="submit"
                onClick={async () => {
                  await trigger(['profile', 'preferences', 'payment', 'tradein'])
                  beforeSubmit()
                }}
              ></Button>
            </Grid>
          </Grid>
        </PageHeader>

        <form onSubmit={(e) => e.preventDefault()}>
          <DuoTab theme={theme} varient={'underline'} items={tabs} defaultTabIndex={0} />
        </form>
      </LayoutWithSideNav>
    </DrawerWrap>
  )
}

export default DealerConfigurations
