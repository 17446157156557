import { FC } from 'react'
import { useTheme } from '@mui/material'
import { Grid } from '@ntpkunity/controls'
import CalculationGrid from './finance-lease-calculator'
import PaymentDetails from './payment-details'
import { FinanceLeaseHeader } from './finance-lease-header'
import MultipleVehicleComparison from './product-list-view'
import { useDeskingContext, VehiclesForComparisonTypes } from './desking-context-provider'
import DealPreviewComponent from './deal-preview-component'

const CalculationSection: FC = () => {
  const theme = useTheme()
  const { state } = useDeskingContext()
  const { vehiclesForComparison, isVehicleSelectedAndOrderSaved } = state

  return (
    <>
      {(!state?.isStipulationScreen || state?.submission_tier) && <FinanceLeaseHeader />}
      <Grid theme={theme} container columnSpacing={2} rowSpacing={2}>
        <Grid theme={theme} item xl={6} lg={7} md={12} sm={12} xs={12}>
          {isVehicleSelectedAndOrderSaved ? (
            <DealPreviewComponent />
          ) : (
            <>
              {vehiclesForComparison === VehiclesForComparisonTypes.SINGLE ? (
                <CalculationGrid />
              ) : (
                <MultipleVehicleComparison />
              )}
            </>
          )}
        </Grid>
        <Grid theme={theme} item xl={6} lg={5} md={12} sm={12} xs={12}>
          <PaymentDetails />
        </Grid>
      </Grid>
    </>
  )
}

export default CalculationSection
