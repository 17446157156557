import { FC, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTheme } from '@mui/material'
import {
  Icon,
  Select,
  FileDragDrop,
  DatePicker,
  Grid,
  Input,
  Box,
  Typography,
  Button
} from '@ntpkunity/controls'
import dayjs from 'dayjs'
import { ICustomerLicenseData, ILenderIntegration } from '../../models/customer-details.interface'
import { Call_Type } from '../../helpers/enums/license-details.enums'
import {
  useAddLicenseDetails,
  useAddLicenseDetailsToMitek,
  // useGetCustomerLicenseInfo,
  useGetIntegrationByProviderName,
  useGetStates,
  useUpdateCustomerLicenseInfo
} from '../../apis/customer-detail.service'
import { formatPayloadDate, isEmpty, isValidUrl, validation } from '../../helpers/methods'
import { CustomImageUploader } from '@components'
import { useStoreContext } from '@store/storeContext'
import { IntegrationProvider, LenderIntegrationType } from '@helpers/enums'

const messages = {
  name: {
    firstName: 'first_name',
    lastName: 'last_name',
    front_image: 'front_image',
    back_image: 'back_image',
    licenseNumber: 'license_number',
    licenseIssueState: 'issue_state',
    expiry_date: 'expiry_date',
    dateOfBirth: 'date_of_birth'
  },
  label: {
    firstName: 'First Name',
    lastName: 'Last Name',
    licenseNumber: 'License Number',
    licenseIssueState: 'License Issue State',
    expiry_date: 'Expiration',
    dateOfBirth: 'Date Of Birth',
    front_image: 'Front Image',
    back_image: 'Back Image'
  },
  placeholder: {
    typeHere: 'Type here...',
    back_image_placeholder: "Upload the image of the back side of your driver's license.",
    front_image_placeholder: "Upload the image of the front side of your driver's license."
  }
}

interface ILicenseDetailsComponentProps {
  setIsLicense?: (value: boolean) => void
  setIsLicenseErrors?: (value: boolean) => void
  setRoute: (value: number) => void
  licenseoData?: any
  customerReferenceId: any
  dealer_code?: any
}

export const LicenseDetailsComponent: FC<ILicenseDetailsComponentProps> = ({
  setIsLicense,
  setIsLicenseErrors,
  setRoute,
  licenseoData,
  customerReferenceId,
  dealer_code
}) => {
  const theme = useTheme()
  const {
    control,
    formState: { errors, isDirty, dirtyFields },
    getValues,
    setValue,
    watch,
    trigger,
    reset,
    handleSubmit
  } = useForm<ICustomerLicenseData>({
    mode: 'onChange'
  })
  const { states } = useStoreContext()
  const { mutate: createLicenseInfoByReferenceId } = useAddLicenseDetails(dealer_code ?? '')
  const { data: MitekData, mutate: verifyLicenseOnMitek } = useAddLicenseDetailsToMitek()
  const { mutate: updateLicenseByInfoReferenceId } = useUpdateCustomerLicenseInfo(dealer_code ?? '')
  const { data: licenseStates } = useGetStates()

  const { mutate: getProvider, data: mitekProvider } = useGetIntegrationByProviderName(
    IntegrationProvider.MITEK,
    LenderIntegrationType.IDENTITY_VERIFICATION
  )
  const [, setProvider] = useState<ILenderIntegration>()

  useEffect(() => {
    if (
      MitekData?.mitek_verification_status === 'Verified' ||
      MitekData?.mitek_verification_status === 'Rejected'
    ) {
      let fetch_data = licenseStates?.find(
        (field: { code: any }) =>
          field?.code ===
          MitekData?.mitek_response?.evidence[0]?.extractedData?.address?.stateProvince
      )
      setValue(
        'first_name',
        MitekData?.mitek_response?.evidence[0]?.extractedData?.name?.givenNames,
        { shouldDirty: true }
      )
      setValue('last_name', MitekData?.mitek_response?.evidence[0]?.extractedData?.name?.surname, {
        shouldDirty: true
      })
      setValue(
        'date_of_birth',
        MitekData?.mitek_response?.evidence[0]?.extractedData?.dateOfBirth,
        { shouldDirty: true }
      )
      setValue('expiry_date', MitekData?.mitek_response?.evidence[0]?.extractedData?.dateOfExpiry, {
        shouldDirty: true
      })
      setValue(
        'license_number',
        MitekData?.mitek_response?.evidence[0]?.extractedData?.documentNumber,
        { shouldDirty: true }
      )
      setValue('issue_state', fetch_data?.id, { shouldDirty: true })
      trigger([
        'date_of_birth',
        'first_name',
        'last_name',
        'expiry_date',
        'license_number',
        'issue_state'
      ])
    }
    if (MitekData?.mitek_verification_status === 'Manual Review') {
      setValue('first_name', '', { shouldDirty: true })
      setValue('last_name', '', {
        shouldDirty: true
      })
      setValue('date_of_birth', '', { shouldDirty: true })
      setValue('expiry_date', '', {
        shouldDirty: true
      })
      setValue('license_number', '', { shouldDirty: true })
      setValue('issue_state', '', { shouldDirty: true })
    }
  }, [MitekData])

  useEffect(() => {
    getProvider({
      provider_name: IntegrationProvider.MITEK,
      integration_type: LenderIntegrationType.IDENTITY_VERIFICATION
    })
    setProvider(mitekProvider)
  }, [])

  useEffect(() => {
    if (licenseoData) {
      reset({
        first_name: licenseoData?.first_name,
        last_name: licenseoData?.last_name,
        expiry_date: formatPayloadDate(dayjs(licenseoData?.expiry_date)),
        date_of_birth: formatPayloadDate(dayjs(licenseoData?.date_of_birth)),
        license_number: licenseoData?.license_number,
        front_image: licenseoData?.front_image,
        back_image: licenseoData?.back_image,
        issue_state: licenseoData?.address?.state_name
      })
    }
  }, [licenseoData])

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      if (file) {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
          const result: string = reader.result as string
          const base64Data = result.split(',')[1] // Remove the data URL scheme
          resolve(base64Data)
        }
        reader.onerror = (error) => reject(error)
      } else {
        resolve(null)
      }
    })
  }

  const hasErrors = Object.keys(errors).length > 0
  if (hasErrors) {
    setIsLicense?.(false)
    setIsLicenseErrors?.(hasErrors)
  }

  useEffect(() => {
    return () => {
      if (licenseoData) {
        setIsLicenseErrors?.(true)
        setIsLicense?.(true)
      }
    }
  }, [licenseoData])

  const onSubmit = async (data: ICustomerLicenseData) => {
    const {
      first_name,
      last_name,
      issue_state,
      license_number,
      front_image,
      expiry_date,
      date_of_birth,
      back_image
    } = data

    let front_img_string: string = ''
    let back_img_string: string = ''
    if (dirtyFields?.front_image) {
      front_img_string = (await getBase64(front_image)) as string
    }
    if (dirtyFields?.back_image) {
      back_img_string = (await getBase64(back_image)) as string
    }

    const partialPayload = Object.keys(dirtyFields).reduce<Partial<ICustomerLicenseData>>(
      (payload, field) => {
        const key = field as keyof ICustomerLicenseData

        if (key in dirtyFields && dirtyFields[key]) {
          if (field === 'front_image') {
            payload[key] = front_img_string
          } else if (field === 'back_image') {
            payload[key] = back_img_string
          } else {
            payload[key] = data[key]
          }
        }

        return payload
      },
      {}
    )

    const payload = {
      ...partialPayload,
      first_name,
      last_name,
      expiry_date: formatPayloadDate(expiry_date),
      date_of_birth: formatPayloadDate(date_of_birth),
      license_number,
      issue_date:
        MitekData && MitekData?.mitek_response?.evidence[0]?.dateOfIssue !== null
          ? formatPayloadDate(MitekData?.mitek_response?.evidence[0]?.dateOfIssue)
          : null,
      status:
        MitekData && MitekData?.mitek_verification_status === 'Approved' ? 'Approved' : 'Rejected',
      call_type: MitekData ? Call_Type.AUTO : Call_Type.MANUAL,
      reference_id: customerReferenceId,
      mitek_response: null,
      address: {
        address_line_1:
          MitekData && MitekData?.mitek_response?.evidence[0]?.extractedData?.addressLine1 !== null
            ? MitekData?.mitek_response?.evidence[0]?.extractedData?.address?.addressLine1
            : null,
        address_line_2:
          MitekData && MitekData?.mitek_response?.evidence[0]?.extractedData?.addressLine2 !== null
            ? MitekData?.mitek_response?.evidence[0]?.extractedData?.address?.addressLine2
            : null,
        state_name: issue_state,
        zip_code:
          MitekData && MitekData?.mitek_response?.evidence[0]?.extractedData?.postalCode !== null
            ? MitekData?.mitek_response?.evidence[0]?.extractedData?.address?.postalCode
            : null,
        city:
          MitekData && MitekData?.mitek_response?.evidence[0]?.extractedData?.city !== null
            ? MitekData?.mitek_response?.evidence[0]?.extractedData?.address?.city
            : null,
        verified: MitekData && MitekData?.mitek_verification_status === 'Approved' ? true : false,
        identifier: MitekData && MitekData?.identifier !== null ? MitekData?.identifier : null
      }
    }
    if (isEmpty(licenseoData)) {
      createLicenseInfoByReferenceId(payload, {
        onSuccess: () => {
          setRoute(2)
          setIsLicense?.(true)
          setIsLicenseErrors?.(true)
        }
      })
    } else if (isDirty) {
      updateLicenseByInfoReferenceId(payload, {
        onSuccess: () => {
          setRoute(2)
          setIsLicense?.(true)
          setIsLicenseErrors?.(true)
        }
      })
    } else {
      setRoute(2)
      setIsLicense?.(true)
      setIsLicenseErrors?.(true)
    }
  }
  const licenseImage = (image: any) => {
    // let image = getValues("front_image");
    let image_url
    const validUrl = isValidUrl(image)
    if (!validUrl) {
      image_url = URL.createObjectURL(image as unknown as File)
    }
    const url = validUrl ? image : image_url ?? ''
    return url
  }

  const verifyLicenseByMitek = async () => {
    if (dirtyFields?.back_image && dirtyFields?.front_image && mitekProvider?.is_active === true) {
      const backImage = await getBase64(watch('back_image'))
      const frontIimage = await getBase64(watch('back_image'))
      const dealerCode =
        mitekProvider?.dealer_code && mitekProvider?.is_active === true
          ? states?.dealerInfo?.dealer_code
          : ''
      const payload = {
        front_image: frontIimage,
        back_image: backImage,
        customer_id: customerReferenceId,
        api_version: 1.2,
        ...(dealerCode ? { dealer_code: dealerCode } : {})
      }
      verifyLicenseOnMitek(payload)
    }
  }

  const onFileChange = (event: any, type: 'front_image' | 'back_image') => {
    setValue(type, (event?.dataTransfer ?? event?.target).files?.[0], {
      shouldDirty: true
    })
  }

  const hasFrontImage = watch('front_image')
  const hasBackImage = watch('back_image')

  return (
    <>
      <Typography theme={theme} component="h3" variant="h3" sx={{ mb: 3 }}>
        License Details
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          theme={theme}
          container
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ mb: 4 }}
        >
          <Grid theme={theme} item xs={12} sm={6}>
            {hasFrontImage ? (
              <CustomImageUploader>
                <Box theme={theme} className="img-wrap">
                  <Button
                    onClick={() =>
                      setValue(messages?.name?.front_image as 'front_image', '', {
                        shouldDirty: true
                      })
                    }
                    theme={theme}
                    className="btn-close"
                    iconText={<Icon name="CloseBlack" />}
                  />
                  <img src={licenseImage(getValues('front_image') as any)} />
                </Box>
              </CustomImageUploader>
            ) : (
              <>
                <Controller
                  name={messages?.name?.front_image as any}
                  control={control}
                  defaultValue={''}
                  render={({ field }) => (
                    <FileDragDrop
                      {...field}
                      theme={theme}
                      id="front_id"
                      hoverLabel="Drag and drop image file, or browse"
                      onChange={async (e) => {
                        onFileChange(e, 'front_image')
                        verifyLicenseByMitek()
                      }}
                      onDrop={async (e) => {
                        onFileChange(e, 'front_image')
                        verifyLicenseByMitek()
                      }}
                    />
                  )}
                />
                <Typography
                  theme={theme}
                  component="p"
                  variant="body2"
                  textAlign={'center'}
                  sx={{ color: theme.palette.grey[600] }}
                >
                  {messages?.placeholder?.front_image_placeholder}
                </Typography>
              </>
            )}
          </Grid>
          <Grid theme={theme} item xs={12} sm={6}>
            {hasBackImage ? (
              <CustomImageUploader>
                <Box theme={theme} className="img-wrap">
                  <Button
                    onClick={() =>
                      setValue(messages?.name?.back_image as 'back_image', '', {
                        shouldDirty: true
                      })
                    }
                    theme={theme}
                    className="btn-close"
                    iconText={<Icon name="CloseBlack" />}
                  />
                  <img src={licenseImage(getValues('back_image') as any)} />
                </Box>
              </CustomImageUploader>
            ) : (
              <>
                <Controller
                  name={messages?.name?.back_image as any}
                  control={control}
                  defaultValue={''}
                  render={({ field }) => (
                    <FileDragDrop
                      {...field}
                      theme={theme}
                      id="back_id"
                      hoverLabel="Drag and drop image file, or browse"
                      onChange={async (e) => {
                        onFileChange(e, 'back_image')
                        verifyLicenseByMitek()
                      }}
                      onDrop={async (e) => {
                        onFileChange(e, 'back_image')
                        verifyLicenseByMitek()
                      }}
                    />
                  )}
                />
                <Typography
                  theme={theme}
                  component="p"
                  variant="body2"
                  textAlign={'center'}
                  sx={{ color: theme.palette.grey[600] }}
                >
                  {messages?.placeholder?.back_image_placeholder}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
        <Box theme={theme} sx={{ mb: 4 }}>
          <hr />
        </Box>
        <Grid
          theme={theme}
          container
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ mb: 4 }}
        >
          <Grid theme={theme} item xs={12} sm={6}>
            <Controller
              name={messages.name.firstName as any}
              control={control}
              rules={validation(messages.label.firstName, true)}
              render={({ field }) => (
                <Input
                  theme={theme}
                  {...field}
                  fullWidth
                  placeholder={messages.placeholder.typeHere}
                  label={messages.label.firstName}
                  type="text"
                  error={errors?.first_name?.message}
                />
              )}
            />
          </Grid>
          <Grid theme={theme} item xs={12} sm={6}>
            <Controller
              name={messages.name.lastName as any}
              control={control}
              rules={validation(messages.label.lastName, true)}
              render={({ field }) => (
                <Input
                  theme={theme}
                  {...field}
                  fullWidth
                  placeholder={messages.placeholder.typeHere}
                  label={messages.label.lastName}
                  type="text"
                  error={errors?.last_name?.message}
                />
              )}
            />
          </Grid>
          <Grid theme={theme} item xs={12} sm={6}>
            <Controller
              name={messages.name.licenseNumber as any}
              control={control}
              rules={{
                maxLength: {
                  value: 50,
                  message: 'Maximum Character Limit Is 50'
                },
                required: {
                  value: true,
                  message: 'License Number is required'
                }
              }}
              render={({ field }) => (
                <Input
                  theme={theme}
                  {...field}
                  fullWidth
                  placeholder={messages.placeholder.typeHere}
                  label={messages.label.licenseNumber}
                  type="text"
                  error={errors?.license_number?.message}
                />
              )}
            />
          </Grid>
          <Grid theme={theme} item xs={12} sm={6}>
            <Controller
              name={messages.name.licenseIssueState as any}
              control={control}
              rules={validation(messages.label.licenseIssueState, true)}
              defaultValue=""
              render={({ field }) => (
                <Select
                  theme={theme}
                  {...field}
                  label={messages.label.licenseIssueState}
                  items={licenseStates?.map((x: { id: any; name: any }) => {
                    return {
                      value: x.id,
                      text: x.name
                    }
                  })}
                  selectError={errors?.issue_state?.message}
                  sxProps={''}
                />
              )}
            />
          </Grid>
          <Grid className="custom-date-picker" theme={theme} item xs={12} sm={6}>
            <Controller
              name={messages.name.expiry_date as any}
              key={licenseoData?.expiry_date}
              control={control}
              rules={{
                ...validation(messages.label.expiry_date, true),
                validate: {
                  notPastOrCurrentDate: (value) => {
                    const selectedDate = dayjs(value).startOf('day')
                    const currentDate = dayjs().startOf('day')
                    if (selectedDate.isBefore(currentDate) || selectedDate.isSame(currentDate)) {
                      return 'Expiry date cannot be less than or equal to current date.'
                    }
                    return true
                  }
                }
              }}
              defaultValue={licenseoData?.expiry_date}
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  label={messages.label.expiry_date}
                  theme={theme}
                  value={value ?? ''}
                  onChange={(e: any) => {
                    if (
                      e instanceof Date &&
                      e.getFullYear() === 1970 &&
                      e.getMonth() === 0 &&
                      e.getDate() === 1
                    ) {
                      onChange(null)
                    } else {
                      onChange(e.toISOString())
                    }
                  }}
                  error={errors?.expiry_date?.message}
                  minDate={dayjs().add(1, 'day')}
                  inputReadOnly
                />
              )}
            />
          </Grid>
          <Grid className="custom-date-picker" theme={theme} item xs={12} sm={6}>
            <Controller
              name={messages.name.dateOfBirth as any}
              key={licenseoData?.dateOfBirth}
              control={control}
              rules={{
                ...validation(messages.label.dateOfBirth, true),
                validate: {
                  notFutureOrCurrentDate: (value) => {
                    const selectedDate = dayjs(value).startOf('day')
                    const currentDate = dayjs().startOf('day')
                    if (selectedDate.isAfter(currentDate) || selectedDate.isSame(currentDate)) {
                      return 'Date Of Birth cannot be equal to or greater than current date.'
                    }
                    return true
                  }
                }
              }}
              defaultValue={licenseoData?.date_of_birth}
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  label={messages.label.dateOfBirth}
                  theme={theme}
                  value={value ?? ''}
                  clearable
                  onChange={(e: any) => {
                    if (
                      e instanceof Date &&
                      e.getFullYear() === 1970 &&
                      e.getMonth() === 0 &&
                      e.getDate() === 1
                    ) {
                      onChange(null)
                    } else {
                      onChange(e.toISOString())
                    }
                  }}
                  error={errors?.date_of_birth?.message}
                  maxDate={dayjs().subtract(1, 'day')}
                  inputReadOnly
                />
              )}
            />
          </Grid>
        </Grid>
        <Box theme={theme} sx={{ mb: 4 }}>
          <hr />
        </Box>
        {isDirty ? (
          <Box theme={theme} textAlign="right" sx={{ mb: 5 }}>
            <Button theme={theme} primary text="Save License Details" type="submit" />
          </Box>
        ) : null}
      </form>
    </>
  )
}

export default LicenseDetailsComponent
