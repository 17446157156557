import { FC, useEffect } from 'react'
import { useTheme } from '@mui/material'
import { Box, Button, Icon, Menu, Typography } from '@ntpkunity/controls'
import { useModal } from 'react-modal-hook'
import { DeskingActions, DeskingActionTypes, useDeskingContext } from './desking-context-provider'
import CustomerDetailsControl from 'controls/customer-details-control'
import { formatPhoneNumber } from '@helpers/methods'
import { UseQuery_Get_CustomerContact } from '@apis/customer-detail.service'
import { ICustomer } from '@models'
import { useStoreContext } from '@store/storeContext'

interface ICustomerPopupProps {
  defaultData: any
  hide: () => void
  customerDataCallback: (customerData: any) => void
}

const CustomerPopup: FC<ICustomerPopupProps> = ({ defaultData, hide, customerDataCallback }) => {
  return (
    <CustomerDetailsControl
      defaultData={defaultData}
      checkCustomerStatusData={customerDataCallback}
      closedModelFunction={hide}
      creditScore={true}
    />
  )
}

interface ICustomDetailProps {
  customerDatail: any
}

export const useCustomerModal = (dispatch: React.Dispatch<DeskingActions>) => {
  const {
    state: { customer }
  } = useDeskingContext()

  const { data: customerData } = UseQuery_Get_CustomerContact((customer as ICustomer).reference_id)
  useEffect(() => {
    if (customerData) {
      dispatch({
        type: DeskingActionTypes.ADD_CUSTOMER_ADDRESS,
        payload: customerData?.customer_addresses
      })
    }
  }, [customerData])
  const handleCustomerData = (data: any) => {
    dispatch({
      type: DeskingActionTypes.ADD_CUSTOMER,
      payload: data
    })
    hideCustomerPopup()
  }

  const [showCustomerPopup, hideCustomerPopup] = useModal(
    () => (
      <CustomerPopup
        defaultData={customer}
        hide={hideCustomerPopup}
        customerDataCallback={handleCustomerData}
      />
    ),
    [customer]
  )

  return { showCustomerPopup, hideCustomerPopup }
}

const CustomDetail: FC<ICustomDetailProps> = ({ customerDatail }) => {
  const theme = useTheme()
  const { state, dispatch } = useDeskingContext()
  const { showCustomerPopup } = useCustomerModal(dispatch)
  const { states } = useStoreContext()

  const creditTerms = states?.dealDesk?.creditTerms || []

  const { email, mobile_number, credit_score, credit_rating } = customerDatail
  const matchedTerm = creditTerms.find((term) => term?.description === credit_rating)
  const creditRatingDetail = matchedTerm
    ? `${matchedTerm.description}: ${matchedTerm.score_from} - ${matchedTerm.score_to}`
    : '-'

  const disableButton = Object.keys(state?.order).length !== 0

  return (
    <>
      <Box theme={theme} className="vl-item">
        <Box theme={theme} className="vl-details">
          <Typography
            theme={theme}
            variant="body2"
            component="p"
            className="text-primary text-overflow vl-main-title"
            children={<b>Customer</b>}
          />
          <Typography
            theme={theme}
            variant="body2"
            component="p"
            className="text-overflow"
            children={<b>{email}</b>}
          />
          <Typography
            theme={theme}
            variant="caption"
            component="p"
            className=" text-overflow vl-title-ul"
            children={
              <>
                Mobile: <b>{formatPhoneNumber(mobile_number) || '-'}</b>, Credit Score:{' '}
                <b>{credit_score ?? creditRatingDetail}</b>
              </>
            }
          />
        </Box>
        <Menu
          theme={theme}
          disablePortal
          options={[
            {
              optionText: (
                <>
                  <Icon className="menu-icon" name="EditIcon" /> Edit
                </>
              ),
              optionValue: 'edit',
              disabled: disableButton
            },
            {
              optionText: (
                <>
                  <Icon className="menu-icon" name="DeleteIcon" /> Delete
                </>
              ),
              optionValue: 'delete',
              disabled: disableButton
            }
          ]}
          handleOptionClick={(_event, _key, value) => {
            switch (value) {
              case 'delete':
                dispatch({ type: DeskingActionTypes.DELETE_CUSTOMER })
                break
              case 'edit':
                showCustomerPopup()
                break
            }
          }}
          render={(cb) => (
            <Button
              theme={theme}
              defaultBtn
              className="vl-menu-btn"
              iconText={<Icon name="MoreIcon" />}
              onClick={cb}
            />
          )}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        />
      </Box>
    </>
  )
}

export const AddCustomer: FC = () => {
  const theme = useTheme()
  const {
    state: { customer },
    dispatch
  } = useDeskingContext()

  const { showCustomerPopup } = useCustomerModal(dispatch)

  const isCustomerAdded = Object.keys(customer).length > 0

  return (
    <>
      {!isCustomerAdded ? (
        <Button
          theme={theme}
          fullWidth
          startIcon={<Icon name="AddCircleIcon" />}
          text="Add Customer"
          size="large"
          onClick={showCustomerPopup}
        />
      ) : (
        <CustomDetail customerDatail={customer} />
      )}
    </>
  )
}
