import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const NoData: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: '48px',
  color: theme.palette.grey[600],
  backgroundColor: theme.customVariables.tableStripedBg,
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  textAlign: 'center',
  fontFamily: theme.typography.fontFamily,
  '&.nd-lg': {
    lineHeight: '300px'
  }
}))
