import { FC, useState, useEffect } from 'react'
import { useTheme, styled, Link } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useStoreContext } from '@store/storeContext'
import { useGetDataAffordabilityCheck, useGetFindVehicles } from '@apis/configurations.service'
import {
  Accordion,
  Box,
  Button,
  DualSlider,
  Grid,
  Input,
  Select,
  Switch,
  Typography
} from '@ntpkunity/controls'
import { Controller } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { QueryKeys } from '@helpers/enums'
import { VehicleCardView } from './cardView'
// import { Chatbot } from './chatbot'
import { StyleVariables } from '@styles/theme'
interface IFormInputs {
  min_term: string
  max_term: string
  upfront?: string
  annual_usage?: number
  balloon_payment?: number
  trade_in_amount?: number
  trade_in_payoff?: number
  credit_rating?: string
  min_payment: number
  max_payment: number
}

// interface Message {
//   sender: string
//   content: string
// }

const ChatIcon = `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_45051_125926)"><path d="M7.5 8.3H12.5" stroke="%239295A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.5 11.8H12.5" stroke="%239295A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M6.24453 16.493C7.81994 17.4047 9.67319 17.7125 11.4587 17.3588C13.2443 17.0051 14.8403 16.0141 15.9491 14.5706C17.058 13.1271 17.604 11.3296 17.4855 9.51318C17.3669 7.69681 16.5918 5.98554 15.3047 4.69843C14.0176 3.41133 12.3063 2.63623 10.4899 2.51766C8.67355 2.39909 6.87604 2.94514 5.43253 4.05399C3.98902 5.16285 2.99804 6.75883 2.64434 8.54438C2.29064 10.3299 2.59837 12.1832 3.51015 13.7586L2.53281 16.6766C2.49609 16.7867 2.49076 16.9049 2.51742 17.0178C2.54408 17.1308 2.60168 17.2341 2.68377 17.3162C2.76585 17.3983 2.86918 17.4559 2.98216 17.4826C3.09514 17.5092 3.21331 17.5039 3.32344 17.4672L6.24453 16.493Z" stroke="%239295A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_45051_125926"><rect width="20" height="20" fill="white"/></clipPath></defs></svg>')`
const FilterIcon = `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_45059_127354)"><path d="M12.0833 8.33334C13.2339 8.33334 14.1667 7.4006 14.1667 6.25001C14.1667 5.09941 13.2339 4.16667 12.0833 4.16667C10.9327 4.16667 10 5.09941 10 6.25001C10 7.4006 10.9327 8.33334 12.0833 8.33334Z" stroke="%239295A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M3.33334 6.25H10" stroke="%239295A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M14.1667 6.25L16.6667 6.25" stroke="%239295A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.91667 15.8333C9.06726 15.8333 10 14.9006 10 13.75C10 12.5994 9.06726 11.6667 7.91667 11.6667C6.76608 11.6667 5.83334 12.5994 5.83334 13.75C5.83334 14.9006 6.76608 15.8333 7.91667 15.8333Z" stroke="%239295A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M3.33334 13.75H5.83334" stroke="%239295A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M10 13.75L16.6667 13.75" stroke="%239295A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_45059_127354"><rect width="20" height="20" fill="white"/></clipPath></defs></svg>')`

const AffordabilityWrap: any = styled('div')(({ theme }) => ({
  '&.affordability-wrap': {
    '.divider': {
      width: '100%',
      display: 'block',
      margin: '24px 0',
      borderBottom: '1px solid' + theme.palette.divider
    },
    '.u-form-group .u-form-control.MuiInputBase-adornedStart .startAdornment': {
      marginRight: 5
    },

    '.btn-group-list': {
      '.btn': {
        width: '100%',
        padding: '9px 5px',
        display: 'inline-block',
        fontWeight: 'normal',
        lineHeight: 'normal',
        '&.btn-secondary': {
          backgroundColor: StyleVariables.lightGrey,
          borderColor: StyleVariables.lightGrey,
          '&:hover': {
            backgroundColor: StyleVariables.lightGrey,
            borderColor: StyleVariables.lightGrey,
            color: theme.palette.grey[900]
          }
        },
        '&.btn-primary': {
          backgroundColor: theme.palette.grey[700],
          borderColor: theme.palette.grey[700],
          color: theme.palette.common.white,
          fontWeight: theme.customVariables.fontWeightSemiBold
        },
        '.caption-text': {
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.caption.fontSize,
          fontWeight: theme.typography.fontWeightRegular,
          width: '100%',
          display: 'inline-block',
          clear: 'both',
          opacity: 0.5
        },
        '&:hover': {
          '.caption-text': {
            opacity: 0.75
          }
        },
        '&.selected, &.selected:hover': {
          background: theme.palette.grey[700],
          borderColor: theme.palette.grey[700],
          color: theme.palette.common.white,
          '.caption-text': {
            opacity: 1
          }
        }
      }
    },

    '.grey-bg': {
      width: '100%',
      display: 'inline-block',
      backgroundColor: StyleVariables.lightGreyB,
      borderRadius: 16,

      '.scroll-container': {
        padding: '0 80px',
        [theme.breakpoints.down('lg')]: {
          padding: '0 20px'
        }
      }
    },

    '.footer-text': {
      display: 'block',
      textAlign: 'center',
      color: theme.palette.grey[600],
      maxWidth: 775,
      margin: '24px auto'
    },

    '.filters-wrap': {
      width: '100%',
      display: 'inline-block',
      padding: '24px 80px 20px 80px',
      [theme.breakpoints.down('lg')]: {
        padding: '24px 20px 20px 20px'
      },
      '.btn': {
        float: 'left',
        marginRight: 8,
        padding: '8px 16px',

        '&:last-child': {
          marginRight: 0
        },

        '&.btn-secondary': {
          backgroundColor: theme.palette.common.white,
          borderColor: theme.palette.common.white,
          '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.grey[900],
            borderColor: theme.palette.grey[900]
          }
        },

        '&.btn-reset': {
          float: 'right',

          '&:hover': {
            backgroundColor: 'rgba(0,0,0, 0.05))'
          }
        }
      },
      '.MuiPopover-root .MuiMenu-paper': {
        padding: 24,
        maxHeight: 'calc(100vh - 50px) !important',
        minWidth: 200,
        overflow: 'initial',

        '.MuiPopover-root .MuiMenu-paper': {
          padding: '8px 1px',
          overflowY: 'auto',
          overflowX: 'hidden'
        }
      },
      '.title': {
        marginBottom: 16,
        '&.w-max': {
          width: 232
        }
      },
      '.custom-checkbox-wrap:last-child': {
        '.u-custom-control-label': {
          marginBottom: 0
        }
      }
    },

    '.card-list': {
      width: '100%',
      display: 'inline-block',

      '.card-list-item': {
        width: '100%',
        display: 'inline-block',
        marginBottom: 16,

        '&.with-bg': {
          borderRadius: 8,
          overflow: 'hidden',
          backgroundColor: theme.palette.common.white,
          padding: '22px 24px',
          display: 'flex',
          transition: 'all 0.3s'
        },

        '&.with-toggle': {
          display: 'block',
          textAlign: 'center',
          marginTop: -24,
          marginBottom: 0,

          '.MuiButtonBase-root': {
            width: 32,
            height: 32,
            borderRadius: 32,
            padding: 4,
            backgroundColor: theme.palette.common.white,
            border: '1px solid' + theme.palette.grey[50],
            boxShadow: '0px 0 8px 0px rgba(0, 0, 0, 0.10)',

            '&.Mui-selected': {
              backgroundColor: theme.palette.common.white,
              svg: {
                transform: 'scaleY(-1)'
              }
            }
          }
        },

        '.thumbnail': {
          height: 105,
          width: '100%',
          display: 'flex',
          verticalAlign: 'middle',
          textAlign: 'center',
          borderRadius: 8,
          overflow: 'hidden',
          border: '1px solid' + theme.palette.grey[50],

          img: {
            maxWidth: '100%',
            maxHeight: 105,
            width: 'auto',
            height: 'auto',
            display: 'block',
            margin: 'auto'
          }
        },

        '.MuiTypography-root': {
          width: '100%',
          display: 'inline-block',
          color: theme.palette.grey[900]
        },

        h4: {
          marginBottom: 16
        },

        small: {
          color: theme.palette.grey[600],

          b: {
            color: theme.palette.grey[900]
          }
        },

        '.details': {
          visibility: 'visible',
          opacity: 1,
          marginRight: 0,
          transition: 'all 0.3s'
        },

        '.buttons': {
          maxWidth: 210,
          float: 'right',
          visibility: 'hidden',
          opacity: 0,
          marginRight: -100,
          marginTop: -100,
          transition: 'all 0.3s',

          '.btn': {
            paddingLeft: 10,
            paddingRight: 10,

            '&.btn-secondary': {
              marginBottom: 8
            }
          }
        },
        '.btn-order-wrap': {
          width: '100%',
          display: 'flex',
          justifyContent: 'end',
          textAlign: 'right',
          '.btn-order': {
            width: 126,
            marginRight: -151,
            marginLeft: 22,
            whiteSpace: 'nowrap',
            transition: 'all 0.3s'
          }
        },

        '&:hover': {
          '.col-right': {
            '.details': {
              visibility: 'hidden',
              opacity: 0,
              marginRight: -100
            },
            '.buttons': {
              visibility: 'visible',
              opacity: 1,
              marginRight: 0
            }
          },
          '.btn-order-wrap': {
            '.btn-order': {
              marginRight: 0,
              opacity: 1,
              visibility: 'visible'
            }
          }
        }
      }
    },

    '.drawer-wrap': {
      width: '100%',
      display: 'inline-block',
      paddingLeft: 16,
      '.scroll-container': {
        height: 'calc(100vh - 245px)',
        paddingRight: 40,
        paddingBottom: 24
      },
      '.drawer-header': {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '24px 40px 24px 24px',

        '.u-switch-mode': {
          '.PrivateSwitchBase-root': {
            '.MuiSwitch-thumb': {
              '&::before': {
                backgroundImage: ChatIcon
              }
            },
            '&.Mui-checked': {
              '.MuiSwitch-thumb': {
                '&::before': {
                  backgroundImage: FilterIcon
                }
              }
            }
          },
          '.MuiSwitch-track': {
            '&::before': {
              backgroundImage: ChatIcon
            },
            '&::after': {
              backgroundImage: FilterIcon
            }
          },
          '.MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {
            backgroundColor: theme.palette.common.white
          },
          '.MuiSwitch-switchBase.Mui-checked +.MuiSwitch-track': {
            backgroundColor: 'rgba(0, 0, 0, 0.05) !important'
          }
        }
      },
      '.u-accordian-wrap': {
        '.u-accordion-header': {
          padding: 0,
          '.MuiAccordionSummary-content': {
            marginTop: 0,
            marginBottom: 24
          },
          '.MuiAccordionSummary-expandIconWrapper': {
            marginTop: -20
          }
        },
        '.MuiCollapse-root': {
          '.MuiCollapse-wrapper .MuiCollapse-wrapperInner .MuiAccordion-region .u-accordian-body': {
            padding: '0 0 24px 0'
          }
        }
      },
      '.drawer-footer': {
        display: 'block',
        padding: '24px 0',
        margin: '0 40px 0 24px',
        borderTop: '1px solid' + theme.palette.divider
      }
    },
    '.sub-item-wrap': {
      width: '97%',
      minHeight: 8,
      display: 'table',
      padding: '0 16px',
      backgroundColor: 'rgba(0,0,0, 0.05)',
      borderRadius: '0 0 8px 8px',
      margin: '-24px auto 24px auto',
      transition: 'all 0.3s',

      '.card-list-item': {
        transition: 'all 0.3s',
        '&.with-bg': {
          padding: '8px 24px !important',
          '.MuiTypography-root': {
            padding: '14px 0'
          },
          '&:hover': {
            paddingRight: '8px !important'
          }
        },
        '&:first-child': {
          marginTop: 16
        }
      }
    },
    '.u-form-group-max-label': {
      '.u-form-group .u-input-label': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 'calc(100% - 45px)',
        '&.MuiFormLabel-filled, &.Mui-focused': {
          maxWidth: 'calc(133% - 24px)'
        }
      }
    },
    '.grid-card-wrap .vehicle-card .vc-img-wrap': {
      marginBottom: 24
    },
    '.blank-frame-wrap': {
      height: 'calc(100vh - 80px)',
      display: 'flex',
      width: '100%',
      textAlign: 'center',
      padding: '0 40px',

      '.blank-frame': {
        maxWidth: 600,
        margin: 'auto',

        img: {
          maxWidth: '100%',
          marginBottom: 40
        },
        '.title': {
          marginBottom: 16
        },
        small: {
          color: theme.palette.grey[600]
        }
      }
    },
    '.chatbot-wrap': {
      width: '100%',
      display: 'inline-block',
      '.scroll-container': {
        paddingBottom: '0 !important',
        transform: 'rotate(180deg)',
        direction: 'rtl'
      },
      '.chatbot-container': {
        width: '100%',
        display: 'inline-block',
        transform: 'rotate(180deg)',
        direction: 'ltr',
        position: 'sticky',
        top: 0,
        '.rounded': {
          backgroundColor: StyleVariables.lightGrey,
          borderRadius: 16,
          padding: '16px 24px',
          width: '100%',
          clear: 'both',
          display: 'inline-block',
          color: theme.palette.grey[600],
          textAlign: 'center',
          marginBottom: 16,
          lineHeight: '20px',
          wordWrap: 'break-word',

          '&.received': {
            borderRadius: '16px 16px 16px 0px',
            textAlign: 'left'
          },

          '&.sent': {
            backgroundColor: theme.palette.grey[700],
            borderRadius: '16px 16px 0px 16px',
            color: theme.palette.common.white,
            textAlign: 'right'
          },
          '&.active': {
            backgroundColor: theme.palette.grey[700],
            color: theme.palette.common.white
          }
        },
        '.toast': {
          width: '100%',
          display: 'inline-block',
          textAlign: 'center',

          '.MuiTypography-root': {
            padding: '8px 16px',
            width: 'auto',
            margin: 'auto',
            borderRadius: 16,
            display: 'inline-flex',
            cursor: 'pointer',

            svg: {
              marginRight: 3,
              width: 16,
              height: 16
            },

            '&.light': {
              backgroundColor: theme.palette.common.white,
              border: '1px solid' + theme.palette.divider,
              boxShadow: '0px 8px 8px 0px rgba(0, 0, 0, 0.10)'
            },

            '&.dark': {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.grey[700],

              svg: {
                path: {
                  stroke: theme.palette.common.white
                }
              }
            },

            '&.no-shadow': {
              boxShadow: '0 0 0'
            }
          },
          '&.top': {
            position: 'sticky',
            top: 0
          }
        },
        '.chat-wrap': {
          width: '100%',
          display: 'inline-block',
          clear: 'both',
          marginLeft: -15,
          '.rounded': {
            width: 'auto',
            maxWidth: '85%',
            float: 'left',
            '&.sent': {
              float: 'right'
            }
          }
        },
        '.blank-frame-wrap': {
          height: 'auto',
          marginLeft: -15,
          clear: 'both',
          '.blank-frame': {
            maxWidth: '100%',
            paddingBottom: 24,
            img: {
              marginBottom: 'initial'
            },
            '.rounded': {
              marginBottom: 8,
              cursor: 'pointer'
            }
          }
        }
      },
      '.drawer-footer': {
        padding: '8px 0 0 0 !important',
        border: '0 solid transparent !important',

        '.u-form-group .MuiFormControl-root': {
          padding: 8,
          borderRadius: 16,
          backgroundColor: StyleVariables.lightGreyB,
          '.u-form-control': {
            padding: 0,

            '.MuiInputBase-input': {
              paddingRight: 16,
              '&::placeholder': {
                opacity: '0.5 !important'
              },
              '&:focus::placeholder': {
                opacity: '0 !important'
              }
            },

            'fieldset.MuiOutlinedInput-notchedOutline': {
              border: '0 solid transparent',
              padding: 0
            },
            '.adornment-icon': {
              maxHeight: 'initial'
            }
          }
        }
      }
    }
  }
}))

const AffordabilityCheck: FC<{ setOpenAffordibilityDialog: any }> = ({
  setOpenAffordibilityDialog
}) => {
  const {
    control,
    setValue,
    watch,
    reset,
    formState: { errors, isDirty },
    handleSubmit,
    getValues
  } = useForm<IFormInputs>()
  const { states, actions } = useStoreContext()
  const theme = useTheme()
  const [vehicleDetails, setVehicleDetails] = useState()
  const [programData, setProgramData] = useState()
  const [defaultCurrency, setDefaultCurrency] = useState('')
  const { mutate: getSetupData, data: setupDataIndicator } = useGetDataAffordabilityCheck()
  const { mutate: getDataForVehicles, isLoading: vehicleDataLoading } = useGetFindVehicles()
  const [minslidervalue, setMinSliderValue] = useState([0, 100])
  const [finalTerms, setFinalTerms] = useState([0, 0])
  const [balloonPayment, setBalloonPayment] = useState('')
  const [toggleButton, setToggleButton] = useState(true)
  const queryClient = useQueryClient()
  const currencies: any = queryClient.getQueryData([
    QueryKeys.GET_ALL_CURRENCIES,
    states?.lenderInfo?.id
  ])
  // const [conversation, setConversation] = useState<Message[]>([])

  useEffect(() => {
    if (states?.lenderInfo?.id) {
      getSetupData(
        {
          query: `?company_id=${states.lenderInfo.id}&setup_data_indicators=credit-ratings&setup_data_indicators=contract-terms&setup_data_indicators=contract-allowed-annum-mileage`
        },
        {
          onSuccess(response: any) {
            setMinSliderValue([
              response?.['contract-terms'].at(-1)?.term,
              response?.['contract-terms'][0]?.term
            ])
            setValue('min_term', response?.['contract-terms'].at(-1)?.term.toString())
            setValue('max_term', response?.['contract-terms'][0]?.term.toString())
          }
        }
      )
    }
  }, [states?.lenderInfo])
  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === states?.lenderInfo?.default_currency
    )

    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0 ? filteredCurrency[0].symbol : ''
    )
  }, [currencies])
  const onSubmit = (data: IFormInputs) => {
    const validData = Object.fromEntries(
      Object.entries(data).filter(
        ([_, value]) => value !== '' && value !== undefined && value !== null
      )
    )

    if (Object.keys(validData).length === 0) {
      console.error('Invalid data:', data)
      return
    }
    const request_data = {
      ...validData,
      terms: validData.max_term,
      max_payment: validData.max_payment,
      upfront: validData.upfront,
      balloon_payment: validData.balloon_payment || 0,
      annual_usage: parseFloat(validData.annual_usage) ?? undefined,
      dealer_code: states?.dealerInfo?.dealer_code,
      company_code: states?.lenderInfo?.company_code,
      lender_code: states?.lendersData?.find((lender: any) => lender?.default_lender)?.external_code
    }

    setFinalTerms(minslidervalue)
    setBalloonPayment(validData?.balloon_payment || 0)

    getDataForVehicles(request_data, {
      onSuccess(response: any) {
        setVehicleDetails(response?.assets)
        setProgramData(response?.program)
        reset(getValues())
      }
    })
  }

  return (
    <>
      <Box theme={theme} className="lane-dialog-content">
        {/*********** Top Bar ***********/}
        <Box theme={theme} className="top-bar">
          <Link onClick={() => setOpenAffordibilityDialog(false)}>x</Link>
        </Box>
        {/*********** Main Container ***********/}
        <Box theme={theme} className="wrap">
          <Box theme={theme} className="container">
            <AffordabilityWrap className="affordability-wrap">
              <Grid theme={theme} container columnSpacing={0}>
                {/*********** Filter + List Section ***********/}
                <Grid theme={theme} item xl={7.5} lg={7.5} md={6} sm={6}>
                  <VehicleCardView
                    vehicleDetails={vehicleDetails}
                    programData={programData}
                    finalTerms={finalTerms}
                    defaultCurrency={defaultCurrency}
                    balloonPayment={balloonPayment}
                    creditScore={getValues('credit_rating')}
                  />
                </Grid>
                {/*********** Drawer Section ***********/}
                <Grid theme={theme} item xl={4.5} lg={4.5} md={6} sm={6}>
                  <Box theme={theme} className="drawer-wrap">
                    <Box theme={theme} className="drawer-header">
                      <Typography
                        theme={theme}
                        component="h3"
                        variant="h3"
                        children={toggleButton ? 'Customize Payment' : 'Your Assistant'}
                      />
                      <Switch
                        theme={theme}
                        switchEnabled={toggleButton}
                        onChange={(event) => {
                          setToggleButton(event.target.checked)
                        }}
                      />
                    </Box>
                    {toggleButton ? (
                      <Box theme={theme}>
                        <Box theme={theme} className="scroll-container">
                          <Accordion
                            theme={theme}
                            items={[
                              {
                                title: 'How much you want to pay monthly?',
                                content: (
                                  <>
                                    <Grid theme={theme} container columnSpacing={2}>
                                      <Grid theme={theme} item xs={6}>
                                        <Controller
                                          name="min_payment"
                                          control={control}
                                          rules={{
                                            required: 'Min Payment is required',
                                            max: {
                                              value: watch('max_payment'),
                                              message: 'Min Payment should be less than Max Payment'
                                            },
                                            min: {
                                              value: 0,
                                              message: 'Min Payment should be greater than zero'
                                            }
                                          }}
                                          render={({ field: { onChange, value } }) => (
                                            <Input
                                              theme={theme}
                                              fullWidth
                                              label="Min Payment"
                                              type="text"
                                              placeholder="000.00"
                                              value={value}
                                              onChange={(e) => {
                                                onChange(parseFloat(e))
                                              }}
                                              startAdornment={
                                                <span className="startAdornment">
                                                  {defaultCurrency}
                                                </span>
                                              }
                                              masking
                                              maskDecimalScale={2}
                                              maskNumeric
                                              error={errors?.min_payment?.message}
                                            />
                                          )}
                                        />
                                      </Grid>
                                      <Grid theme={theme} item xs={6}>
                                        <Controller
                                          name="max_payment"
                                          control={control}
                                          rules={{
                                            required: 'Max Payment is required',
                                            min: {
                                              value: watch('min_payment'),
                                              message:
                                                'Max Payment should be greater than Min Payment'
                                            }
                                          }}
                                          render={({ field: { onChange, value } }) => (
                                            <Input
                                              theme={theme}
                                              fullWidth
                                              label="Max Payment"
                                              type="decimal"
                                              placeholder="000.00"
                                              value={value}
                                              onChange={(e) => {
                                                onChange(parseFloat(e))
                                              }}
                                              startAdornment={
                                                <span className="startAdornment">
                                                  {defaultCurrency}
                                                </span>
                                              }
                                              masking
                                              maskDecimalScale={2}
                                              maskNumeric
                                              error={errors?.max_payment?.message}
                                            />
                                          )}
                                        />
                                      </Grid>
                                    </Grid>
                                  </>
                                ),
                                key: 'string1',
                                isExpanded: true
                              },
                              {
                                title: 'What will be the term of your deal?',
                                content: (
                                  <>
                                    <Grid theme={theme} container columnSpacing={2}>
                                      <Grid theme={theme} item xs={6}>
                                        <Controller
                                          name="min_term"
                                          control={control}
                                          rules={{
                                            required: 'Min Term is required',
                                            max: {
                                              value: Number(getValues('max_term')),
                                              message: 'Min Term should be less than Max Term'
                                            }
                                          }}
                                          render={({ field }) => (
                                            <Select
                                              theme={theme}
                                              label={'Min Term'}
                                              items={setupDataIndicator?.['contract-terms']?.map(
                                                (term: any) => ({
                                                  text: term.term,
                                                  value: term.term
                                                })
                                              )}
                                              {...field}
                                              value={field.value ?? minslidervalue[0]}
                                              onChange={(e) => {
                                                if (Number(e.target.value) <= minslidervalue[1]) {
                                                  setMinSliderValue([
                                                    Number(e.target.value),
                                                    minslidervalue[1]
                                                  ])
                                                  field.onChange(e)
                                                } else {
                                                  actions.setToast({
                                                    toastMessage:
                                                      'Min Term should be less than Max Term',
                                                    toastState: true,
                                                    variant: 'error'
                                                  })
                                                }
                                              }}
                                              fullWidth
                                              selectError={(
                                                errors as any
                                              )?.min_term?.message?.toString()}
                                            />
                                          )}
                                        />
                                      </Grid>
                                      <Grid theme={theme} item xs={6}>
                                        <Controller
                                          name="max_term"
                                          control={control}
                                          rules={{
                                            required: 'Max Term is required',
                                            min: {
                                              value: Number(getValues('min_term')),
                                              message: 'Max Term should be greater than Min Term'
                                            }
                                          }}
                                          render={({ field }) => (
                                            <Select
                                              theme={theme}
                                              label={'Max Term'}
                                              items={setupDataIndicator?.['contract-terms']?.map(
                                                (term: any) => ({
                                                  text: term.term,
                                                  value: term.term
                                                })
                                              )}
                                              {...field}
                                              value={field.value ?? minslidervalue[1]}
                                              onChange={(e) => {
                                                if (Number(e.target.value) >= minslidervalue[0]) {
                                                  setMinSliderValue([
                                                    minslidervalue[0],
                                                    Number(e.target.value)
                                                  ])
                                                  field.onChange(e)
                                                } else {
                                                  actions.setToast({
                                                    toastMessage:
                                                      'Max Term should be greater than Min Term',
                                                    toastState: true,
                                                    variant: 'error'
                                                  })
                                                }
                                              }}
                                              fullWidth
                                              selectError={(
                                                errors as any
                                              )?.max_term?.message?.toString()}
                                            />
                                          )}
                                        />
                                      </Grid>
                                    </Grid>
                                    {setupDataIndicator && (
                                      <DualSlider
                                        theme={theme}
                                        label={''}
                                        value={minslidervalue}
                                        valuetext={() => ''}
                                        minValue={
                                          setupDataIndicator?.['contract-terms'].at(-1)?.term
                                        }
                                        maxValue={setupDataIndicator?.['contract-terms'][0]?.term}
                                        onChange={(e) => {
                                          setMinSliderValue(e)
                                          setValue('min_term', e[0], { shouldDirty: true })
                                          setValue('max_term', e[1], { shouldDirty: true })
                                        }}
                                        steps={12}
                                      />
                                    )}
                                  </>
                                ),
                                key: 'string2',
                                isExpanded: true
                              }
                            ]}
                          />
                          <Box theme={theme} className="u-form-group-max-label" sx={{ mb: 3 }}>
                            <Controller
                              name="upfront"
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <Input
                                  theme={theme}
                                  fullWidth
                                  label="Down Payment (Optional)"
                                  type="text"
                                  placeholder="000.00"
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e)
                                  }}
                                  startAdornment={
                                    <span className="startAdornment">{defaultCurrency}</span>
                                  }
                                  masking
                                  maskDecimalScale={2}
                                  maskNumeric
                                />
                              )}
                            />
                            <Box theme={theme} className="divider" />
                            <Controller
                              name="annual_usage"
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <Input
                                  theme={theme}
                                  fullWidth
                                  label="What will be your annual usage? (Optional)"
                                  type="text"
                                  placeholder="000"
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e)
                                  }}
                                  endAdornment="Miles"
                                  masking
                                  maskNumeric
                                />
                              )}
                            />
                            <Controller
                              name="balloon_payment"
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <Input
                                  theme={theme}
                                  fullWidth
                                  label="Would you like to make a balloon payment? (Optional)"
                                  type="text"
                                  placeholder="000.00"
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e)
                                  }}
                                  startAdornment={
                                    <span className="startAdornment">{defaultCurrency}</span>
                                  }
                                  masking
                                  maskDecimalScale={2}
                                  maskNumeric
                                />
                              )}
                            />
                            <Controller
                              name="trade_in_amount"
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <Input
                                  theme={theme}
                                  fullWidth
                                  label="Do you have any estimated trade-In amount? (Optional)"
                                  type="text"
                                  placeholder="000.00"
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e)
                                  }}
                                  startAdornment={
                                    <span className="startAdornment">{defaultCurrency}</span>
                                  }
                                  masking
                                  maskDecimalScale={2}
                                  maskNumeric
                                />
                              )}
                            />
                          </Box>
                          <Controller
                            name="trade_in_payoff"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <Input
                                theme={theme}
                                fullWidth
                                label="Trade Pay-Off (Optional)"
                                type="text"
                                placeholder="000.00"
                                value={value}
                                onChange={(e) => {
                                  onChange(e)
                                }}
                                masking
                                maskDecimalScale={2}
                                maskNumeric
                              />
                            )}
                          />
                        </Box>
                        <Box theme={theme} className="drawer-footer">
                          <Button
                            theme={theme}
                            primary
                            fullWidth
                            text={vehicleDataLoading ? 'Finding Vehicles.....' : 'Find Vehicles'}
                            disabled={vehicleDataLoading || !isDirty}
                            onClick={handleSubmit(onSubmit)}
                          />
                        </Box>
                      </Box>
                    ) : (
                      <Box theme={theme}>
                        {/* <Chatbot conversation={conversation} setConversation={setConversation} /> */}
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </AffordabilityWrap>
          </Box>
        </Box>
      </Box>
    </>
  )
}
export default AffordabilityCheck
