import { FC } from 'react'

const BrowsingIcon: FC<{ className?: string }> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_42041_34611)">
      <path
        d="M13 20L20 20C20.5304 20 21.0391 19.7893 21.4142 19.4142C21.7893 19.0391 22 18.5304 22 18L22 6C22 5.46957 21.7893 4.96086 21.4142 4.58579C21.0391 4.21071 20.5304 4 20 4L4 4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21072 4.96086 2 5.46957 2 6L2 11"
        stroke="#696C80"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 15L3 15C2.73478 15 2.48043 15.1054 2.29289 15.2929C2.10536 15.4804 2 15.7348 2 16L2 19C2 19.2652 2.10536 19.5196 2.29289 19.7071C2.48043 19.8946 2.73478 20 3 20L8 20C8.26522 20 8.51957 19.8946 8.70711 19.7071C8.89464 19.5196 9 19.2652 9 19L9 16C9 15.7348 8.89464 15.4804 8.70711 15.2929C8.51957 15.1054 8.26522 15 8 15Z"
        stroke="#696C80"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.9523 13.7825C11.9789 13.902 12.0414 14.0105 12.1314 14.0935C12.2214 14.1764 12.3346 14.2298 12.4559 14.2466C12.5771 14.2634 12.7006 14.2426 12.8097 14.1872C12.9189 14.1318 13.0085 14.0443 13.0665 13.9366L14.1116 12.3898L16.5655 14.8437C16.6151 14.8933 16.6739 14.9326 16.7386 14.9594C16.8034 14.9862 16.8727 15 16.9428 15C17.0129 15 17.0823 14.9862 17.147 14.9594C17.2118 14.9326 17.2706 14.8933 17.3201 14.8437L17.8437 14.3201C17.8933 14.2706 17.9326 14.2118 17.9594 14.147C17.9862 14.0823 18 14.0129 18 13.9428C18 13.8727 17.9862 13.8034 17.9594 13.7386C17.9326 13.6739 17.8933 13.6151 17.8437 13.5655L15.3898 11.1116L16.9466 10.0665C17.0544 10.0084 17.1418 9.9188 17.1972 9.80963C17.2526 9.70046 17.2732 9.57696 17.2564 9.4557C17.2396 9.33444 17.1861 9.22123 17.1031 9.13125C17.0201 9.04127 16.9116 8.97883 16.792 8.9523L10.8686 7.24967C10.4908 7.14109 10.1413 7.49079 10.25 7.86852L11.9523 13.7825Z"
        fill="#696C80"
      />
    </g>
    <defs>
      <clipPath id="clip0_42041_34611">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default BrowsingIcon
