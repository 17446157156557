import { FC, useState, useEffect } from 'react'
import { styled } from '@mui/system'
import { useTheme } from '@mui/material/styles' // Import useTheme from the correct path
import { ResponsiveCarousel, Menu, VehicleCardUi } from 'components'
import {
  Button,
  Grid,
  Box,
  Typography,
  IBoxProps,
  IGridProps,
  Icon,
  Dialog
} from '@ntpkunity/controls'
import { useModal } from 'react-modal-hook'
import { useStoreContext } from '@store/storeContext'
import clsx from 'clsx'
import CustomFilterControl from '@app/vehicle-selection/filter/filter-control'
import { useQueryClient } from 'react-query'
import { QueryKeys } from '@helpers/enums'
import noImage from '@public/assets/images/no-img.svg'
import {
  useGetFilterReferenceData,
  useGetInventoryByFilters
} from '@apis/inventory-management.service'
import NotFound from 'pages/not-found'
import NotInventory from 'pages/not-inventory'

const FilterControl = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.filters-control': {
    borderBottom: '1px solid' + theme.palette.grey[100]
  }
}))

const FilterWrap = styled(
  Grid,
  {}
)<Partial<IGridProps>>(({ theme }) => ({
  '&.filters-toggle-wrap': {
    transition: 'all 0.5s ease-in-out 0s',
    position: 'absolute',
    width: '100%',
    left: 0,
    '&.hide': {
      left: '-100%',
      [theme.breakpoints.down('md')]: {
        left: 0,
        position: 'absolute',
        transform: 'scale(0)'
      }
    },
    [theme.breakpoints.down('md')]: {
      backgroundColor: theme.palette.common.white,
      zIndex: 10,
      transform: 'scale(1)',
      position: 'relative'
    }
  }
}))

const RightPanel = styled(
  Grid,
  {}
)<Partial<IGridProps>>(({ theme }) => ({
  '&.right-content': {
    transition: 'all 0.5s ease-in-out 0s',
    marginLeft: '25%',
    '&.expand': {
      marginLeft: 0
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 0
    }
  }
}))

const InventoryComponent: FC<{ selectVinObj?: any }> = ({ selectVinObj }): any => {
  const theme = useTheme()
  const queryClient = useQueryClient()
  const { actions, states } = useStoreContext()

  const {
    dealerInfo: { dealer_code }
  } = states

  const currencies: any = queryClient.getQueryData([
    QueryKeys.GET_ALL_CURRENCIES,
    states?.lenderInfo?.id
  ])

  const [openFilter, setOpenFilter] = useState(true)
  const [defaultCurrency, setDefaultCurrency] = useState('')
  const [orderByState, setOrderByState] = useState<any>(null)
  const [selectedVehicle, setSelectedVehicle] = useState<any>(null)

  const { mutate: getInventory, data: inventoryData } = useGetInventoryByFilters()
  const { mutate: getRefernceData, data: refernceData } = useGetFilterReferenceData()

  const filterData = (data: any) => {
    getInventory(
      {
        data:
          `dealer_code=${dealer_code}&page_number=${0}&page_size=${100}&${data}` +
          (orderByState ? `&order_by_price=${orderByState}` : ``)
      },
      {
        onSuccess() {}
      }
    )
  }

  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === states?.lenderInfo?.default_currency
    )

    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0 ? filteredCurrency[0].symbol : ''
    )
  }, [currencies])

  useEffect(() => {
    getRefernceData({ dealer_code: dealer_code })
  }, [dealer_code])

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }

  const [openVdModal, closeVdModal] = useModal(
    () => (
      <>
        <Dialog
          className="dialog-with-border"
          theme={theme}
          title={'Asset Details'}
          disablePortal
          open
          onCloseDialog={closeVdModal}
          customFooter={
            <>
              <Button
                primary
                theme={theme}
                text={'Select & Continue'}
                onClick={() => {
                  selectVinObj(selectedVehicle)
                }}
              ></Button>
            </>
          }
        >
          <VehicleCardUi theme={theme} className="vehicle-card full-width vd-popup m-dialog">
            <Box
              theme={theme}
              className="vc-info-head"
              display={'flex'}
              justifyContent={'space-between'}
            >
              <Box theme={theme} className="vc-type-name">
                <Typography
                  theme={theme}
                  className="vc-type text-muted"
                  display={'block'}
                  variant="body1"
                  component="p"
                >
                  {selectedVehicle?.vehicle?.year} {selectedVehicle?.vehicle?.make}{' '}
                  {selectedVehicle?.vehicle?.model}
                </Typography>
                <Typography
                  theme={theme}
                  className="vc-name text-dark"
                  display={'block'}
                  variant="h3"
                  component="h3"
                >
                  {selectedVehicle?.vehicle?.trim_description}
                </Typography>
              </Box>
              <Box theme={theme} className="price-info">
                <Typography
                  theme={theme}
                  className="price-title text-muted"
                  display={'block'}
                  variant="body1"
                  component="p"
                >
                  Selling Price
                </Typography>
                <Typography
                  theme={theme}
                  className="vc-price text-primary"
                  display={'block'}
                  variant="h3"
                  component="h3"
                >
                  {defaultCurrency}
                  {selectedVehicle?.vehicle?.internet_price?.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}
                </Typography>
              </Box>
            </Box>
            {selectedVehicle?.vehicle?.photo_urls?.every(
              (photo: any) => photo.location !== '' && photo.location?.split('//')?.[1] !== ''
            ) ? (
              <ResponsiveCarousel responsive={responsive}>
                {selectedVehicle?.vehicle?.photo_urls?.length > 0 &&
                  selectedVehicle?.vehicle?.photo_urls?.map((obj: any, index: any) => (
                    <Box theme={theme} key={index}>
                      <img src={obj?.location === '' ? noImage : obj?.location} alt="vehicle" />
                    </Box>
                  ))}
              </ResponsiveCarousel>
            ) : (
              <Box theme={theme} className="vc-img-wrap blank-img-wrap">
                <img src={noImage} alt="Blank" />
              </Box>
            )}
            {selectedVehicle?.vehicle?.photo_urls?.length <= 0 && (
              <Icon name="NoPreviewAvailable" />
            )}
            <Box theme={theme} className="vc-features">
              <Typography
                theme={theme}
                className="vf-title text-dark"
                display={'block'}
                variant="h4"
                component="h4"
              >
                Vehicle Features & Specifications
              </Typography>
              <Grid theme={theme} container item spacing={3}>
                <Grid theme={theme} item xs={6} md={3} lg={4}>
                  <Box theme={theme} className="feat-item">
                    <Typography
                      theme={theme}
                      className="text-muted"
                      display={'block'}
                      variant="caption"
                      component="small"
                    >
                      Drivetrain:
                    </Typography>
                    <Typography
                      theme={theme}
                      className="text-dark"
                      display={'block'}
                      variant="body2"
                      component="span"
                    >
                      {selectedVehicle?.vehicle?.drive_train}
                    </Typography>
                  </Box>
                </Grid>
                <Grid theme={theme} item xs={6} md={3} lg={4}>
                  <Box theme={theme} className="feat-item">
                    <Typography
                      theme={theme}
                      className="text-muted"
                      display={'block'}
                      variant="caption"
                      component="small"
                    >
                      Engine:
                    </Typography>
                    <Typography
                      theme={theme}
                      className="text-dark"
                      display={'block'}
                      variant="body2"
                      component="span"
                    >
                      {selectedVehicle?.vehicle?.engine_description}
                    </Typography>
                  </Box>
                </Grid>
                <Grid theme={theme} item xs={6} md={3} lg={4}>
                  <Box theme={theme} className="feat-item">
                    <Typography
                      theme={theme}
                      className="text-muted"
                      display={'block'}
                      variant="caption"
                      component="small"
                    >
                      Exterior Color:
                    </Typography>
                    <Typography
                      theme={theme}
                      className="text-dark"
                      display={'block'}
                      variant="body2"
                      component="span"
                    >
                      {selectedVehicle?.vehicle?.exterior_color_description}
                    </Typography>
                  </Box>
                </Grid>
                <Grid theme={theme} item xs={6} md={3} lg={4}>
                  <Box theme={theme} className="feat-item">
                    <Typography
                      theme={theme}
                      className="text-muted"
                      display={'block'}
                      variant="caption"
                      component="small"
                    >
                      Interior Color:
                    </Typography>
                    <Typography
                      theme={theme}
                      className="text-dark"
                      display={'block'}
                      variant="body2"
                      component="span"
                    >
                      {selectedVehicle?.vehicle?.interior_color_description}
                    </Typography>
                  </Box>
                </Grid>
                <Grid theme={theme} item xs={6} md={3} lg={4}>
                  <Box theme={theme} className="feat-item">
                    <Typography
                      theme={theme}
                      className="text-muted"
                      display={'block'}
                      variant="caption"
                      component="small"
                    >
                      Transmission Type:
                    </Typography>
                    <Typography
                      theme={theme}
                      className="text-dark"
                      display={'block'}
                      variant="body2"
                      component="span"
                    >
                      {selectedVehicle?.vehicle?.transmission_type}
                    </Typography>
                  </Box>
                </Grid>
                <Grid theme={theme} item xs={6} md={3} lg={4}>
                  <Box theme={theme} className="feat-item">
                    <Typography
                      theme={theme}
                      className="text-muted"
                      display={'block'}
                      variant="caption"
                      component="small"
                    >
                      Stock Number:
                    </Typography>
                    <Typography
                      theme={theme}
                      className="text-dark"
                      display={'block'}
                      variant="body2"
                      component="span"
                    >
                      {selectedVehicle?.vehicle?.stock_number}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </VehicleCardUi>
        </Dialog>
      </>
    ),
    [selectedVehicle]
  )

  return (
    <>
      {refernceData?.result === null ? (
        <NotInventory />
      ) : (
        <Grid theme={theme} container spacing={2}>
          <FilterWrap
            theme={theme}
            item
            xs={12}
            md={12}
            lg={3}
            className={clsx('filters-toggle-wrap', openFilter ? '' : 'hide')}
          >
            {refernceData && (
              <CustomFilterControl
                theme={theme}
                referenceData={refernceData}
                orderBy={orderByState}
                onSave={(data) => {
                  filterData(data)
                }}
              ></CustomFilterControl>
            )}
          </FilterWrap>
          <RightPanel
            theme={theme}
            item
            xs={12}
            md={12}
            lg={openFilter ? 9 : 12}
            className={clsx('right-content', openFilter ? '' : 'expand')}
          >
            <Grid theme={theme} container item spacing={2}>
              <Grid theme={theme} item xs={12}>
                <FilterControl
                  pb={2}
                  theme={theme}
                  className="filters-control"
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <Button
                    defaultBtn
                    theme={theme}
                    startIcon={<Icon name="FilterIcon" />}
                    text={openFilter ? 'Hide Filters' : 'Show Filters'}
                    onClick={() => setOpenFilter(!openFilter)}
                  ></Button>
                  <Menu
                    theme={theme}
                    options={[
                      {
                        optionText: 'Selling Price (High to Low)',
                        optionKey: 'descending'
                      },
                      {
                        optionText: 'Selling Price (Low to High)',
                        optionKey: 'ascending'
                      }
                    ]}
                    handleOptionClick={(_e, key, _value) => {
                      setOrderByState(key)
                    }}
                    render={(cb) => (
                      <Button
                        defaultBtn
                        endIcon={<Icon name="ChevronDown" />}
                        onClick={cb}
                        text={'Sort By'}
                      ></Button>
                    )}
                  />
                </FilterControl>
              </Grid>
              {inventoryData?.result?.length == 0 ? (
                <Grid theme={theme} item xs={12}>
                  <NotFound />
                </Grid>
              ) : (
                inventoryData?.result?.map((vehicle: any, index: number) => (
                  <Grid theme={theme} key={index} item xs={12} md={6} lg={openFilter ? 4 : 3}>
                    <VehicleCardUi theme={theme} className="vehicle-card full-width mini-carousel">
                      <Grid container spacing={2} theme={theme} sx={{ mb: 2 }}>
                        <Grid item xs={7} theme={theme}>
                          <Box theme={theme}>
                            <Typography
                              theme={theme}
                              variant="subtitle2"
                              component="span"
                              textAlign="left"
                              className="w-100 text-muted"
                            >
                              {vehicle?.vehicle.year} {vehicle?.vehicle.model}
                            </Typography>
                          </Box>
                          <Box theme={theme}>
                            <Typography
                              theme={theme}
                              variant="subtitle2"
                              component="span"
                              textAlign="left"
                              className="w-100 text-dark"
                            >
                              {vehicle?.vehicle.trim_description}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={5} theme={theme}>
                          <Box theme={theme}>
                            <Typography
                              theme={theme}
                              variant="caption"
                              component="span"
                              textAlign="right"
                              className="w-100 text-muted"
                            >
                              Selling Price
                            </Typography>
                          </Box>
                          <Box theme={theme}>
                            <Typography
                              theme={theme}
                              variant="subtitle2"
                              component="span"
                              textAlign="right"
                              className="w-100 text-dark"
                            >
                              {defaultCurrency}
                              {vehicle?.vehicle?.internet_price?.toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2
                              })}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      {vehicle?.vehicle?.photo_urls?.every(
                        (photo: any) =>
                          photo.location !== '' && photo.location?.split('//')?.[1] !== ''
                      ) ? (
                        <>
                          <ResponsiveCarousel
                            responsive={responsive}
                            removeArrowOnDeviceType={'xs'}
                            swipeable={false}
                            draggable={false}
                            autoPlay={true}
                            pauseOnHover={false}
                            autoPlaySpeed={3000}
                          >
                            {vehicle?.vehicle?.photo_urls?.length > 0 &&
                              vehicle?.vehicle?.photo_urls?.map((obj: any, index: any) => (
                                <img
                                  src={obj?.location === '' ? noImage : obj?.location}
                                  key={index}
                                  alt="vehicle"
                                />
                              ))}
                          </ResponsiveCarousel>
                          {vehicle?.vehicle?.photo_urls?.length > 0 && (
                            <Box theme={theme} className="vc-img-wrap single-img">
                              <img
                                src={vehicle?.vehicle?.photo_urls[0]?.location}
                                key={index}
                                alt="vehicle"
                              />
                            </Box>
                          )}
                        </>
                      ) : (
                        <Box theme={theme} className="vc-img-wrap blank-img-wrap">
                          <img src={noImage} alt="Blank" />
                        </Box>
                      )}
                      <Box theme={theme} className="vc-footer w-100" textAlign={'center'}>
                        <Box theme={theme} className="specs">
                          <Box theme={theme} className="w-100">
                            <Typography
                              theme={theme}
                              variant="caption"
                              component="span"
                              className="text-muted"
                              sx={{ mr: 0.5 }}
                            >
                              Stock:{' '}
                            </Typography>
                            <Typography
                              theme={theme}
                              variant="subtitle2"
                              component="span"
                              className="text-dark subtitle2"
                            >
                              {vehicle?.vehicle?.stock_number}
                            </Typography>
                          </Box>
                          <Box theme={theme} className="w-100">
                            <Typography
                              theme={theme}
                              variant="caption"
                              component="span"
                              className="text-muted"
                              sx={{ mr: 0.5 }}
                            >
                              VIN:{' '}
                            </Typography>
                            <Typography
                              theme={theme}
                              variant="subtitle2"
                              component="span"
                              className="text-dark subtitle2"
                            >
                              {vehicle?.vehicle?.vin}
                            </Typography>
                          </Box>
                        </Box>
                        <Box theme={theme} className="action">
                          <Button
                            theme={theme}
                            onClick={() => {
                              setSelectedVehicle(vehicle)
                              openVdModal()
                            }}
                            secondary
                            className="btn-view"
                            iconText={<Icon name="IcView" />}
                          >
                            Button
                          </Button>
                          <Button
                            theme={theme}
                            primary
                            text={'Select & Continue'}
                            onClick={() => {
                              actions.setOfferInitialData({
                                selected_vehicle: vehicle
                              })
                              selectVinObj(vehicle)
                            }}
                          ></Button>
                        </Box>
                      </Box>
                    </VehicleCardUi>
                  </Grid>
                ))
              )}
            </Grid>
          </RightPanel>
        </Grid>
      )}
    </>
  )
}

export default InventoryComponent
