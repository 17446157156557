import IActions from '../configs'
import { Dispatch, ReducerAction } from 'react'

export const SET_CREDIT_RATING_INIT_STATE = 'Set Rating Data'

export const initialState: any[] = []

const creditRatingRreducer = (
  state: typeof initialState,
  action: IActions
): typeof initialState => {
  switch (action.type) {
    case SET_CREDIT_RATING_INIT_STATE:
      return action.payload as typeof initialState
  }
  return state
}

export const setCreditRatingData = (
  data: typeof initialState,
  dispatch: Dispatch<ReducerAction<typeof creditRatingRreducer>>
): void => {
  if (data) {
    dispatch({ type: SET_CREDIT_RATING_INIT_STATE, payload: data })
  }
}

export default creditRatingRreducer
