import { useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import { FC } from 'react'

const Quotation: FC = () => {
  const theme = useTheme()
  return <LayoutWithSideNav theme={theme}>Quotation</LayoutWithSideNav>
}

export default Quotation
