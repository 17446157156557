import { FC, useState, useEffect } from 'react'
import { Link, useTheme } from '@mui/material'
import { Box, Typography, Icon } from '@ntpkunity/controls'
import { ProductDetailDialog } from '@components'
import { useQueryClient } from 'react-query'
import { useStoreContext } from '@store/storeContext'
import { QueryKeys } from '@helpers/enums'

const ProductDetailtPopup: FC<{ details?: any }> = ({ details }) => {
  const theme = useTheme()
  const queryClient = useQueryClient()
  const { states } = useStoreContext()
  const [defaultCurrency, setDefaultCurrency] = useState('')
  const currencies: any = queryClient.getQueryData([
    QueryKeys.GET_ALL_CURRENCIES,
    states?.lenderInfo?.id
  ])
  useEffect(() => {
    const filteredCurrency = currencies?.filter(
      (currency: any) => currency.id === states?.lenderInfo?.default_currency
    )
    setDefaultCurrency(
      filteredCurrency && filteredCurrency.length > 0 ? filteredCurrency[0].symbol : ''
    )
  }, [currencies])
  const handleBrochureLink = () => {
    let url = details?.brochure_url
    window.open(url)
  }
  const handleVideoLink = () => {
    let url = details?.video_url
    window.open(url)
  }

  return (
    <ProductDetailDialog theme={theme}>
      <Box theme={theme} className="img-wrap">
        <img
          src={
            details?.image[0]?.location
            // :require("../../../../public/assets/images/vc-img.png")
          }
          alt="Product"
        />
      </Box>
      <Box theme={theme} className="" sx={{ mt: 3 }}>
        <Typography theme={theme} component="p" variant="body2">
          {details?.offered_by}
        </Typography>
        <Typography theme={theme} component="h3" variant="h3">
          {details?.product_name}
        </Typography>
        <Typography theme={theme} component="h4" variant="h4">
          {defaultCurrency}
          {parseFloat(details?.price).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}
        </Typography>
        <Typography theme={theme} component="p" variant="body2" sx={{ mt: 1 }}>
          Part Number: {details?.part_no}
        </Typography>
      </Box>
      <Typography theme={theme} component="p" variant="body1" sx={{ mt: 3 }}>
        {details?.description}
      </Typography>
      <Box theme={theme} className="" sx={{ mt: 3 }}>
        <Box theme={theme}>
          <Link className="custom-link" onClick={handleBrochureLink}>
            <Typography
              theme={theme}
              variant="body2"
              component={'span'}
              className="custom-link"
              children={
                <>
                  View Product Brochure <Icon name="IconLeftArrow" />
                </>
              }
            />
          </Link>
        </Box>
        <Link className="custom-link" onClick={handleVideoLink}>
          <Typography
            theme={theme}
            variant="body2"
            component={'span'}
            children={
              <>
                View Product Video <Icon name="IconLeftArrow" />
              </>
            }
          />
        </Link>
      </Box>
    </ProductDetailDialog>
  )
}

export default ProductDetailtPopup
