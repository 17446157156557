import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material'
import { Typography, Box, Grid, Icon, Button, Skeleton } from '@ntpkunity/controls'
import { NoData, VehicleCardWrap, VehicleCardUi } from 'components'
import { IWorkQueueOrderReturn } from '@models'

const VehicleCardSkeleton: FC<IVehicleCard> = () => {
  const theme = useTheme()

  return (
    <VehicleCardUi theme={theme} className="vehicle-card">
      <Box theme={theme} sx={{ mb: 2, textAlign: 'center' }}>
        <Skeleton theme={theme} width={160} height={20} sx={{ margin: 'auto' }} />
        <Skeleton theme={theme} width={104} height={20} sx={{ margin: 'auto' }} />
      </Box>
      <Box theme={theme} mb={2}>
        <Skeleton variant="rectangular" theme={theme} width={272} height={152} />
      </Box>
      <Box theme={theme} className="vc-footer w-100">
        <Box theme={theme} className="specs">
          <Box theme={theme} className="w-100">
            <Skeleton theme={theme} width={104} height={20} sx={{ margin: 'auto' }} />
            <Skeleton theme={theme} width={160} height={20} sx={{ margin: 'auto' }} />
          </Box>
        </Box>
      </Box>
    </VehicleCardUi>
  )
}

interface IVehicleCard {
  data?: any
  componentType?: String
  anOrdersData?: any
}

const VehicleCard: FC<IVehicleCard> = ({ data, componentType }) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const handleClick = (e: any, data: any) => {
    navigate('vehicle-return/' + data?.external_order_id + '/' + data?.identifier, {
      state: {
        ...data,
        componentType
      }
    })
    e.stopPropagation()
  }

  return (
    <>
      <VehicleCardUi
        theme={theme}
        className="vehicle-card"
        onClick={(e: any) => {
          handleClick(e, data)
        }}
      >
        <Box theme={theme} sx={{ mb: 2, textAlign: 'center' }}>
          <Typography
            theme={theme}
            variant="caption"
            component="span"
            textAlign="left"
            className="w-100 text-muted"
            sx={{ textAlign: 'center' }}
            children={data && data?.year + ' ' + data?.make + ' ' + data?.model}
          />
          <Typography
            theme={theme}
            variant="subtitle2"
            component="span"
            textAlign="left"
            className="w-100 text-dark"
            sx={{ textAlign: 'center' }}
            children={data && data?.trim}
          />
        </Box>
        <Box theme={theme} className="vc-img-wrap">
          <img
            src={
              data &&
              data?.asset_image?.length > 0 &&
              data?.asset_image[0]?.replace('[w]x[h]', '166x124')
            }
            alt="vehicle"
          />
        </Box>
        <Box theme={theme} className="vc-footer w-100">
          <Box theme={theme} className="specs">
            <Box theme={theme} className="w-100">
              <Typography
                theme={theme}
                variant="caption"
                component="span"
                className="text-muted"
                sx={{ mr: 0.5 }}
              >
                Stock:
              </Typography>
              <Typography
                theme={theme}
                variant="subtitle2"
                component="span"
                className="text-dark subtitle2"
              >
                {data?.stock_number}
              </Typography>
            </Box>
            <Box theme={theme} className="w-100">
              <Typography
                theme={theme}
                variant="caption"
                component="span"
                className="text-muted"
                sx={{ mr: 0.5 }}
              >
                VIN:
              </Typography>
              <Typography
                theme={theme}
                variant="subtitle2"
                component="span"
                className="text-dark subtitle2"
              >
                {data && data?.vin}
              </Typography>
            </Box>
            <Box theme={theme} className="w-100">
              <Typography
                theme={theme}
                variant="caption"
                component="span"
                className="text-muted"
                sx={{ mr: 0.5 }}
              >
                Customer Name:
              </Typography>
              <Typography
                theme={theme}
                variant="subtitle2"
                component="span"
                className="text-dark subtitle2"
              >
                {data?.customer_first_name
                  ? data?.customer_first_name +
                    `${data?.customer_last_name ? ' ' + data?.customer_last_name : ''}`
                  : ''}
              </Typography>
            </Box>
          </Box>
          <Box theme={theme} className="action">
            <Grid container spacing={1} theme={theme}>
              <Grid item xs={9.5} theme={theme}>
                <Button
                  fullWidth
                  theme={theme}
                  primary
                  text="Available"
                  onClick={(e: any) => handleClick(e, data)}
                />
              </Grid>
              <Grid item xs={2.5} theme={theme}>
                <Button
                  fullWidth
                  theme={theme}
                  secondary
                  iconText={<Icon name="CloseBlack" />}
                  onClick={(e: any) => handleClick(e, data)}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </VehicleCardUi>
    </>
  )
}

interface IVehicleCardGridData {
  data?: IWorkQueueOrderReturn[]
  componentType?: String
  ordersLoading?: any
}

const OrderReturnGrid: FC<IVehicleCardGridData> = ({ data, componentType, ordersLoading }) => {
  const theme = useTheme()

  return (
    <>
      <VehicleCardWrap theme={theme} className="vehicle-card-wrap">
        {!ordersLoading &&
          data &&
          data?.length > 0 &&
          data
            ?.sort(
              (a: any, b: any) =>
                new Date(b?.created_at).getTime() - new Date(a?.created_at).getTime()
            )
            .map((obj: any, index: any) => (
              <VehicleCard key={index} data={obj} componentType={componentType} />
            ))}
        {ordersLoading && [1, 2, 3, 4]?.map((val) => <VehicleCardSkeleton key={val} />)}
        {!ordersLoading && data && data?.length <= 0 && (
          <NoData className="nd-lg">No data available</NoData>
        )}
      </VehicleCardWrap>
    </>
  )
}

export default OrderReturnGrid
