import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const AgreementPopupWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.agreement-popup-wrap': {
    '.custom-radio-wrap': {
      width: '100%',
      '.MuiFormGroup-root': {
        '.u-custom-control-label': {
          width: '100%',
          marginBottom: 24,
          '.custom-radio': {
            '&.Mui-checked': {
              '~': {
                '.MuiFormControlLabel-label': {
                  color: theme.palette.grey[900],
                  wordBreak: 'break-all'
                }
              }
            }
          },
          '.MuiFormControlLabel-label': {
            width: '100%',
            wordBreak: 'break-all'
          }
        },
        '.u-form-group': {
          marginBottom: 8
        }
      }
    }
  }
}))
