import { styled } from '@mui/material'

export const VehicleCardWrap = styled('div')(({ theme }) => ({
  '&.vehicle-card-wrap': {
    width: '100%',
    paddingBottom: 24,
    overflowX: 'auto',
    overflowY: 'hidden',
    display: 'flex',
    flexWrap: 'nowrap',
    borderRadius: theme.shape.borderRadius,
    '@supports (-moz-appearance:none)': {
      scrollbarWidth: 'thin'
    },
    '&::-webkit-scrollbar': {
      width: 7,
      height: 7,
      borderRadius: 7
    },
    '&::-webkit-scrollbar-track': {
      padding: '0 1px',
      backgroundColor: '#f0f0f0',
      borderRadius: 7
    },
    '&::-webkit-scrollbar-thumb': {
      transition: 'all 0.3s',
      backgroundColor: '#cdcdcd',
      borderRadius: 7
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#a6a6a6'
    },

    '.vehicle-card': {
      width: 320,
      height: 320,
      minWidth: 320,
      maxWidth: 320,
      cursor: 'pointer',

      '.MuiTypography-root': {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
      },

      '&:last-child': {
        marginRight: 0
      },

      '.vc-footer .action': {
        display: 'none'
      },

      '&:hover': {
        '.vc-footer .action': {
          display: 'none'
        }
      }
    }
  }
}))
