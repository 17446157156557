import { FC, useEffect } from 'react'
import { Controller } from 'react-hook-form'
import { useTheme } from '@mui/material'
import { unityTheme, Select, IBoxProps } from '@ntpkunity/controls'
import { Grid, Input, Box, TextBox } from 'components'
import { styled } from '@mui/material/styles'
import { useGetAllowedMileage, useGetContractTerms } from '@apis/configurations.service'

const TabWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(() => ({
  '&.tab-wrap': {
    width: '100%',
    display: 'inline-block',

    hr: {
      opacity: 0.4
    },

    '.title-icon': {
      width: '100%',
      display: 'inline-block',
      'span, Icon': {
        float: 'left'
      },
      span: {
        marginRight: 10
      }
    },
    '.buttons-row': {
      display: 'inline-block',
      textAlign: 'center',
      width: '100%',

      Button: {
        margin: '0 20px 20px 0',

        '&:last-child': {
          marginRight: 0
        }
      }
    }
  }
}))

const Preferences: FC<{
  form: any
}> = ({ form }) => {
  const theme = useTheme()

  const { mutate: getContractTerms, data: contractTermsData } = useGetContractTerms()
  const { mutate: getAllowedMileage, data: allowedMileageData } = useGetAllowedMileage()
  const {
    control,
    formState: { errors }
  } = form
  useEffect(() => {
    getContractTerms()
    getAllowedMileage()
  }, [])
  return (
    <TabWrap className="tab-wrap">
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mb: 2.7 }}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="preferences.default_mileage_id"
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <Select
                theme={theme}
                label={'Default Mileage'}
                items={allowedMileageData
                  ?.filter((x: any) => x.is_active == true)
                  ?.map((mileage: any) => {
                    return {
                      text: mileage.mileage,
                      value: mileage.id
                    }
                  })}
                {...field}
                value={field.value}
                onChange={field.onChange}
                sxProps={''}
                selectError={errors?.preferences?.default_mileage_id?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="preferences.default_term_id"
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <Select
                theme={theme}
                label={'Default Term'}
                items={contractTermsData?.map((term: any) => {
                  return {
                    text: term.term,
                    value: term.id
                  }
                })}
                sxProps={''}
                {...field}
                value={field.value}
                onChange={field.onChange}
                selectError={errors?.preferences?.default_term_id?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="preferences.minimum_down_payment_id"
            control={control}
            render={({ field }) => (
              <TextBox
                label="Minimum Down Payment"
                type="text"
                {...field}
                value={field.value ? parseFloat(field.value) : undefined}
                endAdornment="%"
                masking
                scale={2}
                numeric
                error={errors?.preferences?.minimum_down_payment_id?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="preferences.maximum_down_payment_id"
            control={control}
            render={({ field }) => (
              <TextBox
                label="Maximum Down Payment"
                type="text"
                {...field}
                value={field.value ? parseFloat(field.value) : undefined}
                endAdornment="%"
                masking
                scale={2}
                numeric
                error={errors?.preferences?.maximum_down_payment_id?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="preferences.default_down_payment_id"
            control={control}
            render={({ field }) => (
              <TextBox
                label="Default Down Payment"
                type="text"
                {...field}
                value={field.value ? parseFloat(field.value) : undefined}
                endAdornment="%"
                masking
                scale={2}
                numeric
                error={errors?.preferences?.default_down_payment_id?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="preferences.quotation_expiry_days"
            control={control}
            rules={{
              min: {
                value: 0,
                message: 'Quotation Expiry Days is invalid'
              },
              max: {
                value: 999,
                message: 'Quotation Expiry Days is invalid'
              }
            }}
            render={({ field }) => (
              <Input
                theme={unityTheme}
                fullWidth
                placeholder={'Type here..'}
                label={'Quotation Expiry Days (Optional)'}
                type="number"
                {...field}
                error={errors?.preferences?.quotation_expiry_days?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="preferences.application_expiry_days"
            control={control}
            rules={{
              min: {
                value: 0,
                message: 'Application Expiry Days is invalid'
              },
              max: {
                value: 999,
                message: 'Application Expiry Days is invalid'
              }
            }}
            render={({ field }) => (
              <Input
                theme={unityTheme}
                fullWidth
                placeholder={'Type here...'}
                label={'Application Expiry Days (Optional)'}
                type="number"
                {...field}
                error={errors?.preferences?.application_expiry_days?.message}
              />
            )}
          />
        </Grid>
      </Grid>
    </TabWrap>
  )
}
export default Preferences
