import { useTheme } from '@mui/material'
import ChatHeader from './ChatHeader/ChatHeader.component'
import ChatMessages from './ChatMessages/ChatMessages.component'
import ChatMessagesWindowWrap from './ChatMessagesWindow.style'
import ChatBox from './ChatBox/ChatBox.component'
import { useEffect } from 'react'
import { useChatServiceContext } from '@hooks/useChatService'

const ChatMessagesWindow = () => {
  const theme = useTheme()
  const { readMessage, selectedUser, messages } = useChatServiceContext()

  useEffect(() => {
    if (selectedUser && messages) readMessage()
  }, [selectedUser, messages])

  return (
    <ChatMessagesWindowWrap theme={theme} className="chats-messages-wrap">
      <ChatHeader />
      <ChatMessages />
      <ChatBox />
    </ChatMessagesWindowWrap>
  )
}

export default ChatMessagesWindow
