import { FC, useState, useEffect, useMemo } from 'react'
import { useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import { Icon } from '@ntpkunity/controls'

import { Typography, Grid, Button, DrawerWrap, PageHeader } from 'components'
import FinancialInsuranceTable from '@app/financial-insurance/financial-insurance-table'
import NewFinancialInsurance from '@app/financial-insurance/financial-insurance-drawer'
import { IFinancialInsurance } from '@models'
import { ActionOptions } from '@helpers/enums'
import { useForm } from 'react-hook-form'

const FinancialInsurance: FC = () => {
  const theme = useTheme()
  const [popUpState, setPopUpState] = useState(false)

  const [actionTitle, setActionTitle] = useState<string>('Add New Product')

  const [actionType, setActionType] = useState<ActionOptions>(ActionOptions.ADD)
  const [actionData, setActionData] = useState<IFinancialInsurance>()

  const handleSelection = (_event: any, key: any, value: any) => {
    switch (key) {
      case ActionOptions.ADD:
        setActionType(ActionOptions.ADD)
        setPopUpState(true)
        setActionTitle('Add New Product')
        setActionData(value)
        form.reset({} as IFinancialInsurance)
      default:
        return
    }
  }

  const defaultValues: IFinancialInsurance = useMemo(() => {
    const updatedFormDefaultValues = actionData
    setActionData(updatedFormDefaultValues)
    return actionData
  }, [actionData]) as unknown as IFinancialInsurance

  useEffect(() => {
    if (actionType == ActionOptions.EDIT) {
      const a = JSON.stringify(defaultValues?.applicable_finance_type)
      const b = JSON.parse(a)
      const c = JSON.parse(b)
      form.reset({ ...defaultValues, applicable_finance_type: c })
    } else {
      form.reset()
    }
  }, [defaultValues])

  useEffect(() => {
    if (popUpState == false) {
      form.reset()
    }
  }, [popUpState])

  const form: {
    handleSubmit: any
    reset: any
  } = useForm<IFinancialInsurance>()

  return (
    <DrawerWrap open={popUpState}>
      <LayoutWithSideNav theme={theme}>
        <PageHeader className="main-page-header" theme={theme}>
          <Grid container spacing={2}>
            <Grid item lg={7} md={6} sm={12} xs={12}>
              <Typography variant="h2" component="h2" theme={theme}>
                F&amp;I Products
              </Typography>
            </Grid>
            <Grid item lg={5} md={6} sm={12} xs={12} textAlign="right">
              <Button
                primary
                text={'Add New Product'}
                startIcon={<Icon name="AddIcon" />}
                onClick={(e) => handleSelection(e, ActionOptions.ADD, undefined)}
              ></Button>
            </Grid>
          </Grid>
        </PageHeader>

        <form onSubmit={(e) => e.preventDefault()}>
          <NewFinancialInsurance
            popUpState={popUpState}
            setPopUpState={setPopUpState}
            title={actionTitle}
            actionType={actionType}
            financialInsuranceData={actionData}
            form={form}
          ></NewFinancialInsurance>
        </form>

        <FinancialInsuranceTable
          setPopUpState={setPopUpState}
          setActionData={setActionData}
          setActionType={setActionType}
        ></FinancialInsuranceTable>
      </LayoutWithSideNav>
    </DrawerWrap>
  )
}

export default FinancialInsurance
