import { useTheme } from '@mui/material'
import { ISwitchProps, Switch } from '@ntpkunity/controls'
import { FC } from 'react'

const Component: FC<ISwitchProps> = (props) => {
  const theme = useTheme()

  return <Switch {...props} theme={theme} />
}

export default Component
