import { FC, useState, useEffect } from 'react'
import CardBanner from '../card-banner'
import {
  Typography,
  Grid,
  Radio,
  Skeleton,
  Textarea,
  Icon,
  AddressLayout,
  Button,
  Box,
  Input
} from '@ntpkunity/controls'
import { Divider, InputMaskWrap } from 'components'
import { IRequestReadyCheckList } from '@models'
import {
  useGetReadyCheckListRequest,
  useGetChecklistSavedProgress,
  useDonwnloadDocument,
  useGetStates,
  useGetCountries,
  useGetInsuranceDetails,
  useGetCustomerLicenseInfo,
  useDocumentPackage
} from '@apis/dealer-workqueue.service'
import { useTheme } from '@mui/material'
import { useStoreContext } from '@store/storeContext'
//@ts-ignore
import { useHasPermissions } from '@ntpkunity/controls-ums'
import { dealexPermissions } from '@ntpkunity/controls-ums/dealexPermissions'
import { Controller } from 'react-hook-form'
import {
  compareAnswers,
  containsKeyValue,
  question_check,
  removeApostrophes
} from '@helpers/methods'
import InsuranceDetails from '../schedule-pickup/insurance-details'
import License from '../schedule-pickup/license'
import { Documents } from './documentStyle'
import CustomerDetailsSection from '../customer-details'
import { Link } from 'react-router-dom'
import AttachementIcon from '../../../public/assets/icons/attachment-icon.svg'

const ReadyChecklistUi: FC<{ form: any; checkListCallback: any; checklistErrors: any }> = ({
  form,
  checkListCallback,
  checklistErrors
}: any) => {
  const {
    control,
    watch,
    trigger,
    getValues,
    formState: { errors }
  } = form

  const theme = useTheme()
  const { actions, states } = useStoreContext()
  const { mutate: getContractDocument } = useDocumentPackage()
  const { mutate: getDocURL } = useDonwnloadDocument()
  const [radioValues, setRadioValues] = useState({})
  const [savedChecklistOptions, setSavedChecklistOptions] = useState<boolean>(false)
  const [checklistValuesProps, setChecklistValuesProps] = useState<boolean>(false)
  const [savedChecklist, setSavedChecklist] = useState<any>()
  const [questionArray, setQuestionArray] = useState<any>([])

  const description = watch('description')
  const {
    mutate: getEventAssociatedReadyCheckList,
    data: checkList,
    isLoading
  } = useGetReadyCheckListRequest()
  const { mutate: getChecklistProgressOptions, data: _savedChecklist } =
    useGetChecklistSavedProgress()
  const { mutate: getInsuranceDetails, data: insuranceData } = useGetInsuranceDetails()
  const { mutate: getCustomerLicenseInfo } = useGetCustomerLicenseInfo()
  const { mutate: getStates, data: statesData } = useGetStates()
  const { mutate: getCountry, data: countriesData } = useGetCountries()
  const justUSA = countriesData?.filter((x: any) => x?.country_code == 'US')
  const {
    states: {
      insuranceInformation: { insurer_address }
    }
  } = useStoreContext()
  const [showAccordionDetail, setShowAccordionDetail] = useState(false)
  const [contractDocuments, setContractDocuments] = useState<any>()
  const [formErrors, setFormErrors] = useState<any>()

  const reqCheckListObject: IRequestReadyCheckList = {
    event_name: 'get_ready_checklist_event',
    ...(states?.dealerSelectedOrderData &&
      Object.keys(states?.dealerSelectedOrderData).length > 0 &&
      states?.dealerSelectedOrderData?.fulfillment &&
      (states?.dealerSelectedOrderData?.fulfillment?.dealerAddress?.state === 'FL' ||
        states?.dealerSelectedOrderData?.fulfillment?.dealerAddress?.state === 'CA') && {
        criteria: {
          state: states?.dealerSelectedOrderData?.fulfillment?.dealerAddress?.state
        }
      })
  }
  useEffect(() => {
    getCountry()
    getStates()
    if (states?.dealerSelectedOrderData?.customerId != undefined) {
      getInsuranceDetails({
        customer_ref_id: states?.dealerSelectedOrderData?.customerId
      })
      getCustomerLicenseInfo({
        customer_ref_id: states?.dealerSelectedOrderData?.customerId
      })
    }
  }, [states?.dealerSelectedOrderData?.customerId])
  useEffect(() => {
    if (states?.dealerSelectedOrderData?.customerId) {
      if (states?.dealerSelectedOrderData?.fulfillment?.dealerAddress?.state) {
        getEventAssociatedReadyCheckList(
          { ...reqCheckListObject },
          {
            onSuccess(res: any) {
              setQuestionArray(() => {
                const questions = res.flatMap((checklist: any[]) =>
                  checklist.flatMap((item) =>
                    item?.questions.map((question: { question: any }) => question.question)
                  )
                )
                return questions.map((question: string) => ({
                  originalQuestion: question,
                  modifiedQuestion: removeApostrophes(question)
                }))
              })

              getChecklistProgressOptions(
                {
                  customer_reference_id: states?.dealerSelectedOrderData?.customerId,
                  checklist_identifier: res[0]?.[0]?.checklist_identifier,
                  order_reference_id: states?.dealerSelectedOrderData?.id
                },
                {
                  onSuccess(res: any) {
                    setSavedChecklist(res)
                    for (let i = 0; i < res.length; i++) {
                      setRadioValues((previousValues) => {
                        return {
                          ...previousValues,
                          [res[i].question_text]: res[i].primary_answer
                        }
                      })
                    }
                    setSavedChecklistOptions(true)
                  },
                  onError({ error }: any) {
                    if (error?.response?.data?.detail?.code === 404) {
                      setSavedChecklistOptions(false)
                    }
                  }
                }
              )
            }
          }
        )
      } else {
        actions.setToast({
          toastMessage: 'Checklist does not exist due to absence of state',
          toastState: true
        })
      }
    }
  }, [states?.dealerSelectedOrderData?.customerId])
  const onRadioChange = (val: any, question: any) => {
    setRadioValues({ ...radioValues, [question]: val })
  }
  useEffect(() => {
    if (radioValues && Object.keys(radioValues).length > 0) {
      let question: { [x: string]: any } = {}
      if (!checklistValuesProps) {
        question = question_check(getValues('radio_checklist'), questionArray)
      } else {
        question = question_check(getValues('ready_radio_checklist'), questionArray)
      }
      checkListCallback(
        question,
        savedChecklistOptions,
        Object.keys(checkList[0]?.[0]?.questions)?.length,
        checkList[0]?.[0]?.checklist_name,
        checkList[0]?.[0]?.checklist_identifier,
        description,
        checkList
      )
    }
  }, [radioValues, savedChecklistOptions, description])

  const openNewTab = (document: string) => {
    window.open(document, '_blank')
  }

  const hasPermission = useHasPermissions(
    dealexPermissions?.SCREEN?.GET_READY_CHECKLIST?.MARK_CHECKLIST_OPTIONS
  )
  useEffect(() => {
    if (states?.dealerSelectedOrderData?.customerId != undefined) {
      getContractDocument(
        { reference_id: states?.dealerSelectedOrderData?.customerId },
        {
          onSuccess(response: any) {
            setContractDocuments(
              response?.documents.filter((f: any) => {
                return f?.title.includes('Lease')
              })[0]
            )
          }
        }
      )
    }
  }, [states?.dealerSelectedOrderData?.customerId])
  useEffect(() => {
    const errors = compareAnswers(radioValues, checkList)
    setFormErrors(errors)
  }, [savedChecklistOptions, checkList, radioValues])

  useEffect(() => {
    if (savedChecklist && savedChecklistOptions) {
      const checklistQuestionNames = savedChecklist
        .filter(
          (item: any) => removeApostrophes(item?.question_text) in getValues('radio_checklist.')
        )
        .map((item: any) => `radio_checklist.${removeApostrophes(item?.question_text)}`)

      ;(async () => await trigger(checklistQuestionNames))()
    }
  }, [savedChecklistOptions, savedChecklist, radioValues])

  const isAllSameChars = (str: string) => {
    const firstChar = str.charAt(0)
    return str.split('').every((char) => char === firstChar)
  }
  const isValid = (value: string) => {
    const trimmedValue = value?.replace(/[^a-zA-Z0-9]/g, '')?.toUpperCase()
    const isFullSerial = trimmedValue?.length === 12
    const isLastSeven = trimmedValue?.length === 7
    if (!value || typeof value !== 'string') {
      return ''
    }
    if (!isFullSerial && !isLastSeven) {
      return 'Please enter either the full serial number or the last 7 of the serial number to proceed.'
    }
    if (isAllSameChars(trimmedValue)) {
      return 'Please enter either the correct full serial number or the correct last 7 of the serial number to proceed.'
    }
    return true
  }

  return (
    <>
      {useHasPermissions(dealexPermissions?.SCREEN?.GET_READY_CHECKLIST?.VEHICLE_DETAILS) && (
        <CardBanner />
      )}
      <Grid theme={theme} spacing={2} container sx={{ mb: 1 }}>
        {isLoading &&
          [1, 2, 3, 4]?.map((val) => (
            <Grid theme={theme} item md={4} sm={6} xs={12} key={val}>
              <Skeleton theme={theme} width={'25%'} height={'20px'} />
              <Skeleton theme={theme} width={'25%'} height={'20px'} />
            </Grid>
          ))}
        {checkList?.length > 0 &&
          checkList?.map((innerArray: any, index: any) => (
            <span key={index} style={{ padding: '20px' }}>
              <Grid container theme={theme}>
                <Typography
                  theme={theme}
                  variant="h3"
                  component="h3"
                  className="title"
                  children={'Get Ready Checklist'}
                  sx={{ mb: 3 }}
                />
              </Grid>
              <Grid container theme={theme}>
                {innerArray?.map((list: any) =>
                  list?.questions?.map((obj: any, index: any) => (
                    <Grid theme={theme} item md={4} sm={6} xs={12} key={index}>
                      {savedChecklist?.find(
                        (myObj: any) => myObj?.question_text == obj?.question
                      ) &&
                        obj?.question && (
                          <>
                            <Typography
                              theme={theme}
                              variant="subtitle2"
                              component="p"
                              sx={{ mb: 1.5 }}
                              children={
                                obj?.question === 'GeoTab Serial Number'
                                  ? `${obj?.question} (from the box)`
                                  : obj.question
                              }
                            />
                            {obj?.question === 'GeoTab Serial Number' ? (
                              <InputMaskWrap className="input-mask-wrap">
                                <Controller
                                  name={`radio_checklist.${removeApostrophes(obj?.question)}`}
                                  control={control}
                                  defaultValue={
                                    savedChecklist?.find(
                                      (myObj: any) =>
                                        myObj?.question_text === 'GeoTab Serial Number'
                                    )?.primary_answer ?? ''
                                  }
                                  rules={{
                                    validate: isValid
                                  }}
                                  render={({ field: { onChange, value } }: any) => (
                                    <>
                                      <Box theme={theme} sx={{ width: '250px' }}>
                                        <Input
                                          theme={theme}
                                          type="text"
                                          value={value}
                                          onChange={(e) => {
                                            let filteredValue = e
                                              ?.replace(/[^a-zA-Z0-9]/g, '')
                                              ?.toUpperCase()
                                            if (filteredValue?.length === 7) {
                                              filteredValue =
                                                filteredValue?.slice(0, 3) +
                                                '-' +
                                                filteredValue?.slice(3, 7)
                                            } else if (filteredValue?.length === 12) {
                                              filteredValue =
                                                filteredValue?.slice(0, 2) +
                                                '-' +
                                                filteredValue?.slice(2, 5) +
                                                '-' +
                                                filteredValue?.slice(5, 8) +
                                                '-' +
                                                filteredValue?.slice(8, 12)
                                            }
                                            onChange(filteredValue)
                                            onRadioChange(e, obj.question)
                                          }}
                                          error={
                                            errors.radio_checklist &&
                                            (
                                              errors.radio_checklist as {
                                                [key: string]: { message?: string }
                                              }
                                            )[removeApostrophes(obj?.question)]?.message
                                          }
                                        />
                                      </Box>
                                    </>
                                  )}
                                />
                              </InputMaskWrap>
                            ) : (
                              <>
                                <Controller
                                  name={`radio_checklist.${removeApostrophes(obj?.question)}`}
                                  control={control}
                                  rules={{
                                    required: 'Select an option to proceed.'
                                  }}
                                  defaultValue={
                                    savedChecklist?.find(
                                      (myObj: any) => myObj?.question_text === obj?.question
                                    )?.primary_answer ?? ''
                                  }
                                  render={({ field: { onChange, value } }: any) => (
                                    <Radio
                                      theme={theme}
                                      row={true}
                                      onChange={(val: any) => {
                                        onChange(val)
                                        onRadioChange(val, obj.question)
                                      }}
                                      value={value}
                                      radioError={
                                        (errors?.radio_checklist
                                          ? (
                                              errors.radio_checklist as {
                                                [key: string]: { message?: string }
                                              }
                                            )[removeApostrophes(obj?.question)]?.message
                                          : undefined) ??
                                        checklistErrors?.find(
                                          (error: { question: any }) =>
                                            error.question === obj?.question
                                        )?.message
                                      }
                                      options={obj?.answers.map((obj: any) => ({
                                        label: obj?.answer,
                                        value: obj?.answer,
                                        disabled: hasPermission === true ? false : true
                                      }))}
                                    />
                                  )}
                                />
                                {!errors?.[`radio_checklist.${obj?.question}`] &&
                                formErrors.length > 0 &&
                                !checklistErrors?.find(
                                  (error: { question: any }) =>
                                    error?.question === formErrors[0]?.question
                                ) ? (
                                  <Typography
                                    className="text-danger"
                                    theme={theme}
                                    variant="caption"
                                    component="small"
                                    display="block"
                                    // children={obj?.question}
                                  >
                                    {
                                      formErrors?.find(
                                        (error: { question: any }) =>
                                          error.question === obj?.question
                                      )?.message
                                    }
                                  </Typography>
                                ) : null}
                              </>
                            )}
                          </>
                        )}
                      {!savedChecklist?.find(
                        (myObj: any) => myObj?.question_text == obj?.question
                      ) &&
                        obj?.question && (
                          <>
                            <Typography
                              theme={theme}
                              variant="subtitle2"
                              component="p"
                              sx={{ mb: 1.5 }}
                              children={
                                obj?.question === 'GeoTab Serial Number'
                                  ? `${obj?.question} (from the box)`
                                  : obj.question
                              }
                            />
                            {obj?.question === 'GeoTab Serial Number' ? (
                              <InputMaskWrap className="input-mask-wrap">
                                <Controller
                                  name={`ready_radio_checklist.${removeApostrophes(obj?.question)}`}
                                  control={control}
                                  defaultValue={''}
                                  rules={{
                                    validate: isValid
                                  }}
                                  render={({ field: { onChange, value } }: any) => (
                                    <>
                                      <Box theme={theme} sx={{ width: '250px' }}>
                                        <Input
                                          theme={theme}
                                          type="text"
                                          value={value}
                                          onChange={(e) => {
                                            let filteredValue = e
                                              ?.replace(/[^a-zA-Z0-9]/g, '')
                                              ?.toUpperCase()
                                            if (filteredValue?.length === 7) {
                                              filteredValue =
                                                filteredValue?.slice(0, 3) +
                                                '-' +
                                                filteredValue?.slice(3, 7)
                                            } else if (filteredValue?.length === 12) {
                                              filteredValue =
                                                filteredValue?.slice(0, 2) +
                                                '-' +
                                                filteredValue?.slice(2, 5) +
                                                '-' +
                                                filteredValue?.slice(5, 8) +
                                                '-' +
                                                filteredValue?.slice(8, 12)
                                            }
                                            onChange(filteredValue)
                                            onRadioChange(e, obj.question)
                                            setChecklistValuesProps(true)
                                          }}
                                          error={
                                            errors.ready_radio_checklist &&
                                            (
                                              errors.ready_radio_checklist as {
                                                [key: string]: { message?: string }
                                              }
                                            )[removeApostrophes(obj?.question)]?.message
                                          }
                                        />
                                      </Box>
                                    </>
                                  )}
                                />
                              </InputMaskWrap>
                            ) : (
                              <>
                                <Controller
                                  name={`ready_radio_checklist.${removeApostrophes(obj?.question)}`}
                                  control={control}
                                  rules={{
                                    required: 'Select an option to proceed.'
                                  }}
                                  defaultValue={''}
                                  render={({ field: { onChange } }: any) => (
                                    <Radio
                                      theme={theme}
                                      row={true}
                                      onChange={(val: any) => {
                                        onChange(val)
                                        onRadioChange(val, obj.question)
                                        setChecklistValuesProps(true)
                                      }}
                                      options={obj?.answers.map((obj: any) => ({
                                        label: obj?.answer,
                                        value: obj?.answer,
                                        disabled: hasPermission === true ? false : true
                                      }))}
                                      radioError={
                                        (errors?.ready_radio_checklist
                                          ? (
                                              errors.ready_radio_checklist as {
                                                [key: string]: { message?: string }
                                              }
                                            )[removeApostrophes(obj?.question)]?.message
                                          : undefined) ??
                                        checklistErrors?.find(
                                          (error: { question: any }) =>
                                            error.question === obj?.question
                                        )?.message
                                      }
                                    />
                                  )}
                                />
                                {!errors?.[`ready_radio_checklist.${obj?.question}`] &&
                                formErrors.length > 0 &&
                                !checklistErrors?.find(
                                  (error: { question: any }) =>
                                    error?.question === formErrors[0]?.question
                                ) ? (
                                  <Typography
                                    className="text-danger"
                                    theme={theme}
                                    variant="caption"
                                    component="small"
                                    display="block"
                                    // children={obj?.question}
                                  >
                                    {
                                      formErrors?.find(
                                        (error: { question: any }) =>
                                          error.question === obj?.question
                                      )?.message
                                    }
                                  </Typography>
                                ) : null}
                              </>
                            )}
                          </>
                        )}
                      {obj?.question == 'Does the vehicle have any damage?' &&
                        ((savedChecklist?.find(
                          (myObj: any) => myObj?.question_text == obj?.question
                        )?.primary_answer === 'Yes' &&
                          containsKeyValue(
                            radioValues,
                            'Does the vehicle have any damage?',
                            'Yes'
                          )) ||
                          (radioValues &&
                            Object.keys(radioValues).length > 0 &&
                            containsKeyValue(
                              radioValues,
                              'Does the vehicle have any damage?',
                              'Yes'
                            ))) && (
                          <Grid theme={theme} item xs={12} sx={{ mt: 2 }}>
                            <Controller
                              name={`description`}
                              control={control}
                              defaultValue={
                                savedChecklist?.find(
                                  (myObj: any) => myObj?.question_text == obj?.question
                                )?.description
                              }
                              render={({ field }) => (
                                <Textarea
                                  theme={theme}
                                  label={'Details'}
                                  type="text"
                                  id="description"
                                  placeholder="Type here..."
                                  rows={3}
                                  fullWidth
                                  {...field}
                                  value={field.value}
                                />
                              )}
                            />
                          </Grid>
                        )}
                    </Grid>
                  ))
                )}
              </Grid>
            </span>
          ))}
      </Grid>
      {useHasPermissions(dealexPermissions?.SCREEN?.GET_READY_CHECKLIST?.CUSTOMER_DETAILS) && (
        <CustomerDetailsSection />
      )}

      <Divider sx={{ mt: '35px', mb: '35px' }} />
      <>
        <Documents>
          <Grid
            theme={theme}
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ mb: 3.5 }}
          >
            {contractDocuments != null ? (
              <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                <Typography
                  variant="subtitle1"
                  component="h6"
                  rowGap={5}
                  mb={1.5}
                  theme={theme}
                  children={contractDocuments?.title}
                />
                <Box theme={theme} className="card">
                  <Box theme={theme} className="row">
                    <Icon name="PdfFileIcon" />
                    <Typography theme={theme} variant="subtitle2" component="p">
                      {'Lease Agreement'}
                    </Typography>
                  </Box>
                  <Box theme={theme} className="row">
                    <Button
                      // disabled={isDownloading || !viewUploadedAccountDocsPermissionState}
                      theme={theme}
                      defaultBtn
                      onClick={() => {
                        getDocURL(
                          {
                            artifact_id: contractDocuments?.generated_document_uri
                          },
                          {
                            onSuccess(res: any) {
                              window.open(res?.document_url)
                            }
                          }
                        )
                      }}
                      iconText={<Icon name="IcView" />}
                    />
                  </Box>
                </Box>
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
              <Typography
                variant="subtitle1"
                component="h6"
                rowGap={5}
                mb={1.5}
                theme={theme}
                children={'Title and Registration Instructions'}
              />
              <Box theme={theme} className="card">
                <Box theme={theme} className="row">
                  <Box theme={theme} className="image-area">
                    <img src={AttachementIcon} />
                  </Box>
                  <Typography theme={theme} variant="subtitle2" component="p">
                    <Link
                      className="link"
                      to={process.env.MOBILITY_DEALER_CENTRAL_URL?.toString() ?? ''}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Mobility on Dealer Central
                    </Link>
                  </Typography>
                </Box>
                <Box theme={theme} className="row">
                  <Button
                    // disabled={isDownloading || !viewUploadedAccountDocsPermissionState}
                    theme={theme}
                    defaultBtn
                    onClick={() => {
                      openNewTab(checkList?.[0]?.[0]?.guideline_document)
                    }}
                    iconText={<Icon name="IcView" />}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Documents>
      </>
      {/* )} */}
      <Divider sx={{ mt: '35px', mb: '35px' }} />
      {useHasPermissions(dealexPermissions?.SCREEN?.SCHEDULE_PICKUP?.INSURANCE_DETAILS) && (
        <Typography theme={theme} variant="h3" component="h3" className="title" sx={{ mb: 3 }}>
          Insurance Details
          <Icon
            name="ChevronDown"
            className={`toggle-btn${showAccordionDetail ? ' ch-up' : ''}`}
            onClick={() => setShowAccordionDetail(!showAccordionDetail)}
          />
        </Typography>
      )}
      {useHasPermissions(dealexPermissions?.SCREEN?.SCHEDULE_PICKUP?.INSURANCE_DETAILS) &&
        showAccordionDetail && (
          <>
            <InsuranceDetails />
            <Divider sx={{ mt: '35px', mb: '35px' }} />
            <Box theme={theme} sx={{ mb: 3 }}>
              <Typography
                theme={theme}
                variant="h4"
                component="h4"
                className="title"
                children="Insurance Provider Address"
              />
              <AddressLayout
                theme={theme}
                state={{
                  data: statesData,
                  selectedItemObject: { text: 'name', value: 'code' },
                  controlName: 'state_name'
                }}
                countries={justUSA}
                value={insurer_address}
                validationProps
                //@ts-ignore
                onChange={(val: any) => {}}
                addressVerificationRequired={false}
                isContactRequired={false}
                makeFieldsDisabled={true}
                verifyAddressURL="https://integration-api-dev.netsolapp.io/api/verify-address/"
                googleApiKey={'X_GOOGLE_API_KEY'}
                showCountryDropdown={false}
                hideAddressButton
              />
            </Box>
            <Typography
              theme={theme}
              variant="h4"
              component="h4"
              className="title"
              children="Proof of Insurance"
              sx={{ mb: 2 }}
            />
            <>
              <Documents>
                {insuranceData?.insurance_documents?.length > 0 ? (
                  <>
                    <Grid
                      theme={theme}
                      container
                      rowSpacing={3}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      sx={{ mb: 3.5 }}
                    >
                      {insuranceData?.insurance_documents?.map((item: any, index: any) => {
                        return (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            theme={theme}
                            key={item?.identifier}
                          >
                            <Box theme={theme} className="card">
                              <Box theme={theme} className="row">
                                {item?.content_type === ('image/jpeg' || 'image/png') ? (
                                  <Icon name="ImageIcon" />
                                ) : (
                                  <Icon name="PdfFileIcon" />
                                )}
                                <Typography theme={theme} variant="body2" component="p">
                                  {item?.name}
                                </Typography>
                              </Box>

                              <Box theme={theme} className="row" key={index}>
                                <Button
                                  theme={theme}
                                  defaultBtn
                                  onClick={() => {
                                    getDocURL(
                                      {
                                        artifact_id: item?.document_uri
                                      },
                                      {
                                        onSuccess(res: any) {
                                          window.open(res?.document_url)
                                        }
                                      }
                                    )
                                  }}
                                  iconText={<Icon name="IcView" />}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </>
                ) : (
                  <Typography
                    variant="body2"
                    component="span"
                    mb={3}
                    theme={theme}
                    className="title-caption"
                    children={'There is no document added here.'}
                  />
                )}
              </Documents>
            </>
          </>
        )}
      <Divider sx={{ mt: '35px', mb: '35px' }} />
      {useHasPermissions(dealexPermissions?.SCREEN?.SCHEDULE_PICKUP?.DRIVER_LICENSE_DETAILS) && (
        <License />
      )}
    </>
  )
}

export default ReadyChecklistUi
