import { FC, useState } from 'react'
import { Stack, useTheme } from '@mui/material'
import {
  Typography,
  Box,
  Icon,
  Tooltip,
  ButtonGroup,
  Menu,
  Button,
  Dialog
} from '@ntpkunity/controls'
import { PageHeader, Divider, CustomDialogWrap } from '@components'
import { useNavigate } from 'react-router'
import { APP_ROUTES } from '@helpers/links'
import { styled } from '@mui/material/styles'
import { WorkQueueState } from '@helpers/enums'
import { useModal } from 'react-modal-hook'
import { CustomerOrderList } from '@app/index'
import CompareIcon from '../../public/assets/icons/compare-icon.svg'
import AffordabilityCheck from 'components/Affordability'
import PreQualificationDialog from '@app/work-queue-tables/pre-qualification-dialog/pre-qualification-dialog'
import { IPreQualificationResponse } from 'types/pre-qualified'

const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1)
}))

const WorkQueueHeader: FC<{
  total_results: number
  heading: string
  header_text: string
  state: WorkQueueState
  handleSearchClick: (state: WorkQueueState) => void
  handleChangeView: (state: WorkQueueState) => void
  setPreQualificationResponse?: (value: IPreQualificationResponse) => void
  showCreateOrderButton?: boolean
  disableButton?: boolean
  isCardView?: boolean
  hideActionArea?: boolean
}> = ({
  total_results,
  heading,
  header_text,
  handleSearchClick,
  state,
  handleChangeView,
  showCreateOrderButton,
  disableButton,
  isCardView,
  hideActionArea,
  setPreQualificationResponse
}) => {
  const theme = useTheme()
  let navigate = useNavigate()
  const [openVdModal, closeVdModal] = useModal(
    () => <CustomerOrderList closeVdModal={closeVdModal} navigate={navigate} />,
    []
  )
  const [openAffoedibilityDialog, setOpenAffordabilityDialog] = useState(false)
  const [showPreQualificationForm, setShowPreQualificationForm] = useState<boolean>(false)

  const handleOptionClick = (_event: any, _key: any, value: any) => {
    switch (value) {
      case 'Finance First':
        setOpenAffordabilityDialog(true)
        localStorage.setItem('closeModal', 'false')
        break
      case 'Pre Qualifications':
        setShowPreQualificationForm(true)
        break
    }
  }

  return (
    <>
      <PageHeader className="main-page-header">
        <Box flexGrow={'1'} theme={theme}>
          <Typography theme={theme} variant="h2" component="h2" className="title">
            {heading}
          </Typography>
          {total_results > 0 && (
            <Typography theme={theme} variant="subtitle1" component="p" className="counter">
              {total_results}
            </Typography>
          )}
          <Typography theme={theme} variant="body2" component="span" className="caption">
            {header_text}
          </Typography>
        </Box>
        {!hideActionArea && (
          <Box
            className="action-area"
            flexShrink={0}
            display={'flex'}
            alignItems={'center'}
            theme={theme}
          >
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
              className="stack"
              sx={{ mr: 2 }}
            >
              {heading === 'Deals' ? (
                <Item theme={theme}>
                  <Tooltip title="Compare Deals" theme={theme} placement="top">
                    <Button
                      theme={theme}
                      className="btn-compare"
                      defaultBtn
                      disabled={disableButton}
                      iconText={<img src={CompareIcon} />}
                      onClick={() => {
                        openVdModal()
                      }}
                    />
                  </Tooltip>
                </Item>
              ) : (
                <></>
              )}
              <Item theme={theme}>
                <Button
                  theme={theme}
                  defaultBtn
                  iconText={<Icon name="SearchIcon" />}
                  onClick={() => {
                    handleSearchClick(state)
                  }}
                />
              </Item>
              <Item theme={theme}>
                <Button
                  theme={theme}
                  defaultBtn
                  iconText={<Icon name={isCardView ? 'ListIcon' : 'GridIcon'} />}
                  onClick={(_e) => {
                    handleChangeView(state)
                  }}
                />
              </Item>
            </Stack>
            {showCreateOrderButton && (
              <ButtonGroup
                theme={theme}
                variant="contained"
                disabled={disableButton}
                children={
                  <>
                    <Button
                      secondary
                      theme={theme}
                      text={'Deal Desk'}
                      onClick={() => {
                        localStorage.setItem('closeModal', 'false')
                        localStorage.removeItem('step')
                        navigate(APP_ROUTES.DESKING)
                      }}
                    />
                    <Menu
                      theme={theme}
                      options={[
                        {
                          optionText: 'Finance First',
                          optionValue: 'Finance First'
                        },
                        {
                          optionText: 'Pre-Qualification',
                          optionValue: 'Pre-Qualification'
                        }
                      ]}
                      handleOptionClick={handleOptionClick}
                      render={(onMenuSelection) => (
                        <Button
                          theme={theme}
                          secondary
                          iconText={<Icon name="MoreIcon" />}
                          onClick={onMenuSelection}
                        />
                      )}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                    />
                  </>
                }
              />
            )}
          </Box>
        )}
      </PageHeader>

      <PreQualificationDialog
        open={showPreQualificationForm}
        onClose={(values?: IPreQualificationResponse) => {
          if (values && setPreQualificationResponse) setPreQualificationResponse(values)
          setShowPreQualificationForm(false)
        }}
      />
      <CustomDialogWrap theme={theme} className="custom-dialog-wrap lane-dialog-wrap s-xl">
        <Dialog
          className="dialog-with-border"
          theme={theme}
          title={''}
          open={openAffoedibilityDialog}
          onCloseDialog={() => {
            setOpenAffordabilityDialog(false)
          }}
          fullWidth
          size="xl"
          disablePortal
          noFooter
        >
          <AffordabilityCheck setOpenAffordibilityDialog={setOpenAffordabilityDialog} />
        </Dialog>
      </CustomDialogWrap>
    </>
  )
}

export default WorkQueueHeader
