import { useTheme } from '@mui/material'
import { Button, Dialog } from '@ntpkunity/controls'
import { PreQualificationDialogWrap } from './pre-qualification-dialog.style'
import { PreQualificationForm } from '@ntpkunity/controls-common'
import { useForm } from 'react-hook-form'
import { useStoreContext } from '@store/storeContext'
import { IPreQualification, IPreQualificationResponse } from 'types/pre-qualified'
import { useSavePreQualification } from '@apis/customer-management.service'
import { useGetStates } from '@apis/customer-detail.service'
import { PRE_QUALIFICATION_CONSTANTS } from '@helpers/constants'
import DisableLoader from '@public/assets/images/loader-disabled.gif'
import { useEffect } from 'react'

interface IPreQualificationDialogProps {
  open: boolean
  onClose: (values?: IPreQualificationResponse) => void
}

const PreQualificationDialog = ({ open, onClose }: IPreQualificationDialogProps) => {
  const theme = useTheme()
  const form = useForm<IPreQualification>()
  const { handleSubmit } = form
  const { actions, states } = useStoreContext()
  const { data: stateData } = useGetStates()

  const { mutate: saveQualification, isLoading: saveLoading } = useSavePreQualification()

  useEffect(() => {
    if (open) {
      if (open) form.reset(PRE_QUALIFICATION_CONSTANTS.defaultValues as IPreQualification)
    }
  }, [open])

  const onSubmit = (values: IPreQualification) => {
    if (saveLoading) return

    saveQualification(
      {
        ...values,
        dealer_id: states?.dealerAndLenderInfo?.id
      },
      {
        onSuccess: (response: IPreQualificationResponse) => {
          form.reset(PRE_QUALIFICATION_CONSTANTS.defaultValues as IPreQualification)
          onClose(response)
        },
        onError(error: any) {
          actions.setToast({
            toastMessage: error?.error?.toString(),
            toastState: true,
            variant: 'error'
          })
        }
      }
    )
  }

  return (
    <PreQualificationDialogWrap theme={theme} className="qualification-dialog-wrap">
      <Dialog
        variant={undefined}
        size="lg"
        title={PRE_QUALIFICATION_CONSTANTS.title}
        open={open}
        onCloseDialog={() => onClose()}
        theme={theme}
        disablePortal
        customFooter={
          <>
            <Button
              theme={theme}
              primary
              text="Submit"
              disabled={saveLoading}
              onClick={handleSubmit(onSubmit)}
              startIcon={saveLoading && <img src={DisableLoader} alt="Loader" />}
            />
          </>
        }
      >
        <PreQualificationForm
          theme={theme}
          addressVerified={false}
          form={form}
          statesData={{
            data: stateData || [],
            selectedItemObject: { text: 'name', value: 'code' }
          }}
          countries={[{ id: 229 }]}
          addressTitle={PRE_QUALIFICATION_CONSTANTS.addressTitle}
          disclaimerText={PRE_QUALIFICATION_CONSTANTS.disclaimer}
        />
      </Dialog>
    </PreQualificationDialogWrap>
  )
}

export default PreQualificationDialog
