import { styled } from '@mui/material/styles'
import { StyleVariables } from '@styles/theme'

export const BtnGroupList = styled('div')(({ theme }) => ({
  '.MuiButtonBase-root.btn': {
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    paddingLeft: 5,
    paddingRight: 5,
    display: 'inline-block',
    '&.btn-secondary': {
      backgroundColor: StyleVariables.lightGrey,
      borderColor: StyleVariables.lightGrey,
      '&:hover': {
        backgroundColor: theme.palette.grey[50],
        borderColor: theme.palette.grey[50],
        color: theme.palette.grey[900]
      }
    },
    '&.btn-primary': {
      backgroundColor: theme.palette.grey[700] + '!important',
      color: theme.palette.common.white + '!important'
    },
    '.caption-text': {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.caption.fontSize,
      fontWeight: theme.typography.fontWeightRegular,
      width: '100%',
      display: 'inline-block',
      clear: 'both',
      opacity: 0.5
    },
    '&.selected': {
      background: theme.palette.grey[700],
      borderColor: theme.palette.grey[700],
      color: theme.palette.common.white,
      // fontWeight: theme.typography.fontWeightBold
      '.caption-text': {
        opacity: 1
      }
    },
    '&:hover': {
      '.caption-text': {
        opacity: 0.75
      }
    }
  },
  '.btn-row': {
    display: 'flex',
    flexWrap: 'wrap',
    '.flexible-btn': {
      minWidth: '62px',
      marginRight: 8,
      marginBottom: 8
    }
  }
}))
