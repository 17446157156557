import { FC } from 'react'
import { useTheme } from '@mui/material'
import { Grid, Input, DatePicker } from '@ntpkunity/controls'
import { useStoreContext } from '@store/storeContext'

const InsuranceDetails: FC = () => {
  const theme = useTheme()
  const {
    states: { insuranceInformation }
  } = useStoreContext()

  return (
    <Grid theme={theme} container columnSpacing={3} rowSpacing={4}>
      <Grid theme={theme} item md={3} sm={6} xs={12}>
        <Input
          theme={theme}
          type={'text'}
          disabled
          value={insuranceInformation?.insurer_name}
          fullWidth
          label="Insurance Provider Name"
          placeholder="Type here.."
        />
      </Grid>
      <Grid theme={theme} item md={3} sm={6} xs={12}>
        <Input
          theme={theme}
          type={'text'}
          disabled
          value={insuranceInformation?.policy_number}
          fullWidth
          label="Policy Number"
          placeholder="123456789"
        />
      </Grid>
      <Grid theme={theme} item md={3} sm={6} xs={12}>
        <DatePicker
          theme={theme}
          disabled
          value={insuranceInformation?.start_date}
          // onChange={(val:any)=>setDate(val)}
          label="Policy Start Date"
        />
      </Grid>
      <Grid theme={theme} item md={3} sm={6} xs={12}>
        <DatePicker
          theme={theme}
          disabled
          label="Expiration Date"
          value={insuranceInformation?.expiration_date}
        />
      </Grid>
    </Grid>
  )
}

export default InsuranceDetails
