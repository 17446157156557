import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const AlertBox: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  padding: 10,
  borderRadius: theme.shape.borderRadius,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.subtitle2.fontSize,
  lineHeight: theme.typography.subtitle2.lineHeight,
  fontWeight: theme.typography.fontWeightMedium,
  marginBottom: 24,
  display: 'inline-block',
  textAlign: 'center',
  width: '100%',

  '&.default': {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.grey[900]
  },

  '&.danger': {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white
  },

  '&.dark': {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },

  '&.buttons': {
    padding: '0 0 0 16px',
    textAlign: 'left',

    span: {
      float: 'left',
      marginRight: 10,
      lineHeight: '36px'
    },

    '.btn-wrap': {
      float: 'right',
      display: 'inline-block',
      '.btn': {
        padding: '8px 16px',
        border: 0,
        borderLeftWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.grey[700],
        color: theme.palette.common.white,
        fontSize: theme.typography.htmlFontSize,
        fontWeight: theme.typography.fontWeightMedium,
        borderRadius: 0,

        '&:last-child': {
          borderTopRightRadius: theme.shape.borderRadius,
          borderBottomRightRadius: theme.shape.borderRadius
        },

        '&:hover': {
          backgroundColor: theme.palette.primary.main
        }
      }
    },

    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      padding: '16px 0 0 0',
      span: {
        padding: '0 16px',
        float: 'none',
        width: '100%',
        display: 'inline-block',
        lineHeight: 'normal',
        marginBottom: 16
      },
      '.btn-wrap': {
        float: 'none',
        width: '100%',
        clear: 'both',
        borderToptWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: theme.palette.grey[700],
        '.btn': {
          width: '50%',
          border: 0,
          borderColor: 'transparent',
          borderTopRightRadius: 0,
          borderBottomRightRadius: theme.shape.borderRadius,
          borderBottomLeftRadius: theme.shape.borderRadius,
          '&:first-child': {
            borderBottomRightRadius: 0
          },
          '&:last-child': {
            borderLeftWidth: 1,
            borderLeftStyle: 'solid',
            borderLeftColor: theme.palette.grey[700],
            borderTopRightRadius: 0,
            borderBottomLeftRadius: 0
          }
        }
      }
    }
  }
}))
