import { useGetIntegrationByProviderName } from '@apis/customer-detail.service'
import {
  useGetAllFinancialInsuranceProductsByDealerCode,
  useGetFinancialInsuranceProductsByDealerId,
  useGetFnIProductRate
} from '@apis/financial-insurance.service'
import { IntegrationProvider, LenderIntegrationType } from '@helpers/enums'
import { useTheme } from '@mui/material'
import { DuoTab, Box } from '@ntpkunity/controls'
import { FC, useEffect, useState } from 'react'
import FiProductsTable from './../financial-insurance/individual-products/products-table'
import {
  FinanceInsuranceControlProps,
  FinanceLeaseItem,
  FniPriceState,
  ProductInfo,
  SumState,
  DealerInfo
} from './finance-insurance-types'
import { DialogTotal } from '@components'
import ProductsBundle from 'controls/financial-insurance/product-bundles/products-bundle'
import { useStoreContext } from '@store/storeContext'
import { roundToTwoDecimal } from '@app/desking/utils'

const FinanceInsuranceControl: FC<FinanceInsuranceControlProps> = ({
  dealerProfileData,
  defaultCurrency,
  fniData,
  setFniData,
  terms,
  apr,
  selectedFinanceType,
  selectedVehicle,
  company_id,
  isMSP = false
}) => {
  const theme = useTheme()
  const { states } = useStoreContext()
  const [products_info, setNewProductsInfo] = useState<ProductInfo[]>([])
  const isFinanceType = selectedFinanceType === 'Finance'
  const [activeTab, _] = useState<any>(isFinanceType ? 0 : 1)
  const [selectedFinanceItems, setSelectedFinanceItems] = useState<FinanceLeaseItem[]>([])
  const [selectedLeaseItems, setSelectedLeaseItems] = useState<FinanceLeaseItem[]>([])
  const [selectFANDIProductsList, setselectFANDIProductsList] = useState<FinanceLeaseItem[]>([])
  const [selectMSPProductsList, setselectMSPProductsList] = useState<FinanceLeaseItem[]>([])
  const [fniBundleChanges, setFniBundleChanges] = useState<
    Record<number, { removed: number[]; added: number[]; prices: number[] }>
  >({})
  const [addedItems, setAddedItems] = useState<any>([])
  const [dealerInfo, setDealerInfo] = useState<DealerInfo | undefined>(undefined)
  const [penEnabled, setPenEnabled] = useState<boolean>(false)
  const [columnFilters, setColumnFilters] = useState()
  const [filters, setFilters] = useState<any>()
  const [fniPrice, setFniPrice] = useState<FniPriceState>({ finance: [], lease: [] })
  const { mutate: getPenIntegrations } = useGetIntegrationByProviderName(
    IntegrationProvider.PEN,
    LenderIntegrationType.FNI_PRODUCTS
  )
  const { data: fniProducts } = useGetFinancialInsuranceProductsByDealerId({
    data: `dealer_code=${dealerProfileData?.dealer_code}&is_active=true&MSP=${isMSP}${
      columnFilters ? columnFilters : `&page_number=${0}&page_size=${5}`
    }`,
    company_id: company_id
  })
  const {
    mutate: getFinanceProductRates,
    data: financeProductRates,
    isLoading: loadingFinanceProductRates
  } = useGetFnIProductRate()
  const {
    mutate: getLeaseProductRates,
    data: leaseProductRates,
    isLoading: loadingLeaseProductRates
  } = useGetFnIProductRate()
  const { data: allFniProducts } = useGetAllFinancialInsuranceProductsByDealerCode(
    states?.dealerInfo?.dealer_code
  )

  useEffect(() => {
    if (fniProducts) {
      const fniProductsList = fniProducts?.result?.filter((x: { MSP: any }) => !x.MSP)
      const mspProductsList = fniProducts?.result?.filter((x: { MSP: any }) => x.MSP)
      setselectFANDIProductsList(fniProductsList)
      setselectMSPProductsList(mspProductsList)
    }
  }, [
    fniProducts,
    terms,
    activeTab,
    financeProductRates,
    leaseProductRates,
    selectedFinanceItems,
    selectedLeaseItems,
    terms
  ])

  const [sum, setSum] = useState<SumState>({
    finance: '0.00',
    lease: '0.00',
    fniFinance: '0.00',
    fniLease: '0.00',
    mspFinance: '0.00',
    mspLease: '0.00'
  })

  useEffect(() => {
    setFniPrice({
      finance: [],
      lease: []
    })
    setSelectedFinanceItems(fniData?.finance || [])
    setSelectedLeaseItems(fniData?.lease || [])

    const selectedArray = activeTab == 0 ? fniData?.finance : fniData?.lease
    selectedArray
      ?.filter((arrayItem: any) => !arrayItem?.bundle_id)
      ?.forEach((itemId: any) => {
        setAddedItems((prevAddedItems: any) => [
          ...prevAddedItems,
          itemId?.financial_insurance_id ?? itemId?.id
        ])
      })

    setSum({
      ...sum,
      finance: fniData?.finance?.length > 0 ? getTotal(fniData?.finance) : '0.00',
      lease: fniData?.lease?.length > 0 ? getTotal(fniData?.lease) : '0.00'
    })
  }, [])

  useEffect(() => {
    if (
      products_info?.length == allFniProducts?.length &&
      products_info?.length > 0 &&
      dealerProfileData
    ) {
      if (isFinanceType) {
        getFinanceProductRates({
          data: getRateRequestObject(products_info, 'Finance')
        })
      } else {
        getLeaseProductRates({
          data: getRateRequestObject(products_info, 'Lease')
        })
      }
    }
  }, [products_info, dealerProfileData])

  useEffect(() => {
    if (fniProducts) {
      const financeHasOrderIdField = fniData?.finance?.some((item) => 'order_id' in item)
      const leaseHasOrderIdField = fniData?.lease?.some((item) => 'order_id' in item)

      if (financeHasOrderIdField || leaseHasOrderIdField) {
        setSelectedFinanceItems(mapFnIArray(fniData?.finance || []))
        setSelectedLeaseItems(mapFnIArray(fniData?.lease || []))
      }
    }
  }, [fniProducts])

  const mapFnIArray = (array: any) => {
    const mappedArray = array?.map((priceItem: any) => {
      const otherProperties = fniProducts?.result?.find(
        (item: any) => item.id === priceItem.financial_insurance_id
      )

      const baseObject = otherProperties
        ? { ...otherProperties, form_id: priceItem?.form_id, rate_id: priceItem?.rate_id, surcharges: priceItem?.surcharges, price: priceItem?.applied_price }
        : { ...priceItem, price: priceItem?.applied_price }

      if (priceItem?.bundle_id) {
        baseObject.bundle_id = priceItem?.bundle_id
      }

      return baseObject
    })

    return mappedArray
  }

  useEffect(() => {
    getPenIntegrations(
      {
        provider_name: IntegrationProvider.PEN,
        integration_type: LenderIntegrationType.FNI_PRODUCTS
      },
      {
        onSuccess(response: any) {
          setPenEnabled(response?.is_active)
        }
      }
    )
  }, [])

  useEffect(() => {
    if (allFniProducts) {
      let productInfo: Array<any> = []
      allFniProducts?.map((obj: any) => {
        const newObj: any = {
          product_id: obj?.product_id,
          starting_mileage: 0,
          ending_mileage: 10000,
          filters: [{ term_months: `${terms}` }]
        }
        productInfo.push(newObj)
      })
      setNewProductsInfo(productInfo)
    }
  }, [allFniProducts])

  useEffect(() => {
    if (allFniProducts?.length > 0 && financeProductRates && isFinanceType) {
      applyRetailPrice(allFniProducts, financeProductRates)
    }
    if (fniProducts?.result?.length > 0 && financeProductRates && isFinanceType) {
      applyRetailPrice(fniProducts?.result, financeProductRates)
    }

    if (allFniProducts?.length > 0 && leaseProductRates && !isFinanceType) {
      applyRetailPrice(allFniProducts, leaseProductRates)
    }
    if (fniProducts?.result?.length > 0 && leaseProductRates && !isFinanceType) {
      applyRetailPrice(fniProducts?.result, leaseProductRates)
    }
  }, [allFniProducts, fniProducts, leaseProductRates, financeProductRates])

  const getRateRequestObject = (new_products_info: any, finance_type: string) => {
    const currentDate = new Date(Date.now()).toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    })
    const ratesRequestObject = {
      deal_info: {
        pen_dealer_id: dealerProfileData?.pen_dealer_id,
        vin: selectedVehicle?.vin,
        mileage:
          finance_type === 'Lease' && selectedVehicle?.mileage ? selectedVehicle.mileage : 24970,
        car_status: 'NEW',
        effective_date: currentDate,
        in_service_date: currentDate,
        finance_type: finance_type,
        vehicle_msrp: selectedVehicle?.msrp,
        vehicle_purchase_price: selectedVehicle?.internetPrice || 346445,
        deal_type: 'LOAN',
        finance_terms: [36],
        finance_terms_mileage: [24],
        apr,
        financed_amount: selectedVehicle?.financedAmount || 33718.28,
        language: 'ENGLISH',
        user_role: 'FI_MANAGER'
      },
      products_info: new_products_info,
      test_request: true
    }
    setDealerInfo(ratesRequestObject?.deal_info)
    return ratesRequestObject
  }
  const getTotal = (array: Array<any>, isMSPItem: boolean = false) => {
    if (!array?.length) return '0.00'

    const filteredArray = array.filter((obj) => {
      const isMSP = obj?.MSP ?? obj?.is_msp
      return isMSPItem ? isMSP && !obj?.bundle_id : obj?.bundle_id ? true : !isMSP
    })

    const sum = filteredArray.reduce((total, obj) => {
      const price = roundToTwoDecimal(obj?.price || 0)
      return total + price
    }, 0)

    return sum.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }

  useEffect(() => {
    if (isFinanceType) {
      const fniTotal = getTotal(selectedFinanceItems, false)
      const mspTotal = getTotal(selectedFinanceItems, true)
      const totalSum = (parseFloat(fniTotal) + parseFloat(mspTotal)).toFixed(2)

      setSum((prev) => ({
        ...prev,
        finance: totalSum,
        fniFinance: fniTotal,
        mspFinance: mspTotal
      }))
    }
  }, [selectedFinanceItems])

  useEffect(() => {
    if (!isFinanceType) {
      const fniTotal = getTotal(selectedLeaseItems, false)
      const mspTotal = getTotal(selectedLeaseItems, true)
      const totalSum = (parseFloat(fniTotal) + parseFloat(mspTotal)).toFixed(2)

      setSum((prev) => ({
        ...prev,
        lease: totalSum,
        fniLease: fniTotal,
        mspLease: mspTotal
      }))
    }
  }, [selectedLeaseItems])

  useEffect(() => {
    if (isFinanceType) {
      const updatedFinanceItems = selectedFinanceItems.map((item) => {
        const fniItem = fniPrice.finance.find((f) => f.id === item.id)
        return {
          ...item,
          mileage: fniItem ? fniItem.mileage : 999999,
          term: fniItem ? fniItem.term : terms
        }
      })

      const fniTotal = getTotal(updatedFinanceItems, false)
      const mspTotal = getTotal(updatedFinanceItems, true)
      const totalSum = (parseFloat(fniTotal) + parseFloat(mspTotal)).toFixed(2)

      setSum((prev) => ({
        ...prev,
        finance: totalSum,
        fniFinance: fniTotal,
        mspFinance: mspTotal
      }))

      setFniData((prev: any) => ({
        ...prev,
        finance: updatedFinanceItems
      }))
    }
  }, [selectedFinanceItems, fniPrice])

  useEffect(() => {
    if (!isFinanceType) {
      const updatedLeaseItems = selectedLeaseItems.map((item) => {
        const fniItem = fniPrice.lease.find((l) => l.id === item.id)
        return {
          ...item,
          mileage: fniItem ? fniItem.mileage : 999999,
          term: fniItem ? fniItem.term : terms
        }
      })

      const fniTotal = getTotal(updatedLeaseItems, false)
      const mspTotal = getTotal(updatedLeaseItems, true)
      const totalSum = (parseFloat(fniTotal) + parseFloat(mspTotal)).toFixed(2)

      setSum((prev) => ({
        ...prev,
        lease: totalSum,
        fniLease: fniTotal,
        mspLease: mspTotal
      }))

      setFniData((prev: any) => ({
        ...prev,
        lease: updatedLeaseItems
      }))
    }
  }, [selectedLeaseItems, fniPrice])

  function applyRetailPrice(fniProduct: any, fniRates: any) {
    const rates = fniRates?.rates?.rate
    return fniProduct?.map((product: any) => {
      let smallestRetailPrice = Infinity
      let rate_id = ''
      let form_id = ''
      let term_months = ''
      let term_miles = ''
      let coverage_name = ''
      let session_id = ''
      const normalizedRates = Array.isArray(rates) ? rates : [rates]
      const matchingRate = normalizedRates?.find(
        (rate: any) => rate?.product_id === product?.product_id
      )
      if (matchingRate) {
        let coverages = matchingRate?.coverages?.coverage
        if (Array.isArray(coverages)) {
          coverages = coverages.flat()
          coverages?.forEach((coverage: any) => {
            if (Array.isArray(coverage.deductibles.deductible)) {
              coverage.deductibles.deductible.forEach((deductible: any) => {
                const retailPrice = deductible?.retail_price
                if (retailPrice < smallestRetailPrice) {
                  smallestRetailPrice = retailPrice
                  rate_id = coverage?.deductibles?.deductible?.rate_id
                  form_id = coverage?.form?.form_id
                  term_months = coverage?.term_months
                  term_miles = coverage?.term_miles
                  coverage_name = coverage?.coverage_name
                  session_id = matchingRate?.session_id
                }
              })
            } else {
              const retailPrice = coverage?.deductibles?.deductible?.retail_price
              if (retailPrice < smallestRetailPrice) {
                smallestRetailPrice = retailPrice
                rate_id = coverage?.deductibles?.deductible?.rate_id
                form_id = coverage?.form?.form_id
                term_months = coverage?.term_months
                term_miles = coverage?.term_miles
                coverage_name = coverage?.coverage_name
                session_id = matchingRate?.session_id
              }
            }
          })
        } else {
          smallestRetailPrice =
            matchingRate?.coverages?.coverage?.deductibles?.deductible?.retail_price
          rate_id = matchingRate?.coverages?.coverage?.deductibles?.deductible?.rate_id
          form_id = matchingRate?.coverages?.coverage?.form?.form_id
          term_months = matchingRate?.coverages?.coverage?.term_months
          term_miles = matchingRate?.coverages?.coverage?.term_miles
          coverage_name = matchingRate?.coverages?.coverage?.coverage_name
          session_id = matchingRate?.session_id
          // deductiblePrice = matchingRate?.coverages?.coverage?.deductibles?.deductible?.retail_price
        }
      }
      // If we found a valid smallest retail price, append it to the product
      if (smallestRetailPrice !== Infinity) {
        const leaseSelectedProduct = selectedLeaseItems?.find(
          (item) =>
            item.product_id === product?.product_id && item?.bundle_id === product?.bundle_id
        )
        const financeSelectedProduct = selectedFinanceItems?.find(
          (item) =>
            item.product_id === product?.product_id && item?.bundle_id === product?.bundle_id
        )

        const selectedProductCoverage =
          leaseSelectedProduct?.coverage_name ?? financeSelectedProduct?.coverage_name
        const selectedProductPrice = leaseSelectedProduct?.price ?? financeSelectedProduct?.price
        const selectedSurcharges =
          leaseSelectedProduct?.surcharges ?? financeSelectedProduct?.surcharges
        const deductablePrice =
          leaseSelectedProduct?.deductiblePrice ?? financeSelectedProduct?.deductiblePrice
        const selectedRateId = leaseSelectedProduct?.rate_id ?? financeSelectedProduct?.rate_id
        const selectedFormID = leaseSelectedProduct?.form_id ?? financeSelectedProduct?.form_id
        product.rate_id = selectedRateId ?? rate_id
        product.form_id = selectedFormID ?? form_id
        product.session_id = session_id
        product.term_months = term_months
        product.term_miles = term_miles
        product.deductiblePrice = deductablePrice ?? smallestRetailPrice
        product.pen_dealer_id = dealerProfileData?.pen_dealer_id
        product.coverage_name = selectedProductCoverage ?? coverage_name
        product.surcharges = selectedSurcharges
        product.price =
          selectedProductPrice ??
          (smallestRetailPrice !== undefined && smallestRetailPrice !== null
            ? smallestRetailPrice +
              ((product?.bundle_markup ?? product?.markup) / 100) * smallestRetailPrice
            : '')
      }
      return product
    })
  }

  return (
    <>
      {penEnabled && !loadingFinanceProductRates && !loadingLeaseProductRates ? (
        <>
          {!isMSP && (
            <>
              <DuoTab
                theme={theme}
                varient={'underline'}
                items={[
                  {
                    title: 'Individual Products',
                    content: (
                      <FiProductsTable
                        isMSP={isMSP}
                        activeTab={activeTab}
                        setSelectedLeaseItems={setSelectedLeaseItems}
                        setSelectedFinanceItems={setSelectedFinanceItems}
                        selectedFinanceItems={selectedFinanceItems}
                        selectedLeaseItems={selectedLeaseItems}
                        defaultCurrency={defaultCurrency}
                        terms={terms}
                        data={selectFANDIProductsList}
                        setColumnFilters={setColumnFilters}
                        filters={filters}
                        setFniPrice={setFniPrice}
                        fniPrice={fniPrice}
                        addedItems={addedItems}
                        setAddedItems={setAddedItems}
                        paginationData={fniProducts}
                        setFilters={setFilters}
                        dealerInfo={dealerInfo}
                      />
                    )
                  },
                  {
                    title: 'Product Bundles',
                    content: (
                      <ProductsBundle
                        defaultCurrency={defaultCurrency}
                        isFinanceType={isFinanceType}
                        terms={terms}
                        allFniProducts={allFniProducts}
                        financeProductRates={financeProductRates}
                        leaseProductRates={leaseProductRates}
                        setSelectedLeaseItems={setSelectedLeaseItems}
                        setSelectedFinanceItems={setSelectedFinanceItems}
                        selectedFinanceItems={selectedFinanceItems}
                        selectedLeaseItems={selectedLeaseItems}
                        applyRetailPrice={applyRetailPrice}
                        dealerInfo={dealerInfo}
                        fniBundleChanges={fniBundleChanges}
                        setFniBundleChanges={setFniBundleChanges}
                      />
                    )
                  }
                ]}
                defaultTabIndex={0}
              />
              <DialogTotal
                theme={theme}
                className="dialog-total"
                display={'flex'}
                gap={2}
                justifyContent={'space-between'}
              >
                <Box theme={theme} className="fw-700">
                  Total
                </Box>
                <Box theme={theme} className="primary" textAlign="right">
                  {defaultCurrency}
                  {activeTab == 0 ? sum?.fniFinance : sum?.fniLease}
                </Box>
              </DialogTotal>
            </>
          )}
          {isMSP && (
            <>
              <DuoTab
                theme={theme}
                varient={'underline'}
                items={[
                  {
                    title: 'Individual Products',
                    content: (
                      <FiProductsTable
                        isMSP={isMSP}
                        activeTab={activeTab}
                        setSelectedLeaseItems={setSelectedLeaseItems}
                        setSelectedFinanceItems={setSelectedFinanceItems}
                        selectedFinanceItems={selectedFinanceItems}
                        selectedLeaseItems={selectedLeaseItems}
                        defaultCurrency={defaultCurrency}
                        terms={terms}
                        data={selectMSPProductsList}
                        setColumnFilters={setColumnFilters}
                        filters={filters}
                        setFniPrice={setFniPrice}
                        fniPrice={fniPrice}
                        addedItems={addedItems}
                        setAddedItems={setAddedItems}
                        paginationData={fniProducts}
                        setFilters={setFilters}
                        dealerInfo={dealerInfo}
                      />
                    )
                  }
                ]}
                defaultTabIndex={0}
              />
              <DialogTotal
                theme={theme}
                className="dialog-total"
                display={'flex'}
                gap={2}
                justifyContent={'space-between'}
              >
                <Box theme={theme} className="fw-700">
                  Total
                </Box>
                <Box theme={theme} className="primary" textAlign="right">
                  {defaultCurrency}
                  {activeTab == 0 ? sum?.mspFinance : sum?.mspLease}
                </Box>
              </DialogTotal>
            </>
          )}
        </>
      ) : (
        ''
      )}
    </>
  )
}

export default FinanceInsuranceControl
